export class BELoaderHolder {
    static beLoader:any;
  
    static setLoader(beLoader:any) {
        this.beLoader = beLoader; 
    }
  
    static getLoader() {
        return this.beLoader;
    }
  }