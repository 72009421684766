import React, { useState } from 'react';
import { View } from "react-native"
import { PlayerPremiumItem, PremiumItemProps } from '../store/types/auth_svc';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Text, TouchableOpacity } from './NativeOverrides';
import Colors from '../constants/Colorsv2';
import { Icon } from 'react-native-elements';
import { navigate } from '../navigation/RootNavigation';
import { getIconByFeature } from '../screensV1/Auth/PremiumScreen';
import { player_purchasePremiumItem } from '../store/actionsv1/player';

type ALaCartePremiumProps = {
    identifier: string
}
const ALaCartePremium = ({ identifier }:ALaCartePremiumProps) => {
    const [ expanded, setExpanded ] = useState(false);
    const [ purchase_loading, setPurchaseLoading ] = useState(false);
    const [ selected_price, setSelectedPrice ] = useState<'day'|'month'|undefined>(undefined);
    const [ a_la_carte_disclaimer, setALaCarteDiscliamer ] = useState(false);

    const dispatch = useDispatch();
    const player = useSelector((state:any) => state.player.player, (left, right) => left?.updated == right?.updated);
    const premium_item:PremiumItemProps | undefined = useSelector((state:any) => state.player.premium_items.find(pi => pi.identifier == identifier), (left, right) => left?.premium_item_id == right?.premium_item_id);
    const player_premium_item:PlayerPremiumItem|undefined = useSelector((state:any) => state.player.player_premium_items_list.player_premium_items.find(ppi => ppi.premium_item_id == premium_item?.premium_item_id && ppi.status == 'active' && moment().isBefore(ppi.expire_datetime)), (left, right) => left?.player_premium_item_id == right?.player_premium_item_id);
    
    const handlePurchasePremiumItem = async() => {
        if(!selected_price){ return } //Must include a selected price
        if(purchase_loading){ return }//No mashy mashy
        if(!premium_item){ return }
        if(player_premium_item){ return }//Already have this one
        setPurchaseLoading(true);
        await dispatch(player_purchasePremiumItem(premium_item.premium_item_id, selected_price))
        setPurchaseLoading(false)
    }


    //Never render this if the player is already premium or if they are not logged in
    if(!player || player.type == 'premium' || !premium_item){ return <></> }
    if(!premium_item.a_la_carte){ return <></> }//This is not an a la carte item

    if(player_premium_item){ return <></> } //We already have this!
    return (
        <View style={{ backgroundColor:Colors.incentive.gold_faded, padding:10, borderRadius:4 }}>
            <TouchableOpacity native_id={`a_la_cart_${identifier}`} style={{ flexDirection:'row', alignItems:'center' }} onPress={() => setExpanded(!expanded)}>
                <View style={{ padding:5 }}>
                    {getIconByFeature(premium_item)}
                </View>
                <View style={{ flex:1, marginLeft:10 }}>
                    <Text size={14} color={Colors.brand.cobalt} weight='bold'>Unlock Premium Item</Text>
                    <Text size={14} color={Colors.brand.midnight}>{premium_item.name}</Text>
                </View>
                <Icon style={{ padding:10 }} type='feather' name={expanded?'chevron-up':'chevron-down'} color={Colors.brand.cobalt} size={16} />
            </TouchableOpacity>
            {expanded ?
            <View>
                <Text style={{ padding:10 }} size={14} color={Colors.brand.midnight}>{premium_item.description}</Text>
                {selected_price ?
                <View style={{ backgroundColor:Colors.shades.white, padding:10, borderRadius:4 }}>
                    <View style={{ flexDirection:'row', marginTop:5 }}>
                        <TouchableOpacity style={{ flex:1, borderRadius:8, padding:10, backgroundColor:Colors.utility.error, marginRight:2 }} onPress={() => {
                            setSelectedPrice(undefined)
                            setALaCarteDiscliamer(false);
                        }}>
                            <Text size={12} color={Colors.shades.white} textAlign='center' weight='semibold'>Cancel</Text>
                        </TouchableOpacity>
                        <TouchableOpacity disabled={a_la_carte_disclaimer?false:true} style={{ flex:2, opacity:a_la_carte_disclaimer?1:0.5, borderRadius:8, padding:10, backgroundColor:Colors.utility.success, marginLeft:2, marginRight:2 }} onPress={() => handlePurchasePremiumItem()}>
                            <Text size={12} color={Colors.shades.white} textAlign='center' weight='semibold'>Confirm ${selected_price == 'day' ? premium_item.daily_price:premium_item.monthly_price}</Text>
                        </TouchableOpacity>
                    </View>
                    <TouchableOpacity style={{ flexDirection:'row', marginTop:10 }} onPress={() => setALaCarteDiscliamer(!a_la_carte_disclaimer)}>
                        <View style={{ marginRight:5, height:20, width:20, borderRadius:4, borderWidth:2, borderColor:Colors.brand.midnight, justifyContent:'center', alignItems:'center' }}>
                            {a_la_carte_disclaimer ?
                            <View style={{ height:10, width:10, backgroundColor:Colors.brand.midnight }} />
                            :<></>}
                        </View>
                        <Text size={14} color={Colors.brand.midnight} weight='semibold'>I understand this feature will be unlocked for the next {selected_price==='day'?'24 hours':'month'} and ${selected_price == 'day' ? premium_item.daily_price : premium_item.monthly_price} will be deducted from my wallet immediately.</Text>
                    </TouchableOpacity>
                </View>
                :
                <View style={{ backgroundColor:Colors.shades.white, padding:10, borderRadius:4 }}>
                    <Text size={14} color={Colors.incentive.gold} weight='bold' textAlign='left'>Unlock Options</Text>
                    <View style={{ flexDirection:'row', marginTop:5 }}>
                        <TouchableOpacity style={{ flex:1, borderRadius:8, padding:10, backgroundColor:Colors.incentive.gold, marginRight:2 }} onPress={() => navigate('AuthStack', { screen: 'Premium' })}>
                            <Text size={12} color={Colors.shades.white} textAlign='center' weight='semibold'>VIEW PREMIUM</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flex:1, borderRadius:8, padding:10, backgroundColor:Colors.brand.midnight, marginLeft:2, marginRight:2 }} onPress={() => setSelectedPrice('day')}>
                            <Text size={12} color={Colors.shades.white} textAlign='center' weight='semibold'>${premium_item.daily_price} - 24 Hours</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flex:1, borderRadius:8, padding:10, backgroundColor:Colors.brand.midnight, marginLeft:2 }} onPress={() => setSelectedPrice('month')}>
                            <Text size={12} color={Colors.shades.white} textAlign='center' weight='semibold'>${premium_item.monthly_price} - 1 Month</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                }
            </View>
            :<></>}
        </View>
    )
}

export default ALaCartePremium