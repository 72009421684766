//Import Dropdown holder
import store from '../store';
import { V1_LOAD_ALERT } from './actionsv1/types';


export const errHandler = (error) => {
    try {
      if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          store.dispatch({ type: V1_LOAD_ALERT, alert: { title:'Error', type:'error', body:error.response.data.message } })
  
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          store.dispatch({ type: V1_LOAD_ALERT, alert: { title:'Error', type:'error', body:error.toString() } })
        } else {
          // Something happened in setting up the request that triggered an Error
          store.dispatch({ type: V1_LOAD_ALERT, alert: { title:'Error', type:'error', body:error.message } })
        }
    } catch (error) {
        console.log(error)
    }
}