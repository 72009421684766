import moment from 'moment';
import React, { useEffect } from 'react';
import { View } from "react-native"
import { Icon } from "react-native-elements"
import Colors from "../../../constants/Colorsv2"
import { Text } from "../../../globalComponents/NativeOverrides"
import { EventProps, LeagueProps } from "../../../store/types/sr_svc"
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { V1_UPDATE_HELMET } from '../../../store/actionsv1/types';

type EventInfoProps = {
    event: EventProps,
    hide_info?:boolean,
    league?: LeagueProps
}
const EventInfoCard = ({event, hide_info, league}:EventInfoProps) => {
    const { info } = event;
    let time_detail = event.time_detail
    if(['scheduled','created','not_started'].includes(time_detail??'')){
        time_detail = moment(event.scheduled_datetime).format('MM/DD @ hh:mm a')
    }
    
    return (
        <View>
            {hide_info ?
            <View>
                <Text size={16} color={Colors.shades.black} weight='semibold' textAlign='center'>{time_detail}</Text>
            </View>
            :<></>}
            {!hide_info && league?.league_name ?
            <View style={{ marginTop:12, borderRadius:2, backgroundColor:Colors.brand.cyan, padding:3, paddingRight:8, paddingLeft:8, alignSelf:'center' }}>
                <Text size={10} weight='semibold' color={Colors.shades.white} textAlign='center'>{league.league_name}</Text>
            </View>
            :<></>}
            <View nativeID='team-header' style={{flexDirection:'row', alignItems:'center', marginTop:8}}>
                <Text size={16} color={Colors.shades.black} weight='bold' textAlign='right' style={{flex:1/2}}>{event.away.market_name} {event.away.name} - {event.away_team_score}</Text>
                <Text size={12} color={Colors.brand.slate} weight='regular' textAlign='center' style={{flex:1/10}}>VS</Text>
                <Text size={16} color={Colors.shades.black} weight='bold' textAlign='left' style={{flex:1/2}}>{event.home_team_score} - {event.home.market_name} {event.home.name}</Text>
            </View>
            {!hide_info ?
            <View>
                <View nativeID='info-1' style={{flexDirection:'row', alignItems:'center', marginTop:8}}>
                    <View style={{flexDirection:'row', flex:1/2, alignItems:'center', justifyContent:'flex-end', marginRight:10}}>
                        <Text size={14} color={Colors.brand.slate} weight='regular' textAlign='right'>{time_detail}</Text>
                        <Icon containerStyle={{marginLeft:10}} name='calendar' size={14} color={Colors.brand.slate} type='feather' />
                    </View>
                    <View style={{flexDirection:'row', flex:1/2, alignItems:'center', justifyContent:'flex-start', marginLeft:10}}>
                        <Icon containerStyle={{marginRight:10}} name='cloud-rain' size={14} color={Colors.brand.slate} type='feather' />
                        <Text size={14} color={Colors.brand.slate} weight='regular' textAlign='left'>{info?.weather?.condition??'TBD'}</Text>
                    </View>
                </View> 
                <View nativeID='info-2' style={{flexDirection:'row', alignItems:'center', marginTop:8}}>
                    <View style={{flexDirection:'row', flex:1/2, alignItems:'center', justifyContent:'flex-end', marginRight:10}}>
                        <Text size={14} color={Colors.brand.slate} weight='regular' textAlign='right'>{info?.broadcast?.network??'TBD'}</Text>
                        <Icon containerStyle={{marginLeft:10}} name='tv' size={14} color={Colors.brand.slate} type='feather' />
                    </View>
                    <View style={{flexDirection:'row', flex:1/2, alignItems:'center', justifyContent:'flex-start', marginLeft:10}}>
                        <Icon containerStyle={{marginRight:10}} name='map' size={14} color={Colors.brand.slate} type='feather' />
                        <Text size={14} color={Colors.brand.slate} weight='regular' textAlign='left'>{info?.venue?.name??'TBD'}</Text>
                    </View>
                </View>
            </View>
            :<></>}
        </View>
    )
}

export default EventInfoCard