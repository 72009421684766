import moment from "moment";
import { BestAvailableOrderProps, HedgedPositionProps, MarketProps, OrderActivityProps, OrderProps, PlayerFilterProps, PositionProps } from "../../../store/types/mk_svc";
import { AthleteProps, EventProps, ExoticProps, MatchProps, TeamProps, TournamentProps } from "../../../store/types/sr_svc";
import { market_getOddsLabel, market_getVar1Label } from "./markets";


export const order_isOrderValid = (order:OrderProps, market?:MarketProps):string[] => {
    let errors:string[] = []
    if(isNaN(order.open_amt)){ errors.push('Quantity is not valid') }
    if(order.open_amt < 1){ errors.push('Order quantity must be greater than 1') }
    if(isNaN(order.var_1)){ errors.push('Order is not valid') }
    if(order.var_1 % 0.5 !== 0){ errors.push('Order is not valid') } 
    if(order.probability >= 1 || order.probability <= 0){ errors.push('Probability is not valid') }
    if(isNaN(order.odds) || isNaN(order.probability)){ errors.push('Odds are not valid') }
    if(order.odds < 100 && order.odds > -100){ errors.push('Invalid odds amount') }
    const prob = order_calcProbabilityFromOdds(order.odds)
    if(Math.abs(prob - order.probability) > 0.01){ errors.push('Odds and probability do not match') }
    if(market && market.type === 'Spread' && order.var_1 === 0){ errors.push('Please use a Winner Market for a Spread of 0') }
    if(market && market.type === 'Total' && order.var_1 <= 0){ errors.push('Total must be greater than 0') }
    if(market && market.var_1_required && market.type === 'Stat' && order.var_1 <= 0){ errors.push('Stat total must be greater than 0') }
    return errors
}


export const order_getOddsFromProbability = (probability:number):number => {
    let p = probability*100; //Convert to whole number
    if (p === 100){ return -9999 }
    if(p === 0){ return 9999 }
    if(p === 50){ return 100 }
    let odds;
    if(p < 50){
        odds = ((100/(p/100))-100)
        return odds
    }
    odds = ((p/(1-(p/100)))*-1)
    return odds
}

export const order_getCheapestOrders = (orders:OrderProps[], market:MarketProps) => {
    let above_1_orders = orders.filter(o => o.open_amt >= 1)
    let trade_side_orders = above_1_orders.filter(o => o.side === market.trade_side)
    let other_side_orders = above_1_orders.filter(o => o.side !== market.trade_side)
    let trade_side_cheapest = trade_side_orders.sort((a,b) => a.probability - b.probability)[0]
    let other_side_cheapest = other_side_orders.sort((a,b) => a.probability - b.probability)[0]
    return { other_side_cheapest, trade_side_cheapest }
}


export const order_getUniqueVar1 = (orders:OrderProps[], market:MarketProps) => {
    let unique_var1s:number[] = [ ...new Set(orders.map(o => o.var_1)) ]
    if(market.type === 'Spread'){
        unique_var1s = [ ...new Set(orders.map(o =>  { if(o.side !== market.trade_side){ return o.var_1 * -1 } return o.var_1 }))]
    }
    return unique_var1s
}

export const order_getOrdersFromUniqueVar1 = (orders:OrderProps[], var_1:number, market:MarketProps) => {
    let filtered_orders = orders.filter(o => {
        if(market.type !== 'Spread' && o.var_1 == var_1){ return o }
        if(market.type === 'Spread' && o.side === market.trade_side && o.var_1 == var_1){ return o }
        if(market.type === 'Spread' && o.side !== market.trade_side && o.var_1 * -1 == var_1){ return o }
    })
    return filtered_orders
}


export const order_getBestAvailVar1 = (market:MarketProps, bas:BestAvailableOrderProps[], target:number):{ trade_side_avail?:BestAvailableOrderProps, o_side_avail?:BestAvailableOrderProps } => {
    if(bas.length == 0){ return {} }
    console.log('getting best avail!')
    let unique_var1s = [ ...new Set(bas.map(a => `${a.market_id}:${Math.abs(a.var_1)}`)) ]
    console.log(unique_var1s)
    let closest_var1:string|undefined = undefined;
    let closest_single_to_target:BestAvailableOrderProps | undefined = undefined
    let closest_gap = 1
    unique_var1s.map(uvar1 => {
        let var_1 = uvar1.split(':')[1]
        let trade_side_order = bas.find(a => a.side == market.trade_side && Math.abs(a.var_1) == var_1)
        let o_side_order = bas.find(a => a.side != market.trade_side && Math.abs(a.var_1) == var_1)
        if(trade_side_order && o_side_order){
            //Ok we have both orders
            let gap = 1 - (trade_side_order.probability + o_side_order.probability)
            if(gap < closest_gap){
                closest_gap = gap
                closest_var1 = uvar1
            }
        } else {
            let order = trade_side_order ?? o_side_order
            if(!order){ return }
            if(!closest_single_to_target){ closest_single_to_target = order }
            else {
                let curr_diff = Math.abs(closest_single_to_target.probability - target)
                let new_diff = Math.abs(order.probability - target)
                if(new_diff < curr_diff){
                    closest_single_to_target = order
                }
            }
        }
    })

    console.log(closest_var1)

    return {};
}


export const order_getOrderTitleForTeamEvent = (order:OrderProps, market:MarketProps, event:EventProps, athlete?:AthleteProps, exotic?:ExoticProps):string => {
    let market_side = market.side_options.find(o => o.side == order.side)
    if(!market_side){ return order.side }
    var re = /({.*?})/;
    let parsed_text = market_side.parseable_title.split(re)
    let new_title = '';
    parsed_text.map(t => {
        if(t === ''){ return }
        if(t[0] !== '{'){ return new_title += t }
        let variable = t.slice(1)
        variable = variable.slice(0, variable.length -1)
        switch(variable){
            case 'team': 
                let team = event[order.side as keyof EventProps]
                return new_title += team.market_name
            case 'var_1': return new_title += market_getVar1Label(order.var_1, market)
            case 'athlete': return new_title += athlete?.abbr_name
            case 'stat': return new_title += market.stat_label ?? market.stat
            case 'exotic': return new_title += exotic?.name 
            default: return

        }
    })
    return new_title
}


export const order_getOrderTitleForTournament = (order:OrderProps, market:MarketProps, tournament:TournamentProps, athlete?:AthleteProps, team?:TeamProps):string => {
    let market_side = market.side_options.find(o => o.side == order.side)
    if(!market_side){ return order.side }
    var re = /({.*?})/;
    let parsed_text = market_side.parseable_title.split(re)
    let new_title = '';
    parsed_text.map(t => {
        if(t === ''){ return }
        if(t[0] !== '{'){ return new_title += t }
        let variable = t.slice(1)
        variable = variable.slice(0, variable.length -1)
        switch(variable){
            case 'team': return new_title += `${team?.market_name} ${team?.name}`
            case 'var_1': return new_title += market_getVar1Label(order.var_1, market)
            case 'athlete': return new_title += athlete?.abbr_name
            case 'stat': return new_title += market.stat 
            default: return

        }
    })
    return new_title
}

export const order_getOrderTitleForMatch = (order:OrderProps, market:MarketProps, match:MatchProps, athlete?:AthleteProps, team?:TeamProps):string => {
    let market_side = market.side_options.find(o => o.side == order.side)
    if(!market_side){ return order.side }
    var re = /({.*?})/;
    let parsed_text = market_side.parseable_title.split(re)
    let new_title = '';
    parsed_text.map(t => {
        if(t === ''){ return }
        if(t[0] !== '{'){ return new_title += t }
        let variable = t.slice(1)
        variable = variable.slice(0, variable.length -1)
        switch(variable){
            case 'team': return new_title += `${team?.market_name} ${team?.name}`
            case 'var_1': return new_title += market_getVar1Label(order.var_1, market)
            case 'athlete': return new_title += athlete?.abbr_name
            case 'stat': return new_title += market.stat 
            default: return

        }
    })
    return new_title
}



export const order_getOrderFromPositionSale = (order:OrderProps, position:PositionProps, probability?:number, open_amt?:number):OrderProps => {
    let prob = position.probability
    if(probability){
        prob = probability
    }
    let odds = order_calcAmericanOddsFromProbability(prob);

    let open = Math.floor(position.stake * 100) / 100;
    if(open_amt){
        open = open_amt
    }

    return {
        ...order,
        influencer_id: undefined,
        influence_type: undefined,
        tip_pct: undefined,
        tip_processed: undefined,
        buy_sell_ind: 'sell',
        status: 'approved',
        selling_position_id: position.position_id,
        odds: odds,
        probability: prob,
        open_amt: open,
        potential_winnings: order_calcPotentialWinnings(odds, open),
        expire_datetime: moment().add(1, 'hours')
    }
}


export const order_calcSaleValue = (odds:number, potential_winnings:number) => {
        let cash;
        if (odds > 0) {
            var earningsMultiplyer = (odds/100) + 1;
            cash = potential_winnings / earningsMultiplyer
        } else {
            var earningsMultiplyer = (100/(Math.abs(odds))) + 1;
            cash = potential_winnings / earningsMultiplyer
        }
        return cash
}


export const order_calcProbabilityFromOdds = (odds:number) => {
    let prob;
    if(odds < 0) {
        odds = Math.abs(odds)
        prob = odds / (odds + 100) * 100
        return prob / 100
    } else {
        prob = 100 / (odds + 100) * 100
        return prob / 100
    }
}


export const order_calcPotentialWinnings = (odds:number, amt:number) => {
    let potential_winnings;
    if (odds > 0) {
        var earningsMultiplyer = (odds/100) + 1;
        potential_winnings = Math.round(earningsMultiplyer * amt * 100) / 100;
    } else {
        var earningsMultiplyer = (100/(Math.abs(odds))) + 1;
        potential_winnings = Math.round(earningsMultiplyer * amt * 100) / 100;
    }
    return potential_winnings
}


export const order_calcAmericanOddsFromProbability = (probability:number) => {
    let p = probability*100; //Convert to whole number
    if (p === 100){ return -9999 }
    if(p === 0){ return 9999 }
    if(p === 50){ return 100 }
    let odds;
    if(p < 50){
        odds = ((100/(p/100))-100)
        return odds
    }
    odds = ((p/(1-(p/100)))*-1)
    return odds
}



export const order_getOrderActivities = (order?:OrderProps):OrderActivityProps[] => {
    if(!order){ return [] }
    const cl = order.market_type === 'FREE' ? 'E' : '$'
    const type = order.buy_sell_ind === 'buy'? 'Buy' : 'Sell'
    let activities:OrderActivityProps[] = []
    let create_activity:OrderActivityProps = {
        activity_datetime: order.create_datetime,
        activity_type:'create',
        activity_description: `${type} order created for ${cl}${(order.open_amt+order.called_amt).toFixed(2)}`
    }
    activities.push(create_activity)

    if(order.positions && order.positions[0] && order.positions.length > 0) { 
        order.positions.map(p => {
            let trade_activity:OrderActivityProps = {
                activity_datetime: p.create_datetime,
                activity_type: 'trade',
                activity_description: `${cl}${p.original_stake.toFixed(2)} of order fulfilled at ${(p.probability*100).toFixed(2)}% (${market_getOddsLabel(p.odds)})`
            }
            activities.push(trade_activity)
        })
    }

    if(order.status === 'cancelled'){
        let cancel_activity:OrderActivityProps = {
            activity_datetime: order.last_update_datetime,
            activity_type: 'cancel',
            activity_description: `Remaining ${cl}${order.open_amt.toFixed(2)} ${order.cancel_type === 'expired' ? 'expired' : 'cancelled'} and returned to your wallet`
        }
        activities.push(cancel_activity)
    }

    if(order.positions && order.positions.length > 0 && order.resolution_status === 'closed'){
        let winnings = order.positions.reduce((a,b) => a + b.winnings, 0)
        let result_ind = order.positions[0].result_ind

        let final_activity:OrderActivityProps = {
            activity_type: 'resolve',
            activity_datetime: order.positions[0].last_update_datetime,
            activity_description: `Positions finalized resulting in a ${result_ind} and winnings of ${cl}${winnings.toFixed(2)}`
        }
        activities.push(final_activity)
    }

    if(order.status === 'approved'){
        let pending_activity:OrderActivityProps = {
            activity_type: 'pending',
            activity_datetime: moment().format('YYYY-MM-DD hh:mm a'),
            activity_description: `${cl}${order.open_amt.toFixed(2)} is active and awaiting fulfillment`
        }
        activities.push(pending_activity)
    }
    return activities
}




export const order_getEmptyFilter = ():PlayerFilterProps => {
    return {
        player_filter_id: '',
        player_id: '',
        create_datetime: '',
        last_update_datetime: '',
        filter: {
            live_only: false,
            following_only: false,
            market_ids: [],
            market_types: 'event',
            league_ids:[],
            order_id:undefined,
            external_book:undefined,
            player_ids: [],
            team_ids:[],
            athlete_ids: [],
            market_type: 'FOR_MONEY',
            minimum_grade: 0
        },
        pinned: false,
        name: '',
        status: 'active'
    }
}


export const order_getHedgedPositionsFromOrder = (order:OrderProps):HedgedPositionProps[] => {
    let hedged_positions:HedgedPositionProps[] = []
    if(!order.positions || order.positions.length === 0){ return hedged_positions }
    order.positions.map(p => {
        if(!p){ return }
        if(p.hedged_positions && p.hedged_positions[0]){ hedged_positions = hedged_positions.concat(p.hedged_positions) }
    })
    return hedged_positions
}