/*****************************status: 
* environment.js
* path: '/environment.js' (root of your project)
******************************/
import Constants from "expo-constants";

const ENV = {

 dev: {
    APP_VERSION: '2.0.0',
    FACEBOOK_APP_ID: '718748612160500',
    BASE_WEB_URL: 'http://10.198.14.78:19006',
    ENVIRONMENT: 'DEVELOPMENT',
    REACT_APP_MK_URL : 'http://10.198.14.78:3000/mk',
    REACT_APP_MK_URL_DIRECT: 'http://10.198.14.78:3001/mk',
    REACT_APP_AUTH_URL : 'http://10.198.14.78:3000/auth',
    REACT_APP_AUTH_URL_DIRECT : 'http://10.198.14.78:5000/auth',
    AUTH_SVC_API: 'http://10.198.14.78:5000',
    SOC_SVC_API: 'http://10.198.14.78:3011',
    SR_SVC_API: 'http://10.198.14.78:3002',
    MK_SVC_API: 'http://10.198.14.78:3001',
    TP_SVC_API: 'http://10.198.14.78:3006',
    WS_SVC_API: 'ws://10.198.14.78:3000',
    WALLET_SVC_API: 'http://10.198.14.78:3012',
    ANALYTICS_SVC_API: 'http://10.198.14.78:3013',
    REACT_APP_SR_WS : 'ws://10.198.14.78:3000/sr',
    REACT_APP_SR_URL : 'http://10.198.14.78:3000/sr',
    REACT_APP_SR_URL_DIRECT : 'http://10.198.14.78:3002/sr',
    REACT_APP_MK_WS : 'ws://10.198.14.78:3000/mk',
    REACT_APP_AUTH_WS : 'ws://10.198.14.78:3000/auth',
    REACT_APP_PLAY_URL : 'http://10.198.14.78:3000/player',
    REACT_APP_SOCIAL_URL : 'http://10.198.14.78:3000/social',
    REACT_APP_SOCIAL_WS : 'ws://10.198.14.78:3000/social',
    REACT_APP_SOCIAL_URL_DIRECT : 'http://10.198.14.78:3011/social',
    REACT_APP_TP_URL : 'http://10.198.14.78:3000/tp',
    REACT_APP_TP_URL_DIRECT : 'http://10.198.14.78:3006/tp',
    REACT_APP_PAY_URL : 'http://10.198.14.78:3012/pay',
    REACT_APP_FUT_URL : 'http://10.198.14.78:3000',
    REACT_APP_FUT_URL_DIRECT : 'http://10.198.14.78:3009',
    REACT_APP_ANALYTIC_URL: 'http://10.198.14.78:3013',
    VOUCHED_CALLBACK_URL: 'https://d98b-75-168-152-8.ngrok-free.app/v1/players/vouch/hook',
    FASTLINK_URL: 'https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd2-239/fastlink/?channelAppName=usdevexpreprod2',
    SKRILL_PAY_TO_EMAIL: 'jseils107@gmail.com'
 },
 staging: {
    APP_VERSION: '2.2.0',
    FACEBOOK_APP_ID: '478709922852438',
    BASE_WEB_URL: 'https://staging.app.bettoredge.com',
    ENVIRONMENT: 'STAGING',
    REACT_APP_MK_URL : 'https://stage-be-api-gate.herokuapp.com/mk',
    REACT_APP_MK_URL_DIRECT: 'https://stage-be-mk-svc.herokuapp.com/mk',
    REACT_APP_AUTH_URL : 'https://stage-be-api-gate.herokuapp.com/auth',
    AUTH_SVC_API: 'https://stage-be-auth-svc.herokuapp.com',
    SOC_SVC_API: 'https://stage-be-soc-svc.herokuapp.com',
    SR_SVC_API: 'https://stage-be-sr-svc.herokuapp.com',
    MK_SVC_API: 'https://stage-be-mk-svc.herokuapp.com',
    TP_SVC_API: 'https://stage-be-tp-svc.herokuapp.com',
    WALLET_SVC_API: 'https://stage-be-pay-svc.herokuapp.com',
    WS_SVC_API: 'wss://stage-be-ws.herokuapp.com',
    ANALYTICS_SVC_API: 'https://stage-be-analytics-svc.herokuapp.com',
    REACT_APP_AUTH_URL_DIRECT : 'https://stage-be-auth-svc.herokuapp.com/auth',
    REACT_APP_SR_WS : 'wss://stage-be-api-gate.herokuapp.com/sr',
    REACT_APP_SR_URL : 'https://stage-be-api-gate.herokuapp.com/sr',
    REACT_APP_SR_URL_DIRECT : 'https://stage-be-sr-svc.herokuapp.com/sr',
    REACT_APP_MK_WS : 'wss://stage-be-api-gate.herokuapp.com/mk',
    REACT_APP_AUTH_WS : 'wss://stage-be-api-gate.herokuapp.com/auth',
    REACT_APP_PLAY_URL : 'https://stage-be-api-gate.herokuapp.com/player',
    REACT_APP_SOCIAL_URL : 'https://stage-be-api-gate.herokuapp.com/social',
    REACT_APP_SOCIAL_URL_DIRECT : 'https://stage-be-soc-svc.herokuapp.com/social',
    REACT_APP_SOCIAL_WS : 'wss://stage-be-api-gate.herokuapp.com/social',
    REACT_APP_TP_URL : 'https://stage-be-api-gate.herokuapp.com/tp',
    REACT_APP_TP_URL_DIRECT : 'https://stage-be-tp-svc.herokuapp.com/tp',
    REACT_APP_PAY_URL : 'https://stage-be-pay-svc.herokuapp.com/pay',
    REACT_APP_FUT_URL : 'https://stage-be-api-gate.herokuapp.com',
    REACT_APP_FUT_URL_DIRECT : 'https://stage-be-fut-svc.herokuapp.com',
    REACT_APP_ANALYTIC_URL: 'https://stage-be-analytics-svc.herokuapp.com',
    VOUCHED_CALLBACK_URL: 'https://stage-be-auth-svc.herokuapp.com/v1/players/vouch/hook',
    FASTLINK_URL: 'https://fl4.preprod.yodlee.com/authenticate/USDevexPreProd2-239/fastlink/?channelAppName=usdevexpreprod2',
    SKRILL_PAY_TO_EMAIL: 'jseils107@gmail.com'
 },
  prod: {
    APP_VERSION: '8.5.4',
    FACEBOOK_APP_ID: '478709922852438',
    BASE_WEB_URL: 'https://app.bettoredge.com',
    ENVIRONMENT: 'PRODUCTION', 
    REACT_APP_MK_URL : 'https://be-api-gate.herokuapp.com/mk',
    REACT_APP_MK_URL_DIRECT: 'https://be-mk-svc.herokuapp.com/mk',
    REACT_APP_AUTH_URL : 'https://be-api-gate.herokuapp.com/auth',
    AUTH_SVC_API: 'https://be-auth-svc.herokuapp.com',
    SOC_SVC_API: 'https://be-soc-svc.herokuapp.com',
    SR_SVC_API: 'https://be-sr-svc.herokuapp.com',
    MK_SVC_API: 'https://be-mk-svc.herokuapp.com',
    TP_SVC_API: 'https://be-tp-svc.herokuapp.com',
    WS_SVC_API: 'wss://be-ws.herokuapp.com',
    WALLET_SVC_API: 'https://be-pay-svc.herokuapp.com',
    ANALYTICS_SVC_API: 'https://be-analytics-svc.herokuapp.com',
    REACT_APP_AUTH_URL_DIRECT : 'https://be-auth-svc.herokuapp.com/auth',
    REACT_APP_SR_WS : 'wss://be-api-gate.herokuapp.com/sr',
    REACT_APP_SR_URL : 'https://be-api-gate.herokuapp.com/sr',
    REACT_APP_SR_URL_DIRECT : 'https://be-sr-svc.herokuapp.com/sr',
    REACT_APP_MK_WS : 'wss://be-api-gate.herokuapp.com/mk',
    REACT_APP_AUTH_WS : 'wss://be-api-gate.herokuapp.com/auth',
    REACT_APP_PLAY_URL : 'https://be-api-gate.herokuapp.com/player',
    REACT_APP_SOCIAL_URL : 'https://be-api-gate.herokuapp.com/social',
    REACT_APP_SOCIAL_URL_DIRECT : 'https://be-soc-svc.herokuapp.com/social',
    REACT_APP_SOCIAL_WS : 'wss://be-api-gate.herokuapp.com/social',
    REACT_APP_TP_URL : 'https://be-api-gate.herokuapp.com/tp',
    REACT_APP_TP_URL_DIRECT : 'https://be-tp-svc.herokuapp.com/tp',
    REACT_APP_PAY_URL : 'https://be-pay-svc.herokuapp.com/pay',
    REACT_APP_FUT_URL : 'https://be-api-gate.herokuapp.com',
    REACT_APP_FUT_URL_DIRECT : 'https://be-fut-svc.herokuapp.com',
    REACT_APP_ANALYTIC_URL: 'https://be-analytics-svc.herokuapp.com',
    VOUCHED_CALLBACK_URL: 'https://be-auth-svc.herokuapp.com/v1/players/vouch/hook',
    FASTLINK_URL: 'https://fl4.prod.yodlee.com/authenticate/USDevexProd2-183/fastlink/?channelAppName=usdevexprod2',
    SKRILL_PAY_TO_EMAIL: 'support@bettoredge.io'
  }
 //Add more environments here
};

const getEnvVars = (env = Constants.manifest.releaseChannel) => {
    // What is __DEV__ ?
    // This variable is set to true when react-native is running in Dev mode.
    // __DEV__ is true when run locally, but false when published.
    
    if (__DEV__) {
      return ENV.dev;
    } else if (env === 'staging') {
      return ENV.staging;
    } else if (env === 'prod') {
      return ENV.prod;
    } else {
      return ENV.prod;
    }
};

export default getEnvVars;