import React from 'react';
import { Observer } from "be-components"
import { useSelector } from "react-redux"



const ObserverHolder = ({}) => {
    const analytic_tokens = useSelector((state:any) => state.util.analytic_tokens, (left, right) => left == right);
    if(!analytic_tokens.set){ return <></> }
    return (
        <Observer
            distinct_id={analytic_tokens.distinct_id}
            session_id={analytic_tokens.session_id}
            source='pwa'
        />
    )
}

export default ObserverHolder