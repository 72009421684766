import { 
    EventReducerProps,
    V1_CLEAR_SCHEDULE_RANGE,
    V1_LOAD_ACTIVE_EVENT_IDS,
    V1_LOAD_ACTIVE_TOURNAMENT_IDS,
    V1_LOAD_ATHLETES,
    V1_LOAD_BRACKETS,
    V1_LOAD_EVENTS,
    V1_LOAD_LATEST_PRICES,
    V1_LOAD_LEAGUES,
    V1_LOAD_LEAGUE_SCHEDULE,
    V1_LOAD_MATCHES,
    V1_LOAD_ORDER_PRICES,
    V1_LOAD_POPULAR_EVENT_IDS,
    V1_LOAD_PRICES,
    V1_LOAD_TEAMS,
    V1_LOAD_TOURNAMENTS,
    V1_LOAD_TOURNAMENT_SCHEDULE,
    V1_UPDATE_ATHLETE,
    V1_UPDATE_BRACKET,
    V1_UPDATE_EVENT,
    V1_UPDATE_EXOTIC,
    V1_UPDATE_GOLF_COURSES,
    V1_UPDATE_GOLF_LEADERS,
    V1_UPDATE_GOLF_SCORECARDS,
    V1_UPDATE_GOLF_TOURNAMENT_STATISTICS,
    V1_UPDATE_MATCH,
    V1_UPDATE_MMA_DETAILS,
    V1_UPDATE_TEAM,
    V1_UPDATE_TOURNAMENT,
} from "../actionsv1/types"
import { AthleteProps, BracketProps, EventProps, TeamProps, TournamentProps } from "../types/sr_svc"
import { events_getUniqueRangeDays } from "./helpers"


const DEFAULT_STATE:EventReducerProps = {
    leagues: [],
    active_event_ids: [],
    popular_event_ids: [],
    active_tournament_ids: [],
    schedule_range: [],
    events:{},
    matches: {},
    tournaments: {},
    teams_list: {
        updated:1,
        teams: []
    },
    brackets_list: [],
    athletes_list: {
        updated:1,
        athletes: []
    },
    mma_details_list: {
        updated:1,
        mma_details: []
    },
    golf_leaders_list: {
        updated:1,
        golf_leaders: []
    },
    golf_courses_list: {
        updated:1,
        golf_courses: []
    },
    golf_scorecards_list: {
        updated:1,
        golf_scorecards: []
    },
    golf_tournament_statistics_list: {
        updated:1,
        golf_tournament_statistics: []
    },
    latest_prices: [],
    prices: [],
    exotics_list: {
        updated:0,
        exotics: []
    },
    prices_list: {
        updated:0,
        external_prices: []
    }
}

const eventReducer = (state = DEFAULT_STATE, action:any):EventReducerProps => {
    switch(action.type){
        case V1_LOAD_LEAGUES:
            return {
                ...state,
                leagues: action.leagues
            }
        case V1_LOAD_ACTIVE_EVENT_IDS:
            return {
                ...state,
                active_event_ids: action.event_ids
            }
        case V1_LOAD_POPULAR_EVENT_IDS:
            return {
                ...state,
                popular_event_ids: action.offset === 0 ? action.event_ids : state.popular_event_ids.concat(action.event_ids)
            }
        case V1_LOAD_EVENTS:
            return {
                ...state,
                events: {
                    ...state.events,
                    ...action.events
                }
            }
        case V1_LOAD_LEAGUE_SCHEDULE:
            return {
                ...state,
                schedule_range: events_getUniqueRangeDays(state.schedule_range, action.range_dates),
                events: {
                    ...state.events,
                    ...action.events
                }
            }
        case V1_LOAD_TOURNAMENT_SCHEDULE:
            return {
                ...state,
                schedule_range: events_getUniqueRangeDays(state.schedule_range, action.range_dates),
                tournaments: state.tournaments.filter(e => !action.tournaments.find((ne:TournamentProps) => ne.tournament_id == e.tournament_id)).concat(action.tournaments)
            }
        case V1_CLEAR_SCHEDULE_RANGE:
            return {
                ...state,
                schedule_range: []
            }
        case V1_UPDATE_EVENT:
            return {
                ...state,
                events: {
                    ...state.events,
                    ...action.events
                }
            }
        case V1_UPDATE_EXOTIC:
            return {
                ...state,
                exotics_list: {
                    updated: state.exotics_list.updated +1,
                    exotics: state.exotics_list.exotics.filter(e => e.exotic_id != action.exotic.exotic_id).concat(action.exotic)
                }
            }
        case V1_LOAD_TEAMS:
            return {
                ...state,
                teams_list: {
                    updated: state.teams_list.updated + 1,
                    teams: action.offset == 0 ? action.teams : state.teams_list.teams.filter((t:TeamProps) => !action.teams.find((nt:TeamProps) => nt.team_id == t.team_id)).concat(action.teams)
                }
            }
        case V1_UPDATE_TEAM:
            return {
                ...state,
                teams_list: {
                    updated: state.teams_list.updated + 1,
                    teams: state.teams_list.teams.filter(t => t.team_id != action.team.team_id).concat(action.team)
                }
            }
        case V1_LOAD_ATHLETES:
            return {
                ...state,
                athletes_list : {
                    updated: state.athletes_list.updated + 1,
                    athletes: action.offset == 0 ? action.athletes : state.athletes_list.athletes.filter(a => !action.athletes.find((na:AthleteProps) => na.athlete_id == a.athlete_id)).concat(action.athletes)
                }
            }
        case V1_UPDATE_ATHLETE:
            return {
                ...state,
                athletes_list: {
                    updated: state.athletes_list.updated +1,
                    athletes: state.athletes_list.athletes.filter(a => a.athlete_id != action.athlete.athlete_id).concat(action.athlete)
                }
            }
        case V1_LOAD_ACTIVE_TOURNAMENT_IDS:
            return {
                ...state,
                active_tournament_ids: action.tournament_ids
            }
        case V1_LOAD_TOURNAMENTS:
            return {
                ...state,
                tournaments: {
                    ...state.tournaments,
                    ...action.tournaments
                }
            }
        case V1_UPDATE_MMA_DETAILS:
            return {
                ...state,
                mma_details_list: {
                    updated: state.mma_details_list.updated + 1,
                    mma_details: state.mma_details_list.mma_details.filter(md => !action.mma_details.find(nmd => nmd.tournament_id == md.tournament_id)).concat(action.mma_details)
                }
            }
        case V1_UPDATE_GOLF_LEADERS:
            return {
                ...state,
                golf_leaders_list: {
                    updated: state.golf_leaders_list.updated + 1,
                    golf_leaders: state.golf_leaders_list.golf_leaders.filter(tl => !action.golf_leaders.find(ntl => ntl.tournament_id == tl.tournament_id)).concat(action.golf_leaders)
                }
            }
        case V1_UPDATE_GOLF_COURSES:
            return {
                ...state,
                golf_courses_list: {
                    updated: state.golf_courses_list.updated + 1,
                    golf_courses: state.golf_courses_list.golf_courses.filter(tl => !action.golf_courses.find(ntl => ntl.tournament_id == tl.tournament_id)).concat(action.golf_courses)
                }
            }
        case V1_UPDATE_GOLF_SCORECARDS:
            return {
                ...state,
                golf_scorecards_list: {
                    updated: state.golf_scorecards_list.updated + 1,
                    golf_scorecards: state.golf_scorecards_list.golf_scorecards.filter(tl => !action.golf_scorecards.find(ntl => `${ntl.athlete_id}:${ntl.tournament_id}:${ntl.round_number}` == `${tl.athlete_id}:${tl.tournament_id}:${tl.round_number}`)).concat(action.golf_scorecards)
                }
            }
        case V1_UPDATE_GOLF_TOURNAMENT_STATISTICS:
            return {
                ...state,
                golf_tournament_statistics_list: {
                    updated: state.golf_tournament_statistics_list.updated + 1,
                    golf_tournament_statistics: state.golf_tournament_statistics_list.golf_tournament_statistics.filter(gts => gts.tournament_id != action.golf_tournament_statistics.tournament_id).concat(action.golf_tournament_statistics)
                }
            }
        case V1_UPDATE_TOURNAMENT:
            return {
                ...state,
                tournaments: {
                    ...state.tournaments,
                    ...action.tournaments
                }
            }
        case V1_LOAD_MATCHES:
            return {
                ...state,
                matches: {
                    ...state.matches,
                    ...action.matches
                }
            }
        case V1_UPDATE_MATCH:
            return {
                ...state,
                matches: {
                    ...state.matches,
                    ...action.matches
                }
            }
        case V1_LOAD_LATEST_PRICES:
            return {
                ...state,
                latest_prices: action.prices
            }
        case V1_LOAD_PRICES:
            return {
                ...state,
                prices: action.prices
            }
        case V1_LOAD_ORDER_PRICES:
            return {
                ...state,
                updated: state.prices_list.updated + 1,
                order_prices: action.external_prices
            }
        case V1_LOAD_BRACKETS:            
            return {
                ...state,
                brackets_list: action.brackets.map((b:BracketProps) => {
                    return { bracket_id: b.bracket_id, updated: 0, bracket:b, bracket_rounds: [], round_events: [] }
                })
            }
        case V1_UPDATE_BRACKET:
            let existing_bracket = state.brackets_list.find(bl => bl.bracket_id == action.bracket.bracket_id)
            let updated = 0
            if(existing_bracket?.bracket_id){ updated = existing_bracket.updated + 1 }
            return {
                ...state,
                brackets_list: state.brackets_list.filter(bl => bl.bracket_id != action.bracket.bracket_id ).concat({ 
                    bracket_id: action.bracket.bracket_id,
                    updated,
                    bracket: action.bracket, 
                    bracket_rounds: action.bracket_rounds,
                    bracket_groups: action.bracket_groups,
                    round_events: action.round_events 
                })
            }
        default:
            return state
    }
 }
 
 export default eventReducer