import axios from 'axios';
import getEnvVars from '../../env';
import { getPlayerLocation } from '../../utilsv1/location';
import { PublicPlayerProps } from '../types/auth_svc';
import { ArticleProps, GroupMessageProps, ImpressionProps, PlayerFavoriteProps, PlayerFollowerProps, PlayerLinkProps, PlayerTagProps, PodcastEpisodeProps, PodcastProps, PostCommentProps, PostProps, PostReactionProps, URLMetadataProps } from '../types/soc_svc';
import { market_bulkGetOrders } from './markets';
import { player_getBulkPlayers } from './player';
import { 
    V1_LOAD_ARTICLES,
    V1_LOAD_ARTICLE_FEEDS,
    V1_LOAD_FEED_POSTS, 
    V1_LOAD_GROUP, 
    V1_LOAD_HOT_POSTS, 
    V1_LOAD_LINKS,
    V1_LOAD_LIVE_FEEDS,
    V1_LOAD_MY_POST_REACTIONS,
    V1_LOAD_PLAYERS,
    V1_LOAD_PLAYER_COUPONS,
    V1_LOAD_PLAYER_FAVORITES,
    V1_LOAD_PLAYER_LINKS,
    V1_LOAD_PLAYER_TAGS,
    V1_LOAD_PODCASTS,
    V1_LOAD_POST_COMMENTS,
    V1_LOAD_RECENT_ARTICLES,
    V1_LOAD_RECENT_PODCASTS,
    V1_LOAD_TAGS,
    V1_LOAD_VIEWING_EPISODES,
    V1_LOAD_VIEWING_FOLLOWER_STATS,
    V1_LOAD_VIEWING_PLAYER_FOLLOWERS,
    V1_LOAD_VIEWING_PLAYER_LINKS,
    V1_LOAD_VIEWING_PLAYER_POSTS,
    V1_LOAD_VIEWING_PLAYER_TAGS,
    V1_UPDATE_AD,
    V1_UPDATE_ARTICLES,
    V1_UPDATE_GROUP_MESSAGE,
    V1_UPDATE_MY_POST_REACTIONS,
    V1_UPDATE_PLAYER_COUPON,
    V1_UPDATE_PLAYER_FOLLOWERS,
    V1_UPDATE_PLAYER_LINK,
    V1_UPDATE_PLAYER_TAG,
    V1_UPDATE_PLAYING_PODCAST,
    V1_UPDATE_POST,
    V1_UPDATE_POST_COMMENTS,
    V1_UPDATE_POST_COMMENT_STAT,
    V1_UPDATE_POST_REACTION_STATS 
} from './types';
const { AUTH_SVC_API, SOC_SVC_API } = getEnvVars()



//--------------------------------------------------------------------------------------------
//Viewing player
export const social_getPlayerByPlayerId = (player_id:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const existing_player = getState().social.stored_players.find((p:PublicPlayerProps) => p.player_id == player_id)
            if(existing_player){ return }
            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/${player_id}`)
            const { player } = resp.data;
            dispatch({ type: V1_LOAD_PLAYERS, players:[player] })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getPlayerFollowerStatsByPlayerId = (player_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/followers/stats/player/${player_id}`)
            const { player_follower_stats } = resp.data;
            dispatch({ type: V1_LOAD_VIEWING_FOLLOWER_STATS, player_follower_stats })
        } catch (e) {
            console.log(e)
        }
    }
}

//--------------------------------------------------------------------------------------------
//Activity Feed
export const social_getPosts = (offset:number, type?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/posts/all?offset=${offset}&type=${type}`)
            let memo_orders:string[] = []
            let company_ids:string[] = []
            resp.data.posts.map(p => {
                if(p.memo_orders && p.memo_orders.length > 0){ memo_orders = memo_orders.concat(p.memo_orders) }
                if(p.memo_topics && p.memo_topics.length > 0){
                    let company_id = p.memo_topics.find(t => t.tag_type === 'company')?.company_id
                    if(company_id){ company_ids.push(company_id) }
                }
            })
            if(memo_orders.length > 0){
                await dispatch(market_bulkGetOrders(memo_orders))
            }
            if(company_ids.length > 0){
                await dispatch(player_getBulkPlayers([ ...new Set(company_ids.map(id => id)) ]))
            }
            if(type && type === 'hot'){
                return dispatch({ type: V1_LOAD_HOT_POSTS, hot_posts: resp.data.posts, offset })
            }
            return dispatch({ type: V1_LOAD_FEED_POSTS, feed_posts:resp.data.posts, offset })
        } catch (e) {
            console.log(e)
        }
    }
}


export const social_getFollowerStatus = (player_id:string, following_id:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const existing_follower = getState().social.player_followers.find(pf => pf.player_id == player_id && pf.following_id == following_id)
            if(existing_follower){
                return
            }
            if(!player_id){ return }
            const resp = await axios.get(`${SOC_SVC_API}/v1/followers/status?player_id=${player_id}&following_id=${following_id}`)
            dispatch({ type: V1_UPDATE_PLAYER_FOLLOWERS, player_follower:resp.data.player_follower })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getTagFollowStatus = (player_id:string, tag_id:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/tags/status?player_id=${player_id}&tag_id=${tag_id}`)
            const { player_tag } = resp.data
            dispatch({ type: V1_UPDATE_PLAYER_TAG, player_tag })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_updatePlayerFollower = (player_follower:PlayerFollowerProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/followers/player/update`, { player_follower })
            dispatch({ type: V1_UPDATE_PLAYER_FOLLOWERS, player_follower: resp.data.player_follower })
        } catch (e) {
            console.log(e)
        }
    }
}   

export const social_getPostsByPlayerId = (player_id:string, offset:number, tag_id?:string, company_id?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/posts/player/${player_id}?offset=${offset}&tag_id=${tag_id}&company_id=${company_id}`)
            let memo_orders:string[] = []
            let company_ids:string[] = []
            resp.data.posts.map(p => {
                if(p.memo_orders && p.memo_orders.length > 0){ memo_orders = memo_orders.concat(p.memo_orders) }
                if(p.memo_topics && p.memo_topics.length > 0){
                    let company_id = p.memo_topics.find(t => t.tag_type === 'company')?.company_id
                    if(company_id){ company_ids.push(company_id) }
                }
            })
            if(memo_orders.length > 0){
                dispatch(market_bulkGetOrders(memo_orders))
            }
            if(company_ids.length > 0){
                dispatch(player_getBulkPlayers([ ...new Set(company_ids.map(id => id)) ]))
            }
            dispatch({ type: V1_LOAD_VIEWING_PLAYER_POSTS, posts: resp.data.posts, offset })
        } catch (e) {
            console.log(e)
        }
    }
}


export const social_getReactionStatsByPostId = (post_id:string, post_type:string) => {
    return async(dispatch:any, getState:any) => {
        try {            
            const resp = await axios.get(`${SOC_SVC_API}/v1/posts/reactions/stats/post/${post_id}?post_type=${post_type}`)
            dispatch({ type: V1_UPDATE_POST_REACTION_STATS, post_reaction_stats:resp.data.post_reaction_stats, post_id, post_type })
        } catch (e) {
            console.log(e)
        }
    }
}


export const social_getReactionsByPostIdAndPlayerId = (player_id:string, post_id:string, post_type:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/posts/reactions/post/${post_id}?player_id=${player_id}&post_type=${post_type}`)
            dispatch({ type: V1_LOAD_MY_POST_REACTIONS, post_reactions:resp.data.post_reactions, post_id })
        } catch (e) {
            console.log(e)
        }
    }
}


export const social_createPostReaction = (post_reaction:PostReactionProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/posts/reaction/create`, { post_reaction })
            dispatch({ type: V1_UPDATE_MY_POST_REACTIONS, post_reaction: resp.data.post_reaction })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_updatePostReaction = (post_reaction:PostReactionProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/posts/reaction/update`, { post_reaction })
            dispatch({ type: V1_UPDATE_MY_POST_REACTIONS, post_reaction: resp.data.post_reaction })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_createPost = (post:PostProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/posts/post/create`, { post })
            dispatch({ type: V1_UPDATE_POST, post: resp.data.post })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_updatePost = (post:PostProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/posts/post/update`, { post })
            dispatch({ type: V1_UPDATE_POST, post: resp.data.post })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getPostById = (post_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/posts/post/${post_id}`)
            dispatch({ type: V1_UPDATE_POST, post:resp.data.post })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getCommentsForPost = (post_id:string, post_type:string, offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/posts/comments/post/${post_id}?post_type=${post_type}&offset=${offset}`)
            dispatch({ type: V1_LOAD_POST_COMMENTS, post_comments: resp.data.post_comments })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getCommentStatByPostId = (post_id:string, post_type:string) => {
    return async(dispatch:any, getState:any) => {
        try {            
            const resp = await axios.get(`${SOC_SVC_API}/v1/posts/comments/stats/post/${post_id}?post_type=${post_type}`)
            dispatch({ type: V1_UPDATE_POST_COMMENT_STAT, post_comment_stat:resp.data.post_comment_stat, post_id, post_type })
        } catch (e) {
            console.log(e)
        }
    }
}


export const social_createPostComment = (post_comment:PostCommentProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/posts/comment/create`, { post_comment })
            dispatch({ type: V1_UPDATE_POST_COMMENTS, post_comment: resp.data.post_comment })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_updatePostComment = (post_comment:PostCommentProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/posts/comment/update`, { post_comment })
            dispatch({ type: V1_UPDATE_POST_COMMENTS, post_comment: resp.data.post_comment })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getPlayerFollowers = (player_id:string, status: 'following'|'followers', offset:number) => {
    return async(dispatch:any) => {
        try {
            if(!player_id){ return }
            const resp = await axios.get(`${SOC_SVC_API}/v1/followers/player/${player_id}?status=${status}&offset=${offset}`)
            dispatch({ type: V1_LOAD_VIEWING_PLAYER_FOLLOWERS, player_followers: resp.data.player_followers, offset })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getPlayerTagsByPlayerId = (player_id:string, offset:number, me?:boolean) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/tags/player/${player_id}?offset=${offset}`)
            if(me){
                dispatch({ type: V1_LOAD_PLAYER_TAGS, player_tags: resp.data.player_tags })
            } else {
                dispatch({ type: V1_LOAD_VIEWING_PLAYER_TAGS, player_tags: resp.data.player_tags })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getPodcastsByCompanyId = (player_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/podcasts/company/${player_id}`)
            dispatch({ type: V1_LOAD_PODCASTS, podcasts: resp.data.podcasts, offset:1 })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getTags = (offset:number, tag_type:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/tags/all?tag_type=${tag_type}&offset=${offset}&status=${'active'}`)
            dispatch({ type: V1_LOAD_TAGS, tags: resp.data.tags, player_tags: resp.data.player_tags })
        } catch (e) {
            console.log(e)
        }
    }
}


export const social_savePlayerTag = (player_tag:PlayerTagProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/tags/player/create`, { player_tag })
            dispatch({ type: V1_UPDATE_PLAYER_TAG, player_tag:resp.data.player_tag })
        } catch (e) {
            console.log(e)
        }
    }
}


//------
//Player Links
export const social_getLinks = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/links/all?status=active`)
            dispatch({ type: V1_LOAD_LINKS, links: resp.data.links })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getPlayerLinksByPlayerId = (player_id:string, link_status:string, me?:boolean) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/links/player/${player_id}?link_status=${link_status}`)
            if(me){
                dispatch({ type: V1_LOAD_PLAYER_LINKS, player_links: resp.data.player_links })
            } else {
                dispatch({ type: V1_LOAD_VIEWING_PLAYER_LINKS, player_links: resp.data.player_links })
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_savePlayerLink = (player_link: PlayerLinkProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/links/player/create`, { player_link })
            dispatch({ type: V1_UPDATE_PLAYER_LINK, player_link: resp.data.player_link })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_updatePlayerLink = (player_link: PlayerLinkProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/links/player/update`, { player_link })
            dispatch({ type: V1_UPDATE_PLAYER_LINK, player_link: resp.data.player_link })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getPlayerFavoritesByPlayerId = (player_id:string, favorite_type?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/favorites/player/${player_id}?favorite_type=${favorite_type}`)
            dispatch({ type: V1_LOAD_PLAYER_FAVORITES, player_favorites: resp.data.player_favorites })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_createPlayerFavorite = (player_favorite:PlayerFavoriteProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/favorites/player/create`, { player_favorite })
            dispatch({ type: V1_LOAD_PLAYER_FAVORITES, player_favorites: [resp.data.player_favorite] })
        } catch (e) {
            console.log(e)
        }
    }
}

//Groups
export const social_getDirectGroup = (player_id:string, other_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/groups/direct?player_id=${player_id}&other_id=${other_id}`)
            dispatch({ type: V1_LOAD_GROUP, group:resp.data.group, group_players:resp.data.group_players, group_messages: resp.data.group_messages })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getRelatedGroupId = async(related_id:string, related_type:string) => {
    try {
        const resp = await axios.get(`${SOC_SVC_API}/v1/groups/related/${related_id}/${related_type}`)
        return resp.data.group?.group_id
    } catch (e) {
        console.log(e)
        return undefined
    }
}

export const social_getGroupById = (group_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/groups/group/${group_id}`)
            let message_orders:string[] = []
            resp.data.group_messages.map((m:GroupMessageProps) => {
                if(!m.message_orders){ return }
                if(m.message_orders.length === 0){ return }
                message_orders = message_orders.concat(m.message_orders)
            })
            if(message_orders.length > 0){
                await dispatch(market_bulkGetOrders([ ...new Set(message_orders.map(o => o)) ]))
            }
            dispatch({ type: V1_LOAD_GROUP, group:resp.data.group, group_players:resp.data.group_players, group_messages: resp.data.group_messages })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_clearGroup = () => {
    return (dispatch:any) => {
        dispatch({ type: V1_LOAD_GROUP, group:undefined, group_players:[], group_messages:[] })
    }
}

export const social_saveMessage = (group_message:GroupMessageProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/groups/message/create`, { group_message })
            dispatch({ type: V1_UPDATE_GROUP_MESSAGE, group_message: resp.data.group_message })
        } catch (e) {
            console.log(e)
        }
    }
}




//Coupons
export const social_getCouponsByPlayerId = (player_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/coupons/player/player_id/${player_id}`)
            dispatch({ type: V1_LOAD_PLAYER_COUPONS, player_coupons: resp.data.player_coupons })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_showPlayerCoupon = (player_coupon_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/coupons/player/show`, { player_coupon_id })
            dispatch({ type: V1_UPDATE_PLAYER_COUPON, player_coupon: resp.data.player_coupon })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_processPlayerCoupon = (code:string) => {
    return async(dispatch:any) => {
        try {
            await axios.post(`${SOC_SVC_API}/v1/coupons/player/process`, { code })
        } catch (e) {
            console.log(e)
            throw new Error('Invalid Code')
        }
    }
}

export const social_getPlayerCouponById = (player_coupon_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/coupons/player/player_coupon_id/${player_coupon_id}`)
            dispatch({ type: V1_UPDATE_PLAYER_COUPON, player_coupon: resp.data.player_coupon })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_clearPlayerCoupons = () => {
    return (dispatch:any) => {
        dispatch({ type: V1_LOAD_PLAYER_COUPONS, player_coupons: [] })
    }
}



//Podcasts
export const social_getPodcasts = (type:string, offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/podcasts/all?type=${type}&offset=${offset}`)
            if(type === 'recent'){ dispatch({ type: V1_LOAD_RECENT_PODCASTS, recent_podcast_ids: resp.data.podcasts.map((p:PodcastProps) => p.podcast_id) }) }
            if(type === 'suggested'){ dispatch({ type: V1_LOAD_RECENT_PODCASTS, recent_podcast_ids: resp.data.podcasts.map((p:PodcastProps) => p.podcast_id) }) }
            dispatch({ type: V1_LOAD_PODCASTS, podcasts: resp.data.podcasts, offset: 1 })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getArticles = (type:string, offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/articles/all?type=${type}&offset=${offset}`)
            if(type === 'recent'){ dispatch({ type: V1_LOAD_RECENT_ARTICLES, recent_article_ids: resp.data.articles.map((p:ArticleProps) => p.article_id) }) }
            //if(type === 'suggested'){ dispatch({ type: V1_LOAD_RECENT_PODCASTS, recent_podcast_ids: resp.data.podcasts.map((p:PodcastProps) => p.podcast_id) }) }
            dispatch({ type: V1_LOAD_ARTICLES, articles: resp.data.articles, offset: 1 })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_bulkGetArticles = (article_ids:string[]) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/articles/bulk/get`, { attribute:'article_id', values: article_ids })
            dispatch({ type:V1_UPDATE_ARTICLES, articles: resp.data.articles })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getArticleFeedById = (article_feed_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/articles/feed/${article_feed_id}`)
            dispatch({ type: V1_LOAD_ARTICLE_FEEDS, article_feeds: [resp.data.article_feed], offset: 1 })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getLiveFeeds = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/articles/feeds/live`)
            dispatch({ type:V1_LOAD_LIVE_FEEDS, article_feeds:resp.data.article_feeds })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_searchArticleFeeds = (search_value:string, offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/articles/feeds/search`, { search_value, offset })
            dispatch({ type: V1_LOAD_ARTICLE_FEEDS, article_feeds: resp.data.article_feeds, offset })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getArticleFeedsByCompanyId = (company_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/articles/feeds/company/${company_id}`)
            dispatch({ type: V1_LOAD_ARTICLE_FEEDS, article_feeds: resp.data.article_feeds, offset: 1 })
        } catch (e) {
            
        }
    }
}

export const social_searchArticles = (search_value:string, offset:number, article_feed_id?:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/articles/search`, { search_value, article_feed_id, offset })
            dispatch({ type: V1_LOAD_ARTICLES, articles: resp.data.articles, offset })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getPodcastById = (podcast_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/podcasts/podcast/${podcast_id}`)
            dispatch({ type: V1_LOAD_PODCASTS, podcasts: [resp.data.podcast], offset:1 })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_getPodcastEpisodeById = (podcast_episode_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/podcasts/episodes/episode/${podcast_episode_id}`)
            dispatch({ type: V1_LOAD_VIEWING_EPISODES, podcast_episodes: [resp.data.podcast_episode], offset:1 })
        } catch (e) {
            console.log(e)
        }
    }
}

export const social_searchPodcasts = (search_value:string, offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/podcasts/search`, { search_value, offset })
            dispatch({ type: V1_LOAD_PODCASTS, podcasts:resp.data.podcasts, offset:0 })
        } catch (e) {
            console.log(e)
        }
    }
}


export const social_claimPodcast = (podcast_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${SOC_SVC_API}/v1/podcasts/podcast/claim`, { podcast_id })
            dispatch({ type: V1_LOAD_PODCASTS, podcasts: [resp.data.podcast], offset:1 })
        } catch (e) {
            console.log(e)
        }
    }
}


export const social_getEpisodesByPodcastId = (podcast_id:string, offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${SOC_SVC_API}/v1/podcasts/episodes/podcast/${podcast_id}?offset=${offset}`)
            dispatch({ type: V1_LOAD_VIEWING_EPISODES, podcast_episodes:resp.data.podcast_episodes, offset })
        } catch (e) {
            console.log(e)
        }
    }
}

//Ads
export const social_findAd = (ad_type:string, identifier_id:string, exclude_ids?:string[], company_id?:string) => {
    return async(dispatch:any) => {
        try {
            const loc = await getPlayerLocation()
            const resp = await axios.post(`${SOC_SVC_API}/v1/ads/ad/find`, { ad_type, exclude_ids, company_id, region:loc?.region, coords:loc?.location })
            dispatch({ type: V1_UPDATE_AD, ad: { ...resp.data.ad, identifier_id } })
        } catch (e) {
            console.log(e)
        }
    }
} 


//Audio actions

export const social_updatePlayingPodcastEpisode = ( podcast_episode?: PodcastEpisodeProps ) => {
    return (dispatch:any) => {
        dispatch({ type: V1_UPDATE_PLAYING_PODCAST, podcast_episode })
    }
}



//NON DISPATCH RETURNS
export const social_getLinkPreview = async(url:string):Promise<URLMetadataProps | undefined> => {
    try {
       
        const resp = await axios.post(`${SOC_SVC_API}/v1/posts/link/preview`, { url })
        return resp.data.link_preview
    } catch (e) {
        return undefined
    }
}



//Impression
export const social_logImpression = async(impression:ImpressionProps):Promise<ImpressionProps | undefined> => {
    try {
        const resp = await axios.post(`${SOC_SVC_API}/v1/impressions/impression/create`, { impression })
        return resp.data.impression
    } catch (e) {
        console.log(e)
        return undefined
    }
}