import { combineReducers } from 'redux';

//Import individual reducers
import authReducer from '../reducersv1/auth';
import prefReducer from './prefReducer';
import playerReducer from '../reducersv1/player';
import utilReducer from '../reducersv1/utils';
import eventReducer from '../reducersv1/events';
import marketReducer from '../reducersv1/markets';
import compReducer from '../reducersv1/comps';
import socialReducer from '../reducersv1/social';
import payReducer from './payReducer';
import analyticReducer from '../reducersv1/analytics';
import draftReducer from './draftReducer';
import pollReducer from '../reducersv1/polls';

const rootReducer = combineReducers({
    auth: authReducer,
    pref: prefReducer,
    event: eventReducer,
    player: playerReducer,
    market: marketReducer,
    comp: compReducer,
    social: socialReducer,
    pay: payReducer,
    analytics: analyticReducer,
    draft: draftReducer,
    util: utilReducer,
    poll: pollReducer
})

export default rootReducer;