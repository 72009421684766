import { PlayerSquareType, SquareOfferType, SquareType } from "../../../objectTypes"

export const updateSquaresWithUpdatedSquares = (squares:SquareType[], updated_squares:SquareType[]) => {
    updated_squares.map((s:SquareType) => {
        squares = squares.filter(square => square.sq_square_id != s.sq_square_id).concat(s)
    })
    return squares
}
export const updatePlayerSquaresWithSavedSquares = (pSquares:PlayerSquareType[], saved_player_squares:PlayerSquareType[]) => {
    saved_player_squares.map(ps => {
        pSquares = pSquares.filter(square => square.sq_player_square_id != ps.sq_player_square_id).concat(ps)
    })
    return pSquares
}
export const updatePlayerSquaresWithRemovedSquares = (pSquares:PlayerSquareType[], removed_player_squares:PlayerSquareType[]) => {
    removed_player_squares.map((ps:PlayerSquareType) => {
        let existIndex = pSquares.findIndex(s => s.sq_player_square_id == ps.sq_player_square_id)
        if(existIndex != -1){
            pSquares.splice(existIndex, 1)
        }
    })
    return pSquares
}

export const updatePlayerSquareHistoryWithRemovedSquares = (pSquareHistory:PlayerSquareType[], removed_player_squares:PlayerSquareType[], player_id:string) => {
    removed_player_squares.map((ps:PlayerSquareType) => {
        if(ps.player_id == ps.player_id){ pSquareHistory = pSquareHistory.concat(ps) }
    })
    return pSquareHistory
}

export const updateSquareOffersWithUpdatedOffers = (square_offers:SquareOfferType[], updated_offers:SquareOfferType[]) => {
    updated_offers.map(o => {
        switch(o.status){
            case 'offered': square_offers = square_offers.concat(o); break;
            case 'accepted': square_offers = square_offers.filter(so => so.sq_offer_id != o.sq_offer_id); break;  
            case 'rejected': square_offers = square_offers.filter(so => so.sq_offer_id != o.sq_offer_id); break; 
            case 'withdrawn': square_offers = square_offers.filter(so => so.sq_offer_id != o.sq_offer_id); break;
            default: break;
        }
    })
    return square_offers
}