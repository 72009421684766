import React, { useState } from 'react';
import { Image, View } from "react-native";
import Colors from '../constants/Colorsv2';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { Icon } from 'react-native-elements';


type T0HelpModalProps = {
    is_authenticated?:boolean
    client_native_id: string,
    prompt?:string,
    full_screen?:boolean,
    body?:string,
    actions:any[],
    onHelp: (client_native_id:string) => void,
    onClose: () => void,
    noHelp: () => void
}

const T0HelpModal = ({ is_authenticated, full_screen, client_native_id, prompt, body, actions, onHelp, onClose, noHelp }:T0HelpModalProps) => {
    const [ confirm_close, setConfirmClose ] = useState(false);

    return (
        <View style={{ position:'absolute', bottom:0, left:0, right:0, top:full_screen?0:undefined, padding:20, backgroundColor:full_screen?Colors.shades.black_faded:undefined, justifyContent:'flex-end', alignItems:'center' }}>
                <View style={{ maxWidth:600, backgroundColor:Colors.brand.mint, padding:10, borderRadius:8, shadowColor: "#000", shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5 }}>
                {confirm_close ?
                <View>
                    <View style={{ flexDirection:'row', alignItems:'center', justifyContent:'center' }}>
                        <Image
                            source={{ uri: require('../assets/images/help_james.png') }}
                            style={{ height:40, width:40 }}
                            resizeMode='cover'
                        />
                        <Text style={{ marginLeft:10 }} size={16} color={Colors.brand.midnight} textAlign='center' weight='bold'>ARE YOU SURE?</Text>
                    </View>
                    <View style={{ margin:20, justifyContent:'center', alignItems:'center' }}>
                        <Image
                            source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1701793972/help_toggle_k6ibpz.webp' }}
                            style={{ height:200, width:180 }}
                            resizeMode='cover'
                        />
                    </View>
                    <Text style={{ marginTop:10 }} size={14} color={Colors.brand.midnight} weight='semibold' textAlign='center'>If you would like to turn these prompts back on or launch any of our help tutorials, you can do so from your player menu.</Text>
                    <View style={{ flexDirection:'row', alignItems:'center', marginTop:20 }}>
                        <TouchableOpacity style={{ flex:1, marginRight:5, backgroundColor:Colors.brand.electric, padding:10, borderRadius:8 }} onPress={() => {
                            setConfirmClose(false)
                        }}>
                            <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>Keep Helping Me</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flex:1, marginLeft:5, backgroundColor:Colors.utility.error, padding:10, borderRadius:8 }}
                        onPress={() => {
                            noHelp()
                            setConfirmClose(false);
                        }}>
                            <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>Stop Helping Me</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                :
                <View>
                    <View style={{ flexDirection:'row', paddingBottom:10 }}>
                        <TouchableOpacity 
                            style={{ flex:1, flexDirection:'row' }}
                            onPress={() => onHelp(client_native_id)}
                        >
                            <Image
                                source={{ uri: require('../assets/images/help_james.png') }}
                                style={{ height:50, width:50 }}
                                resizeMode='cover'
                            />
                            <View style={{ flex:1, marginLeft:5, marginRight:5 }}>
                                <Text weight='semibold' size={14} color={Colors.brand.midnight}>{prompt??'Need Help?'}</Text>
                                {body ?
                                <Text style={{ marginTop:4 }} weight='regular' size={14} color={Colors.brand.midnight}>{body}</Text>
                                :<></>}
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => onClose()}>
                            <Icon name='x' type='feather' color={Colors.utility.error} size={16} raised/>
                        </TouchableOpacity>
                    </View>
                    {actions.length > 0 ?
                    <View nativeID='action_row' style={{ flexDirection:'row' }}>
                        {actions.map(a => {
                            return (
                                <TouchableOpacity style={{ flex:1, margin:5, padding:10, backgroundColor:a.background_color, borderRadius:8 }} onPress={() => a.onPress()}>
                                    <Text textAlign='center' size={14} color={a.title_color} weight='semibold'>{a.title}</Text>
                                </TouchableOpacity>
                            )
                        })}
                    </View>
                    :<></>}
                    {is_authenticated ?
                    <View style={{ flexDirection:'row', alignItems:'center', padding:10, borderTopWidth:1, borderColor:Colors.shades.shade100 }}>
                        <Text style={{ flex:1 }} size={14} weight='regular'>Got it all figured out?</Text>
                        <TouchableOpacity onPress={() => {
                            setConfirmClose(true)
                        }}>
                            <Text size={14} color={Colors.utility.error} weight='semibold'>Turn off guide</Text>
                        </TouchableOpacity>
                    </View>
                    :<></>}
                </View>
                }
            </View>
        </View>
    )
}

export default T0HelpModal