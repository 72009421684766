import { V1_LOGIN, AuthReducerProps, V1_LOGOUT, V1_UPDATE_ACCOUNT, V1_UPDATE_MARKET_TYPE, V1_LOAD_NOTIFICATION_ID, V1_LOAD_INIT_NOTIFICATION, V1_HIDE_ALERT, V1_VIEW_PROMPT, V1_UPDATE_NO_HELP, V1_UPDATE_DEFAULT_LINES, V1_SHOW_UNIQUE_ORDERS, V1_AUTHENTICATE_APP } from "../actionsv1/types"

const DEFAULT_STATE:AuthReducerProps = {
    isAuthenticated: false,
    market_type: 'FOR_MONEY',
    no_help:false,
    account: { 
        player_id:'',
        role:'player',
        first_name:'', 
        last_name:'', 
        username:'', 
        password:'', 
        rePassword:'', 
        email:'', 
        verified: false,
        phone:'',
        phone_verified:false,
        profile_pic: '',
        legal_ind:true,
        bio:'',
        dob:undefined,
        show_name:'',
        facebook_id:'',
        type: 'freemium',
        vouched_status:'unverified',
        referral_code:undefined,
        location: undefined,
     },
    hidden_alerts:[],
    default_lines: 'best_available',
    show_unique_orders: false,
    access_token: undefined,
    refresh_token: undefined,
    expire_datetime: undefined,
    notification_id: undefined,
    notification: undefined
}

const authReducer = (state = DEFAULT_STATE, action:any):AuthReducerProps => {
    switch(action.type){
        case V1_LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                access_token: action.access_token,
                refresh_token: action.refresh_token,
                expire_datetime: action.expire_datetime
            }
        case V1_AUTHENTICATE_APP:
            return {
                ...state,
                isAuthenticated: action.auth_tokens.authenticated ? true : false
            }
        case V1_UPDATE_ACCOUNT:
            return {
                ...state,
                account: action.account
            }
        case V1_UPDATE_NO_HELP:
            return {
                ...state,
                no_help: action.no_help
            }
        case V1_UPDATE_DEFAULT_LINES:
            return {
                ...state,
                default_lines: action.default_lines
            }
        case V1_HIDE_ALERT:
            let ha = state.hidden_alerts
            if(!ha){
                ha = []
            }
            return {
                ...state,
                hidden_alerts: ha.concat(action.app_alert_id)
            }
        case V1_LOAD_NOTIFICATION_ID:
            return {
                ...state,
                notification_id: action.notification_id
            }
        case V1_SHOW_UNIQUE_ORDERS:
            return {
                ...state,
                show_unique_orders: action.show_unique_orders
            }
        case V1_LOAD_INIT_NOTIFICATION:
            return {
                ...state,
                notification: action.player_notification
            }
        case V1_UPDATE_MARKET_TYPE:
            return {
                ...state,
                market_type: action.market_type
            }
        case V1_LOGOUT:
            return {
                ...DEFAULT_STATE,
                market_type: state.market_type,
                hidden_alerts: state.hidden_alerts,
                notification: state.notification,
                no_help: state.no_help,
                default_lines: state.default_lines,
                notification_id: state.notification_id
            }
        default:
            return state
    }
 }
 
 export default authReducer