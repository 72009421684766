import moment from "moment"
import { updateNotificationBadge } from "../../utils/notifications/webNotification"
import { 
    PlayerReducerProps, 
    V1_ADD_PLAYER_NOTIFICATIONS, 
    V1_CLEAR_PLAYER_NOTIFICATIONS, 
    V1_LOAD_PLAYER_ORDERS, 
    V1_LOAD_PLAYER_REFERRAL,
    V1_LOAD_PLAYER_SETTINGS,
    V1_LOAD_SETTINGS,
    V1_UPDATE_ME,
    V1_UPDATE_PLAYER_ORDER, 
    V1_UPDATE_PLAYER_BALANCE, 
    V1_UPDATE_PLAYER_NOTIFICATION, 
    V1_UPDATE_PLAYER_SETTING, 
    V1_LOAD_ALERT_ORDER,
    V1_LOAD_CHARITIES,
    V1_LOAD_ALERT,
    V1_LOAD_VIEWING_REFERRAL,
    V1_LOAD_PLAYER_FAVORITES,
    V1_LOGOUT,
    V1_LOAD_H2H_REQUESTS,
    V1_LOAD_PLAYER_COUPONS,
    V1_UPDATE_PLAYER_COUPON,
    V1_CLEAR_ALERT,
    V1_LOAD_RESOLVED_ORDERS,
    V1_UPDATE_ORDER_SUGGESTIONS,
    V1_LOAD_PLAYER_HEDGES,
    V1_UPDATE_PLAYER_HEDGE,
    V1_LOAD_PLAYER_VOUCH_TOKEN,
    V1_UPDATE_PROCESSED_HEDGE,
    V1_LOAD_CODE_REQUEST_STATS,
    V1_LOAD_CODE_REQUESTS,
    V1_LOAD_PLAYER_PUSH_SUBSCRIPTIONS,
    V1_UPDATE_PLAYER_PUSH_SUBSCRIPTION,
    V1_UPDATE_PLAYER_NOTIFICATION_COUNT,
    V1_LOAD_PLAYER_PREMIUM_ITEMS,
    V1_LOAD_PREMIUM_ITEMS
} from "../actionsv1/types"
import { PlayerNotificationProps } from "../types/auth_svc"
import { HedgeProps, OrderProps } from "../types/mk_svc"



const DEFAULT_STATE:PlayerReducerProps = {
    player: undefined,
    player_balance: undefined,
    player_referral: undefined,
    player_vouch_token:undefined,
    code_request_stats:undefined,
    promo:undefined,
    reward_option:undefined,
    player_notifications: [],
    player_favorites: [],
    player_premium_items_list: {
        updated:1,
        player_premium_items: []
    },
    premium_items: [],
    player_settings_list: {
        updated:1,
        player_settings: []
    },
    player_notification_count: 0,
    player_coupons:[],
    orders: {
        updated: 1,
        orders: [],
    },
    hedges: {
        updated:1,
        hedges: [],
        orders: []
    },
    player_push_subscriptions_list: {
        updated: 0,
        player_push_subscriptions: []
    },
    resolved_orders:[],
    h2h_requests: [],
    alert_order: undefined,
    settings: [],
    charities: [],
    viewing_player_referral: undefined,
    code_request: undefined,
    viewing_referrer:undefined,
    code_requests_list: {
        updated: 0,
        code_requests: []
    },
    viewing_promo: undefined,
    alerts: [],
}

const playerReducer = (state = DEFAULT_STATE, action:any):PlayerReducerProps => {
    switch(action.type){
        case V1_UPDATE_ME: 
            if(!action.player){
                return {
                    ...state,
                    player: undefined
                }
            }
            let p_updated = 1
            if(state.player?.updated){ p_updated = state.player.updated + 1 }
            return {
                ...state,
                player: { ...action.player, updated: p_updated }
            }
        case V1_UPDATE_PLAYER_BALANCE:
            return {
                ...state,
                player_balance: action.player_balance
            }
        case V1_UPDATE_PLAYER_NOTIFICATION_COUNT:
            return {
                ...state,
                player_notification_count: action.player_notification_count
            }
        case V1_LOAD_PREMIUM_ITEMS:
            return {
                ...state,
                premium_items: action.premium_items
            }
        case V1_LOAD_PLAYER_PREMIUM_ITEMS:
            return {
                ...state,
                player_premium_items_list: {
                    updated: state.player_premium_items_list.updated + 1,
                    player_premium_items: state.player_premium_items_list.player_premium_items.filter(ppi => !action.player_premium_items.find(nppi => nppi.player_premium_item_id == ppi.player_premium_item_id)).concat(action.player_premium_items)
                }

            }
        case V1_ADD_PLAYER_NOTIFICATIONS:
            return {
                ...state,
                player_notifications: action.offset === 0 ? action.player_notifications : state.player_notifications.concat(action.player_notifications)
            }
        case V1_UPDATE_PLAYER_NOTIFICATION:
            let player_notification_count = state.player_notification_count
            if(action.player_notification.status == 'read'){ player_notification_count -= 1 } else { player_notification_count += 1 }
            updateNotificationBadge(player_notification_count)
            return {
                ...state,
                player_notification_count,
                player_notifications: state.player_notifications.filter(pn => pn.player_notification_id != action.player_notification.player_notification_id).concat(action.player_notification)
            }
        case V1_CLEAR_PLAYER_NOTIFICATIONS:
            let new_notifications:PlayerNotificationProps[] = []
            if(action.notification_type){ new_notifications = state.player_notifications.filter(pn => pn.type !== action.notification_type) }
            return {
                ...state,
                player_notifications: new_notifications
            }
        case V1_LOAD_PLAYER_SETTINGS:
            return {
                ...state,
                player_settings_list: {
                    updated: state.player_settings_list.updated + 1,
                    player_settings: action.player_settings
                }
            }
        case V1_LOAD_SETTINGS:
            return {
                ...state,
                settings: action.settings
            }
        case V1_UPDATE_PLAYER_SETTING:
            return {
                ...state,
                player_settings_list: {
                    updated: state.player_settings_list.updated + 1,
                    player_settings: state.player_settings_list.player_settings.filter(ps => ps.player_setting_id != action.player_setting.player_setting_id).concat(action.player_setting)
                }
            }
        case V1_LOAD_PLAYER_FAVORITES:
            return {
                ...state,
                player_favorites:state.player_favorites.filter(f => !action.player_favorites.find(pf => pf.player_favorite_id == f.player_favorite_id) ).concat(action.player_favorites)
            }
        case V1_LOAD_PLAYER_REFERRAL:
            return {
                ...state,
                player_referral: action.player_referral,
                promo:action.promo
            }
        case V1_LOAD_CODE_REQUEST_STATS:
            return {
                ...state,
                code_request_stats: action.code_request_stats
            }
        case V1_LOAD_VIEWING_REFERRAL:
            return {
                ...state,
                viewing_player_referral: action.player_referral,
                viewing_referrer: action.referrer,
                viewing_promo: action.promo,
                code_request: action.code_request,
                reward_option:action.reward_option,
            }
        case V1_LOAD_CODE_REQUESTS:
            return {
                ...state,
                code_requests_list:{
                    updated: state.code_requests_list.updated + 1,
                    code_requests: action.offset == 0 ? action.code_requests : state.code_requests_list.code_requests.concat(action.code_requests)
                }
            }
        case V1_LOAD_PLAYER_VOUCH_TOKEN:
            return {
                ...state,
                player_vouch_token: action.player_vouch_token
            }
        case V1_LOAD_PLAYER_ORDERS:
            let updated = moment().unix()
            if(updated === state.orders.updated){ updated += 1 }
            return {
                ...state,
                orders: {
                    updated,
                    orders: state.orders.orders.filter(o => !action.orders.find((no:OrderProps) => no.order_id == o.order_id)).concat(action.orders)
                }
            }
        case V1_LOAD_PLAYER_HEDGES:
            let hedge_updated = moment().unix()
            if(hedge_updated === state.hedges.updated){ hedge_updated += 1 }
            return {
                ...state,
                hedges: {
                    updated: hedge_updated,
                    hedges: action.hedges,
                    orders: action.orders
                }
            }
        case V1_UPDATE_ORDER_SUGGESTIONS:
            let order = state.orders.orders.find(o => o.order_id == action.order_id)
            if(!order){ return state }
            order = { ...order, jump_queue_probability: action.jump_queue_probability, buy_now_probability: action.buy_now_probability }
            let new_date = moment().unix()
            if(new_date === state.orders.updated){ new_date += 1 }
            return {
                ...state,
                orders: {
                    updated:new_date,
                    orders: state.orders.orders.filter(o => o.order_id != action.order_id).concat(order),
                }
            }
        case V1_LOAD_RESOLVED_ORDERS:
            return {
                ...state,
                resolved_orders: action.offset === 0 ? action.orders : state.resolved_orders.concat(action.orders)
            }
        case V1_LOAD_H2H_REQUESTS:
            return {
                ...state,
                h2h_requests: state.h2h_requests.filter(r => !action.orders.find((o:OrderProps) => o.order_id == r.order_id)).concat(action.orders)
            }
        case V1_UPDATE_PLAYER_ORDER:
            let updated_2 = moment().unix()
            if(updated_2 === state.orders.updated){ updated_2 += 1 }
            return {
                ...state,
                orders: {
                    updated:updated_2,
                    orders: state.orders.orders.filter(o => o.order_id != action.order.order_id).concat(action.order),
                }
            }
        case V1_UPDATE_PLAYER_HEDGE:
            let hedge_updated_2 = moment().unix()
            if(hedge_updated_2 === state.hedges.updated){ hedge_updated_2 += 1 }
            return {
                ...state,
                hedges: {
                    updated:hedge_updated_2,
                    hedges: state.hedges.hedges.filter(h => h.hedge_id != action.hedge.hedge_id).concat(action.hedge),
                }
            }
        case V1_LOAD_ALERT_ORDER:
            return {
                ...state,
                alert_order: action.order
            }
        case V1_LOAD_PLAYER_COUPONS:
            return {
                ...state,
                player_coupons: action.player_coupons
            }
        case V1_UPDATE_PLAYER_COUPON:
            return {
                ...state,
                player_coupons: state.player_coupons.filter(pc => pc.player_coupon_id != action.player_coupon.player_coupon_id).concat(action.player_coupon)
            }
        case V1_LOAD_CHARITIES:
            return {
                ...state,
                charities: action.charities
            }
        case V1_LOAD_ALERT:
            return {
                ...state,
                alerts: state.alerts.concat(action.alert)
            }
        case V1_CLEAR_ALERT:
            return {
                ...state,
                alerts: state.alerts.slice(1)
            }
        case V1_LOAD_PLAYER_PUSH_SUBSCRIPTIONS:
            return {
                ...state,
                player_push_subscriptions_list: {
                    updated: state.player_push_subscriptions_list.updated + 1,
                    player_push_subscriptions: action.player_push_subscriptions
                }
            }
        case V1_UPDATE_PLAYER_PUSH_SUBSCRIPTION:
            return {
                ...state,
                player_push_subscriptions_list: {
                    updated: state.player_push_subscriptions_list.updated + 1,
                    player_push_subscriptions: state.player_push_subscriptions_list.player_push_subscriptions.filter(ps => ps.player_push_subscription_id != action.player_push_subscription.player_push_subscription_id).concat(action.player_push_subscription)
                }
            }
        case V1_LOGOUT:
            return DEFAULT_STATE
        default:
            return state
    }
 }
 
 export default playerReducer