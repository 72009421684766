import moment from "moment"
import { BookStatsProps, OrderBookProps, OrderProps } from "../types/mk_svc"


export const events_getUniqueRangeDays = (existing_days:string[], new_days:string[]):string[] => {
    let combined = existing_days.concat(new_days).sort((a,b) => moment(a).unix() - moment(b).unix())
    return [ ...new Set(combined.map(c => c)) ]
}



export const markets_updateOrderBookWithOrder = (order_book:OrderBookProps | undefined, existing_orders:OrderProps[], order:OrderProps):OrderBookProps | undefined => {
    if(!order_book){ return undefined }
    if(order.event_id != order_book.event_id){ return order_book }
    if(order.event_type !== order_book.event_type){ return order_book }
    if(order.market_id !== order_book.market_id){ return order_book }
    let new_stat:BookStatsProps = {
        var_1: order.var_1,
        side: order.side,
        side_id: order.side_id,
        probability: order.probability,
        order_count: 1
    }
    let book_stat = order_book.book_stats.find(s => s.var_1 == order.var_1 && s.side === order.side && s.side_id === order.side_id)
    let book_orders = existing_orders.filter(o => o.event_id == order.event_id && o.event_type == order.event_type && o.market_id == order.market_id && o.var_1 == order.var_1 && o.side_id == order.side_id)
    
    switch(order.status){
        case 'approved':
            if(!book_stat || order.probability < book_stat.probability){ 
                return { ...order_book, updated: moment().format('hh:mm ss'), book_stats: order_book.book_stats.filter(s => `${s.side}:${s.side_id}:${s.var_1}` !== `${order.side}:${order.side_id}:${order.var_1}`).concat({ ...new_stat, probability: order.probability }) } 
            }
            return order_book
        default:
            if(book_stat && order.probability.toFixed(2) != book_stat.probability.toFixed(2)){ return order_book }
            if(book_orders.length <= 1){
                 return { ...order_book, updated:moment().format('hh:mm ss'), book_stats: order_book.book_stats.filter(s => `${s.side}:${s.side_id}:${s.var_1}` !== `${order.side}:${order.side_id}:${order.var_1}`) } 
            }
            let cheapest_order = book_orders.filter(o => o.order_id != order.order_id).sort((a,b) => a.probability - b.probability)[0]
            return { ...order_book, updated:moment().format('hh:mm ss'), book_stats: order_book.book_stats.filter(s => `${s.side}:${s.side_id}:${s.var_1}` !== `${order.side}:${order.side_id}:${order.var_1}`).concat({ ...new_stat, probability: cheapest_order.probability }) }
    }
}