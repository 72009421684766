import { LOAD_SKRILL_PAYMENT, LOAD_TRANSACTION_HISTORY, LOAD_CUSTOMER_ACCOUNTS, UDPATE_CUSTOMER_ACCOUNT, LOAD_MX_URL, LOGOUT, SAVE_ACH_ACCOUNT, LOAD_IBT_SESSION_TOKEN, LOAD_ACCOUNT_SETTINGS, LOAD_REDIRECT_URL, DEPOSIT_STATUS, UNLINK_ACCOUNT } from '../actions/types';

const DEFAULT_STATE = {
    customer: null,
    accounts : [],
    account_id:undefined,
    accountLimit: undefined,
    accountSnooze:undefined,
    achTransactionDetails: null,
    depositLimit:undefined,
    skrillActive: false,
    skrillHTML : '',
    transactionHistory: [],
    mxURL: '',
    mxActive: false,
    achAccount: null,
    ibt_session_token: undefined,
    redirect_url:undefined,
    depositStatus:undefined,
    instant_amount:0,
    week_withdraw_transactions:0,
    amount: 0,

}

const eventReducer = (state = DEFAULT_STATE, action) => {
    switch(action.type){
        case LOAD_SKRILL_PAYMENT:
            return {
                ...state,
                skrillActive: action.status,
                skrillHTML: action.html
            }
        case LOAD_TRANSACTION_HISTORY:
            return {
                ...state,
                transactionHistory: action.transactionHistory
            }
        case LOAD_CUSTOMER_ACCOUNTS:
            return { 
                ...state,
                customer: action.customer,
                accounts: action.accounts,
                achTransactionDetails: action.achTransactionDetails,
                depositLimit:action.depositLimit
            }
        case UNLINK_ACCOUNT:
            return {
                ...state,
                accounts: state.accounts.filter(a => a.payliance_account_id != action.account.payliance_account_id),
                accountLimit: undefined,
                accountSnooze: undefined
            }
        case LOAD_ACCOUNT_SETTINGS:
            return {
                ...state,
                account_id:action.account_id,
                accountLimit:action.accountLimit,
                accountSnooze:action.accountSnooze,
                week_withdraw_transactions: action.week_withdraw_transactions
            }
        case UDPATE_CUSTOMER_ACCOUNT:
            if(action.add_ind){
                return {
                    ...state,
                    accounts: state.accounts.filter(a => a.payliance_account_id != action.account.payliance_account_id).concat(action.account)
                }
            }
            return {
                ...state,
                accounts: state.accounts.filter(a => a.payliance_account_id !== action.account.payliance_account_id)
            }
        case LOAD_IBT_SESSION_TOKEN:
            return {
                ...state,
                ibt_session_token: action.ibt_session_token
            }
        case LOAD_REDIRECT_URL:
            return {
                ...state,
                redirect_url:action.redirect_url
            }
        case LOAD_MX_URL:
            return {
                ...state,
                mxURL: action.mxURL,
                mxActive: action.active
            }
        case SAVE_ACH_ACCOUNT: 
            return {
                ...state,
                achAccount: action.account
            }
        case DEPOSIT_STATUS:
            return {
                ...state,
                depositStatus:action.status
            }
        case LOGOUT:
            return DEFAULT_STATE
        default:
            return state
    }
}

export default eventReducer;