import Colors from "./Colorsv2";

const Styles = {
    viewStyles: {  
        background: { flex:1, background:Colors.shades.white },
        lozengeContainer : { flexDirection:'row', alignItems:'center', backgroundColor:Colors.brand.midnight, padding:4, borderRadius:22, marginLeft:30, marginRight:30 },
        postContainer: { justifyContent:'center', margin:10, marginBottom:8, marginTop:8, padding:15, borderRadius: 10, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 0, 0, 0.12)", shadowOffset: { width: 0, height: 10 }, shadowRadius: 10, shadowOpacity: 1 },
        reactionBarContainer: { flexDirection:'row', alignItems:'center', paddingTop:9, paddingBottom:9, paddingLeft:16, paddingRight:16, borderRadius: 18, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 41, 120, 0.12)", shadowOffset: { width: 0, height: 2 }, shadowRadius: 4, shadowOpacity: 1},
        messageInputContainer: {  flexDirection:'row', alignItems:'center', backgroundColor: Colors.shades.white, shadowColor: "rgba(221, 221, 221, 0.6)", shadowOffset: { width: 0, height: -15 }, shadowRadius: 20, shadowOpacity: 1 },
        reactButtonContainer: { flexDirection:'row', alignItems:'center', padding:9, paddingRight:13, borderRadius: 18, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 41, 120, 0.12)", shadowOffset: { width: 0, height: 2 }, shadowRadius: 4, shadowOpacity: 1 },
        competitionCardStyle: { borderRadius:8, backgroundColor:'rgba(26,205,106,0.1)', margin:6, borderWidth:1, borderColor:Colors.highlights.highlight400Faded },
        modalCloseContainer: { backgroundColor: Colors.shades.white, shadowColor: "rgba(221, 221, 221, 0.6)", shadowOffset: { width: 0, height: -15 }, shadowRadius: 20, shadowOpacity: 1},
        positionCardContainer: { margin:10, padding:15, borderRadius: 10, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 0, 0, 0.12)", shadowOffset: {  width: 0,height: 10 }, shadowRadius: 10, shadowOpacity: 1 },
        profileToggleContainer: {flexDirection:'row', alignItems:'center', backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 0, 0, 0.06)", shadowOffset: { width: 0, height: 10 }, shadowRadius: 10, shadowOpacity: 1 },
        textToolbarContainer : { flexDirection:'row', alignItems:'center', borderRadius: 2, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 0, 0, 0.06)", shadowOffset: { width: 0, height: 10 },  shadowRadius: 10, shadowOpacity: 1 },
        playerFollowerCardStyle: {  margin:8, padding:20, paddingLeft:12, paddingRight:12, borderRadius: 4, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 0, 0, 0.12)", shadowOffset: { width: 0, height: 2 }, shadowRadius: 2, shadowOpacity: 1},
        navStyle: { flex:1, flexDirection:'row', alignItems:'center', justifyContent:'space-between' },
        playerTagStyle: { padding:10, marginTop:2, marginBottom:2, borderRadius: 18, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 41, 120, 0.12)", shadowOffset: { width: 0, height: 2 }, shadowRadius: 4, shadowOpacity: 1 },
        buttonBodyStyle: { borderRadius: 22.8, backgroundColor: Colors.shades.shade400, paddingTop:12, paddingBottom:12, paddingLeft:20, paddingRight:20 },
        smallButtonBodyStyle: { borderRadius: 22.8, backgroundColor: Colors.brand.electric, height:36, paddingRight:18, paddingLeft:18, justifyContent:'center' },
        mentionsContainer : { margin:20, padding:10, borderRadius: 10, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 0, 0, 0.12)", shadowOffset: { width: 0, height: 10 }, shadowRadius: 10, shadowOpacity: 1, borderStyle: "solid", borderWidth: 1, borderColor: Colors.highlights.highlight200 },
        cardFollow: { flexDirection:'row', alignItems:'center', padding:5, paddingTop:20, paddingBottom:20 },
        line: { flex:1, height: 1, opacity: 0.1, borderStyle: "solid", borderWidth: 1, borderColor: Colors.brand.cobalt },
        buttonSquare: { borderRadius:4, padding:11, backgroundColor:Colors.brand.electric, justifyContent:'center', alignItems:'center' },
        buttonSquareOutline: { borderRadius:4, borderWidth:1, borderColor:Colors.brand.cobalt, padding:11, justifyContent:'center', alignItems:'center' },
        searchBoxStyle: { flexDirection: 'row', alignItems:'center', padding:11, borderRadius:4, backgroundColor:Colors.shades.shade400 },
        sportCardStyle: { 
            flexDirection:'row', margin:4, padding:10, paddingLeft:20, paddingRight:20, alignItems:'center',
            borderRadius: 54, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 0, 0, 0.15)", shadowOffset: { width: 0, height: 2}, shadowRadius: 4,
            shadowOpacity: 1, borderStyle: "solid", borderWidth: 1, borderColor: "#f8f8f8" },
        buttonStyle: { position: 'absolute', bottom:50, right:10, borderRadius:100, justifyContent: 'center', alignItems:'center', backgroundColor:Colors.shades.white, borderColor:Colors.highlights.highlight400,
        shadowColor: "#000", shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5 },
        inputContainer: { borderWidth:1, borderColor:Colors.accents.accent100, borderRadius: 4, backgroundColor:Colors.shades.white },
        halfPageButtonBody: { flex:1/2, flexDirection:'row', height:'63.5px', backgroundColor:Colors.brand.electric, justifyContent:'center', alignItems:'center' },
        rectangle: { padding:'20px', marginRight:'40px', marginLeft:'40px', marginTop:'24px', backgroundColor:Colors.brand.cobalt, borderRadius:'4px' },
        socialButtonBody: { flexDirection:'row', justifyContent:'center', alignItems:'center', backgroundColor:Colors.brand.facebook, borderRadius:22.8 },
        marketEventCard: { padding:7, margin:8, borderRadius: 12, borderWidth: 1, borderColor: Colors.shades.shade400 },
        marketEventOddsButton: {  flex:1/3, padding:4, margin:4, borderRadius: 2, backgroundColor: "#eff5ff", shadowColor: "#b6bfde", shadowOffset: { width: 0, height: 1 }, shadowRadius: 0, shadowOpacity: 1 },
        pickedEventOddsButton: {  flex:1/3, padding:4, margin:4, borderRadius: 2, backgroundColor: Colors.highlights.highlight200, shadowColor: "#b6bfde", shadowOffset: { width: 0, height: 1 }, shadowRadius: 0, shadowOpacity: 1 },
        containerWhiteShadow: { marginBottom:12, marginLeft:40, marginRight:60, borderRadius: 8, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 0, 0, 0.16)", shadowOffset: { width: 0, height: 2 }, shadowRadius: 12, shadowOpacity: 1 },
        toggleBox: { flex:1, padding:2, flexDirection:'row', borderRadius: 6, backgroundColor: "#e9e9e9" },
        raisedRoundedContainer: { borderRadius:10, padding:10, margin:5, justifyContent: 'center', alignItems:'center', backgroundColor:Colors.shades.white, borderColor:Colors.highlights.highlight400,
        shadowColor: "#000", shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5 },
        inactiveSocialToggle: { paddingTop:14, paddingBottom:14, paddingRight:6, paddingLeft:6},
        activeSocialToggle: { paddingTop:14, paddingBottom:14, paddingRight:6, paddingLeft:6, backgroundColor:Colors.shades.shade100, borderBottomWidth:2, borderBottomColor:Colors.highlights.highlight200  },
        inactiveToggle: { padding:12, justifyContent:'center', alignItems:'center' },
        activeToggle: { padding:12, justifyContent:'center', alignItems:'center' , borderRadius: 5, backgroundColor: Colors.shades.white, shadowColor: "rgba(0, 0, 0, 0.2)", shadowOffset: { width: 0, height: 1 }, shadowRadius: 0, shadowOpacity: 1 }
     },
    textStyles: {
        pageHeading: {  marginTop: '16px', marginRight:'113px', marginBottom:'36px', marginLeft:'40px', fontFamily: 'roboto-bold', fontSize:'32px', color:Colors.brand.cobalt },
        accountBalance: { fontFamily: 'barlow-regular', color:Colors.shades.white, textAlign:'left', fontSize:14 },
        accountName: { fontFamily: 'barlow-bold', color:Colors.shades.white, textAlign:'center', fontSize:16 },
        notifyAlert: { fontFamily: 'barlow-regular', color:Colors.shades.white, textAlign:'center', fontSize:8 },
        sectionTitle: { padding:12, paddingLeft:16, fontFamily: 'barlow-semibold', fontSize:20, color:Colors.shades.black, textAlign:'left', letterSpacing:0 },
        step1Of3: { marginTop: '0px', marginRight:'258px', marginBottom:'16px', marginLeft:'40px', fontFamily: 'roboto-regular', fontSize:'16px', color:Colors.brand.slate },
        emailAddress: { flex:1, paddingTop:'8px', paddingBottom:'8px', borderBottomWidth:'1px', borderBottomColor:Colors.brand.slate, marginTop: '0px', marginRight:'0px', marginBottom:'16px', marginLeft:'0px', fontFamily:'roboto-regular', fontSize:'16px', color:Colors.brand.cobalt},
        whiteDescription: { fontFamily: 'roboto-regular', fontSize:16, color:Colors.shades.white, textAlign:'center' },
        fadedDescription: { fontFamily: 'roboto-regular', fontSize:16, color:Colors.brand.slate, textAlign:'center' },
        darkDescription: { fontFamily: 'roboto-regular', fontSize:16, color:Colors.brand.cobalt, textAlign:'center' },
        darkDescription14: { fontFamily: 'roboto-regular', fontSize:14, color:Colors.brand.cobalt, textAlign:'left' },
        errorDescription: { fontFamily: 'roboto-regular', fontSize:16, color:Colors.utility.error, textAlign:'center' },
        darkDescriptionBold: { fontFamily: 'roboto-bold', fontSize:16, color:Colors.brand.cobalt, textAlign:'left' },
        darkDescriptionBold14: { fontFamily: 'roboto-bold', fontSize:14, color:Colors.brand.cobalt, textAlign:'left' },
        darkDescriptionMedium: { fontFamily: 'roboto-medium', fontSize:16, color:Colors.brand.cobalt, textAlign:'left' },
        text: { fontFamily: "roboto-regular", fontSize: 16, color: Colors.brand.cobalt, textAlign: 'center' },
        sportText: { fontFamily: 'barlow-bold', fontSize:16, color:Colors.shades.black, textAlign:'right', letterSpacing:0 },
        dateTime: { fontFamily:'barlow-semibold', fontSize: 12, color:Colors.shades.black, textAlign:'left', letterSpacing:0 },
        line: { fontFamily:'barlow-regular', fontSize: 12, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        pickedLine: { fontFamily:'barlow-regular', fontSize: 12, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },
        price: { fontFamily:'barlow-semibold', fontSize: 12, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        pickedPrice: { fontFamily:'barlow-semibold', fontSize: 12, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },

        marketLabel: { fontFamily:'barlow-regular', fontSize: 12, color:Colors.brand.slate, textAlign:'center', letterSpacing:-0.5 },
        marketTeamText: { fontFamily:'barlow-bold', fontSize: 12, color:Colors.shades.black, textAlign:'left', letterSpacing:0 },
        marketTouchableText: { fontFamily:'barlow-regular', fontSize: 12, color:Colors.brand.electric, letterSpacing:0 },
        competitionTimeDate: { fontFamily:'barlow-semibold', fontSize: 16, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        eventTitle: { fontFamily:'barlow-bold', fontSize: 20, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        eventSubTitle: { fontFamily:'barlow-bold', fontSize: 14, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        eventSubTitleWhite: { fontFamily:'barlow-bold', fontSize: 14, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },
        eventDesc: { fontFamily:'barlow-regular', fontSize: 12, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        activeToggleText: { fontFamily:'barlow-bold', fontSize: 14, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        buttonTextBold: { fontFamily:'barlow-bold', fontSize: 14, color:Colors.brand.cobalt, textAlign:'center', letterSpacing:0 },
        inactiveToggleText: { fontFamily:'barlow-regular', fontSize: 14, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        //NEW STRUCTURED 
        //Blue
        blueRegularCenter14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.cobalt, textAlign:'center', letterSpacing:0 },
        blueRegularLeft14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.cobalt, textAlign:'left', letterSpacing:0 },
        blueRegularRight14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.cobalt, textAlign:'right', letterSpacing:0 },
        blueBoldCenter14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.brand.cobalt, textAlign:'center', letterSpacing:0 },
        blueBoldLeft14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.brand.cobalt, textAlign:'left', letterSpacing:0 },
        blueBoldRight14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.brand.cobalt, textAlign:'right', letterSpacing:0 },
        blueBoldCenter16: { fontFamily:'barlow-bold', fontSize:16, color:Colors.brand.cobalt, textAlign:'center', letterSpacing:0 },
        blueBoldLeft16: { fontFamily:'barlow-bold', fontSize:16, color:Colors.brand.cobalt, textAlign:'left', letterSpacing:0 },
        blueBoldRight16: { fontFamily:'barlow-bold', fontSize:16, color:Colors.brand.cobalt, textAlign:'right', letterSpacing:0 },


        greyRegularCenter14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.slate, textAlign:'center', letterSpacing:0 },
        greyRegularRight14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.slate, textAlign:'right', letterSpacing:0 },
        greyRegularLeft14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.slate, textAlign:'left', letterSpacing:0 },
        greyRegularCenter16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.brand.slate, textAlign:'center', letterSpacing:0 },
        greyRegularRight16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.brand.slate, textAlign:'right', letterSpacing:0 },
        greyRegularLeft16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.brand.slate, textAlign:'left', letterSpacing:0 },
        
        //Success texts
        successRegularCenter12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.highlights.highlight400, textAlign:'center', letterSpacing:0 },
        successRegularRight12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.highlights.highlight400, textAlign:'right', letterSpacing:0 },
        successRegularLeft12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.highlights.highlight400, textAlign:'left', letterSpacing:0 },
        successRegularCenter16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.highlights.highlight400, textAlign:'center', letterSpacing:0 },
        successRegularRight16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.highlights.highlight400, textAlign:'right', letterSpacing:0 },
        successRegularLeft16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.highlights.highlight400, textAlign:'left', letterSpacing:0 },
        
        
        fadedRegularLeft12: { fontFamily: 'barlow-regular', fontSize:12, color:Colors.brand.slate, textAlign:'left' },
        fadedRegularCenter12: { fontFamily: 'barlow-regular', fontSize:12, color:Colors.brand.slate, textAlign:'center' },
        fadedRegularRight12: { fontFamily: 'barlow-regular', fontSize:12, color:Colors.brand.slate, textAlign:'right' },
        
        //
        blackBoldCenter12: { fontFamily:'barlow-bold', fontSize:12, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        blackBoldRight12: { fontFamily:'barlow-bold', fontSize:12, color:Colors.shades.black, textAlign:'right', letterSpacing:0 },
        blackBoldLeft12: { fontFamily:'barlow-bold', fontSize:12, color:Colors.shades.black, textAlign:'left', letterSpacing:0 },
        blackBoldCenter14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        blackBoldRight14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.shades.black, textAlign:'right', letterSpacing:0 },
        blackBoldLeft14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.shades.black, textAlign:'left', letterSpacing:0 },
        blackBoldCenter16: { fontFamily:'barlow-bold', fontSize:16, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        blackBoldRight16: { fontFamily:'barlow-bold', fontSize:16, color:Colors.shades.black, textAlign:'right', letterSpacing:0 },
        blackBoldLeft16: { fontFamily:'barlow-bold', fontSize:16, color:Colors.shades.black, textAlign:'left', letterSpacing:0 },
        //
        blackRegularCenter12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        blackRegularRight12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.shades.black, textAlign:'right', letterSpacing:0 },
        blackRegularLeft12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.shades.black, textAlign:'left', letterSpacing:0 },
        blackRegularCenter14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        blackRegularRight14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.shades.black, textAlign:'right', letterSpacing:0 },
        blackRegularLeft14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.shades.black, textAlign:'left', letterSpacing:0 },
        blackRegularCenter16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.shades.black, textAlign:'center', letterSpacing:0 },
        blackRegularRight16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.shades.black, textAlign:'right', letterSpacing:0 },
        blackRegularLeft16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.shades.black, textAlign:'left', letterSpacing:0 },
        //
        whiteRegularCenter8: { fontFamily:'barlow-regular', fontSize:8, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },
        whiteRegularRight8: { fontFamily:'barlow-regular', fontSize:8, color:Colors.shades.white, textAlign:'right', letterSpacing:0 },
        whiteRegularLeft8: { fontFamily:'barlow-regular', fontSize:8, color:Colors.shades.white, textAlign:'left', letterSpacing:0 },
        whiteRegularCenter10: { fontFamily:'barlow-regular', fontSize:10, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },
        whiteRegularRight10: { fontFamily:'barlow-regular', fontSize:10, color:Colors.shades.white, textAlign:'right', letterSpacing:0 },
        whiteRegularLeft10: { fontFamily:'barlow-regular', fontSize:10, color:Colors.shades.white, textAlign:'left', letterSpacing:0 },

        whiteRegularCenter12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },
        whiteRegularRight12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.shades.white, textAlign:'right', letterSpacing:0 },
        whiteRegularLeft12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.shades.white, textAlign:'left', letterSpacing:0 },


        
        whiteRegularCenter16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },
        whiteRegularRight16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.shades.white, textAlign:'right', letterSpacing:0 },
        whiteRegularLeft16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.shades.white, textAlign:'left', letterSpacing:0 },
        whiteBoldCenter16: { fontFamily:'barlow-bold', fontSize:16, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },
        whiteBoldLeft16: { fontFamily:'barlow-bold', fontSize:16, color:Colors.shades.white, textAlign:'left', letterSpacing:0 },
        whiteBoldRight16: { fontFamily:'barlow-bold', fontSize:16, color:Colors.shades.white, textAlign:'right', letterSpacing:0 },

        whiteBoldCenter12: { fontFamily:'barlow-bold', fontSize:12, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },
        whiteBoldLeft12: { fontFamily:'barlow-bold', fontSize:12, color:Colors.shades.white, textAlign:'left', letterSpacing:0 },
        whiteBoldRight12: { fontFamily:'barlow-bold', fontSize:12, color:Colors.shades.white, textAlign:'right', letterSpacing:0 },

        whiteBoldCenter14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },
        whiteBoldLeft14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.shades.white, textAlign:'left', letterSpacing:0 },
        whiteBoldRight14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.shades.white, textAlign:'right', letterSpacing:0 },
        whiteRegularCenter14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },
        whiteRegularLeft14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.shades.white, textAlign:'left', letterSpacing:0 },
        whiteRegularRight14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.shades.white, textAlign:'right', letterSpacing:0 },
        whiteBoldCenter20: { fontFamily:'barlow-bold', fontSize:20, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },
        whiteBoldLeft20: { fontFamily:'barlow-bold', fontSize:20, color:Colors.shades.white, textAlign:'left', letterSpacing:0 },
        whiteBoldRight20: { fontFamily:'barlow-bold', fontSize:20, color:Colors.shades.white, textAlign:'right', letterSpacing:0 },
        whiteRegularCenter20: { fontFamily:'barlow-regular', fontSize:20, color:Colors.shades.white, textAlign:'center', letterSpacing:0 },
        whiteRegularLeft20: { fontFamily:'barlow-regular', fontSize:20, color:Colors.shades.white, textAlign:'left', letterSpacing:0 },
        whiteRegularRight20: { fontFamily:'barlow-regular', fontSize:20, color:Colors.shades.white, textAlign:'right', letterSpacing:0 },
        //Midnight
        midnightBoldLeft12: { fontFamily:'barlow-bold', fontSize:12, color:Colors.brand.midnight, textAlign:'left', letterSpacing:0 },
        midnightBoldCenter12: { fontFamily:'barlow-bold', fontSize:12, color:Colors.brand.midnight, textAlign:'center', letterSpacing:0 },
        midnightBoldRight12: { fontFamily:'barlow-bold', fontSize:12, color:Colors.brand.midnight, textAlign:'right', letterSpacing:0 },

        midnightRegularLeft12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.brand.midnight, textAlign:'left', letterSpacing:0 },
        midnightRegularCenter12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.brand.midnight, textAlign:'center', letterSpacing:0 },
        midnightRegularRight12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.brand.midnight, textAlign:'right', letterSpacing:0 },

        midnightMediumLeft12: { fontFamily:'barlow-semibold', fontSize:12, color:Colors.brand.midnight, textAlign:'left', letterSpacing:0 },
        midnightMediumCenter12: { fontFamily:'barlow-semibold', fontSize:12, color:Colors.brand.midnight, textAlign:'center', letterSpacing:0 },
        midnightMediumRight12: { fontFamily:'barlow-semibold', fontSize:12, color:Colors.brand.midnight, textAlign:'right', letterSpacing:0 },

        midnightMediumLeft14: { fontFamily:'barlow-semibold', fontSize:14, color:Colors.brand.midnight, textAlign:'left', letterSpacing:0 },
        midnightMediumCenter14: { fontFamily:'barlow-semibold', fontSize:14, color:Colors.brand.midnight, textAlign:'center', letterSpacing:0 },
        midnightMediumRight14: { fontFamily:'barlow-semibold', fontSize:14, color:Colors.brand.midnight, textAlign:'right', letterSpacing:0 },
        midnightMediumLeft16: { fontFamily:'barlow-semibold', fontSize:16, color:Colors.brand.midnight, textAlign:'left', letterSpacing:0 },
        midnightMediumCenter16: { fontFamily:'barlow-semibold', fontSize:16, color:Colors.brand.midnight, textAlign:'center', letterSpacing:0 },
        midnightMediumRight16: { fontFamily:'barlow-semibold', fontSize:16, color:Colors.brand.midnight, textAlign:'right', letterSpacing:0 },
        midnightRegularLeft14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.midnight, textAlign:'left', letterSpacing:0 },
        midnightRegularCenter14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.midnight, textAlign:'center', letterSpacing:0 },
        midnightRegularRight14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.midnight, textAlign:'right', letterSpacing:0 },
        midnightBoldLeft14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.brand.midnight, textAlign:'left', letterSpacing:0 },
        midnightBoldCenter14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.brand.midnight, textAlign:'center', letterSpacing:0 },
        midnightBoldRight14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.brand.midnight, textAlign:'right', letterSpacing:0 },
        midnightRegularLeft16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.brand.midnight, textAlign:'left', letterSpacing:0 },
        midnightRegularCenter16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.brand.midnight, textAlign:'center', letterSpacing:0 },
        midnightRegularRight16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.brand.midnight, textAlign:'right', letterSpacing:0 },
        midnightBoldLeft16: { fontFamily:'barlow-bold', fontSize:16, color:Colors.brand.midnight, textAlign:'left', letterSpacing:0 },
        midnightBoldCenter16: { fontFamily:'barlow-bold', fontSize:16, color:Colors.brand.midnight, textAlign:'center', letterSpacing:0 },
        midnightBoldRight16: { fontFamily:'barlow-bold', fontSize:16, color:Colors.brand.midnight, textAlign:'right', letterSpacing:0 },
        midnightBoldLeft20: { fontFamily:'barlow-bold', fontSize:20, color:Colors.brand.midnight, textAlign:'left', letterSpacing:0 },
        midnightBoldCenter20: { fontFamily:'barlow-bold', fontSize:20, color:Colors.brand.midnight, textAlign:'center', letterSpacing:0 },
        midnightBoldRight20: { fontFamily:'barlow-bold', fontSize:20, color:Colors.brand.midnight, textAlign:'right', letterSpacing:0 },
        midnightMediumLeft20: { fontFamily:'barlow-semibold', fontSize:20, color:Colors.brand.midnight, textAlign:'left', letterSpacing:0 },
        midnightMediumCenter20: { fontFamily:'barlow-semibold', fontSize:20, color:Colors.brand.midnight, textAlign:'center', letterSpacing:0 },
        midnightMediumRight20: { fontFamily:'barlow-semibold', fontSize:20, color:Colors.brand.midnight, textAlign:'right', letterSpacing:0 },
        //Primary50
        primary50RegularLeft12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.accents.accent200, textAlign:'left', letterSpacing:0 },
        primary50RegularCenter12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.accents.accent200, textAlign:'center', letterSpacing:0 },
        primary50RegularRight12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.accents.accent200, textAlign:'right', letterSpacing:0 },

        //Grey
        grey300RegularLeft8: { fontFamily:'barlow-regular', fontSize:8, color:Colors.brand.slate, textAlign:'left', letterSpacing:0 },
        grey300RegularCenter8: { fontFamily:'barlow-regular', fontSize:8, color:Colors.brand.slate, textAlign:'center', letterSpacing:0 },
        grey300RegularRight8: { fontFamily:'barlow-regular', fontSize:8, color:Colors.brand.slate, textAlign:'right', letterSpacing:0 },
        grey300RegularLeft12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.brand.slate, textAlign:'left', letterSpacing:0 },
        grey300RegularCenter12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.brand.slate, textAlign:'center', letterSpacing:0 },
        grey300RegularRight12: { fontFamily:'barlow-regular', fontSize:12, color:Colors.brand.slate, textAlign:'right', letterSpacing:0 },
        grey300RegularLeft14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.slate, textAlign:'left', letterSpacing:0 },
        grey300RegularCenter14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.slate, textAlign:'center', letterSpacing:0 },
        grey300RegularRight14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.slate, textAlign:'right', letterSpacing:0 },
        grey300RegularLeft16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.brand.slate, textAlign:'left', letterSpacing:0 },
        grey300RegularCenter16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.brand.slate, textAlign:'center', letterSpacing:0 },
        grey300RegularRight16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.brand.slate, textAlign:'right', letterSpacing:0 },
        //Electric
        electricRegularLeft14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.electric, textAlign:'left', letterSpacing:0 },
        electricRegularCenter14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.electric, textAlign:'center', letterSpacing:0 },
        electricRegularRight14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.brand.electric, textAlign:'right', letterSpacing:0 },
        electricRegularLeft16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.brand.electric, textAlign:'left', letterSpacing:0 },
        electricRegularCenter16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.brand.electric, textAlign:'center', letterSpacing:0 },
        electricRegularRight16: { fontFamily:'barlow-regular', fontSize:16, color:Colors.brand.electric, textAlign:'right', letterSpacing:0 },
        //highlight
        highlightRegularLeft14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.highlights.highlight200, textAlign:'left', letterSpacing:0 },
        highlightRegularCenter14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.highlights.highlight200, textAlign:'center', letterSpacing:0 },
        highlightRegularRight14: { fontFamily:'barlow-regular', fontSize:14, color:Colors.highlights.highlight200, textAlign:'right', letterSpacing:0 },
        highlightBoldLeft14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.highlights.highlight200, textAlign:'left', letterSpacing:0 },
        highlightBoldCenter14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.highlights.highlight200, textAlign:'center', letterSpacing:0 },
        highlightBoldRight14: { fontFamily:'barlow-bold', fontSize:14, color:Colors.highlights.highlight200, textAlign:'right', letterSpacing:0 }
    } 
}

export default Styles
 