import moment from "moment"
import { AnalyticsReducerProps, V1_LOAD_CATEGORIES, V1_LOAD_LEADERS, V1_LOAD_OVERALL_METRICS, V1_LOAD_TIMERANGES, V1_UPDATE_LEADER, V1_UPDATE_METRIC_FILTER } from "../actionsv1/types"
import { LeaderProps } from "../types/analytics_svc"


const DEFAULT_STATE:AnalyticsReducerProps = {
    leaders:[],
    filter: { market_type:'FREE', active_filter:'week', data_level:'overall', range:{ start_time:moment().subtract(7, 'days'), end_time: moment() } },
    metrics: { overall: [], markets: [], competitions: [], squares: [] },
    order_ids: [],
    stored_categories:[],
    stored_timeranges:[]
}

const analyticsReducer = (state = DEFAULT_STATE, action:any) => {
    switch(action.type){
        case V1_LOAD_LEADERS:
            return {
                ...state,
                leaders: state.leaders.filter(l => !action.leaders.find((nl:LeaderProps) => nl.leader_id == l.leader_id)).concat(action.leaders),
                stored_categories: action.category ? state.stored_categories.filter(c => c.category_id != action.category.category_id).concat(action.category) : state.stored_categories,
                stored_timeranges: action.time_range ? state.stored_timeranges.filter(t => t.timerange_id != action.timerange.timerange_id).concat(action.timerange) : state.stored_timeranges
            }
        case V1_LOAD_CATEGORIES:
            return {
                ...state,
                stored_categories: action.categories
            }
        case V1_UPDATE_LEADER:
            return {
                ...state,
                leaders: state.leaders.filter((l:LeaderProps) => l.leader_id != action.leader.leader_id).concat(action.leader)
            }
        case V1_LOAD_TIMERANGES:
            return {
                ...state,
                stored_timeranges: action.time_ranges
            }
        case V1_UPDATE_METRIC_FILTER:
            return {
                ...state,
                filter: action.filter
            }
        case V1_LOAD_OVERALL_METRICS:
            return {
                ...state,
                metrics: { ...state.metrics, [action.filter.data_level] : action.metrics_data }, 
                order_ids: action.order_ids,
                //competitions: action.competitions,
                //squares: action.squares,
                filter: action.filter
            }
        default:
            return state
    }
}


export default analyticsReducer