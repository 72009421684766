import React, { useState, useRef } from 'react'
import { TouchableOpacity } from 'react-native'
import { useDispatch } from 'react-redux'
import { player_getHelpPromptById } from '../store/actionsv1/player'

type HelpCalloutProps = { 
    client_native_id:string, style?:any, 
    children?:any, disabled?:boolean, pressed?:any
}
const HelpCallout = ({ client_native_id, style, children, disabled, pressed }: HelpCalloutProps ) => {
    const [ calloutSize, setCalloutSize ] = useState({ height:0, width:0 })
    const helpRef = useRef()


    const dispatch = useDispatch();

    const handleCalloutPress = () => {
        if(disabled){ return }
        if(!helpRef?.current){ return }
        if(pressed){ pressed() }
        const { top, left } = getCoords(helpRef.current)
        dispatch(player_getHelpPromptById(client_native_id))
    }

    return (
        <TouchableOpacity ref={helpRef} style={{ ...style }} disabled={disabled} onPress={() => handleCalloutPress()}
        onLayout={(ev) => {
            const { height, width } = ev.nativeEvent.layout
            setCalloutSize({ height, width })
        }}>
            {children}
        </TouchableOpacity>
    )
}

const getCoords = (elem:any) => { // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top  = box.top +  scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
}


export default HelpCallout