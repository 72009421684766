import React, { useState } from 'react';
import { View } from 'react-native';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { HelpPromptType } from '../objectTypes';

type T4HelpModalProps = { help_prompt:HelpPromptType, tooltipLocation?:{ x:number, y:number, height:number, width:number }, tip_direction?:string, clearHelp:any }
const T4HelpModal = ({ help_prompt, tooltipLocation, tip_direction, clearHelp }: T4HelpModalProps) => {
    const [ toolTipSize, setToolTipSize ] = useState({ width:0, height:0 })

    const getTooltipContentStyle = () => {
        if(!tooltipLocation){ return {} }
        let maxWidth = toolTipSize.width - (toolTipSize.width - (tooltipLocation.x - (tooltipLocation.width / 2)))
        let dir = tip_direction ?? 'bottom'
        switch(dir){
            case 'left': 
                return { position:'absolute', top:(tooltipLocation.y - tooltipLocation.height), left:0, maxWidth }
            case 'right': 
                return { position:'absolute', top:(tooltipLocation.y - tooltipLocation.height), right:0, maxWidth }
            case 'bottom':
                return { position:'absolute', top:(tooltipLocation.y + (tooltipLocation.height/2)), left:0, right:0 }
            default: return {}
        }
    }

    return (
        <TouchableOpacity native_id='btx_clear_t4_help' style={{position:'absolute', bottom:0, top:0, right:0, left:0, padding:20}} onLayout={(ev:any) => {
             const { width, height } = ev.nativeEvent.layout
             setToolTipSize({ width, height })
        }}
        onPress={() => clearHelp()}>
            <View style={[ {backgroundColor:'#566BF6', padding:15, margin:10, borderRadius:4}, getTooltipContentStyle()]}>
                 <Text size={12} color={help_prompt.body_font_color} weight='regular'>{help_prompt.body}</Text>
            </View>
        </TouchableOpacity>
    )
}

export default T4HelpModal