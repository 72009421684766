import getEnvVars from "../../env";
import { PublicPlayerType } from "../../objectTypes"
const { ENVIRONMENT } = getEnvVars();
import { isIOS, isSafari, isAndroid, isChrome, deviceDetect } from 'react-device-detect';

const isAndroidPWAInstalled = () => {
    return (window.matchMedia('(display-mode: standalone)').matches);
}
export const isPWAInstalled = () => {
    if(isSafari && isIOS){ return isIOSPWAInstalled() }
    if(isAndroid){ return isAndroidPWAInstalled() }
    return false
}

const isIOSPWAInstalled = () => {
    if(('standalone' in window.navigator) && (window.navigator.standalone)){ return true }
    return false
}
export const device = { ...deviceDetect(), pwa:isPWAInstalled() }



export const startSegment = () => {
    if(ENVIRONMENT === 'DEVELOPMENT'){ return }
   let segmentKey = ''
    switch(ENVIRONMENT){
        case 'DEVELOPMENT':
            segmentKey = "LiJfVvGJGfGrD3xTqD5oce9hi7BJBGWk";
            break
        case 'STAGING':
            segmentKey = "Yc73YD8dxlVuKSRrosiESULy5ImiEBZD";
            break
        case 'PRODUCTION':
            segmentKey = "JGskobR9PmFc26dv3aDQLO9FTWMKPXVF";
            break
        default: return
    }
    const script = document.createElement("script");
    script.src = function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="LiJfVvGJGfGrD3xTqD5oce9hi7BJBGWk";analytics.SNIPPET_VERSION="4.13.2";
    analytics.load(segmentKey);
    analytics.page();
  }}();
}


export const segPageHit = (pageName:string) => {
    if(window.analytics){
        window.analytics.page(pageName, { device })
    }
}


type AnalyticsTrackType = { event:string, properties: any}

export const segEventTrack = (track:AnalyticsTrackType) => {
    if(window.analytics){
        window.analytics.track(track.event, { ...track.properties, device })
    }
}

type AnalyticsIdentifyType = { userId:string, traits: PublicPlayerType }

export const segIdentify = (identity:AnalyticsIdentifyType) => {
    if(window.analytics){
        window.analytics.identify(identity.userId, { ...identity.traits, device })
    }
}