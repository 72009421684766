import React from 'react';
import { MyWallet, Components, BEEventApi } from 'be-components';
import { View } from 'react-native';
import Colors from '../constants/Colorsv2';
import { useDispatch, useSelector } from 'react-redux';
import { V1_SHOW_CHECKOUT, V1_SHOW_WALLET } from '../store/actionsv1/types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const WalletHolder = () => {
    const dispatch = useDispatch();
    const insets = useSafeAreaInsets();
    const show_wallet = useSelector((state:any) => state.util.show_wallet, (left, right) => left == right);
    const app_size = useSelector((state:any) => state.util.app_size, (left, right) => left?.height == right?.height);
    const player_balance = useSelector((state:any) => state.player.player_balance, (left, right) => left?.last_update_datetime == right?.last_update_datetime);
    const alignment = app_size.width > 500 ? 'center' : 'flex-end'
    const width = app_size.width > 500 ? 500 : app_size.width
    if(!show_wallet.visible){ return <></> }

    const wallet_height = app_size.height - insets.bottom - insets.top

    return (
        <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:alignment, alignItems:'center' }}>
            <Components.Spring
                to={0}
                from={app_size.height}
                slide='vertical'
                style={{ 
                    borderTopRightRadius:alignment=='center'?22:0, 
                    borderTopLeftRadius:alignment=='center'?22:0,
                    borderBottomLeftRadius:alignment=='center'?22:0,
                    borderBottomRightRadius:alignment=='center'?22:0,
                    backgroundColor:Colors.shades.shade100, 
                    alignItems:'center',
                    width,
                    paddingTop:insets.top,
                    paddingBottom:insets.bottom,
                    minHeight:500
                }}
            >
                <MyWallet
                    height={wallet_height}
                    new_balance={player_balance}
                    onEvent={(event) => BEEventApi.saveEvent(event)}
                    onClose={() => dispatch({ type:V1_SHOW_WALLET, show_wallet: { visible:false } })}
                    onTransact={(item_order, account_id) => dispatch({ type:V1_SHOW_CHECKOUT, show_checkout: { visible: true, draft_order:item_order, account_id } })}
                />
            </Components.Spring>
        </View>
    )
}

export default WalletHolder