import React from 'react';
import { Dimensions, View, ActivityIndicator } from "react-native";
import Modal from 'modal-enhanced-react-native-web'
import { Text } from 'react-native-elements';
import Colors from '../constants/Colorsv2';

const { width, height } = Dimensions.get('screen');

const initState = {
    isVisible: false,
    message: ''
}

class BELoader extends React.Component {
    state = initState;

    launchLoader = (message) => {
        this.setState({ isVisible: true, message })
    }
    closeLoader = () => {
        this.setState({ isVisible: false })
    }

    render() {
        const { isVisible, message } = this.state
        return (
            <Modal isVisible={isVisible} style={{ flex:1, justifyContent: 'center', alignItems:'center'}} animationIn='bounceIn' animationOut='fadeOut' animationOutTiming={100}>
                <View style={{ height:width>800?800*.5:width*.5, width:width>800?800*.5:width*.5, justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.shades.white, borderRadius: 10, padding:10 }}>
                    <View style={{flex: 2, flexDirection: 'row', justifyContent: 'center', alignItems:'center'}}>
                        <ActivityIndicator size='large'/>
                    </View>
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems:'center'}}>
                        <Text style={{textAlign:'center'}}>{message?message:'Loading...'}</Text>
                    </View>
                </View>
            </Modal>
        )
    }
}


export default BELoader
