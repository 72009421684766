import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Authenticator, BEEventApi, Components } from "be-components"
import { useDispatch, useSelector } from "react-redux"
import Colors from '../constants/Colorsv2';
import { V1_SHOW_AUTHENTICATE, V1_SHOW_PROFILE } from '../store/actionsv1/types';
import { Text } from '../globalComponents/NativeOverrides';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { navigate } from '../navigation/RootNavigation';


type AuthenticationModalProps = {
    onAuthenticated: (resp:any) => void
}
const MAX_WIDTH = 500
const AuthenticationModal = ({ onAuthenticated }:AuthenticationModalProps) => {
    const dispatch = useDispatch();
    const show_authenticate = useSelector((state:any) => state.util.show_authenticate, (left, right) => left == right);
    const viewing_player_referral = useSelector((state:any) => state.player.viewing_player_referral, (left, right) => left == right);
    const app_size = useSelector((state:any) => state.util.app_size, (left, right) => left.width == right.width);
    const insets = useSafeAreaInsets();
    const handleAuthenticated = (resp:any) => {
        BEEventApi.saveEvent({ event_name: 'login', event_data:{}, level:1 })
        onAuthenticated(resp)
        dispatch({ type:V1_SHOW_AUTHENTICATE, show_authenticate: false })
        if(show_authenticate.nav_out){ navigate('Auth', { screen: 'EventStack' }) }
        if(resp.new_player){
            dispatch({ 
                type: V1_SHOW_PROFILE, 
                show_profile: { 
                    visible: true, 
                    show_welcome: true, 
                    walkthrough: { level: 'basic' } 
                } 
            })
            BEEventApi.saveEvent({
                event_name: 'show_walkthrough',
                event_data: { level: 'basic' },
                level:1
            })
        }
    }
    const justifyContent = app_size.width > MAX_WIDTH ? 'center' : 'flex-end'
    const auth_width = app_size.width > MAX_WIDTH ? MAX_WIDTH : app_size.width
    return (
        <View 
            style={{ position:'absolute', left:0, bottom:0, right:0, top:show_authenticate.visible?0:undefined, 
            justifyContent, 
            backgroundColor:Colors.shades.black_faded_heavy
        }}>
            <Components.Spring
                to={0}
                from={1000}
                slide='vertical'
                style={{ mass:1, friction:200 }}
                >
                <View style={{ alignItems:'center' }}>
                    <View 
                        style={{ 
                            width:auth_width, 
                            backgroundColor:Colors.shades.white, 
                            borderTopRightRadius:22, 
                            borderTopLeftRadius:22, 
                            paddingBottom:justifyContent != 'center' && show_authenticate.visible ? insets.bottom: 0,
                            borderBottomLeftRadius:justifyContent == 'center' ? 22 : 0,
                            borderBottomRightRadius:justifyContent == 'center' ? 22 : 0

                        }}>
                        <Authenticator
                            company_id='1'
                            source={'pwa'}
                            show_referral
                            referral_code={viewing_player_referral?.referral_code ?? 'tipoff'}
                            default_view={show_authenticate.default_view}
                            width={auth_width}
                            auth_strategy_id='3'
                            visible={show_authenticate.visible}
                            onTryAuthenticate={(attribute, result) => {
                                BEEventApi.saveEvent({
                                    event_name: 'login_attr_check',
                                    event_data: {
                                        attribute,
                                        result
                                    },
                                    level:1
                                })
                            }}
                            opt_in_url='https://www.bettoredge.com/terms-of-use'
                            onAuthenticated={(resp) => handleAuthenticated(resp)}
                            onClose={() => dispatch({ type:V1_SHOW_AUTHENTICATE, show_authenticate: { visible:false } })}
                        />
                    </View>
                </View>
            </Components.Spring>
        </View>
    )
}

export default AuthenticationModal