
import { UPDATE_DRAFT_COMPETITION, LOGOUT, DraftReducerState, LOAD_DRAFT_PROP_ORDER } from '../actions/types';

const DEFAULT_STATE:DraftReducerState = {
    competition_head: undefined,
    competition_players: [],
    competition_matches: [],
    competition_match_odds: [],
    propOrder: undefined
}

const draftReducer = (state=DEFAULT_STATE, action:any) => {
    switch(action.type){
        case UPDATE_DRAFT_COMPETITION:
            return {
                ...state,
                competition_head: action.competition_head, 
                competition_players:action.competition_players, 
                competition_matches:action.competition_matches,
                competition_match_odds:action.competition_match_odds 
            }
        case LOAD_DRAFT_PROP_ORDER:
            return {
                ...state,
                propOrder: action.propOrder
            }
        case LOGOUT:
            return DEFAULT_STATE
        default:
            return state
    }
}

export default draftReducer