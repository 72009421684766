import axios from "axios"
import getEnvVars from '../../env';
import { segIdentify } from "../../utils/analytics/segment";
import { errHandler } from "../errHandler";
import { CharityDonationProps, CodeRequestProps, MyPlayerProps, PlayerContactProps, PlayerNotificationProps, PlayerPushSubscriptionProps, PlayerReferralProps, PlayerSettingProps, PlayerVouchTokenProps, PromoProps, PublicPlayerProps, SmartSearchRequestProps } from "../types/auth_svc";
import { AlertProps } from "../types/local";
import { ShareProps } from "../types/soc_svc";
import { auth_getNotificationCount } from "./auth";
import { 
    V1_ADD_PLAYER_NOTIFICATIONS, 
    V1_CLEAR_ALERT, 
    V1_CLEAR_PLAYER_NOTIFICATIONS, 
    V1_LOAD_ALERT, 
    V1_LOAD_CHARITIES, 
    V1_LOAD_CODE_REQUESTS, 
    V1_LOAD_CODE_REQUEST_STATS, 
    V1_LOAD_HELP, 
    V1_LOAD_NEW_PLAYERS, 
    V1_LOAD_PLAYERS, 
    V1_LOAD_PLAYER_PREMIUM_ITEMS, 
    V1_LOAD_PLAYER_PUSH_SUBSCRIPTIONS, 
    V1_LOAD_PLAYER_REFERRAL, 
    V1_LOAD_PLAYER_SETTINGS, 
    V1_LOAD_PLAYER_VOUCH_TOKEN, 
    V1_LOAD_PREMIUM_ITEMS, 
    V1_LOAD_SEARCHED_PLAYERS, 
    V1_LOAD_SETTINGS, 
    V1_LOAD_SUGGESTED_PLAYERS, 
    V1_LOAD_VIEWING_REFERRAL, 
    V1_UPDATE_ME, 
    V1_UPDATE_PLAYER_BALANCE, 
    V1_UPDATE_PLAYER_NOTIFICATION,
    V1_UPDATE_PLAYER_PUSH_SUBSCRIPTION,
    V1_UPDATE_PLAYER_SETTING, 
} from "./types";
import { BEEventApi } from "be-components";
const { AUTH_SVC_API } = getEnvVars()

//--------------------------------------------------------------------------------------------------------------------------------------------
//Player
export const getPlayerByPlayerId = (player_id:string) => {
    return async(dispatch:any) => {
        try {

            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/${player_id}`)
            dispatch(auth_getNotificationCount())
            const { player } = resp.data;
            BEEventApi.identify(player.player_id)
            segIdentify({ userId: player.player_id, traits: player })
            dispatch({ type: V1_UPDATE_ME, player })
        } catch (e) {
            console.log(e)
        }
    }
}

export const getMe = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/me`)
            const { player } = resp.data;
            BEEventApi.identify(player.player_id)
            segIdentify({ userId: player.player_id, traits: player })
            dispatch({ type:V1_UPDATE_ME, player })
            dispatch({ type: V1_LOAD_PLAYER_PREMIUM_ITEMS, player_premium_items: resp.data.player_premium_items })
        } catch (e) {
            console.log(e)
        }
    }
}

export const updatePlayer = (p:MyPlayerProps, hide_alert?:boolean) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/update`, { player: p })
            const { player } = resp.data
            dispatch({ type: V1_UPDATE_ME, player })
            if(!hide_alert){
                dispatch({ type: V1_LOAD_ALERT, alert: { title:`Updated profile successfully`, type:'success' } })
            }
        } catch (e) {
            if(!hide_alert){
                dispatch({ type: V1_LOAD_ALERT, alert: { title:`Unable to process request`, type:'error' } })
            }
            console.log(e)
        }
    }
}

//UPDATE PASSWORD
export const player_updatePassword = async(password:string, re_password:string) => {
    await axios.post(`${AUTH_SVC_API}/v1/players/player/password/update`, { password, re_password })
}

export const player_confirmPassword = (token:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/password/confirm`, { token })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Succesfully updated password`, type:'success' } })
            dispatch({ type: V1_UPDATE_ME, player: resp.data.player })
        } catch (e) {
            throw new Error('Invalid Code')
        }
    }
}
//------------------

//UPDATE EMAIL AND PHONE
export const player_updateEmail = async(email:string):Promise<void> => {
    await axios.post(`${AUTH_SVC_API}/v1/players/player/email/update`, { email })
}

export const player_confirmEmail = (token:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/email/confirm`, { token })
            dispatch({ type: V1_UPDATE_ME, player: resp.data.player })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Succesfully updated email`, type:'success' } })
        } catch (e) {
            throw new Error('Invalid Code')
        }
    }
}

export const player_updatePhone = async(phone:string):Promise<void> => {
    await axios.post(`${AUTH_SVC_API}/v1/players/player/phone/update`, { phone })
}

export const player_confirmPhone = (token:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/phone/confirm`, { token })
            dispatch({ type: V1_UPDATE_ME, player: resp.data.player })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Succesfully updated phone number`, type:'success' } })
        } catch (e) {
            throw new Error('Invalid Code')
        }
    }
}
//--------

export const verifyAttribute = (attribute: string, verify_code:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/code/verify`, { attribute, verify_code })
            dispatch({ type: V1_UPDATE_ME, player: resp.data.player })
        } catch (e) {
            console.log(e)
        }
    }
}

export const player_getPremiumItems = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/premium/items`)
            dispatch({ type: V1_LOAD_PREMIUM_ITEMS, premium_items: resp.data.premium_items })
        } catch (e) {
            console.log('Unable to get premium items')
        }
    }
}

export const player_purchasePremiumItem = (premium_item_id:string, type: 'day'|'month') => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/premium/item/purchase`, { premium_item_id, type })
            if(!resp.data.player_premium_item){ return }
            dispatch({ type:V1_LOAD_PLAYER_PREMIUM_ITEMS, player_premium_items: [resp.data.player_premium_item] })
        } catch (e) {
            console.log(e)
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Unable to purchase this item`, type:'error' } })

        }
    }
}

export const player_resendCode = (attribute: string) => {
    return async(dispatch:any) => {
        try {
            await axios.post(`${AUTH_SVC_API}/v1/players/player/code/resend`, { attribute })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Code resent to ${attribute}`, type:'success' } })

        } catch (e) {
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Unable to process request.  Please try again later`, type:'error' } })
            console.log(e)
        }
    }
}

//--------------------------------------------------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------------------------------------------------
//Player Balance
export const getPlayerBalanceByPlayerId = (player_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/balance/${player_id}`)
            const { player_balance } = resp.data;
            dispatch({ type: V1_UPDATE_PLAYER_BALANCE, player_balance })
        } catch (e) {
            console.log(e)
        }
    }
}
//--------------------------------------------------------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------------------------------------------------------
//Player Notifications
export const getPlayerSettingsByPlayerId = (player_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/settings/player/id/${player_id}`)
            const { player_settings, settings } = resp.data;
            dispatch({ type: V1_LOAD_PLAYER_SETTINGS, player_settings })
            dispatch({ type: V1_LOAD_SETTINGS, settings })
        } catch (e) {
            console.log(e)
        }
    }
}

export const player_activateAnonymous = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/anonymous/activate`)
            dispatch({ type: V1_UPDATE_ME, player: resp.data.player })
        } catch (e) {
            console.log(e)
        }
    }
}


export const player_deactivateAnonymous = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/anonymous/deactivate`)
            dispatch({ type: V1_UPDATE_ME, player: resp.data.player })
        } catch (e) {
            console.log(e)
        }
    }
}

export const createPlayerSetting = (player_setting:PlayerSettingProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/settings/player/create`,  { player_setting })
            dispatch({ type: V1_UPDATE_PLAYER_SETTING, player_setting:resp.data.player_setting })
        } catch (e) {
            console.log(e)
        }
    }
}

export const updatePlayerSetting = (player_setting:PlayerSettingProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/settings/player/update`,  { player_setting })
            dispatch({ type: V1_UPDATE_PLAYER_SETTING, player_setting:resp.data.player_setting })
        } catch (e) {
            console.log(e)
        }
    }
}


//FORGOT
export const player_forgotUsername = (attribute:string, attribute_value:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/forgot/username`, { attribute, attribute_value })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Success', type:'success', body:resp.data.message, data: {} } })
        } catch (e) {
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Unable to process request`, type:'error' } })
            console.log(e)
        }
    }
}

export const player_forgotPassword = (attribute:string, attribute_value:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/forgot/password`, { attribute, attribute_value })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Success', type:'success', body:resp.data.message, data: {} } })
        } catch (e) {
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Unable to process request`, type:'error' } })
            console.log(e)
        }
    }
}

export const player_resetPassword = (password:string, repassword:string, verify_code:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/reset/password`, { password, repassword, verify_code })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Success', type:'success', body:resp.data.message, data: {} } })
        } catch (e) {
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Error', type:'error', body:'Unable to update password', data: {} } })
        }
    }
}

export const player_shareNotification = (player_notification:PlayerNotificationProps, share:ShareProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/notifications/notification/share`, { player_notification, share })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Success', type:'success', body:resp.data.message, data: {} } })
        } catch (e) {
            dispatch({ type: V1_LOAD_ALERT, alert: { title:'Error', type:'error', body:'Unable to share notification', data: {} } })
        }
    }
}

export const getPlayerNotificationsByPlayerId = (player_id:string, notification_type?:string, offset?:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/notifications/player/${player_id}?notification_type=${notification_type}&offset=${offset??0}`)
            const { player_notifications } = resp.data;
            dispatch({ type: V1_ADD_PLAYER_NOTIFICATIONS, player_notifications, offset: offset??0 })
        } catch (e) {
            console.log(e)
        }
    }
}

export const updatePlayerNotification = (pn:PlayerNotificationProps) => {
    return async(dispatch:any, getState:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/notification/update`, { player_notification:pn })
            const { player_notification } = resp.data;
            dispatch({ type: V1_UPDATE_PLAYER_NOTIFICATION, player_notification })            
        } catch (e) {
            console.log(e)
        }
    }
}

export const markNotificationsAsRead = (notification_type?:string) => {
    return async(dispatch:any) => {
        try {
            await axios.post(`${AUTH_SVC_API}/v1/players/notifications/read`, { notification_type })
            dispatch({ type: V1_CLEAR_PLAYER_NOTIFICATIONS, notification_type })
            dispatch(auth_getNotificationCount())
        } catch (e) {
            console.log(e)
        }
    }
}
//--------------------------------------------------------------------------------------------------------------------------------------------



//--------------------------------------------------------------------------------------------------------------------------------------------
//Player Referral
export const player_getReferralByPlayerId = (player_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/referral/player/${player_id}`)
            const { player_referral, promo } = resp.data
            dispatch({ type: V1_LOAD_PLAYER_REFERRAL, player_referral, promo })
        } catch (e) {
            console.log(e)
        }
    }
}

export const player_getCodeRequestByPlayerId = (player_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/request/player/${player_id}`)
            const { player_referral, referrer, promo, code_request, reward_option } = resp.data;
            dispatch({ type: V1_LOAD_VIEWING_REFERRAL, player_referral, promo, referrer, code_request, reward_option })
        } catch (e) {
            console.log(e)
        }
    }
}

export const player_redeemCodeRequest = (code_request_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/promos/request/redeem`, { code_request_id })
            const { player_referral, referrer, promo, code_request, reward_option } = resp.data;
            dispatch({ type: V1_LOAD_VIEWING_REFERRAL, player_referral, promo, referrer, code_request, reward_option })
        } catch (e) {
            console.log(e)
        }
    }
}

export const player_getRequestStatsByPlayerId = (player_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/requests/stats/player/${player_id}`)
            dispatch({ type: V1_LOAD_CODE_REQUEST_STATS, code_request_stats:resp.data.code_request_stats })
        } catch (e) {
            console.log(e)
        }
    }
}

export const player_getRequestDetailsByPlayerIdAndStatus = (player_id:string, status:string, offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/requests/details/player/${player_id}?status=${status}&offset=${offset}`)
            const player_ids = resp.data.code_requests.map((cr:CodeRequestProps) => cr.player_id)
            if(player_ids.length > 0){
                dispatch(player_getBulkPlayers(player_ids))
            }
            dispatch({ type:V1_LOAD_CODE_REQUESTS, code_requests:resp.data.code_requests, offset })
        } catch (e) {
            console.log(e)
        }
    }
}

export const player_updatePlayerReferral = (player_referral:PlayerReferralProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/promos/referral/update`, { player_referral })
            
            dispatch({ type: V1_LOAD_PLAYER_REFERRAL, player_referral: resp.data.player_referral, promo:resp.data.promo })
        } catch (e) {
            errHandler(e)
        }
    }
}

export const player_getReferralByCode = (code:string, alert_fail?:boolean) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/referral/code/${code}`)
            const { player_referral, promo, referrer } = resp.data
            if(!player_referral && alert_fail){
                dispatch({ type: V1_LOAD_ALERT, alert: { title:`Invalid Code`, type:'error', body:`Sorry! We do not recognize this code` } })

            }
            dispatch({ type: V1_LOAD_VIEWING_REFERRAL, player_referral, promo, referrer })
        } catch (e) {
            errHandler(e)
            console.log(e)
        }
    }
}

export const player_getReferredUsersByPlayerId = (player_id:string, status:string, offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/referral/referred/${player_id}?status=${status}&offset=${offset}`)
            const { players } = resp.data
            //dispatch({ type: V1_LOAD_REFERRED_PLAYERS, players, offset })
        } catch (e) {
            console.log(e)
        }
    }
}

export const player_searchByPhone = async(phone:string):Promise<PublicPlayerProps | undefined> => {
    try {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/search/phone`, { phone })
        return resp.data.player
    } catch (e) {
        return undefined
    }
}


const zero_result_searches:string[] = []
export const player_smartSearch = (search_request:SmartSearchRequestProps, type?:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const zero_result_search = zero_result_searches.find(s => s === search_request.search_value)
            if(zero_result_search){ return }
            if(type && type === 'search' && search_request.search_value){
                let matching_players = getState().social.stored_players.filter((sp:PublicPlayerProps) => `${sp.first_name} ${sp.last_name} ${sp.username}`.includes(search_request.search_value??''))
                if(matching_players.length > 0){ return dispatch({ type: V1_LOAD_SEARCHED_PLAYERS, player_ids: matching_players.map((p:PublicPlayerProps) => p.player_id), offset:0 }) }
            }
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/search/smart`, { search_request })
            if(resp.data.players.length === 0 && search_request.search_value){ return zero_result_searches.push(search_request.search_value) }
            if(type && type === 'suggested'){ dispatch({ type: V1_LOAD_SUGGESTED_PLAYERS, player_ids: resp.data.players.map((p:PublicPlayerProps) => p.player_id) }) }
            if(type && type === 'search'){ dispatch({ type: V1_LOAD_SEARCHED_PLAYERS, player_ids: resp.data.players.map((p:PublicPlayerProps) => p.player_id), offset:search_request.offset }) }
            dispatch({ type: V1_LOAD_PLAYERS, players: resp.data.players, offset: search_request.offset })
        } catch (e) {
            console.log(e)
        }
    }
}

export const player_getBulkPlayers = (player_ids:string[]) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/bulk/get`, { player_ids })
            dispatch({ type: V1_LOAD_PLAYERS, players:resp.data.players })
        } catch (e) {
            console.log('THIS ONE IS FAILING')
            console.log(e)
        }
    }
}


export const player_getNewPlayers = (offset:number) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/new?offset=${offset}`)
            dispatch({type: V1_LOAD_NEW_PLAYERS, offset, player_ids:resp.data.players.map((p:PublicPlayerProps) => p.player_id)})
            dispatch({ type: V1_LOAD_PLAYERS, players:resp.data.players })
        } catch (e) {
            console.log(e)
        }
    }
}




//--------------------------------------------------------------------------------------------------------------------------------------------


//Vouch tokens

export const player_getVouchStatus = async():Promise<string | undefined> => {
    try {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/vouch/status`)
        return resp.data.vouched_status
    } catch (e) {
        console.log(e)
        return undefined
    }
}

/**
 * NO DISPATCH
 * Grab the vouch token for a player id
 * if errors, returns undefined
 * @param player_id 
 */
export const player_getVouchTokenByPlayerId = async(player_id:string):Promise<PlayerVouchTokenProps | undefined> => {
    try {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/vouch/token/player/${player_id}`)
        return resp.data.player_vouch_token
    } catch (e) {
        console.log(e)
    }
}



/**
 * NO DISPATCH
 * Save a new player vouch token
 * @param player_vouch_token 
 */
export const player_saveVouchToken = async(player_vouch_token:PlayerVouchTokenProps):Promise<PlayerVouchTokenProps | undefined> => {
    const resp = await axios.post(`${AUTH_SVC_API}/v1/players/vouch/token/create`, { player_vouch_token })
    return resp.data.player_vouch_token
}

//Notifications
export const player_getPushSubsByPlayerId = (player_id:string, device_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/push/player/${player_id}?device_id=${device_id}`)
            dispatch({ type: V1_LOAD_PLAYER_PUSH_SUBSCRIPTIONS, player_push_subscriptions: resp.data.player_push_subscriptions })
        } catch (e) {
            console.log(e)
        }
    }
}

/**
 * No Dispatch - will return the data directly to the component
 * @param player_id 
 * @param device_id 
 * @returns 
 */
export const player_getPushSubByPlayerIdAndDvcId = async(player_id:string, device_id:string):Promise<PlayerPushSubscriptionProps | undefined> => {
    try {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/push/player/${player_id}?device_id=${device_id}`)
        return resp.data.player_push_subscriptions[0] //Only one should be returned with a device id included
    } catch (e) {
        return undefined
    }
}


export const player_savePushSubscription = (player_push_subscription:PlayerPushSubscriptionProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/push/create`, { player_push_subscription })
            dispatch({ type: V1_UPDATE_PLAYER_PUSH_SUBSCRIPTION, player_push_subscription:resp.data.player_push_subscription })
        } catch (e) {
            console.log(e)
        }
    }
}

export const player_updatePushSubscription = (player_push_subscription:PlayerPushSubscriptionProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/push/update`, { player_push_subscription })
            dispatch({ type: V1_UPDATE_PLAYER_PUSH_SUBSCRIPTION, player_push_subscription:resp.data.player_push_subscription })
        } catch (e) {
            console.log(e)
        }
    }
}
//----------------------------------------------------------------------------------------


export const player_getActivePromos = async():Promise<PromoProps[]> => {
    try {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/promos/all?status=active`)
        return resp.data.promos
    } catch (e) {
        return []
    }
}


//Help
export const player_getHelpPromptById = (client_native_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/help/prompts/native_id/${client_native_id}`)

            const { view_type, help_prompt, help_tour, tour_prompts, active_tour_prompt } = resp.data;
            dispatch({ type: V1_LOAD_HELP, initial_id:client_native_id, view_type, help_prompt, help_tour, tour_prompts, active_tour_prompt })
            BEEventApi.saveEvent({
                event_name: 'launch_help',
                event_data: {
                    client_native_id
                },
                level:1
            })
        } catch (e) {
            console.log(e)
        }
    }
}

//----------------------------------------------------


//--------------------------------------------------------------------------------------------------------------------------------------------
//Charities
export const player_getCharities = () => {
    return async(dispatch:any, getState:any) => {
        try {
            let charities = getState().player.charities
            if(charities.length > 0){ return  }
            const resp = await axios.get(`${AUTH_SVC_API}/v1/charities/all?status=active`)
            dispatch({ type:V1_LOAD_CHARITIES, charities: resp.data.charities })
        } catch (e) {
            console.log(e)
        }
    }
}

export const player_donateToCharity = (charity_donation:CharityDonationProps) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/charities/charity/donate`, { charity_donation })
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Thank you!`, type:'success', body:resp.data.message } })
        } catch (e) {
            console.log(e)
        }
    }
}
//--------------------------------------------------------------------------------------------------------------------------------------------


//Contacts ----------
/**
 * No dispatch required.
 * Saves player contacts
 * @param player_contacts 
 */
export const player_saveContacts = async(player_contacts:PlayerContactProps[]) => {
    try {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/contacts/create`, { player_contacts })
    } catch (e) {
        console.log(e)
    }
}
// ------------------


export const player_clearAlert = (alert:AlertProps) => {
    return (dispatch:any) => {
        dispatch({ type: V1_CLEAR_ALERT, alert })
    }
}