import { CreateAccountType } from "../../../store/actions/types"
import { StatesType } from "../../../screensV1/Auth/Register1Screen2"


/**
 * Generates an empty account object
 * @returns CreateAccount Object
 */
export const getEmptyAccount = ():CreateAccountType => {
    return { 
        player_id: '', first_name:'', last_name:'', username:'', password:'', rePassword:'', 
        email:'', verified:false, phone:'', phone_verified:false, role:'player', type:'freemium', bio:'',
        location:undefined, profile_pic:'', dob:'', vouched_status:'unverified', facebook_id:'', legal_ind:false, show_name:''
    }
}

/**
 * Takes a phone number string and returns a formatted verison of the phone number with - between numbers
 * @param phone 
 * @returns formatted phone string
 */
export const formatPhone = (phone?:string) => {
    if(!phone){ return '' }
    let splitPhone = phone.split('')
    if(splitPhone.length === 0){ return '' }
    if(splitPhone.length > 10 && splitPhone[0] == '1'){ splitPhone.splice(0,1) }
    let newPhone = ''
    splitPhone.map((c, i) => {
        newPhone += c
        if(i === 2 && splitPhone.length > 3){ newPhone += '-' }
        if(i === 5 && splitPhone.length > 6){ newPhone += '-' }
    })
    return newPhone
}

/**
 * Removes - from a formatted phone string
 * @param formatted_phone 
 * @returns unformatted phone string
 */
export const unformatPhone = (formatted_phone:string) => {
    let splitPhone = formatted_phone.split('-')
    let newPhone = ''
    splitPhone.map(c => {
        newPhone += c
    })
    return newPhone
}

export const fullUnformatPhone = (formatted_phone:string) => {
    let unformatted_phone = unformatPhone(formatted_phone)
    let no_par = unformatted_phone.split('(')
    unformatted_phone = no_par.join('')
    no_par = unformatted_phone.split(')')
    unformatted_phone = no_par.join('')
    let no_plus = unformatted_phone.split('+1')
    unformatted_phone = no_plus.join('')
    let no_space = unformatted_phone.split(' ')
    unformatted_phone = no_space.join('')
    return unformatted_phone
}

/**
 * Checks if all steps are completed and valid
 * @param validStates 
 * @returns boolean
 */
export const isStepValid = (validStates:StatesType[]) => {
    if(validStates.find(s => ['not_started','invalid'].includes(s.state))){ return false }
    return true
}



/**
 * Checks if all attributes in the account are valid based on the states that you want to check
 * @param account 
 * @param validStates 
 * @returns valid states array with updated status
 */
export const isAccountValid = (account:CreateAccountType, validStates:StatesType[]) => {
    let newStates:StatesType[] = []
    validStates.map(s => {
        switch(s.attribute){
            case 'first_name': 
                s = { ...isNameValid(account[s.attribute]), attribute:s.attribute }
                break
            case 'last_name':
                s = { ...isNameValid(account[s.attribute]), attribute:s.attribute }
                break
            case 'email':
                s = { ...isEmailValid(account[s.attribute]) }
                break
            case 'username':
                s = { ...isUsernameValid(account[s.attribute]) }
                break
            case 'password':
                s = isPasswordValid(account[s.attribute], s)
                break 
            case 'rePassword':
                s = doPasswordsMatch(account)
                break 
            case 'phone':
                s = isPhoneValid(account[s.attribute])
                break
            default: break
        }
        newStates.push(s)
    })
    return newStates
}

/**
 * Checks if phone number is a vali US number
 * @param phone 
 * @returns 
 */
export const isPhoneValid = (phone:string):StatesType => {
    if(!phone || phone === '') { return { attribute:'phone', state:'not_started' } }
    let phoneasnum = parseInt(phone)
    if(isNaN(phoneasnum)){ return { attribute:'phone', state: 'invalid', error:'Phone number must be a US 10 digit number' } }
    if(phone.length === 10){ return { attribute:'phone', state:'valid' } }
    return { attribute:'phone', state:'invalid', error: 'Phone number must be a US 10 digit number' }
}

/**
 * Check is a password is valid
 * @param password 
 * @param validState 
 * @returns Valid states array with the status of each password rule
 */
export const isPasswordValid = (password:string, validState:StatesType):StatesType => {
    if(!password || password === ''){ return { ...validState, state:'not_started' } }
    switch(validState.type){
        case 'min_character':
            if(password.length < 8){ return { ...validState, state:'invalid', error:`Password is only ${password.length} characters` } }
            return { ...validState, state:'valid' }
        case 'case':
            if(doesStringContainUpperAndLowercase(password)){ return { ...validState, state:'valid' } }
            return { ...validState, state:'invalid', error:'Password does not contain upper and lower case' }
        case 'number':
            if(doesStringContainDigit(password)){ return { ...validState, state:'valid' } }
            return { ...validState, state:'invalid', error:'Password does not contain a number' }
        case 'special':
            if(doesStringContainSpecialCharacter(password)) { return { ...validState, state:'valid' } }
            return { ...validState, state:'invalid', error: 'Password does not contain a special character' }
        default: return validState
    }
}

/**
 * Checks if two password strings match
 * @param account 
 * @returns 
 */
const doPasswordsMatch = (account:CreateAccountType):StatesType => {
    if(!account.rePassword){ return { attribute:'rePassword', state:'not_started' } }
    if(account.password === account.rePassword){ return { attribute:'rePassword', state:'valid' } }
    return { attribute: 'rePassword', state:'invalid', error: 'Passwords do not match' }
}

/**
 * Regex to check if string contains upper and lowercase letter
 * @param text 
 * @returns 
 */
const doesStringContainUpperAndLowercase = (text:string) => {
    if(/^(?=.*[a-z])(?=.*[A-Z])/.test(text)){ return true }
    return false
}

/**
 * Regex to check if string contains a number
 * @param text 
 * @returns 
 */
const doesStringContainDigit = (text:string) => {
    if(/^(?=.*\d)/.test(text)){ return true }
    return false
}

/**
 * Regext to check if string contains a special character
 * @param value 
 * @returns 
 */
const doesStringContainSpecialCharacter = (value:string) => {
    var reg = /^(?=.*[~!@#$%^&*()?.,';`])/;
    if(reg.test(value)) {
        return true
    }
    return false
  }

/**
 * Checks if name string is valid
 * @param name 
 * @returns 
 */
const isNameValid = (name:string):StatesType => {
    if(name !== ''){ return { attribute:'name', state:'valid' } }
    return { attribute:'name', state:'not_started' }
}

/**
 * Regex chech if the string is a valid email
 * @param email 
 * @returns 
 */
export const isEmailValid = (email:string):StatesType => {
    if(email === ''){ return { attribute:'email', state:'not_started' } }
    // function that returns true if value is email, false otherwise
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRex.test(email)) {
        return { attribute:'email', state:'valid' };
      }
      return { attribute:'email', state:'invalid', error:'Must be a valid email' };
}

/**
 * Checks if username is valid
 * @param username 
 * @returns 
 */
const isUsernameValid = (username:string):StatesType => {
    if(!username || username === ''){ return { attribute:'username', state:'not_started' } }
    if(username.includes('@')){ return { attribute:'username', state:'invalid', error:'@ is not allowed' } }
    if(username.includes(' ')){ return { attribute: 'username', state:'invalid', error: 'Spaces are not allowed' } }
    return { attribute:'username', state:'valid' }
}