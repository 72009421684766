import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPlayerLocation, loc_getBackgroundPermissionsAsync, loc_requestLocationPermissions } from '../utilsv1/location';
import { ActivityIndicator, Image, View } from 'react-native';
import Colors from '../constants/Colorsv2';
import { isSafari } from 'react-device-detect';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { getLocations } from '../store/actionsv1/auth';
import { V1_LOAD_LOCATION } from '../store/actionsv1/types';
import { LocationProps } from '../store/types/auth_svc';
import { Icon } from 'react-native-elements';

const LocationStatus = () => {
    const [ checking, setChecking ] = useState(false);
    const [ permission, setPermission ] = useState<string>()
    
    const dispatch = useDispatch();
    
    const is_authenticated = useSelector((state:any) => state.auth.isAuthenticated, (left, right) => left == right);
    const market_type = useSelector((state:any) => state.auth.market_type, (left, right) => left == right);
    const location:{ region?: string, legal_ind: boolean, coordinates: any, location?:LocationProps, show?:boolean, loading?:boolean } = useSelector((state:any) => state.util.location, (left, right) => left == right)

    useEffect(() => {
        tryGetLocation()
    },[])

    useEffect(() => {
        if(!location.region){ return }
        if(location.legal_ind && location.show){
            setTimeout(() => {
                dispatch({ type:V1_LOAD_LOCATION, ...location, show:false })
            }, 2000);
        }
    },[location.region])


    const tryGetLocation = async(ask?:boolean) => {
        setChecking(true)
        dispatch({ type: V1_LOAD_LOCATION, ...location, loading: true })
        let locations = await getLocations();
        if(locations.length == 0){ 
            setChecking(false)
            dispatch({ type: V1_LOAD_LOCATION, ...location, loading: true })
            return setPermission('error'); 
        }
        let { status, canAskAgain } = await loc_getBackgroundPermissionsAsync();

        if(status != 'granted' && canAskAgain){
            status = await loc_requestLocationPermissions()
        }
        switch(status){
            case 'denied':
                setPermission(status)
                break
            case 'error':
                setPermission(status)
                break
            case 'undetermined':
                setPermission(status)
                break
            case 'granted':
                const response = await getPlayerLocation()
                if(!response){
                    dispatch({ type: V1_LOAD_LOCATION, ...location, loading: true })
                    setChecking(false); 
                    return setPermission('error') 
                }
                setPermission(status)
                let be_location = locations.find(l => l.label.toLowerCase() == response.region.toLowerCase())
                if(!be_location){
                    let new_loc:LocationProps = {
                        location_id:'', 
                        location_image: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1696434494/loc_icon_qdkurk.png',
                        legal_ind: false,
                        create_datetime:'', last_update_datetime:'',
                        label: response.region,
                        value: response.region
                    }
                    setChecking(false);
                    dispatch({ type: V1_LOAD_LOCATION, region: response.region, legal_ind: false, location:new_loc, coordinates: response.location, show:true, loading:false, })
                    return
                }
                dispatch({ type: V1_LOAD_LOCATION, region: be_location.label, legal_ind: be_location.legal_ind, coordinates: response.location, location: be_location, show:be_location.legal_ind?false:true, loading:false })
                break
            default:
                //alert(`we got a weird status of ${permission}`)
                break
        }
        setChecking(false);
    }

    const handleHelp = () => {
        if(isSafari){ return window.open('https://support.bettoredge.com/hc/en-us/articles/1500010390982-How-do-I-turn-on-Location-Settings-for-Apple-Safari-', '_blank') }
        return window.open(`https://support.bettoredge.com/hc/en-us/articles/1500010423381-How-do-I-turn-on-Location-Settings-for-Android-Chrome-`, '_blank')
    }

    //We dont need to show the location banner if its already granted, or we are not authenticated or if the market type is currently FREE
    if(!is_authenticated || !location.show){ return <></> } //dont show anything if I am not authenticated
    //Show location alert banner
    return (
        <View style={{ position:'absolute', bottom:50, left:0, right:0, margin:10, backgroundColor:Colors.shades.shade600, borderRadius:4, shadowColor: "#000", shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5 }}>
            {checking ?
            <View style={{ flexDirection:'row', alignItems:'center', padding:10  }}>
                <ActivityIndicator size='small' color={Colors.brand.midnight} />
                <Text style={{ marginLeft: 20 }} size={14} color={Colors.brand.midnight}>Verifying your location</Text>
            </View>
            : permission && permission != 'granted' ?
            <View style={{ padding:10 }}>
                <View style={{ flexDirection:'row', alignItems:'flex-start' }}>
                    <Image
                        source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1696434494/loc_icon_qdkurk.png' }}
                        style={{ height:30, width:30 }}
                        resizeMode='center'
                    />
                    <View style={{ flex:1, marginLeft:10, marginRight:10 }}>
                        <Text size={14} color={Colors.brand.midnight} weight='bold'>Location is not enabled</Text>
                        <Text style={{ marginTop:3 }} size={12} color={Colors.brand.midnight}>In order to use real-money, you must enable location tracking on this device.</Text>
                    </View>
                    <TouchableOpacity style={{ padding:10 }} onPress={() => dispatch({ type:V1_LOAD_LOCATION, ...location, show:false })}>
                        <Icon name='x' type='feather' size={14} color={Colors.utility.error} />
                    </TouchableOpacity>
                </View>
                <View nativeID='action_row' style={{ flexDirection:'row', marginTop:10 }}>
                    <TouchableOpacity style={{ flex:1, marginRight:4, backgroundColor:Colors.brand.electric, borderRadius:8, padding:10 }} onPress={() => tryGetLocation()}>
                        <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>Try Again</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ flex:1, marginLeft:4, backgroundColor:Colors.utility.warning, borderRadius:8, padding:10 }} onPress={() => handleHelp()}>
                        <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>Help</Text>
                    </TouchableOpacity>
                </View>
            </View>
            : location.show && location.location ?
            <View style={{ flexDirection:'row', padding:10}}>
                <View style={{ height:35, width:35, borderRadius:100, backgroundColor:Colors.shades.shade100, justifyContent:'center', alignItems:'center' }}>
                    <Image
                        source={{ uri: location.location.location_image }}
                        style={{ height:30, width:30 }}
                        resizeMode='center'
                    />
                    <View style={{ position:'absolute', top:0, left:0, bottom:0, right:0, justifyContent:'center', alignItems:'center' }}>
                        <Icon name={location.legal_ind?'check-circle':'x'} color={location.legal_ind?Colors.utility.success:Colors.utility.error} type='feather' size={40} />
                    </View>
                </View>
                <View style={{flex:1, marginLeft:10}}>
                    <Text style={{ marginBottom:3 }} size={14} color={Colors.brand.midnight} weight='bold'>{location.location.label}</Text>
                    {location.legal_ind ?
                        <Text size={12} color={Colors.brand.midnight}>Awesome! Real money markets are available here!</Text>
                    :
                        <Text size={12} color={Colors.brand.midnight}>Sorry! We do not offer real money markets here.  Feel free to use the demo / free mode!</Text>
                    }
                </View>
                <TouchableOpacity style={{ padding:10 }} onPress={() => dispatch({ type:V1_LOAD_LOCATION, ...location, show:false })}>
                    <Icon name='x' type='feather' size={14} color={Colors.utility.error} />
                </TouchableOpacity>
            </View>
            :<></>
            }
        </View>
    )
}

export default LocationStatus