import moment from 'moment';
import React from 'react';
import { View, Image } from "react-native";
import { Icon } from 'react-native-elements';
import Colors from '../constants/Colorsv2';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { OrderProps } from '../store/types/mk_svc';

type OrderCardHeaderProps = {
    order:OrderProps,
    hide_actions?:boolean,
    onActionPress: () => void
}

const OrderCardHeader = ({ order, hide_actions, onActionPress }:OrderCardHeaderProps) => {
    const getHeaderDetails = (order:OrderProps) => {
        switch(order.status){
            case 'approved':
                if(order.h2h_user){ return { header: `Challenge Requested`, sub_header: `Challenge with ${order.h2h_user.username}` } }
                return { header: `Open ${order.order_type} order`, sub_header: moment(order.create_datetime).format('YYYY-MM-DD hh:mm a') }
            case 'cancelled':
                if(order.h2h_user){ return { header: `Challenge Withdrawn`, sub_header: `Challenge with ${order.h2h_user.username}` } }
                if(order.called_amt === 0){ return { header: 'Order Cancelled', sub_header: moment(order.create_datetime).format('YYYY-MM-DD hh:mm a')  } }
                return { header: `Fulfilled Position`, sub_header: moment(order.create_datetime).format('YYYY-MM-DD hh:mm a') }
            case 'closed':
                if(order.h2h_id){ return  { header: `H2H Challenge`, sub_header: order.h2h_user?.username??'' }  }
                return { header: `Fulfilled Position`, sub_header: moment(order.create_datetime).format('YYYY-MM-DD hh:mm a') }
            default: return { header: 'tbd', sub_header: 'tbd' }
        }
    }

    const { header, sub_header } = getHeaderDetails(order)
    return (
        <View nativeID='order_header' style={{ flexDirection:'row', alignItems:'center' }}>
            <View style={{ flexDirection:'row', alignItems:'center', flex:4/5 }}>
                {order.h2h_user ?
                 <View style={{ height:36, width:36, borderRadius:4, justifyContent:'center', alignItems:'center' }}>
                    <Image style={{ height: 36, width:36, borderRadius:4 }} resizeMode='cover' source={order.h2h_user.profile_pic??require('../assets/images/default-avatar.png')} />
                </View>
                :
                <View style={{ height:36, width:36, borderRadius:4, backgroundColor:Colors.brand.electric, justifyContent:'center', alignItems:'center' }}>
                    <Icon name='activity' type='feather' size={20} color={Colors.shades.white}/>
                </View>
                }
                <View style={{ padding:4, marginLeft:10 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>{header}</Text>
                    <Text size={12} color={Colors.brand.slate} weight='regular'>{order.influencer?`${order.influence_type==='copy'?'Copied':'Faded'} ${order.influencer.username} on `:''}{sub_header}</Text>
                </View>
            </View>
            {!hide_actions ?
            <TouchableOpacity style={{ flex:1/5, alignItems:'flex-end' }} onPress={() => onActionPress()}>
                <Icon name='more-vertical' type='feather' color={Colors.brand.midnight} size={20}/>
            </TouchableOpacity>
            :<></>}
        </View>
    )
}

export default OrderCardHeader