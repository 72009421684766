import React, { useEffect, useState } from "react"
import { ActivityIndicator, FlatList, TextInput, View } from "react-native"
import { connect, useDispatch, useSelector } from "react-redux"
import Colors from "../../../constants/Colorsv2"
import { Text, TouchableOpacity } from "../../../globalComponents/NativeOverrides"
import { player_getReferralByPlayerId, player_searchByPhone, player_smartSearch } from "../../../store/actionsv1/player"
import { PlayerReferralProps, PublicPlayerProps, SmartSearchRequestProps } from "../../../store/types/auth_svc"
import PlayerCard from "./PlayerCard"
import { isPhoneValid } from "../../Authenticate/helpers/account"
import { auth_phoneAuth } from "../../../store/actionsv1/auth"

type PlayerSelectorProps = {
    player_id:string,
    search_value_override?:string,
    max_height?:number
    init_player?:PublicPlayerProps,
    hide_border?:boolean,
    keep_active_on_select?:boolean,
    onPlayerSelect: (player:PublicPlayerProps) => void
}

const PlayerSelector = ({ player_id, search_value_override, init_player, keep_active_on_select, max_height, hide_border, onPlayerSelect }:PlayerSelectorProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ search_type, setSearchType ] = useState('username');
    const [ search_active, setSearchActive ] = useState(false);
    const [ search_value, setSearchValue ] = useState('')
    const [ show_invite, setShowInvite ] = useState(false);
    const [ invite_loading, setInviteLoading ] = useState(false);
    
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state:any) => state.auth.isAuthenticated, (left, right) => left === right)
    const player_referral:PlayerReferralProps | undefined = useSelector((state:any) => state.player.player_referral, (left, right) => left?.player_referral_id == right?.player_referral_id);
    const stored_players:PublicPlayerProps[] = useSelector((state:any) => state.social.stored_players, (left, right) => left?.length == right?.length)
    const filtered_players = stored_players.filter(p =>
        `${p.first_name} ${p.last_name} ${p.username}`.toLowerCase().includes(search_value.toLowerCase())
      ).slice(0, 9)

    useEffect(() => {
        getReferral()
        if(!search_value_override){ return }
        handleSearchTextChange(search_value_override)
    },[search_value_override])

    useEffect(() => {
        if(!init_player){ return }
        setSearchValue(init_player.username)
        onPlayerSelect(init_player)
    },[init_player])

    const getReferral = async() => {
        if(!player_id){ return }
        if(!player_referral){await dispatch(player_getReferralByPlayerId(player_id))}
    }
        
    const handlePlayerSelect = (player: PublicPlayerProps) => {
        if(!onPlayerSelect){ return }
        if(!keep_active_on_select){
            setSearchValue(player.username)
            setSearchActive(false)
        }
        onPlayerSelect(player)
    }

    const handleSearchTextChange = (e:string) => {
        if(!isAuthenticated){ return alert('Must be authenticated to search users') }
        if(e !== '' && !search_active){ setSearchActive(true) }
        setSearchValue(e)
        if(!loading && filtered_players.length === 0){
            searchPlayersFromServer(e)
        }
    }

    const { attribute, state, error } = isPhoneValid(search_value)

    const handleSearchPhoneChange = (phone:string) => {
        if(!isAuthenticated){ return alert('Must be authenticated to search users') }
        if(phone !== '' && !search_active){ setSearchActive(true) }
        setSearchValue(phone)
    }

    const handleQuietInvite = async() => {
        if(error){ return alert('Unable to process invite') }
        setInviteLoading(true);
        //First create the account
        await auth_phoneAuth(search_value, 'invite', player_referral?.referral_code, true)//Silently create invite account
        //Now search for the user again
        await handleSearchByPhone()
        setInviteLoading(false)
    }

    const searchPlayersFromServer = async(search:string) => {
        if(loading){ return }
        if(!search_active){ setSearchActive(true) }
        if(filtered_players.length > 0){ return }
        setLoading(true)
        let search_request:SmartSearchRequestProps = {
            player_id,
            search_value:search,
            offset: 0
        }
        await dispatch(player_smartSearch(search_request, 'search'))
        setLoading(false)
    }

    const handleSearchByPhone = async() => {
        if(error){ return alert('Unable to search that phone') }
        const player = await player_searchByPhone(search_value)
        if(!player){ return setShowInvite(true) }
        setShowInvite(false)
        setSearchActive(false)
        onPlayerSelect(player)
    }

    const renderPlayers = (data: { item: PublicPlayerProps, index:number }) => {
        return (
            <TouchableOpacity style={{ borderBottomWidth:1, borderColor:Colors.shades.shade100 }} onPress={() => handlePlayerSelect(data.item)}>
                <PlayerCard hide_follow_status viewer_id={player_id} player={data.item} onPlayerPress={(player) => handlePlayerSelect(player)}/>
            </TouchableOpacity>
        )
    }
    return (
        <View nativeID="player-selector" style={{ borderWidth:hide_border?0:1, borderColor:Colors.accents.accent100, borderRadius: 4, backgroundColor:Colors.shades.white }}>
            {search_type == 'username' ?
            <View>
                {!search_value_override && search_value_override !== '' ?
                <View style={{ flexDirection:'row' }}>
                    <View style={{borderRightWidth:1, borderColor:Colors.accents.accent100, padding:10}}>
                        <Text size={16} color={Colors.shades.black} weight='bold'>@</Text>
                    </View>
                    <TextInput style={{ fontFamily:'barlow-regular', color:Colors.brand.midnight, padding:10, flex:1 }} 
                        placeholderTextColor={Colors.brand.slate} placeholder='Type a username' value={search_value}
                        onChangeText={(e) => handleSearchTextChange(e)}
                        />
                </View>
                :<></>}
            </View>
            : search_type == 'phone' ?
            <View>
                {!search_value_override && search_value_override !== '' ?
                <View style={{ flexDirection:'row' }}>
                    <View style={{borderRightWidth:1, borderColor:Colors.accents.accent100, padding:10}}>
                        <Text size={16} color={Colors.shades.black} weight='bold'>#</Text>
                    </View>
                    <TextInput style={{ fontFamily:'barlow-regular', color:Colors.brand.midnight, padding:10, flex:1 }} 
                        placeholderTextColor={Colors.brand.slate} placeholder='Type a phone number' value={search_value}
                        onChangeText={(e) => handleSearchPhoneChange(e)}
                        />
                    {state == 'valid' && !error && search_active && !show_invite ?
                    <TouchableOpacity style={{ padding:5, paddingLeft:10, paddingRight:10, justifyContent:'center', alignItems:'center', backgroundColor:Colors.brand.electric, borderRadius:8 }} onPress={() => handleSearchByPhone()}>
                        <Text size={14} color={Colors.shades.white} weight='semibold' textAlign="center">Search</Text>
                    </TouchableOpacity>
                    :<></>}
                     {show_invite ?
                    <TouchableOpacity disabled={invite_loading} style={{ padding:5, paddingLeft:10, paddingRight:10, justifyContent:'center', alignItems:'center', backgroundColor:Colors.incentive.gold, borderRadius:8, opacity:invite_loading?0.5:1 }} onPress={() => handleQuietInvite()}>
                        {invite_loading ?
                        <ActivityIndicator size='small' color={Colors.shades.white} />
                        :
                        <Text size={14} color={Colors.shades.white} weight='semibold' textAlign="center">Invite!</Text>
                        }
                    </TouchableOpacity>
                    :<></>}
                </View>
                :<></>}
            </View>
            :<></>}
            {search_active && filtered_players.length > 0?
            <View style={{ maxHeight:max_height??undefined }}>
                <FlatList data={filtered_players} renderItem={renderPlayers} keyExtractor={(item) => item.player_id.toString()}/>
            </View>
            :<></>}
        </View>
    )
}


export default PlayerSelector