import axios from 'axios';

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Location from 'expo-location';
import getEnvVars from '../env';
const { ENVIRONMENT, SOC_SVC_API } = getEnvVars()

import moment, { Moment } from 'moment';
import { logPlayerLocation } from '../utils/marketing/impressionHelper';
import { PlayerLocationProps } from '../store/types/soc_svc';
import store from '../store';

Location.setGoogleApiKey('AIzaSyAZemliheBdXsREeWfWeZZ8WWylEROMfjA')

let region:string|undefined = undefined
let location:any;
let regionExpireTime:Moment|undefined = undefined


export const getCoordinates = async() => {
    if(location){ return location }
    const loc = await getPlayerLocation()
    if(!loc){ return undefined }
    return loc.location
}

export const loc_getBackgroundPermissionsAsync = async():Promise<{status: 'error'|'denied'|'undetermined'|'granted', canAskAgain: boolean}> => {
    try {
        if(ENVIRONMENT === 'DEVELOPMENT'){ console.log('in dev!'); return { status:'granted', canAskAgain: true } }
        let permissions = await Location.getBackgroundPermissionsAsync()
        return { status: permissions.status, canAskAgain:permissions.canAskAgain }
    } catch (e) {
        console.log(e)
        return { status: 'error', canAskAgain:false }
    }
}

export const loc_requestLocationPermissions = async():Promise<'error'|'denied'|'undetermined'|'granted'> => {
    try {
        let permissions = await Location.requestBackgroundPermissionsAsync()
        return permissions.status
    } catch (e) {
        return 'error'
    }
}

let getting_location = false
export const getPlayerLocation = async():Promise<{ region:string, location:any } | undefined> => {
    if(getting_location){ return undefined }
    if(ENVIRONMENT === 'DEVELOPMENT'){ console.log('in dev!'); return { region:'Minnesota', location: {} } }
    try {
        console.log('getting location!')
        getting_location = true
        //Check if we can get the cached version of the users location
        if(region && location && regionExpireTime){
            if(moment().isBefore(regionExpireTime)){
               getting_location = false
               return { region, location }
            }
        }
        //1) Get the anonymous id of the user to log their location
        let permissions = await Location.getBackgroundPermissionsAsync()
        if(permissions.status !== 'granted'){
            permissions = await Location.requestBackgroundPermissionsAsync()
        }
        if(permissions.status !== 'granted'){ 
            //alert('We are unable to gather your location at this time')
            getting_location = false; 
            return undefined 
        }
        //let permissions = await Location.requestBackgroundPermissionsAsync()

        let anonymous_id = await AsyncStorage.getItem('ajs_anonymous_id')

        region = undefined
        location = undefined
        //2) Check that the user have location services enabled
        //let resp = await Location.hasServicesEnabledAsync()
        //if(!resp){ return undefined } //If not - returned undefined
        //3) Get the location details of the user (coordinates)
        const location_detail = await Location.getCurrentPositionAsync({ maximumAge: 10000, timeout: 5000, accuracy: Location.Accuracy.Highest })
        location = location_detail.coords
        //Log the player's location
        //logPlayerLocation(location_detail.coords, anonymous_id) 
        let player_location:PlayerLocationProps = {
            player_id: "",
            anonymous_id: anonymous_id??'',
            coordinates: location,
            create_datetime:'',
            player_location_id: ''
        }
        const { distance, last_location } = await soc_compareLocation(player_location)
        if(last_location?.region && distance < 5){
            player_location.region = last_location.region
            region = last_location.region
        } else {
            const geo_code_result = await axios(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location_detail.coords.latitude},${location_detail.coords.longitude}&key=AIzaSyAZemliheBdXsREeWfWeZZ8WWylEROMfjA`, {
                headers: {
                    'Authorization': undefined,
                    'x-api-key':undefined,
                    'sessionid':undefined,
                    'distinctid':undefined
                }
            })
            const location_found = geo_code_result.data.results.find(r => r.address_components?.find(c => c.types.includes('administrative_area_level_1')))

            if(!location_found){ 
                getting_location = false; 
                return undefined 
            } //If we cant get it - then return undefined

            let new_region = location_found.address_components.find(c => c.types.includes('administrative_area_level_1'))
            if(!new_region?.long_name){
                getting_location = false
                return undefined
            }
            region = new_region.long_name
            player_location.region = region
        }
        await soc_saveLocation(player_location)

        //4) Get the region by looking up their coords
        regionExpireTime = moment().add(30, 'minutes')
        getting_location = false
        return { region, location }
    } catch(e){
        console.log(e)
        getting_location = false
        return undefined
    }
} 




export const getLocationFromZip = async(zip:string) => {
    try {
        let loc = await Location.geocodeAsync(zip)
        if(!loc[0]){ return undefined }
        const location =  await Location.reverseGeocodeAsync({ latitude: loc[0].latitude, longitude: loc[0].longitude })
        let accurateLocation = location.find(l => l.postalCode && l.region)
        if(!accurateLocation){ return undefined }
        return accurateLocation
    } catch(e) {
        return undefined
    }   
}




export const soc_compareLocation = async(player_location:PlayerLocationProps):Promise<{ last_location?:PlayerLocationProps, distance:number }> => {
    try {
        const resp = await axios.post(`${SOC_SVC_API}/v1/locations/player/compare`, { player_location })
        return { distance: resp.data.distance, last_location: resp.data.last_location }
    } catch (e) {
        console.log(e)
        return { distance:99999999, last_location: undefined }
    }
}

export const soc_saveLocation = async(player_location:PlayerLocationProps):Promise<void> => {
    try {
        await axios.post(`${SOC_SVC_API}/v1/locations/player/create`, { player_location })
    } catch (e) {
        console.log(e)
        return
    }
}