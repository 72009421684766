import React, { useEffect, useRef, useState } from 'react';
import { View, Image } from "react-native"
import { connect, useDispatch, useSelector } from "react-redux"
import Colors from '../../../constants/Colorsv2';
import { TouchableOpacity, Text } from "../../../globalComponents/NativeOverrides"
import { social_getFollowerStatus, social_updatePlayerFollower } from '../../../store/actionsv1/social';
import { PublicPlayerProps } from "../../../store/types/auth_svc"
import { PlayerFollowerProps } from "../../../store/types/soc_svc"
import { useIsInViewport } from '../../../utilsv1/helpers';

type PlayerCardProps = {
    viewer_id: string,
    vertical?: boolean,
    backgroundColor?:string,
    width?:number,
    hide_bio?: boolean,
    player: PublicPlayerProps,
    hide_follow_status?: boolean
    onPlayerPress: (player:PublicPlayerProps) => void
}

const PlayerCard = ({ player, viewer_id, backgroundColor, hide_follow_status, vertical, hide_bio, width, onPlayerPress }:PlayerCardProps) => {
    const [ statusChecked, setStatusChecked ] = useState(false);
    const dispatch = useDispatch();
    const player_follower:PlayerFollowerProps | undefined = useSelector((state:any) => state.social.player_followers.find((pf:PlayerFollowerProps) => pf.following_id == player.player_id), (left, right) => left?.status === right?.status)
    
    const p_ref = useRef()
    const isInViewport1 = useIsInViewport(p_ref)

    useEffect(() => {
        if(isInViewport1 && !statusChecked){
            getFollowerStatusFromServer(viewer_id, player.player_id)
        }
    },[isInViewport1])

    const handlePlayerPress = () => {
        if(!onPlayerPress){ return }
        onPlayerPress(player)
    }

    const handleFollowPlayer = async() => {
        if(!player_follower){ return }
        const status = player_follower.status === 'following' ? 'not_following' : 'following'
        await dispatch(social_updatePlayerFollower({ ...player_follower, status }))
        setStatusChecked(true)
    }

    const getFollowerStatusFromServer = async(player_id:string, following_id:string) => {
        await dispatch(social_getFollowerStatus(player_id, following_id))
    }
    const following = player_follower?.status === 'following' ? true : false
    const username = !vertical ? player.username : player.username.length < 12 ? player.username : player.username.slice(0, 12)
    const name = !vertical ? `${player.first_name} ${player.last_name}` : `${player.first_name} ${player.last_name}`.length < 15 ? `${player.first_name} ${player.last_name}` : `${player.first_name} ${player.last_name}`.slice(0,15)
    return (
        <View style={{ flex:1, backgroundColor:backgroundColor?backgroundColor:Colors.shades.white, borderRadius:8, padding:8, width:width??undefined, shadowColor: "rgba(0, 0, 0, 0.06)", shadowOffset: { width: 0, height: 10 }, shadowRadius: 10, shadowOpacity: 1 }} ref={p_ref}>
            <View style={{flexDirection:vertical?'column':'row', justifyContent:'space-between'}}>
                <TouchableOpacity style={{flexDirection:vertical?'column':'row', alignItems:'center'}} onPress={() => handlePlayerPress()}>
                    <View>
                        <Image style={{height:40, width:40, borderRadius:100}} source={player.profile_pic ? { uri: player.profile_pic }:require('../../../assets/images/default-avatar.png')} />
                    </View>
                    <View style={{ flex:1, flexShrink:1, flexWrap:'wrap', overflow:'hidden', marginLeft:vertical?0:16, marginTop:vertical?5:0, marginBottom:vertical?5:0, alignItems:vertical?'center':'flex-start'}}>
                        <View style={{flexDirection:vertical?'column':'row', alignItems:'center'}}>
                            <Text size={14} color={Colors.brand.midnight} weight='semibold' textAlign={vertical?'center':'left'}>{name}</Text>
                        </View>
                        <Text style={{ flex:1, overflow:'hidden' }} size={14} color={Colors.brand.slate} weight='regular' textAlign={vertical?'center':'left'}>@{username}</Text>
                    </View>
                </TouchableOpacity>
                {player_follower && !hide_follow_status ?
                <TouchableOpacity style={{ margin:4, borderRadius: 20, borderWidth:1, borderColor:Colors.brand.electric, backgroundColor:following ? 'transparent' : Colors.brand.electric, padding:5 }} onPress={() => handleFollowPlayer()}>
                    <Text style={{ padding:5 }} size={14} color={following ? Colors.brand.electric : Colors.shades.white} weight='bold' textAlign='center'>{following?'Unfollow':'Follow'}</Text>
                </TouchableOpacity>
                :<></>}
            </View>
            {!hide_bio ?
            <View style={{ marginTop:5 }}>
                <Text size={14} color={Colors.brand.midnight} weight='regular'>{player.bio??'This player has not set up a bio.  Follow them and tell them to add one!'}</Text>
            </View> 
            :<></>}
        </View>
    )

}

export default PlayerCard