import { placeholder } from '@cloudinary/html';
import React from 'react';
import { Text as RNText, View, TouchableOpacity as RNTouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import Colors from '../constants/Colorsv2';
import { segEventTrack } from '../utils/analytics/segment';

type TextType = { weight?:'bold'|'semibold'|'regular'|'light', color?:string, size?: number, children:any, textAlign?:'left'|'center'|'right', style?:any, selectable?:boolean }
export const Text = ({ weight, color, size, children, textAlign, style, selectable }: TextType) => {

    return (
        <RNText 
            selectable={selectable}
            style={[{ color:color??Colors.shades.black, fontSize: size??14, fontFamily: weight?getFontFamily(weight):'barlow-regular', textAlign:textAlign??'left' }, style]}>
            {children}
        </RNText>
    )
}

type TouchableOpacityType = { native_id?:string, style?:any, children?:any, onPress?:any, onLongPress?:any, onLayout?:any, disabled?:boolean, event_properties?:any }
export const TouchableOpacity = ({ native_id, style, children, onPress, onLongPress, onLayout, disabled, event_properties } : TouchableOpacityType ) => {
    let eProps = { btx_id: native_id, ...event_properties }
    const handleOnPress = () => {
        if(native_id){ segEventTrack({ event: 'Button Click', properties : eProps }) }
        if(onPress){ return onPress() }
    }
    const handleLongPress = () => {
        if(native_id){ segEventTrack({ event: 'Button Click', properties : eProps }) }
        if(onLongPress){ return onLongPress() }
    }
    const handleLayout = (ev:any) => {
        if(onLayout){ onLayout(ev) }
    }

    return (
        <RNTouchableOpacity 
            key={native_id} style={{ ...style }} 
            disabled={disabled} 
            onLayout={(ev) => handleLayout(ev)} 
            onPress={() => handleOnPress()}
            onLongPress={() => handleLongPress()}
            delayLongPress={1000}>
            {children}
        </RNTouchableOpacity>
    )
}

export const CheckBox = ({ selected }: { selected:boolean }) => {
    return (
        <View style={{ height:24, width:24, borderRadius:4, justifyContent:'center', alignItems:'center', borderWidth:1, borderColor:Colors.brand.cobalt, backgroundColor:selected?Colors.brand.cobalt:'transparent' }}>
            {selected?
                <Icon name='check' type='feather' color={Colors.shades.white} size={14}/>
            :<></>}
        </View>
    )
}

const getFontFamily = (weight:string) => {
    switch(weight){
        case 'bold': return 'barlow-bold'
        case 'semibold': return 'barlow-semibold'
        case 'regular': return 'barlow-regular'
        case 'light': return 'barlow-light'
        default: return 'barlow-regular'
    }
}