import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Location from 'expo-location';
import getEnvVars from '../../env';
const { ENVIRONMENT } = getEnvVars()

import moment, { Moment } from 'moment';
import { logPlayerLocation } from '../marketing/impressionHelper';
import { getPlayerLocation } from '../../utilsv1/location';
Location.setGoogleApiKey('AIzaSyAZwcca9MedBHEedDdQmQG3AL9FMUwTvP0')

let region:string|null = null
let coords:any;
let regionExpireTime:Moment|undefined = undefined

export const askForLocation = async() => {
    let resp = await Location.requestBackgroundPermissionsAsync()
    return resp.status
}

export const getCoords = () => {
    if(coords){ 
        return coords 
    }
    getPlayerLocation()
}

export const getLocationPermissions = async () => {
    let resp = await Location.getBackgroundPermissionsAsync()
    return resp.status
}

export const getPlayerRegion = async() => {
    if(ENVIRONMENT === 'DEVELOPMENT'){ console.log('in dev!'); return 'Minnesota' }
    try {
        let anonymous_id = await AsyncStorage.getItem('ajs_anonymous_id')
        if(region && regionExpireTime){
            if(moment().isBefore(regionExpireTime)){
                if(coords){ logPlayerLocation(coords, anonymous_id) }
                return region
            }
        }   
        let resp = await Location.hasServicesEnabledAsync()
        if(!resp){
            return null
        }
        const location_detail = await Location.getCurrentPositionAsync({ maximumAge: 10000, timeout: 25000, accuracy: Location.Accuracy.Balanced })
        logPlayerLocation(location_detail.coords, anonymous_id) 
        const location =  await Location.reverseGeocodeAsync({ latitude: location_detail.coords.latitude, longitude: location_detail.coords.longitude })
        const accurateLocation = location.find(l => l.postalCode && l.region && l.isoCountryCode)
        if(!accurateLocation){
            return null
        }
        coords = location_detail.coords
        region = accurateLocation.region
        regionExpireTime = moment().add(1, 'hours')
        return region
    } catch(e){
        return null
    }
} 

export const getLocationFromZip = async(zip:string) => {
    try {
        let loc = await Location.geocodeAsync(zip)
        if(!loc[0]){ return undefined }
        const location =  await Location.reverseGeocodeAsync({ latitude: loc[0].latitude, longitude: loc[0].longitude })
        let accurateLocation = location.find(l => l.postalCode && l.region)
        if(!accurateLocation){ return undefined }
        return accurateLocation
    } catch(e) {
        return undefined
    }
    
}

export const getCurrentAddress = async() => {
    try {
        let resp = await Location.hasServicesEnabledAsync()
        if(!resp){
            return undefined
        }
        const location_detail = await Location.getCurrentPositionAsync({ maximumAge: 10000, timeout: 25000, accuracy: Location.Accuracy.Balanced })
        const location =  await Location.reverseGeocodeAsync({ latitude: location_detail.coords.latitude, longitude: location_detail.coords.longitude })
        const accurateLocation = location.find(l => l.postalCode && l.region && l.isoCountryCode)
        if(!accurateLocation){
            return undefined
        }
        return accurateLocation
    } catch(e){
        return undefined
    }
}

export const isOrderLegal = async(market_type:'FOR_MONEY'|'FREE', locations:any[]) => {
    if(!locations || locations.length === 0){ return { legal_ind:false, location:undefined, alert_message:'Unable to gather location.  Please ensure location services are enabled' } }
    let location = await getPlayerRegion()
    if(market_type === 'FREE'){ return { legal_ind:true, alert_message:'', location, coords } }
    if(!location){ return { legal_ind:false, location:undefined, alert_message:'Unable to gather location.  Please ensure location services are enabled' } }
    let legal = locations.find(l => l.label === location && l.legal_ind)?true:false
    if(!legal){ return { legal_ind:false, alert_message:`Sorry! We currently do not offer real money activity in ${location}` } }
    return { legal_ind:true, location, alert_message: '', coords }

}



