import React, { useState } from 'react';
import { FlatList, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../constants/Colorsv2';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { player_getHelpPromptById } from '../store/actionsv1/player';
import { PublicPlayerProps } from '../store/types/auth_svc';
import ALaCartePremium from '../globalComponents/ALaCartePremium';

//Typecast a grade box object
type GradeBoxType = {
    min:number,
    max:number,
    success_color:string,
    fail_color:string,
    locked_color:string
}

//Stage the boxes and set the min / max and colors
const grade_boxes:GradeBoxType[] = [
    { min: 0, max: 79, success_color: 'rgba(125,205,133,0.2)', fail_color: 'rgba(213,87, 59, 0.2)', locked_color: Colors.shades.shade100 },
    { min: 80, max: 94, success_color: 'rgba(125,205,133,0.4)', fail_color: 'rgba(213,87, 59, 0.4)', locked_color: Colors.shades.shade100 },
    { min:95, max: 97.5, success_color: 'rgba(125,205,133,0.6)', fail_color: 'rgba(213,87, 59, 0.6)', locked_color: Colors.shades.shade100 },
    { min:97.6, max: 99.99, success_color: 'rgba(125,205,133,0.8)', fail_color: 'rgba(213,87, 59, 0)', locked_color: Colors.shades.shade100 },
    { min: 100, max: 200, success_color: 'rgba(125,205,133,1)', fail_color: 'rgba(213,87, 59, 0)', locked_color: Colors.shades.shade100 }
]

//OrderGradeBar React Component
const OrderGradeBar = ({ grade, view_type }: { grade?: number, view_type: 'card'|'book'|'market' }) => {
    
    //Get the player's role
    const dispatch = useDispatch();
    let premium = false
    const player:PublicPlayerProps | undefined = useSelector((state:any) => state.player.player, (left, right) => left?.type == right?.type);
    const premium_grades = useSelector((state:any) => state.player.player_premium_items_list.player_premium_items.find(ppi => ppi.premium_item?.identifier == 'order_grades'), (left, right) => left?.last_update_datetime == right?.last_update_datetime);
    if(player?.type == 'premium' || premium_grades){ premium = true }

    const handleSelectGrades = async() => {
        if(premium){ return }
        await dispatch(player_getHelpPromptById('t2_grades_1'))
    }

    /**
     * Renders the grade boxes
     * @param data 
     * @returns 
     */
    const renderGradeBoxes = (data :{ item:GradeBoxType, index:number }) => {
        if(!grade && grade !== 0){ return <></> }
        //Check if the grade is greater than the minumum of the box
        const filled = grade >= data.item.min ? true : false
        const success = grade > 97.5 ? true : false
        const locked = !premium ? true : false
        //Render the box
        return (
            <View style={{ margin:2, height:15, width:15, borderRadius:4, backgroundColor:locked? data.item.locked_color : filled ? success ? data.item.success_color : data.item.fail_color : Colors.shades.shade100 }}>
            {locked ?
                <View style={{ position:'absolute', top:0, right:0, left:0, bottom:0, justifyContent:'center', alignItems:'center' }}>
                    <Icon name='lock' type='feather' size={10} color={Colors.incentive.gold} />
                </View>
            :<></>}
            </View>
        )
    }

     /**
     * Renders the grade boxes
     * @param data 
     * @returns 
     */
      const renderGradeDashes = (data :{ item:GradeBoxType, index:number }) => {
        if(!grade && grade !== 0){ return <></> }
        //Check if the grade is greater than the minumum of the box
        const filled = grade >= data.item.min ? true : false
        const success = grade > 97.5 ? true : false
        const locked = !premium ? true : false

        //Render the box
        return (
            <View style={{ margin:1, height:4, width:15, borderRadius:4,  backgroundColor:locked? data.item.locked_color : filled ? success ? data.item.success_color : data.item.fail_color : Colors.shades.shade100 }} />
        )
    }

    if(!grade && grade !== 0){
        return (
            <></>
        )
    }

    if(view_type == 'market'){
        const success = grade > 97.5 ? true : false
        if(!premium){
            return (<></>)
        }
        return (
            <View style={{ flex:1, backgroundColor:success?Colors.utility.success:Colors.utility.error }} />
        )
    }

    return (
        <View>
            <TouchableOpacity onPress={() => handleSelectGrades()}>
                {view_type === 'card' ?
                <FlatList horizontal data={grade_boxes} renderItem={renderGradeBoxes} keyExtractor={(item) => item.min.toString()} />
                :view_type === 'book' ?
                <FlatList data={[ ...grade_boxes ].reverse()} renderItem={renderGradeDashes} keyExtractor={(item) => item.min.toString()} />
                :<></>}
            </TouchableOpacity>
        </View>
    )
}


export default OrderGradeBar