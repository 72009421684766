import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import uuid from 'uuid';
import store from '../../../store';
import getEnvVars from '../../../env';
import { V1_LOGIN, V1_UPDATE_MARKET_TYPE } from '../../../store/actionsv1/types';
import { auth_authenticateApp, auth_logout } from '../../../store/actionsv1/auth';
import moment from 'moment';
import { getMe } from '../../../store/actionsv1/player';
const { AUTH_SVC_API, APP_VERSION } = getEnvVars();
export const cached_requests:any = {}


export const setAPIKey = () => {
    axios.defaults.headers.common = {
        "x-api-key":'mlOfTJPp4TdmppP9ajBmMVo2bO50O2G9',
      }
}

/**
 * Set the JWT auth token into the global header for all axios requests
 * @param token 
 */
export const setAuthorizationToken = (token?:string) => {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
}

/**
 * Request interceptor.  This will check if we are currently attempting to get the data
 * If we are, then throw a cancel
 */
export const setCacheRequestInterceptor = () => {
    axios.interceptors.request.use((request) => {
        if(request.url && request.method === 'get'){
            let cached_request = cached_requests[request.url]
            if(cached_request){ 
                let time_passed = moment().unix() - cached_request.timestamp
                if(time_passed < 3){ 
                    if(request.url != 'https://be-auth-svc.herokuapp.com/v1/players/player/me'){
                        throw new Error('Already getting this data')  
                    }
                }
                
            }
            cached_requests[request.url] = { status: 'requesting', timestamp:moment().unix() }
        }
        return request
    }, (err) => {
        return Promise.reject(err)
    })
}


/**
 * Remove the request cache
 */
export const setCacheResponseInterceptor = () => {
    axios.interceptors.response.use((response) => {
        if(response.config.url){
            setTimeout(() => {
                delete cached_requests[response.config.url as string]
            }, 1000);
        }
        return response
    }, (err) => {
        return Promise.reject(err)
    })
}

export const setRefreshTokenInterceptor = () => {
    axios.interceptors.response.use((response) => {
        return response
    }, async (error) => {
        const originalRequest = error.config;
        //If the user gets a 403 - sign them out if they haven't been signed out yet
        if(error?.response?.status === 403) {
            if(store.getState().auth.isAuthenticated){ store.dispatch(auth_logout()); throw new Error(error);  } 
            else { return Promise.reject({ message: 'Authenication session has expired.' }) }
        }
        //If the user gets a 401 - check for a refresh_token and attempt to get another access token to use the request
        if(error?.response?.status === 401 && !originalRequest._retry) {
            const isAuthenticated = store.getState().auth.isAuthenticated
            if(!isAuthenticated){ return Promise.reject({ message: 'Attempted to refresh after logged out' }) }
            const existing_refresh_token = store.getState().auth.refresh_token
            const device_id = await AsyncStorage.getItem('dvcid')
            if(!existing_refresh_token){ 
                forceSignOut(store)
                return Promise.reject(error) 
            }
            originalRequest._retry = true;
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/tokens/token/refresh`, { refresh_token:existing_refresh_token, device_id, app_version:APP_VERSION })
            if(!resp?.data){ return forceSignOut(store) }
            const { access_token, refresh_token, expire_datetime } = resp.data
            if(!access_token || !refresh_token){ 
                return forceSignOut(store) }
            auth_authenticateApp(access_token, device_id)
            store.dispatch({ type: V1_LOGIN, access_token:access_token, refresh_token:refresh_token, expire_datetime:expire_datetime })
            return Promise.resolve(axios({ ...originalRequest, headers: { ...originalRequest.headers, Authorization: `Bearer ${access_token}` }}));
        }
        return Promise.reject(error)
    })
}


const forceSignOut = (store:any) => {
    if(store.getState().auth.isAuthenticated){ store.dispatch(auth_logout()) }
}


/**
 * Check for saved jwt token and return if exists
 * @returns 
 */
export const autoLoginCheck = async(token?:string):Promise<undefined | { token: string, device_id:string }> => {
    try {

        let device_id = await AsyncStorage.getItem('dvcid')
        if(!device_id){ 
            device_id = `${uuid.v4()}${uuid.v4()}`
            await AsyncStorage.setItem('dvcid', device_id) 
        }
        if(!token){ token = await store.getState().auth.access_token }
        if(token){
            store.dispatch({ type: V1_UPDATE_MARKET_TYPE, market_type: 'FOR_MONEY' })
            setAuthorizationToken(token);
            setRefreshTokenInterceptor()
            store.dispatch(getMe())
            return { token, device_id }
        }
        return undefined
    } catch (e) {
        return undefined
    }
}
