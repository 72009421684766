import { PollReducerProps, V1_UPDATE_POLLS, V1_UPDATE_POLL_CAMPAIGNS, V1_UPDATE_POLL_CAMPAIGN_GOALS, V1_UPDATE_POLL_CAMPAIGN_LEADERS, V1_UPDATE_POLL_OPTIONS, V1_UPDATE_POLL_SUMMARIES } from "../actionsv1/types"


const DEFAULT_STATE:PollReducerProps = {
    poll_campaigns_list: {
        updated: 1,
        poll_campaigns: []
    },
    poll_campaign_goals_list: {
        updated: 1,
        poll_campaign_goals: []
    },
    polls_list: {
        updated:1,
        polls: []
    },
    poll_campaign_leaders_list: {
        updated:1,
        poll_campaign_leaders: []
    },
    poll_options_list: {
        updated:1,
        poll_options: []
    },
    poll_summaries_list: {
        updated:1,
        poll_summaries: []
    }
}

const pollReducer = (state = DEFAULT_STATE, action:any) => {
    switch(action.type){
        case V1_UPDATE_POLL_CAMPAIGNS:
            return {
                ...state,
                poll_campaigns_list: {
                    updated: state.poll_campaigns_list.updated + 1,
                    poll_campaigns: state.poll_campaigns_list.poll_campaigns.filter(pc => !action.poll_campaigns.find(npc => npc.poll_campaign_id == pc.poll_campaign_id)).concat(action.poll_campaigns)
                }
            }
        case V1_UPDATE_POLL_CAMPAIGN_GOALS:
            return {
                ...state,
                poll_campaign_goals_list: {
                    updated: state.poll_campaign_goals_list.updated + 1,
                    poll_campaign_goals: state.poll_campaign_goals_list.poll_campaign_goals.filter(pcg => !action.poll_campaign_goals.find(npcg => npcg.poll_campaign_goal_id == pcg.poll_campaign_goal_id)).concat(action.poll_campaign_goals)
                }
            }
        case V1_UPDATE_POLL_CAMPAIGN_LEADERS:
            return {
                ...state,
                poll_campaign_leaders_list:{
                    updated: state.poll_campaign_leaders_list.updated + 1,
                    poll_campaign_leaders: state.poll_campaign_leaders_list.poll_campaign_leaders.filter(pcl => !action.poll_campaign_leaders.find(npcl => `${npcl.poll_campaign_id}:${npcl.player_id}` == `${pcl.poll_campaign_id}:${pcl.player_id}`)).concat(action.poll_campaign_leaders)
                }
            }
        case V1_UPDATE_POLLS:
            return {
                ...state,
                polls_list: {
                    updated: state.polls_list.updated + 1,
                    polls: state.polls_list.polls.filter(p => !action.polls.find(np => np.poll_id == p.poll_id)).concat(action.polls)
                }
            }
        case V1_UPDATE_POLL_OPTIONS:
            return {
                ...state,
                poll_options_list: {
                    updated: state.poll_options_list.updated + 1,
                    poll_options: state.poll_options_list.poll_options.filter(po => !action.poll_options.find(npo => npo.poll_option_id == po.poll_option_id)).concat(action.poll_options)
                }
            }
        case V1_UPDATE_POLL_SUMMARIES:
            return {
                ...state,
                poll_summaries_list: {
                    updated:state.poll_summaries_list.updated + 1,
                    poll_summaries: state.poll_summaries_list.poll_summaries.filter(ps => !action.poll_summaries.find(nps => nps.poll_id == ps.poll_id)).concat(action.poll_summaries)
                }
            }
        default:
            return state
    }
 }
 
 export default pollReducer