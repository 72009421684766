
import React, { useEffect, useState } from 'react';
import { View, TouchableOpacity, FlatList } from 'react-native';
import Modal from 'modal-enhanced-react-native-web';
import Colors from '../constants/Colorsv2';
import { Text } from '../globalComponents/NativeOverrides';
import { connect, useSelector } from 'react-redux';
import { Icon } from 'react-native-elements';

type ActionModalProps = {
    isVisible: boolean,
    closeModal: () => void,
    locked?: boolean,
    options: any[],
    selected?: any[],
    multi?:boolean,
    label_attribute: string,
    onSelectOption: (option:any) => void,
}

const ActionModal = ({ isVisible, closeModal, options, locked, selected, multi, onSelectOption, label_attribute }:ActionModalProps) => {

    const app_size:{ width:number } = useSelector((state:any) => state.util.app_size, (left, right) => left.width == right.width)


    const handleSelectedOption = (option:any) => {
        if(!onSelectOption){ return closeModal()}
        onSelectOption(option)
        return closeModal()
    }


    const renderOptions = (data: {item: any, index:number}) => {
        return (
            <TouchableOpacity style={{ padding:15, borderBottomWidth:1, borderColor:Colors.shades.shade100 }} onPress={() => handleSelectedOption(data.item)}>
                <Text size={14} weight='semibold' color={data.item.color??Colors.brand.midnight} textAlign='center'>{data.item[label_attribute]}</Text>
            </TouchableOpacity>
        )
    }

    const renderMultiOptions = (data: {item:any, index:number}) => {
        const is_selected = selected?.find(s => s[label_attribute] == data.item[label_attribute]);
        return (
            <TouchableOpacity style={{ flexDirection:'row', alignItems:'center', padding:15, borderBottomWidth:1, borderColor:Colors.shades.shade600 }} onPress={() => onSelectOption(data.item)}>
                {locked?
                <View style={{ height:25, width:25, justifyContent:'center', alignItems:'center' }}>
                    <Icon name='lock' color={Colors.incentive.gold} type='feather' size={16} />
                </View>
                :
                <View style={{ marginRight:10, height:25, width:25, borderWidth:1, borderColor:Colors.brand.midnight, justifyContent:'center', alignItems:'center', borderRadius:4 }}>
                    {is_selected ?
                        <View style={{ height:20, width:20, borderRadius:4, backgroundColor:Colors.brand.midnight }} />
                    :<></>}
                </View>
                }
                <Text size={14} weight='semibold' color={data.item.color??Colors.brand.midnight} textAlign='center'>{data.item[label_attribute]}</Text>
            </TouchableOpacity>
        )
    }


    return (
        <Modal isVisible={isVisible} style={{ flex:1, margin:0, justifyContent: 'flex-end', alignItems:'center'}} animationIn='slideInUp' animationOut='slideOutDown' animationOutTiming={400}
        onBackdropPress={() => closeModal()} >
            <View style={{ width:app_size.width, minHeight:350, backgroundColor:Colors.shades.white, borderTopRightRadius:42, borderTopLeftRadius:42, paddingBottom:35, paddingTop:10 }}>
                {multi ?
                    <FlatList data={options} renderItem={renderMultiOptions} keyExtractor={(item) => item[label_attribute]}/>
                :
                    <FlatList data={options} renderItem={renderOptions} keyExtractor={(item) => item[label_attribute]}/>
                }
                {multi ?
                <TouchableOpacity style={{ margin:10, borderRadius:4, borderColor:Colors.brand.midnight, borderWidth:1, padding:10 }} onPress={() => closeModal()}>
                    <Text size={14} color={Colors.brand.midnight} weight='semibold' textAlign='center'>CLOSE</Text>
                </TouchableOpacity>
                :<></>}    
            </View>
        </Modal>
    )
}

const areEqual = (prevProps:ActionModalProps, nextProps:ActionModalProps) => {
    if(prevProps.isVisible !== nextProps.isVisible){ return false }
    if(prevProps.selected?.length != nextProps.selected?.length){ return false }
    return true
}

export default React.memo(ActionModal, areEqual)