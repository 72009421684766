import moment from "moment";
import { BestAvailableOrderProps, MarketProps, OrderProps } from "../../../store/types/mk_svc";
import { OrderStatsProps } from "../../../store/types/mk_svc";
import { order_calcPotentialWinnings, order_calcSaleValue, order_getOddsFromProbability } from "../../Event/helpers/orders";

type OrderActionProps = { 
    buy_now_price?:number, 
    buy_now_liquidity?:number, 
    jump_queue_price?:number, 
    jump_queue_liquidity?:number,
    cash_outs: { position_id:string, liquidity:number, cash_value:number, earnings:number, remaining_stake:number, stake_reduction:number, cash_out_price:number }[]
}
export const order_getActions = (market:MarketProps, order:OrderProps, best_available?:BestAvailableOrderProps[], player_id?:string) => {
    let response:OrderActionProps = { cash_outs:[] }

    if(!best_available){ return response }
    if(!market){ return response }

    

    //Get the buy now price
    //Filter best available to match the order
    let buy_now_order = best_available.find(ba => ba.event_id == order.event_id  &&
        ba.event_type == order.event_type && 
        ba.market_id == order.market_id && 
        ba.side_id == order.side_id &&
        ba.side == order.side &&
        ba.var_1 == order.var_1 && 
        ba.player_id != player_id
    );
    if(buy_now_order){
        response.buy_now_price = buy_now_order.probability
        response.buy_now_liquidity
    }
    let jump_queue_order = best_available.find(ba => ba.og_order?.event_id == order.event_id  &&
        ba.og_order?.event_type == order.event_type && 
        ba.og_order?.market_id == order.market_id && 
        ba.og_order?.side_id == order.side_id &&
        ba.og_order?.side == order.side &&
        ba.og_order.var_1 == order.var_1 &&
        ba.og_order.player_id != player_id
    );
    if(jump_queue_order){
        response.jump_queue_price = jump_queue_order.og_order.probability + 0.002
        response.jump_queue_liquidity = jump_queue_order.og_order.open_amt
    }

    if(jump_queue_order?.og_order && order.positions && order.positions[0]){
        order.positions.map(p => {
            if(!jump_queue_order?.og_order){ return }
            let sale_value = order_calcSaleValue(jump_queue_order.og_order.odds, p.potential_winnings)
            let sale_available = Math.min(sale_value, jump_queue_order.og_order.open_amt)
            //I can get cash out for the amount of sale that is available
            let pot_winnings = order_calcPotentialWinnings(jump_queue_order.og_order.odds, sale_available)
            let new_pot_winnings = p.potential_winnings - pot_winnings
            let new_stake = order_calcSaleValue(p.odds, new_pot_winnings)
            let stake_reduction = p.stake - new_stake
            response.cash_outs.push({
                position_id: p.position_id,
                cash_value: sale_available,
                cash_out_price: jump_queue_order.og_order.probability,
                liquidity: sale_available,
                earnings: sale_available - stake_reduction,
                remaining_stake: new_stake,
                stake_reduction: p.stake - new_stake
            })
        })
    }

    return response
}



export const order_getOrderStatsFromOrders = (orders:OrderProps[]):OrderStatsProps[] => {
    let order_stats:OrderStatsProps[] = []
    let unique_headers = [ ...new Set(orders.map(o => `${o.event_id}:${o.event_type}`)) ]
    let wins = 0, losses = 0, draws = 0;
    unique_headers.map(h => {
        let found_orders = orders.filter(o => `${o.event_id}:${o.event_type}` === h)
        if(found_orders.length === 0){ return; }
        let max_last_update = found_orders.filter(o => o.status == 'approved').find(o => Math.min(moment(o.expire_datetime).unix()))?.expire_datetime
        if(!max_last_update){ max_last_update = moment().add(1, 'year') }
        let unique_order_titles = [ ...new Set(found_orders.map(o => o.title)) ]

        let open_order_count = 0, open_amt = 0, potential_winnings = 0, called_amt = 0, commission = 0;
        let resolved_stake = 0, original_stake = 0, delayed_cash = 0, winnings = 0, net_winnings = 0, unresolved_stake = 0, resolved_potential_winnings = 0, unresolved_potential_net_winnings = 0, unresolved_potential_winnings = 0, num_trades = 0, cash_rcvd = 0; 
        let result_ind:string|undefined = undefined
        let order_ids:string[] = []
        let open_orders:string[] = []
        found_orders.map(o => {
            let commission_pct = 0
            if(o.commission_pct){ commission_pct = o.commission_pct }
            order_ids.push(o.order_id)
            if(o.status === 'approved'){
                open_orders.push(o.order_id)
                open_amt += o.open_amt
                open_order_count += 1
                potential_winnings += o.potential_winnings
                called_amt += o.called_amt
            }
            if(!o.positions){ return }
            o.positions.map(p => {
                if(!p){ return }
                num_trades += 1
                cash_rcvd += p.cash_rcvd
                if(!p.result_ind){
                    delayed_cash += p.delayed_cash
                    unresolved_stake += p.stake
                    unresolved_potential_winnings += p.potential_winnings
                    unresolved_potential_net_winnings += (p.potential_winnings - ((p.potential_winnings - p.stake) * commission_pct))
                } else {
                    resolved_stake += p.stake
                    original_stake += p.original_stake
                    winnings += p.winnings
                    net_winnings += p.net_winnings
                    result_ind = p.result_ind
                    commission += p.commission
                    resolved_potential_winnings += p.potential_winnings
                }
            })
        })
        if(result_ind == 'win'){ wins += 1 }
        if(result_ind == 'lose'){ losses += 1 }
        if(result_ind == 'draw'){ draws += 1 }
        let unresolved_position_probability = unresolved_stake / unresolved_potential_winnings
        let resolved_position_probability = resolved_stake / resolved_potential_winnings
        let unresolved_position_odds = order_getOddsFromProbability(unresolved_position_probability)
        let resolved_position_odds = order_getOddsFromProbability(resolved_position_probability)

        let offer_probability = open_amt / potential_winnings
        let offer_odds = order_getOddsFromProbability(offer_probability)
        let earnings = winnings + cash_rcvd - original_stake
        let roi = earnings / original_stake
        return order_stats.push({
            stat_id: h,
            unresolved_stake,
            earnings,
            resolved_stake,
            unique_order_titles,
            original_stake,
            roi,
            wins,
            net_winnings,
            commission,
            unresolved_potential_net_winnings,
            losses,
            draws,
            unresolved_position_odds,
            resolved_position_odds,
            result_ind,
            delayed_cash,
            unresolved_potential_winnings,
            resolved_potential_winnings,
            resolved_position_probability,
            unresolved_position_probability,
            num_trades,
            cash_rcvd,
            winnings,
            player_id: found_orders[0].player_id,
            event_id: found_orders[0].event_id,
            event_type: found_orders[0].event_type,
            open_amt, potential_winnings, called_amt, offer_odds, offer_probability,
            orders: order_ids,
            open_orders,
            last_update_datetime: max_last_update
        })
    })
    return order_stats
}


export const order_getAllOrderStatsFromOrders = (orders:OrderProps[]):OrderStatsProps => {
    let open_order_count = 0, open_amt = 0, potential_winnings = 0, called_amt = 0, commission = 0;
    let resolved_stake = 0, original_stake = 0, delayed_cash = 0, winnings = 0, net_winnings = 0, unresolved_stake = 0, resolved_potential_winnings = 0, unresolved_potential_winnings = 0, unresolved_potential_net_winnings = 0, num_trades = 0, cash_rcvd = 0; 
    let unique_order_titles = [ ...new Set(orders.map(o => o.title)) ]
    let result_ind:string|undefined = undefined
    let order_ids:string[] = []
    let open_orders:string[] = []
    let wins = 0, losses = 0, draws = 0;
    orders.map(o => {
        let commission_pct = 0
        if(o.commission_pct){ commission_pct = o.commission_pct }
        order_ids.push(o.order_id)
        if(o.status === 'approved'){
            open_orders.push(o.order_id)
            open_amt += o.open_amt
            open_order_count += 1
            potential_winnings += o.potential_winnings
            called_amt += o.called_amt
        }
        if(!o.positions){ return }
        o.positions.map(p => {
            if(!p){ return }
            num_trades += 1
            cash_rcvd += p.cash_rcvd
            if(!p.result_ind){
                delayed_cash += p.delayed_cash
                unresolved_stake += p.stake
                unresolved_potential_winnings += p.potential_winnings
                unresolved_potential_net_winnings += (p.potential_winnings - ((p.potential_winnings - p.stake) * commission_pct))

            } else {
                resolved_stake += p.stake
                original_stake += p.original_stake
                winnings += p.winnings
                net_winnings += p.net_winnings
                result_ind = p.result_ind
                commission += p.commission
                resolved_potential_winnings += p.potential_winnings
            }
        })
        if(result_ind == 'win'){ wins += 1 }
        if(result_ind == 'lose'){ losses += 1 }
        if(result_ind == 'draw'){ draws += 1 }
    })
   
    let unresolved_position_probability = unresolved_stake / unresolved_potential_winnings
    let resolved_position_probability = resolved_stake / resolved_potential_winnings
    let unresolved_position_odds = order_getOddsFromProbability(unresolved_position_probability)
    let resolved_position_odds = order_getOddsFromProbability(resolved_position_probability)

    let offer_probability = open_amt / potential_winnings
    let offer_odds = order_getOddsFromProbability(offer_probability)
    let earnings = winnings + cash_rcvd - original_stake
    let roi = earnings / resolved_stake
    return {
        stat_id: 'ALL',
        unresolved_stake,
        earnings,
        resolved_stake,
        original_stake,
        roi,
        wins,
        losses,
        commission,
        net_winnings,
        unresolved_potential_net_winnings,
        unique_order_titles,
        draws,
        unresolved_position_odds,
        resolved_position_odds,
        result_ind,
        delayed_cash,
        unresolved_potential_winnings,
        resolved_potential_winnings,
        resolved_position_probability,
        unresolved_position_probability,
        num_trades,
        cash_rcvd,
        winnings,
        player_id: orders[0]?.player_id??'0',
        event_id: 'ALL',
        event_type: 'ALL',
        open_amt, potential_winnings, called_amt, offer_odds, offer_probability,
        orders: order_ids,
        open_orders
    }
}