import { HelpActionType, HelpPromptType } from "../../objectTypes";
import store from "../../store";
import Colors from "../../constants/Colorsv2";
import { segEventTrack } from "../../utils/analytics/segment";
import { auth_getHelpByNativeId } from "../../store/actionsv1/auth";
import { MyPlayerProps, PublicPlayerProps } from "../../store/types/auth_svc";
import { V1_LOAD_HELP_SOFT_PROMPTS, V1_UPDATE_DEFAULT_LINES_VISIBLE, V1_VIEW_PROMPT } from "../../store/actionsv1/types";
import { player_getHelpPromptById } from "../../store/actionsv1/player";
import { navigate } from "../../navigation/RootNavigation";

let auto_prompts = [
    { id: 'w_1', is_authenticated:false, route: 'Authenticate', client_native_id:'t2_welcome_1', prompt: 'Hi there! I am your guide James.', body: 'Are you new? Press here to learn more.', actions: [] },
    { id: 'e_1', is_authenticated:true, full_screen:true, route: 'Events', prompt: 'Hey There!\nBefore we get started let me tell you about our app modes!', body: 'Demo Mode - All action uses a fictional currency called edge coins.\n\nReal Money Mode - All action uses real money. You must be verified and location must be enabled to participate\n\nUse the toggle in the header to switch between modes', actions: [
        { title: 'Tell me about the markets tab', title_color:Colors.shades.white, background_color:Colors.brand.electric, onPress: () => store.dispatch({ type: V1_VIEW_PROMPT, id: 'e_1' }) }
    ] },
    { id: 'e_2', is_authenticated:true, full_screen:true, route: 'Events', prompt: 'Welcome to the Markets tab!', body: 'This is your homebase for finding contests and markets to place action on!', actions: [
        { title: 'What are contests?', title_color:Colors.shades.white, background_color:Colors.brand.electric, onPress: () => store.dispatch({ type: V1_VIEW_PROMPT, id: 'e_2' }) }
    ] },
    { id: 'e_3', is_authenticated:true, full_screen:true, route: 'Events', prompt: 'Contests are upcoming or live sporting events!\nWe categorize contests in 3 categories', body: 'Events - Team based events (i.e., Vikings vs Packers)\n\nTournaments - Multi participant or futures (i.e., 2024 Masters, 2024 Super Bowl Champ)\n\nMatches - Matchups within a tournament (i.e., Rory vs Tiger in 2024 Masters)', actions: [
        { title: 'What are markets?', title_color:Colors.shades.white, background_color:Colors.brand.electric, onPress: () => store.dispatch({ type: V1_VIEW_PROMPT, id: 'e_3' }) }
    ] },
    { id: 'e_4', is_authenticated:true, full_screen:true, route: 'Events', prompt: 'What are markets?', body: 'Every contest supports one or more markets to action on. For example, a team based event has 3 primary markets\n\nWINNER - the outright winner\n\nSPREAD - the margin of victory or loss\n\nTOTAL - the total points scored in the event', actions: [
        { title: 'How do I bet on a market?', title_color:Colors.shades.white, background_color:Colors.brand.electric, onPress: () => store.dispatch({ type: V1_VIEW_PROMPT, id: 'e_4' }) }
    ] },
    { id: 'e_5', is_authenticated:true, full_screen:true, route: 'Events', prompt: 'Just place an order!', body: 'To bet on a market, you can simply press the bet you want and place what is called an order.\n\nSince BettorEdge is a marketplace, we will try to match your order up with available orders made by other users!\n\nWhen a match occurs (we call this a trade), you will have a bet!', actions: [
        { title: 'What are all these numbers?', title_color:Colors.shades.white, background_color:Colors.brand.electric, onPress: () => store.dispatch({ type: V1_VIEW_PROMPT, id: 'e_5' }) }
    ] },
    { id: 'e_6', is_authenticated:true, full_screen:true, client_native_id: 't2_app_modes', route: 'Events', prompt: 'Price (aka, Odds or Probability)', body: 'Every market has 2 possible outcomes. The odds (probability) is simply the likelihood an outcome will occur.\nThe less likely the outcome, the higher the payout!', actions: [
        { title: 'Prices - Best Available vs Last Trade', title_color:Colors.shades.white, background_color:Colors.brand.electric, onPress: () => store.dispatch({ type: V1_VIEW_PROMPT, id: 'e_6' }) }
    ] },
    { id: 'e_7', is_authenticated:true, full_screen:true, route: 'Events', prompt: 'We offer 2 price options when browsing markets\n\nBest Available or Last Trade', body: 'Best Available - Since we are a marketplace, you are technically betting against other users and not us. With best available, we will show you the best available offer prices.\n\nLast Trade - Everytime a bet fulfills, a trade occurs. The last agreed upon price is the last trade price', actions: [
        { title: 'Switch Visible Prices', title_color:Colors.shades.white, background_color:Colors.brand.electric, onPress: () => store.dispatch({ type: V1_UPDATE_DEFAULT_LINES_VISIBLE, default_lines_visible:true }) },
        { title: 'Ok Lets Go!', title_color:Colors.shades.white, background_color:Colors.utility.success, onPress: () => store.dispatch({ type: V1_VIEW_PROMPT, id:'e_7'}) }
    ] },
    { id: 'm_1', is_authenticated:true, client_native_id:'t2_market_1', route: 'Market', prompt: 'Howdy! Looks like you found our Market Details Screen!', body: `There is a lot in here! If you'd like to learn more. Press here!`, actions: [
        { title: 'Tell me more!', title_color:Colors.shades.white, background_color:Colors.brand.electric, onPress: () => store.dispatch(player_getHelpPromptById('t2_market_1')) }
    ] },
    { id: 'pf_1', is_authenticated:true, client_native_id:'t2_betmatch_1', route: 'PlayerFilters', prompt: 'Welcome to Bet Match', body: 'Its kind of like a dating app for sports betting! Set up a filter and accept or reject offers from others that match your criteria.', actions: [
    ] },
    { id: 'bm_1', is_authenticated:true, client_native_id:'t2_betmatch_2', route: 'ManageFilter', prompt: 'Create a new Bet Match Filter', body: 'Filter offers to specific users, league, market and even team or athlete that you want to see.\n\nGo premium and get access to additional premium filters', actions: [
    ] },
    { id: 'c_1', is_authenticated:true, client_native_id:'t1_competition_1', route: 'CompetitionHome', prompt: 'Ready to get competitve?', body: 'Welcome to BettorEdge Competitions.  Here you can participate in pool style competitions like pick-ems, squares and brackets.', actions: [
        { title: 'Tell me more!', title_color:Colors.shades.white, background_color:Colors.brand.electric, onPress: () => store.dispatch(player_getHelpPromptById('t2_competitions_1')) }
    ] }
]

export const autoLaunchHelper = async(route:string, market_type:'FREE'|'FOR_MONEY', isAuthenticated:boolean, player?:MyPlayerProps) => {
    if(route != 'Authenticate' && !player){ return }
    if(player && !player.guide_me){ return }
    let route_prompts = auto_prompts.filter(p => p.route == route && p.is_authenticated == isAuthenticated);
    if(route_prompts.length == 0){ return store.dispatch({ type: V1_LOAD_HELP_SOFT_PROMPTS, help_soft_prompts: [] }) }
    route_prompts = route_prompts.filter(p => !store.getState().util.viewed_prompts.map(vp => vp).includes(p.id))
    store.dispatch({ type: V1_LOAD_HELP_SOFT_PROMPTS, help_soft_prompts: route_prompts })

}

export const handleHelpPromptAction = (prompt:HelpPromptType, action:HelpActionType) => {
    segEventTrack({ event: 'Help Button Click', properties : { prompt_id:prompt.help_prompt_id, client_native_id:prompt.client_native_id, button_label: action.action_label }})
    switch(action.action_type){
        case 'internal_nav': 
        console.log('naving internally!')
        console.log(action.internal_nav_options)
            //return navigate('AuthStack', { screen: 'Premium', initial:false })
            return navigate(action.internal_nav_options.page_stack, { screen: action.internal_nav_options.page, params: { ...action.internal_nav_options.page_params, initial:false } })
        case 'launch_help': 
            if(!action.client_native_id){ return }
            return store.dispatch(auth_getHelpByNativeId(action.client_native_id))
        case 'external_nav':
            if(!action.external_nav_url){ return }
            return window.open(action.external_nav_url, '_blank')
        case 'accept':
            console.log('accept')
            return
        default: return
    }
}


export const getTooltipStyle = (help_prompt:HelpPromptType, layout: {x:number, y:number, width:number, height:number }, tooltipLocation:{ x:number, y:number, width:number, height:number }, tip_direction:'top'|'bottom'|'right'|'left'):any => {
    let style = { position:'absolute', width:layout.width - 20, backgroundColor:help_prompt.help_background_color??Colors.brand.electric, padding:10, paddinLeft:25, borderRadius:4 }
    if(!tooltipLocation || !layout){ return style }
    let dir = tip_direction ?? 'bottom'
    let newWidth = 0
    switch(dir){
        case 'left':
            newWidth = style.width - (style.width - tooltipLocation.x)
            if(newWidth < 100){ return getTooltipStyle(help_prompt, layout, tooltipLocation, 'bottom') }
            return { ...style, top:tooltipLocation.y, left:20, width: newWidth }
        case 'right':
            newWidth = style.width - tooltipLocation.x
            if(newWidth < 100){ return getTooltipStyle(help_prompt, layout, tooltipLocation, 'bottom') }
            return { ...style, top:tooltipLocation.y, right:20, width: newWidth }
        case 'bottom':
            return { ...style, right:10, top:tooltipLocation.y + tooltipLocation.height }
        case 'top':
            return { ...style, right:10, top:tooltipLocation.y - tooltipLocation.height }
        default: return style
    }
}