import { 
    ActivityPostType, CompetitionHeadType, CompetitionMatchOddsType, CompetitionMatchType, CompetitionPlayerType, 
    GroupDetails, CompetitionRecordType, CompetitionResultType, MemoPostType, PlayerPickType, 
    PropOrderType, PublicPlayerType, PlayerSettingsType, FollowDetailsType, GroupMessageType, PlayerLinkType, PlayerTagType, PlayerFollowerType, TagShortType, GroupType, MarketPositionType, AdType, AthleteType, MarketPriceType, CompanyAdType, ImpressionStatType, FollowerStatType, CompetitionSeasonType, AudioType, SquaresCompetitionType, SquareType, PlayerSquareType, SquaresPayoutType, SquareResultType, SquareOfferType, CompetitionPropOddsType, PlayerTieBreakerType
} from "../../objectTypes";

//Misc Actions
export const WS_CONNECT = 'WS_CONNECT';
export const LOAD_ERROR = 'LOAD_ERROR';

//Auth Actions
export const ABOUT_ME = 'ABOUT_ME';
export const MY_CREDS = 'MY_CREDS';
export const SIGN_UP = 'SIGN_UP'; 
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const VERIFY = 'VERIFY';
export const FB_SHOW = 'FB_SHOW';
export const AUTO_LOGIN = 'AUTO_LOGIN';
export const UPDATE_PHONE = 'UPDATE_PHONE';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const LINK_FACEBOOK = 'LINK_FACEBOOK';
//v2
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const ONBOARD = 'ONBOARD';
export const TOGGLE_SIGN_UP = 'TOGGLE_SIGN_UP';
export const VOUCH_PLAYER = 'VOUCH_PLAYER';
export const UPDATE_DOB = 'UPDATE_DOB';
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_REFERRAL_CODE = 'UPDATE_REFERRAL_CODE';
export const LOAD_REFERRED_PLAYERS = 'LOAD_REFERRED_PLAYERS';
export const LOAD_CHARITIES = 'LOAD_CHARITIES';
export const LOAD_GUEST_PLAYER = 'LOAD_GUEST_PLAYER';
export const LOAD_REFERRAL_DETAILS = 'LOAD_REFERRAL_DETAILS';


//Player Actions
export const UPDATE_BALANCE = 'UPDATE_BALANCE';
export const REMOVE_PLAYER_BID = 'REMOVE_PLAYER_BID';
export const ADD_ASSET = 'ADD_ASSET';
export const UPDATE_FAVORITES = 'UPDATE_FAVORITES';
export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const LOAD_LEADERBOARD = 'LOAD_LEADERBOARD';
export const UPDATE_PLAYER = 'UPDATE_PLAYER';
export const UPDATE_PROFILE_PIC = 'UPDATE_PROFILE_PIC';
export const UPDATE_NOTIFY_SETTINGS = 'UPDATE_NOTIFY_SETTINGS';
//v2
export const LOAD_AUTH_DETAILS = 'LOAD_AUTH_DETAILS';
export const LOAD_MARKET_ORDERS = 'LOAD_MARKET_ORDERS';
export const LOAD_PROMOTED_ORDERS = 'LOAD_PROMOTED_ORDERS';
export const LOAD_H2H_REQUESTS = 'LOAD_H2H_REQUESTS';
export const LOAD_MARKET_ASSETS = 'LOAD_MARKET_ASSETS';
export const LOAD_MARKET_RECORDS = 'LOAD_MARKET_RECORDS';
export const LOAD_PROP_ORDERS = 'LOAD_PROP_ORDERS';
export const LOAD_PROP_POSITIONS = 'LOAD_PROP_POSITIONS';
export const LOAD_IND_ORDERS = 'LOAD_IND_ORDERS';
export const LOAD_IND_POSITIONS = 'LOAD_IND_POSITIONS';
export const LOAD_LOCATIONS = 'LOAD_LOCATIONS';
export const SET_OPEN_NOTIFICATION = 'SET_OPEN_NOTIFICATION'
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const UPDATE_PLAYER_V2 = 'UPDATE_PLAYER_v2';
export const LOAD_HELP = 'LOAD_HELP';

//Player Preference
export const CHANGE_PREFERENCE = 'CHANGE_PREFERENCE';
export const CHANGE_ROUTE_NAME = 'CHANGE_ROUTE_NAME';
export const CHANGE_MODAL_NAME = 'CHANGE_MODAL_NAME';
export const LAUNCH_GUIDE = 'LAUNCH_GUIDE';
export const LAUNCH_INFO = 'LAUNCH_INFO';
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';
export const SET_AUDIO_HEIGHT = 'SET_AUDIO_HEIGHT';

//Market Actions
export const LOAD_ADMIN_BIDS = 'LOAD_ADMIN_BIDS';
export const LOAD_COMPLETED_ASSETS = 'LOAD_COMPLETED_STAKES';
export const LOAD_ORDER_HISTORY = 'LOAD_ORDER_HISTORY';
//v2
export const LOAD_MARKETS = 'LOAD_MARKETS';
export const LOAD_IND_MARKETS = 'LOAD_IND_MARKETS';
export const LOAD_MARKET_ORDERS_BY_EVENT = 'LOAD_MARKET_ORDERS_BY_EVENT';
export const LOAD_MARKET_PRICES = 'LOAD_MARKET_PRICES';
export const UPDATE_MARKET_ORDERS = 'UPDATE_MARKET_ORDERS';
export const UPDATE_MARKET_ASSETS = 'UPDATE_MARKET_ASSETS';
export const LOAD_MARKET_ORDER_DETAILS = 'LOAD_MARKET_ORDER_DETAILS';
export const LOAD_TWEETS = 'LOAD_TWEETS';
export const LOAD_NEWS = 'LOAD_NEWS';
export const ASSET_RESOLVED = 'ASSET_RESOLVED';
export const LOAD_DRAFT_PROP_ORDER = 'LOAD_DRAFT_PROP_ORDER';
export const UPDATE_PROP_ORDERS = 'UPDATE_PROP_ORDERS';
export const LOAD_PROP_ORDER_DETAILS = 'LOAD_PROP_ORDER_DETAILS';
export const UPDATE_PROP_POSITIONS = 'UPDATE_PROP_POSITIONS';
export const UPDATE_IND_ORDERS = 'UPDATE_IND_ORDERS';
export const UPDATE_IND_POSITIONS = 'UPDATE_IND_POSITIONS';
export const LOAD_SOCIAL_POSITIONS = 'LOAD_SOCIAL_POSITIONS';
export const LOAD_AVAILABLE_PLAYER_MARKETS = 'LOAD_AVAILABLE_PLAYER_MARKETS';
export const LOAD_AVAILABLE_PLAYERS = 'LOAD_AVAILABLE_PLAYERS';
export const LOAD_RSS_FEED = 'LOAD_RSS_FEED';
export const LOAD_PLAYER_MARKETS = 'LOAD_PLAYER_MARKETS'
export const LOAD_SHAREABLE_POSITION = 'LOAD_SHAREABLE_POSITION'
export const LOAD_ORDER_SUGGESTION = 'LOAD_ORDER_SUGGESTION';
export const LOAD_POSITION_ALERT = 'LOAD_POSITION_ALERT';

//Futures Actions
export const UPDATE_FUTURE_BID = 'UPDATE_FUTURE_BID';
export const LOAD_FUTURE_GROUPS = 'LOAD_FUTURE_GROUPS';
export const LOAD_FUTURE_GROUP_DETAILS = 'LOAD_FUTURE_GROUP_DETAILS';
export const UPDATE_FUT_MARKET = 'UPDATE_FUT_MARKET';
export const UPDATE_FUTURE_ASSET = 'UPDATE_FUTURE_ASSET';
export const LOAD_FUTURE_MARKET_CHART = 'LOAD_FUTURE_MARKET_CHART';
export const LOAD_FUTURE_BID_DETAILS = 'LOAD_FUTURE_BID_DETAILS';
export const LOAD_FUTURE_ORDERS = 'LOAD_FUTURE_ORDERS';
export const LOAD_FUTURE_ASSETS = 'LOAD_FUTURE_ASSETS';
export const LOAD_FUTURE_ORDERS_BY_OPTION = 'LOAD_FUTURE_ORDERS_BY_OPTION';
export const UPDATE_FUTURE_ORDER = 'UPDATE_FUTURE_ORDER';
export const LOAD_FUTURE_ORDER_LIFECYCLE = 'LOAD_FUTURE_ORDER_LIFECYCLE';

//Event Actions
export const LOAD_TEAMS = 'LOAD_TEAMS';
export const LOAD_TEAM_DETAILS = 'LOAD_TEAM_DETAILS';
export const LOAD_SR_PLAYER_STATS = 'LOAD_SR_PLAYER_STATS';
export const UPDATE_EVENTS = 'UPDATE_EVENTS';
export const UPDATE_MARKET = 'UPDATE_MARKET';
export const UPDATE_MARKET_STAT = 'UPDATE_MARKET_STAT';
export const LOAD_EVENTS = 'LOAD_EVENTS';
export const LOAD_EVENTS_BY_TEAM = 'LOAD_EVENTS_BY_TEAM';
export const LOAD_SPORT_SEASON_SCHEDULE = 'LOAD_SPORT_SEASON_SCHEDULE';
export const LOAD_POPULAR_TEAMS = 'LOAD_POPULAR_TEAMS';
export const LOAD_TOURNAMENTS = 'LOAD_TOURNAMENTS';
export const LOAD_TOURNAMENT_DETAILS = 'LOAD_TOURNAMENT_DETAILS';
export const LOAD_LIVE_GOLFER = 'LOAD_LIVE_GOLFER';
export const LOAD_MMA_SEASONS = 'LOAD_MMA_SEASONS';
export const LOAD_MMA_SEASON_SUMMARY = 'LOAD_MMA_SEASON_SUMMARY';
export const LOAD_EXTERNAL_PRICES = 'LOAD_EXTERNAL_PRICES';
export const LOAD_EXTERNAL_PRICE = 'LOAD_EXTERNAL_PRICE';


//Competition Actions
export const ADD_COMPETITIONS = 'ADD_COMPETITIONS';
export const UPDATE_COMPETITION = 'UPDATE_COMPETITION';
export const LOAD_COMPETITION_DETAILS = 'LOAD_COMPETITION_DETAILS';
export const ADD_PLAYER_PICK = 'ADD_PLAYER_PICK';
export const REMOVE_PLAYER_PICK = 'REMOVE_PLAYER_PICK';
export const LOAD_COMPLETED_COMPETITIONS = 'LOAD_COMPLETED_COMPETITIONS';
//v2
export const UPDATE_DRAFT_COMPETITION = 'UPDATE_DRAFT_COMPETITION';
export const LOAD_COMPETITIONS = 'LOAD_COMPETITIONS';
export const LOAD_COMPETITION_BY_CODE = 'LOAD_COMPETITION_BY_CODE';
export const UPDATE_PLAYER_PICK = 'UPDATE_PLAYER_PICK';
export const UPDATE_PLAYER_TIE_BREAKER = 'UPDATE_PLAYER_TIE_BREAKER'
export const REMOVE_COMPETITION_HEAD = 'REMOVE_COMPETITION_HEAD';
export const LOAD_COMPETITOR_PICKS = 'LOAD_COMPETITOR_PICKS';
export const ENTER_COMPETITION = 'ENTER_COMPETITION';
export const LEAVE_COMPETITION = 'LEAVE_COMPETITION';
export const LOAD_COMPETITION_LEADERS = 'LOAD_COMPETITION_LEADERS';
export const RESET_COMPETITION_LEADERS = 'RESET_COMPETITION_LEADERS';
export const LOAD_COMPETITION_SEASON = 'LOAD_COMPETITION_SEASON';
export const LOAD_SQUARES_DETAILS = 'LOAD_SQUARES_DETAILS';
export const UPDATE_SQUARES_DETAILS = 'UPDATE_SQUARES_DETAILS';

//H2H Actions
export const LOAD_CHALLENGES = 'LOAD_CHALLENGES';
export const ADD_CHALLENGE = 'ADD_CHALLENGE';
export const REMOVE_CHALLENGE = 'REMOVE_CHALLENGE';


//Social Actions
export const ADD_PLAYERS = 'ADD_PLAYERS';
export const ADD_BRANDS = 'ADD_BRANDS';
export const LOAD_ACTIVITY_POSTS = 'LOAD_ACTIVITY_POSTS';
export const LOAD_MEMO_POSTS = 'LOAD_MEMO_POSTS';
export const LOAD_MEMO_POST_FOR_EDITING = 'LOAD_MEMO_POST_FOR_EDITING'
export const ADD_ACTIVITY_POST = 'ADD_ACTIVITY_POST';
export const UPDATE_ACTIVITY_POST = 'UPDATE_ACTIVITY_POST';
export const LOAD_POST_DETAILS = 'LOAD_POST_DETAILS';
export const CLEAR_POST_DETAILS = 'CLEAR_POST_DETAILS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const ADD_REACTION = 'ADD_REACTION';
export const LOAD_PLAYER_DETAILS = 'LOAD_PLAYER_DETAILS';
export const CLEAR_PLAYER_DETAILS = 'CLEAR_PLAYER_DETAILS';
export const FOLLOW_PLAYER = 'FOLLOW_PLAYER';
export const LOAD_FOLLOWERS = 'LOAD_FOLLOWERS';
export const LOAD_GROUP_PLAYER = 'LOAD_GROUP_PLAYER';
export const UPDATE_SHARE_SETTINGS = 'UPDATE_SHARE_SETTINGS';
export const LOAD_PLAYER_CONTACTS = 'LOAD_PLAYER_CONTACTS';
export const LOAD_GLOBAL_LEADERS = 'LOAD_GLOBAL_LEADERS';
export const LOAD_MY_DETAILS = 'LOAD_MY_DETAILS';
export const LOAD_MY_GROUPS = 'LOAD_MY_GROUPS';
//V2
export const LOAD_POSTS = 'LOAD_POSTS';
export const LOAD_SOCIAL_PLAYER_DETAILS = 'LOAD_SOCIAL_PLAYER_DETAILS';
export const LOAD_MY_SOCIAL_DETAILS = 'LOAD_MY_SOCIAL_DETAILS';
export const UPDATE_POST_REACTION = 'UPDATE_POST_REACTION';
export const UPDATE_PLAYER_TAGS = 'UPDATE_PLAYER_TAGS';
export const UPDATE_PLAYER_LINKS = 'UPDATE_PLAYER_LINKS';
export const LOAD_AVAILABLE_TAGS = 'LOAD_AVAILABLE_TAGS';
export const LOAD_POST_DETAIL = 'LOAD_POST_DETAIL';
export const LOAD_DRAFT_POSTS = 'LOAD_DRAFT_POSTS';
export const LOAD_ADS = 'LOAD_ADS';
export const LOAD_ACTIVITY_BY_EVENT = 'LOAD_ACTIVITY_BY_EVENT';
export const UPDATE_GLOBAL_AUDIO = 'UPDATE_GLOBAL_AUDIO';
export const LOAD_POSTS_BY_PLAYER_ID = 'LOAD_POSTS_BY_PLAYER_ID';
export const LOAD_PLAYER_COUPONS = 'LOAD_COUPONS';
export const LOAD_PLAYER_COUPON = 'LOAD_COUPON';

//Chat Actions
export const CHAT_STATUS = 'CHAT_STATUS';
export const LOAD_CHAT_HISTORY = 'LOAD_CHAT_HISTORY';
export const LOAD_GROUP = 'LOAD_GROUP';
export const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE';
export const UPDATE_ACTIVE_MEMBERS = 'UPDATE_ACTIVE_MEMBERS';
//v2
export const CONNECT_TO_CHAT = 'CONNECT_TO_CHAT';


//Payment Actions
export const LOAD_CUSTOMER_ACCOUNTS = 'LOAD_CUSTOMER_ACCOUNTS';
export const UDPATE_CUSTOMER_ACCOUNT = 'UPDATE_CUSTOMER_ACCOUNT';
export const LOAD_SKRILL_PAYMENT = 'LOAD_SKRILL_PAYMENT';
export const DEPOSIT_STATUS = 'DEPOSIT_STATUS';
export const WITHDRAW_STATUS = 'WITHDRAW_STATUS';
export const LOAD_TRANSACTION_HISTORY = 'LOAD_TRANSACTION_HISTORY';
export const LOAD_MX_URL = 'LOAD_MX_URL';
export const SAVE_ACH_ACCOUNT = 'SAVE_ACH_ACCOUNT';
export const LOAD_IBT_SESSION_TOKEN = 'LOAD_IBT_SESSION_TOKEN'
export const LOAD_ACCOUNT_SETTINGS = 'LOAD_ACCOUNT_SETTINGS'
export const LOAD_REDIRECT_URL = 'LOAD_REDIRECT_URL';
export const UNLINK_ACCOUNT = 'UNLINK_ACCOUNT';

//Analytic Actions
export const LOAD_OVERALL_ANALYTICS = 'LOAD_OVERALL_ANALYTICS';
export const LOAD_OVERALL_METRICS = 'LOAD_OVERALL_METRICS';
export const UPDATE_METRIC_FILTER = 'UPDATE_METRIC_FILTER';
export const LOAD_SPORT_ANALYTICS = 'LOAD_SPORT_ANALYTICS';
export const LOAD_TEAM_ANALYTICS = 'LOAD_TEAM_ANALYTICS';
export const LOAD_GLOBAL_LEADERS_2 = 'LOAD_GLOBAL_LEADERS_2';
export const LOAD_BALANCE_RECON = 'LOAD_BALANCE_RECON';






//Reducer Types
export type CreateAccountType = { 
    player_id: string,  first_name:string, show_name:string, last_name:string, username:string, password:string, rePassword:string, email:string, verified:boolean, phone:string, phone_verified:boolean,
    bio: string, role:string, type:string, legal_ind:boolean, location?:string, profile_pic:string, dob?:any, vouched_status:PublicPlayerType['vouched_status'], facebook_id:string, referral_code?:string
}
export type AuthReducerState = {
    isAuthenticated:boolean, account:CreateAccountType, isOnboarded:boolean 
}
export type PropReducerState = {
    propOrders: PropOrderType[], propOrderDetails:PropOrderType | undefined, availablePlayers:AthleteType[], marketPrices:MarketPriceType[]
}

export type DraftReducerState = { 
    competition_head:CompetitionHeadType | undefined, competition_players:CompetitionPlayerType[], competition_matches:CompetitionMatchType[],
    competition_match_odds: CompetitionMatchOddsType[], propOrder:PropOrderType | undefined
 }

export type CompetitionReducerState = {
    competitionUpdated:any, squares_competitions:SquaresCompetitionType[], squares_last_updated:any,
    competition_heads: CompetitionHeadType[], competition_head:CompetitionHeadType | undefined, competition_matches:CompetitionMatchType[],
    competition_players:CompetitionPlayerType[], competition_match_odds:CompetitionMatchOddsType[], competition_prop_odds:CompetitionPropOddsType[], competition_records:CompetitionRecordType[], 
    competition_results:CompetitionResultType[], my_picks:PlayerPickType[], my_tie_breaker?:PlayerTieBreakerType, my_cp:CompetitionPlayerType | undefined, 
    competitor_picks:PlayerPickType[], competitor_matches:CompetitionMatchType[], competitor_match_odds:CompetitionMatchOddsType[], competitor_prop_odds:CompetitionPropOddsType[],
    my_competition_results:CompetitionResultType[], 
    my_competition_records:CompetitionRecordType[], completed_competitions:CompetitionHeadType[], recent_competition_heads:CompetitionHeadType[], recent_competition_results:CompetitionResultType[] 
    entered:boolean, found_competition_id:string | undefined,
    competition_season_records:CompetitionRecordType[], competition_season_players:CompetitionPlayerType[], competition_season:CompetitionSeasonType | undefined,
    squares:SquareType[], player_squares:PlayerSquareType[], squares_type:SquareType | undefined, squares_payout_type:SquaresPayoutType|undefined, squares_competition:SquaresCompetitionType|undefined, square_results:SquareResultType[],
    square_offers:SquareOfferType[], player_square_history:PlayerSquareType[]
}

export type SocialReducerState = {
    socialUpdated: any,
    chat_connect_status: boolean,
    activeMembers: number,
    moreAvailable:boolean,
    mySettings: PlayerSettingsType | undefined,
    myPlayerTags: PlayerTagType[],
    myPlayerLinks: PlayerLinkType[],
    topPost:MemoPostType | undefined,
    mySocialStats: ImpressionStatType | undefined,
    myFollowers: PlayerFollowerType[],
    myFollowerStats: FollowerStatType,
    players : PublicPlayerType[],
    brands: PublicPlayerType[],
    posts: Array<MemoPostType | ActivityPostType>,
    playerPosts: Array<MemoPostType | ActivityPostType>,
    rssFeed: MemoPostType[],
    ads: CompanyAdType[],
    myGroups:GroupDetails[],
    draftPosts: MemoPostType[],
    playerDetails: PublicPlayerType | undefined, 
    playerActivityPosts: ActivityPostType[], 
    playerMemoPosts: MemoPostType[], 
    followDetails: FollowDetailsType | undefined,
    chatConnectionId: string,
    group: GroupType | undefined,
    groupPlayers: PublicPlayerType[],
    groupMessages: GroupMessageType[],
    playerPositions:MarketPositionType[],
    playerLinks: PlayerLinkType[],
    playerTags: TagShortType[],
    availableTags: string[],
    playerFollowers: PlayerFollowerType[],
    playerFollowing:PlayerFollowerType[],
    eventActivityPosts:ActivityPostType[],
    audio: undefined | AudioType,
    audioHeight:0,
    player_coupons:any[],
    player_coupon:any
}