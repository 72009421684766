
import AsyncStorage from '@react-native-async-storage/async-storage';
import { PlayerPushSubscriptionProps } from '../../store/types/auth_svc';
import { auth_setNotification } from '../../store/actionsv1/auth';
const pushServerPublicKey = "BB6jBBIUaoBVSAUzX9wkzclZCAGu3Tsa9NSevRptdWKAge_7dIvuh9wjbVOCEVG9aJY1QiOzJyam9c2KJAHfn_s";
const convertedPkey = urlB64ToUint8Array(pushServerPublicKey)



/**
 * Function to get the push notification status of a user.
 */
export const getPushNotificationStatus = async(player_id:string, distinct_id:string) => {
  try {
      //1) Check if the user is even eligible to receive push notifications, if not - return
      if(!isPushNotificationSupported()){ return } //Return out
      //2) If supported, lets check the server if they already have an active subscription
      //3) If there is no device id - return out
      //There is no active subsription, lets try and generate one
      //Let's request permission here
      const permission = await getPushPermission()
      //alert(`permission is ${permission}`)
      //They didn't give us permission, lets do something here on why notifications are important
      if(permission === 'denied'){ return } //The didn't give us permission
      //Permission was accepted! Lets create the push subscription
      let subscription = await createNotificationSubscription()
      if(!subscription){ return } //Some error occurred where we were not able to get the subscription
      let parsed_sub = JSON.parse(JSON.stringify(subscription))

      let player_push_subscription:PlayerPushSubscriptionProps = {
        player_push_subscription_id: '',
        player_id,
        device_id:distinct_id,
        endpoint: subscription.endpoint,
        expiration_time: subscription.expirationTime??0,
        p256dh_key: parsed_sub.keys.p256dh,
        auth_key: parsed_sub.keys.auth,
        status: 'active',
        create_datetime: '',
        last_update_datetime: ''
      }
      
      return player_push_subscription
  } catch(e) {
    console.log(e)
    return undefined
  }
}


export const isPushNotificationSupported = () => {
  return "serviceWorker" in navigator && "PushManager" in window;
}

export const getPushPermission = () => {
  return Notification.permission
}

export const initializePushNotifications = async() => {
  // request user grant to show notification
  try {
    const result = await Notification.requestPermission()
    return result
  } catch (e) {
    return 'denied'
  }
}

export const sendNotification = () => {
  const text = "Take a look at this brand new t-shirt!";
  const title = "New Product Available";
  const options = {
    body: text,
    icon: "",
    vibrate: [200, 100, 200],
    data: {  },
    tag: "new-product",
    badge: "https://spyna.it/icons/android-icon-192x192.png",
    actions: [{ action: "Detail", title: "View", icon: "https://via.placeholder.com/128/ff0000" }]
  };
  navigator.serviceWorker.ready.then(function(serviceWorker) {
    serviceWorker.showNotification(title, options);
  });
}


/**
 * 
 * using the registered service worker creates a push notification subscription and returns it
 * 
 */

export const listenForNotifications = (store:any) => {
  if('serviceWorker' in navigator){
    navigator.serviceWorker.addEventListener('message', event => {
      store.dispatch(auth_setNotification(event.data.data.id))
    })
  }
}


export const updateNotificationBadge = async(badge_number:number) => {
    if("setAppBadge" in navigator && "clearAppBadge" in navigator){
      try {
        if(badge_number == 0){
          await navigator.clearAppBadge()
        } else {
          await navigator.setAppBadge(badge_number)
        }
      } catch (error) {
        console.log(error)
      } 
    }
}



export const createNotificationSubscription = async() => {
  try {
      let serviceWorker = await navigator.serviceWorker.ready
      let push_sub = await serviceWorker.pushManager.getSubscription()
      if(!push_sub){
        push_sub = await serviceWorker.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedPkey
        })
      }
      return push_sub
  } catch (e) {
    console.log(e)
    return undefined
  }
  
}

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
   const base64 = (base64String + padding)
      .replace(/\-/g, '+')
       .replace(/_/g, '/');

   const rawData = window.atob(base64);
   const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
  return outputArray;
  }

