import React, { useEffect, useState } from 'react';
import { Image, ScrollView, View } from "react-native"
import { Text, TouchableOpacity } from '../../../globalComponents/NativeOverrides';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../../../constants/Colorsv2';
import { V1_UPDATE_DEFAULT_LINES, V1_UPDATE_DEFAULT_LINES_VISIBLE } from '../../../store/actionsv1/types';
import ALaCartePremium from '../../../globalComponents/ALaCartePremium';

const DefaultLinesToggle = ({}) => {
    const default_lines_visible:boolean = useSelector((state:any) => state.util.default_lines_visible, (left, right) => left == right);
    const default_lines: 'best_available'|'last_trade'|'premium'|undefined = useSelector((state:any) => state.auth.default_lines, (left, right) => left == right);
    const app_size:{width:number, height:number} = useSelector((state:any) => state.util.app_size, (left, right) => left.width == right.width);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!default_lines){ 
            dispatch({ type: V1_UPDATE_DEFAULT_LINES, default_lines: 'best_available' }) 
        }
    },[])

    if(!default_lines_visible){ return <></> }

    return (
        <View style={{ position:'absolute', bottom:0, right:0, top:0, left:0, padding:10, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.black_faded }}>
            <View style={{ maxHeight: app_size.height * 0.85, maxWidth:app_size.width*0.95,  borderRadius:8, backgroundColor:Colors.shades.white, shadowColor: "#000", shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5 }}>
                <ScrollView style={{ flex:1 }}>
                    <View style={{ padding:10, backgroundColor:Colors.shades.shade100, borderTopRightRadius:8, borderTopLeftRadius:8 }}>
                        <Text size={16} color={Colors.brand.midnight} weight='bold'>Default Line View</Text>
                        <Text style={{ marginTop:5 }} size={14} color={Colors.brand.midnight}>You can change the default view of the lines / prices you see on each market.</Text>
                    </View>
                    <ALaCartePremium identifier='order_grades'/>
                    <View nativeID='action_row' style={{ flexDirection:'row', margin:10 }}>
                        <TouchableOpacity style={{ flex:1, padding:10, borderWidth:1, borderColor:Colors.shades.shade600, borderTopLeftRadius:8, borderBottomLeftRadius:8, backgroundColor:default_lines=='best_available'?Colors.brand.midnight:Colors.shades.white }} onPress={() => {
                            dispatch({ type:V1_UPDATE_DEFAULT_LINES, default_lines: 'best_available' })
                        }}>
                            <Text size={14} weight='bold' color={default_lines=='best_available'?Colors.shades.white:Colors.brand.electric} textAlign='center'>Best Available</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flex:1, padding:10, borderWidth:1, borderColor:Colors.shades.shade600, borderTopRightRadius:8, borderBottomRightRadius:8, backgroundColor:default_lines=='last_trade'?Colors.brand.midnight:Colors.shades.white }} onPress={() => {
                            dispatch({ type:V1_UPDATE_DEFAULT_LINES, default_lines: 'last_trade' })
                        }}>
                            <Text size={14} weight='bold' color={default_lines=='last_trade'?Colors.shades.white:Colors.brand.electric} textAlign='center'>Last Trade</Text>
                        </TouchableOpacity>
                    </View>
                    <View nativeID='descriptor' style={{ padding:10, marginTop:10 }}>
                        {default_lines == 'best_available' ?
                        <View>
                            <Image
                                source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1704999966/IMG_5147_oyumfo.jpg' }}
                                style={{ width: app_size.width*0.7, height: app_size.width*0.7*0.6, alignSelf:'center' }}
                                resizeMode='cover'
                            />
                            <View style={{ marginTop:20 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='bold'>Showing Best Available Prices</Text>
                                <Text size={14} color={Colors.brand.midnight}>Best available will default show the best available price. If there are no available orders, the last trade price will be provided.</Text>
                                <Text style={{ marginTop:5 }} size={14} color={Colors.incentive.gold} weight='bold'>Premium Only</Text>
                                <Text size={14} color={Colors.incentive.gold}>Order grades will show up as highlighted bars at the bottom of each available price</Text>
                            </View>
                            <View style={{ marginTop:20 }}>
                                <View style={{ flexDirection:'row', alignItems:'center' }}>
                                    <View style={{ minHeight:40, width:50, padding:5, borderRadius:4, backgroundColor:Colors.highlights.highlight500Faded, marginRight:10 }}>
                                        <Text size={12} color={Colors.shades.black} textAlign='center' weight='bold'>+3.5</Text>
                                        <Text size={12} color={Colors.shades.black} textAlign='center' weight='bold'>-100</Text>
                                    </View>
                                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>Best Available Price</Text>
                                </View>
                                <View style={{ flexDirection:'row', alignItems:'center', marginTop:5 }}>
                                <View style={{ minHeight:40, width:50, padding:5, borderRadius:4, backgroundColor:Colors.shades.shade200, marginRight:10 }}>
                                        <Text size={12} color={Colors.shades.black} textAlign='center' weight='bold'>+3.5</Text>
                                        <Text size={12} color={Colors.shades.black} textAlign='center' weight='bold'>-100</Text>
                                    </View>
                                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>Last Trade Price (No Available Orders)</Text>
                                </View>
                            </View>
                        </View>
                        :
                        <View>
                            <Image
                                source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1702400137/last_trade_t1fsjs.png' }}
                                style={{ width: app_size.width*0.75, height: app_size.width*0.75*0.53, alignSelf:'center' }}
                                resizeMode='cover'
                            />
                            <View style={{ marginTop:20 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='bold'>Showing Last Trade Prices</Text>
                                <Text size={14} color={Colors.brand.midnight}>Last trade price will show the price of the last executed trade.  That means at least 2 people have agreed on this price for this specific market.</Text>
                            </View>
                            <View style={{ marginTop:20 }}>
                                <View style={{ flexDirection:'row', alignItems:'center', marginTop:5 }}>
                                    <View style={{ minHeight:40, width:50, padding:5, borderRadius:4, backgroundColor:Colors.shades.shade600, marginRight:10 }}>
                                        <Text size={12} color={Colors.shades.black} textAlign='center' weight='bold'>+3.5</Text>
                                        <Text size={12} color={Colors.shades.black} textAlign='center' weight='bold'>-100</Text>
                                    </View>
                                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>Last Trade Price</Text>
                                </View>
                            </View>
                        </View>
                        }
                    </View>
                    <View nativeID='close' style={{ padding:20, backgroundColor:Colors.shades.shade100 }}>
                        <TouchableOpacity style={{ padding:10, borderWidth:1, borderColor:Colors.utility.error, borderRadius:8 }} onPress={() => {
                            dispatch({ type: V1_UPDATE_DEFAULT_LINES_VISIBLE, default_lines_visible: false });
                        }}>
                            <Text size={14} weight='bold' color={Colors.utility.error} textAlign='center'>CLOSE</Text>
                        </TouchableOpacity>
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

export default DefaultLinesToggle