import React, { useState } from 'react';
import { FlatList, View } from 'react-native';
import Colors from '../constants/Colorsv2';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { HelpActionType, HelpPromptType } from '../objectTypes';
import { getTooltipStyle, handleHelpPromptAction } from './HelpLogic/helpers';

type T3HelpModalProps = { help_prompt:HelpPromptType, tooltipLocation:{ x:number, y:number, height:number, width:number }, tip_direction:string, 
is_tour?:boolean, tour_length?:number, onChangeTour?:any, clearHelp:any }

const T3HelpModal = ({ help_prompt, tooltipLocation, tip_direction, is_tour, tour_length, onChangeTour, clearHelp }:T3HelpModalProps) => {
    const [ layout, setLayout ] = useState({ x:0, y:0, width:0, height:0 })

    let tour_steps = []
    if(is_tour && tour_length){
        for(let i = 1; i < tour_length + 1; i++){
            tour_steps.push(i)
        }
    }

    const handleChangeTour = (step:number) => {
        if(!onChangeTour){ return }
        onChangeTour(step)
    }

        
    const renderCarousel = (data:{ item:number, index:number }) => {
        const active = data.item == help_prompt.tour_priority
        return (
            <TouchableOpacity style={{margin:5, height:10, width:10, borderRadius:100, backgroundColor:active?Colors.shades.white:Colors.brand.slate}} onPress={() => handleChangeTour(data.item)}/>
        )
    }
    

    const onActionPress = (action:HelpActionType) => {
        clearHelp()
        handleHelpPromptAction(help_prompt, action)
    }

    const renderActions = (data: {item:HelpActionType, index:number}) => {
        return (
            <TouchableOpacity style={{flex:1, backgroundColor:data.item.button_color, marginRight:4, borderRadius:23, padding:6, paddingRight:15, paddingLeft:15, borderWidth:1, borderColor:data.item.button_border_color}} onPress={() => onActionPress(data.item)}>
                <Text size={12} color={data.item.action_label_font} weight='semibold' textAlign='center'>{data.item.action_label}</Text>
            </TouchableOpacity>
        )
    }

    return (
        <TouchableOpacity native_id='btx_clear_t4_help' style={{position:'absolute', bottom:0, top:0, right:0, left:0, padding:20}} 
        onPress={() => clearHelp()}
        onLayout={(ev:any) => {
             const { x, y, width, height } = ev.nativeEvent.layout
             setLayout({ x, y, width, height })
        }}>
            <View style={getTooltipStyle(help_prompt, layout, tooltipLocation, tip_direction)}>
                <Text size={12} color={help_prompt.body_font_color} weight='regular'>{help_prompt.body}</Text>
                {help_prompt.actions && help_prompt.actions.length > 0 ?
                <View style={{marginTop:8, flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                    {is_tour ?
                    <View>
                        <FlatList data={tour_steps} renderItem={renderCarousel} keyExtractor={(item) => item.toString()} horizontal/>
                    </View>
                    :
                    <View />
                    }
                    <View>
                        <FlatList data={help_prompt.actions} renderItem={renderActions} keyExtractor={(item) => item.action_type} horizontal/>
                    </View>
                </View>
                :<></>}
            </View>
        </TouchableOpacity>
    )
}


export default T3HelpModal