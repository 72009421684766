import React, { useState, useRef, useEffect } from 'react';
import { Image, View, ScrollView, FlatList } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import Colors from '../constants/Colorsv2';
import { HelpActionType, HelpPromptType } from '../objectTypes';
import { Icon } from 'react-native-elements';
import { handleHelpPromptAction } from './HelpLogic/helpers';
import * as RootNavigation from '../navigation/RootNavigation';


type T1HelpModalProps = { isAuthenticated:boolean, help_prompt:HelpPromptType, is_tour?:boolean, tour_length?:number, onChangeTour?:any, clearHelp:any }
const T1HelpModal = ({ isAuthenticated, clearHelp, help_prompt, is_tour, tour_length, onChangeTour }:T1HelpModalProps) => {
    const [ promptSize, setPromptSize ] = useState({ height:0, width:0 })
    const [ imageSize, setImageSize ] = useState({ x:0, y:0, height:0, width:0 })
    const scrollRef = useRef(null)

    let tour_steps = []
    if(is_tour && tour_length){
        for(let i = 1; i < tour_length + 1; i++){
            tour_steps.push(i)
        }
    }
    
    useEffect(() => {
        if(scrollRef?.current){
            scrollRef.current.scrollToEnd({ animated:true })
        }
    },[promptSize.height])

    const updateImageSize = (media_obj:any) => {
        if(!media_obj){ return { width:0, height:0 } }
        let max_height = promptSize.height * 0.6
        let image_ratio = media_obj.height / media_obj.width
        let image_height = promptSize.width * image_ratio
        let image_width = promptSize.width
        if(image_height > max_height){
            image_height = max_height
            image_width = image_height / image_ratio
        }
        return { width:image_width, height: image_height }
    }

    const handleChangeTour = (step:number) => {
        if(!onChangeTour){ return }
        onChangeTour(step)
    }

    const handleActionTaken = (action:HelpActionType) => {
        clearHelp()
        handleHelpPromptAction(help_prompt, action)
    }

    const renderActions = (data: {item:HelpActionType, index:number}) => {
        return (
            <TouchableOpacity style={{flex:1, backgroundColor:data.item.button_color, marginRight:4, borderRadius:23, padding:10, borderWidth:1, borderColor:data.item.button_border_color}} onPress={() => handleActionTaken(data.item)}>
                <Text size={16} color={data.item.action_label_font} weight='semibold' textAlign='center'>{data.item.action_label}</Text>
            </TouchableOpacity>
        )
    }

    
    const renderCarousel = (data:{ item:number, index:number }) => {
        const active = data.item == help_prompt.tour_priority
        return (
            <TouchableOpacity style={{margin:5, height:2, width:20, borderBottomWidth:2, borderColor:active?Colors.shades.white:Colors.brand.slate}} onPress={() => handleChangeTour(data.item)}/>
        )
    }
    
    if(!help_prompt){ return (<></>) }
    /*
    If the prompt_type is beneficial or full screen prompt
    */
    let image_size = updateImageSize(help_prompt?.media_obj)
    return (
        <ScrollView ref={scrollRef} style={{flex:1, backgroundColor:Colors.shades.white, position:'absolute', right:0, left:0, bottom:0, top:0}} onLayout={(ev) => {
            const { x, y, height, width } = ev.nativeEvent.layout
            setPromptSize({ height, width })
        }}>
            <View style={{ backgroundColor:help_prompt.help_background_color, justifyContent:'center', alignItems:'center', paddingTop:50 }} onLayout={(ev) => {
                const { x, y, height, width } = ev.nativeEvent.layout
                setImageSize({ x, y, height, width })
            }}>
                {help_prompt.media_obj?.media_url?
                <Image source={help_prompt.media_obj.media_url} style={{height:image_size.height, width:image_size.width}}/>
                :<></>}
                {is_tour && help_prompt.tour_priority && help_prompt.tour_priority != 1 ?
                <TouchableOpacity style={{ position:'absolute', top:imageSize.height/2, left:10, backgroundColor:'rgba(255,255,255,0.5)', borderRadius:100, padding:10, justifyContent:'center', alignItem:'center' }} onPress={() => handleChangeTour(help_prompt.tour_priority - 1)}>
                    <Icon name='chevron-left' type='feather' size={18} color={Colors.shades.white}/>
                </TouchableOpacity>
                :<></>}
                {is_tour && help_prompt.tour_priority != tour_length ?
                <TouchableOpacity style={{ position:'absolute', top:imageSize.height/2, right:10, backgroundColor:'rgba(255,255,255,0.5)', borderRadius:100, padding:10, justifyContent:'center', alignItem:'center' }} onPress={() => handleChangeTour(help_prompt.tour_priority + 1)}>
                    <Icon name='chevron-right' type='feather' size={18} color={Colors.shades.white}/>
                </TouchableOpacity>
                :<></>}
            </View>
            <LinearGradient start={{ x:0, y:50 }} colors={[help_prompt.help_background_color , help_prompt.help_background_opacity_color]} style={{height:84, marginTop:-125}}/>
            <View style={{ padding:20, backgroundColor:help_prompt.help_background_color, borderBottomRightRadius:30, borderBottomLeftRadius:30}}>
                <Text size={26} color={Colors.shades.white} weight='semibold' textAlign='center'>{help_prompt?.title??''}</Text>
                <Text size={14} color={help_prompt?.body_font_color??Colors.shades.white} weight='regular' textAlign='center' style={{marginTop:12}}>{help_prompt?.body??''}</Text>
                {is_tour?
                <View style={{justifyContent:'center', alignItems:'center', margin:20}}>
                    <FlatList data={tour_steps} renderItem={renderCarousel} keyExtractor={(item) => item.toString()} horizontal/>
                </View>
                :
                <View style={{alignSelf:'center', marginTop:25, marginBottom:25, height:2, width:80, borderBottomWidth:2, borderColor:Colors.shades.white}} />
                }
            </View>
            {help_prompt.actions ?
            <View style={{padding:20}}>
                <FlatList data={help_prompt.actions} renderItem={renderActions} keyExtractor={(item) => item.action_label} numColumns={2}/>
            </View>
            :<></>}
            {!isAuthenticated ?
            <TouchableOpacity style={{ marginBottom:25 }} onPress={() => { clearHelp(); RootNavigation.navigate('AuthStack', { screen:'Login', initial:false, params:{} })}}>
                <Text size={15} color={Colors.brand.electric} weight='regular' textAlign='center'>Already a user? Login</Text> 
            </TouchableOpacity>
            :<></>}
        </ScrollView>
    )
}

export default T1HelpModal