import moment from "moment";
import { MarketProps } from "../../../store/types/mk_svc";
import { EventProps, TeamProps, TournamentProps } from "../../../store/types/sr_svc";

const finalized_statuses = [
    'complete',
    'closed',
    'postponed',
    'suspended',
    'unnecessary',
    'cancelled'
]

export const event_getClosestDay = (schedule_range:string[]):string => {
    let closest:any|undefined;
    let exact_day = schedule_range.find(d => moment(d).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD'))
    if(exact_day){ return moment(exact_day).format('YYYY-MM-DD') }
    schedule_range.map(d => {
        if(!closest){ closest = moment(d) }
        if(moment(d).isBefore(moment())){ return } //Day is in the past
        let new_range = moment(d).diff(moment(), 'days')
        let closest_range = closest.diff(moment(), 'days')
        if(Math.abs(new_range) < Math.abs(closest_range)){ closest = moment(d) }
    })
    return closest.format('YYYY-MM-DD')
}

export const event_isLive = (event:EventProps) => {
    if(moment().isAfter(moment(event.scheduled_datetime))){ return true }
    return false
}


export const event_isEventFinal = (event:EventProps) => {
    if(finalized_statuses.includes(event.status)){ return true }
}

export const event_getUniqueDivisions = (events:EventProps[]):string[] => {
    let away_divisions = events.map(e => e.away?.division)
    let home_divisions = events.map(e => e.home?.division)
    let divisions = away_divisions.concat(home_divisions)
    let options = [ ...new Set(divisions.map(d => d)) ].sort((a,b) => a > b ? 1 : -1)
    let ranks = events.find(e => e.away?.rank || e.home?.rank)
    if(ranks){ options = ['Top25'].concat(options) }
    return options
}

export const event_getUniqueSubDivisions = (events:EventProps[]):string[] => {    
    let away_divisions = events.map(e => e.away?.sub_division)
    let home_divisions = events.map(e => e.home?.sub_division)
    let sub_divisions = away_divisions.concat(home_divisions)
    return [ ...new Set(away_divisions.concat(home_divisions).map(d => d)) ].sort((a,b) => a > b ? 1 : -1)
}



export const event_getSideLabelsFromActiveMarket = (active_market:MarketProps, event:EventProps) => {
    const away_label = active_market.trade_side === 'home' ? event?.away?.abbr : active_market.side_options.find(o => o.side !== active_market.trade_side)?.label??'away'
    const home_label = active_market.trade_side === 'home' ? event?.home?.abbr : active_market.side_options.find(o => o.side === active_market.trade_side)?.label??'home'
    return { away_label, home_label }
}


/**
 * Search an array of events by a team name
 * @param search_value 
 * @param events 
 * @returns 
 */
export const event_searchEventsByTeam = (search_value:string, events:EventProps[], tournaments:TournamentProps[]) => {
    let filtered_keys:string[] = []
    events.map(e => {
        if(`${e.away?.market_name} ${e.away?.name} ${e.home?.market_name} ${e.home?.name}`.toLowerCase().includes(search_value.toLowerCase())){
            filtered_keys.push(`team:${e.event_id}`)
        }
    })
    tournaments.map(t => {
        if(t.tournament_name.toLocaleLowerCase().includes(search_value.toLowerCase())){
            filtered_keys.push(`tournament:${t.tournament_id}`)
        }
    })
    return filtered_keys
}
