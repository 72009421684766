import { MarketProps, TradeProps } from "../../../store/types/mk_svc";
import { AthleteProps, SupportedMarketProps } from "../../../store/types/sr_svc";

export const market_getMarketsFromArrayOfIds = (market_ids:string[], markets:MarketProps[], event_type:'match'|'team'|'tournament', level:MarketProps['level']) => {
    let available_markets:MarketProps[] = []
    markets.map(m => {
        if(market_ids.includes(m.market_id) && m.event_type === event_type && m.level === level){
            available_markets.push(m)
        }
    })
    return available_markets
}


export const market_getMarketsFromSupportedMarkets = (supported_markets:SupportedMarketProps[], markets:MarketProps[]):MarketProps[] => {
    if(!supported_markets){ return [] }
    let available_markets:MarketProps[] = []
    markets.map(m => {
        if(supported_markets.filter(sm => !sm.removed).map(m => m.market_id.toString()).includes(m.market_id.toString())){
            available_markets.push(m)
        }
    })
    return available_markets
}


/**
 * Takes an odds value and converts to a string with the + - and fixes to a supplied decimal value
 * @param odds 
 * @param decimal 
 * @returns 
 */
export const market_getOddsLabel = (odds:number, decimal?:number):string => {
    if(!odds){ return '' }
    if(odds > 0){ return `+${odds.toFixed(decimal??0)}` }
    return odds.toFixed(decimal??0)
}



export const market_getVar1Label = (var_1:number, market:MarketProps):string => {
    if(market.type === 'Spread'){ 
        if(var_1 >= 0){ return `+${var_1}` }
    }
    return `${var_1}`
}


/**
 * Takes the trades and the route params for the market page and returns the trades
 * @param latest_trades 
 * @param event_id 
 * @param event_type 
 * @param side_id 
 * @returns 
 */
export const market_filterTrades = (trades:TradeProps[], event_id:string, event_type:string, side_id?:string) => {
    if(side_id && side_id !== 'undefined'){ 
        let new_trades = trades.filter(lt => lt.event_id == event_id && lt.event_type === event_type && lt.side_id == side_id) 
        return new_trades
    }
    return trades.filter(lt => lt.event_id == event_id && lt.event_type === event_type)
}