import axios from 'axios';
import getEnvVars from '../../env';
const { WALLET_SVC_API  } = getEnvVars()

import { AccountProps, ItemProps, RecurringOrderProps } from "../types/pay_svc";



export const wallet_getMyAccounts = async():Promise<AccountProps[]> => {
    try {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/accounts/me`)
        return resp.data.accounts
    } catch (e) {
        console.log(e)
        return []
    }
}

export const wallet_getItemsByCatalogId = async(catalog_id:string):Promise<ItemProps[]> => {
    try {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/items/catalog/${catalog_id}`)
        return resp.data.items
    } catch (e) {
        return []
    }
}


export const wallet_cancelRecurringOrder = async(recurring_order_id:string):Promise<RecurringOrderProps | undefined> => {
    try {
        const resp = await axios.post(`${WALLET_SVC_API}/v1/orders/recurring/cancel`, { recurring_order_id })
        return resp.data.recurring_order
    } catch (e) {
        console.log(e)
    }
}

export const wallet_restoreRecurringOrder = async(recurring_order:RecurringOrderProps):Promise<RecurringOrderProps | undefined> => {
    try {
       const resp = await axios.post(`${WALLET_SVC_API}/v1/orders/recurring/restore`, { recurring_order })
       return resp.data.recurring_order 
    } catch (e) {
        console.log(e)
        return undefined
    }
}

export const wallet_updateRecurringOrder = async(recurring_order:RecurringOrderProps):Promise<RecurringOrderProps | undefined> => {
    try {
       const resp = await axios.post(`${WALLET_SVC_API}/v1/orders/recurring/update`, { recurring_order })
       return resp.data.recurring_order 
    } catch (e) {
        console.log(e)
        return undefined
    }
}

export const wallet_getMyRecurringOrders = async():Promise<RecurringOrderProps[]> => {
    try {
        const resp = await axios.get(`${WALLET_SVC_API}/v1/orders/recurring/me`)
        return resp.data.recurring_orders
    } catch (e) {
        console.log(e)
        return []
    }
}