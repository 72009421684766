import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'modal-enhanced-react-native-web';
import { ActivityIndicator, FlatList, Image, ScrollView, View } from "react-native"
import { Icon } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../../../constants/Colorsv2';
import { Text, TouchableOpacity } from "../../../globalComponents/NativeOverrides"
import { event_getLatestPrices } from '../../../store/actionsv1/events';
import { MarketProps, TradeProps } from '../../../store/types/mk_svc';
import { EventProps, ExternalPriceProps } from '../../../store/types/sr_svc';
import { market_filterTrades, market_getOddsLabel, market_getVar1Label } from '../helpers/markets';
import { event_getSideLabelsFromActiveMarket } from '../helpers/events';
import { MyPlayerProps, PlayerPremiumItem, PublicPlayerProps } from '../../../store/types/auth_svc';
import { navigate } from '../../../navigation/RootNavigation';
import { PremiumPriceComparison } from '../../../Componentsv1/Icons';
import { V1_LOAD_EXTERNAL_PRICE_COMPARISON } from '../../../store/actionsv1/types';
import { player_purchasePremiumItem } from '../../../store/actionsv1/player';
import ALaCartePremium from '../../../globalComponents/ALaCartePremium';

type ExternalPriceCardProps = {
    width:number,
}


const ExternalPriceCard = ({ width }:ExternalPriceCardProps) => {
    const [ loading, setLoading ] = useState(false);
    const [ confirm_purchase, setConfirmPurchase ] = useState(false);
    const [ purchase_loading, setPurchaseLoading ] = useState(false);
    const [ price_card_state, setPriceCardState ] = useState<
    { 
        min_height:number,
        active_side?:string;
        active_market?: MarketProps;
    }
    >({
        min_height: 100,
        active_side: '',
        active_market: undefined
    })
   const { active_market, active_side } = price_card_state;

    const dispatch = useDispatch()

    const prices:ExternalPriceProps[] = useSelector((state:any) => state.event.latest_prices, (left, right) => {
        if(left[0]?.market_id != right[0]?.market_id){ return false }
        if(left[0]?.participant_id != right[0]?.participant_id){ return false }
        return true
    })
    const external_price_comparison: { event_id?:string, event_type?:string, live?:boolean, available_markets?:MarketProps[], init_market?:MarketProps, side?:string, participant_id?:string, participant_type?:string } = useSelector((state:any) => state.market.external_price_comparison, (left, right) => left == right);
    const { event_id, event_type, participant_id, participant_type, init_market, side, live, available_markets } = external_price_comparison;

    let premium = false
    const player:MyPlayerProps | undefined = useSelector((state:any) => state.player.player, (left, right) => left?.updated == right?.updated);
    //See if the user has this premium item purchased
    const player_premium_items_list: {updated:number, player_premium_items:PlayerPremiumItem[]} = useSelector((state:any) => state.player.player_premium_items_list, (left, right) => left.updated == right.updated);
    const external_pricing_premium = player_premium_items_list.player_premium_items.find(ppi => ppi.premium_item?.identifier == 'external_pricing')
    if(external_pricing_premium?.status == 'active'){ premium = true  }
    if(player?.type == 'premium'){ premium = true }

    const event:EventProps | undefined = useSelector((state:any) => state.event.events[event_id], (left, right) => left?.last_update_datetime == right?.last_update_datetime)
    let away_label = 'away', home_label = 'home'
    if((event_type === 'tournament' || event_type === 'match') && active_market){
        away_label = active_market.side_options.find(o => o.side != active_market.trade_side)?.label??''
        home_label = active_market.side_options.find(o => o.side === active_market.trade_side)?.label??''
    }
    if(event && active_market){
        const labels = event_getSideLabelsFromActiveMarket(active_market, event)
        away_label = labels.away_label??'away'
        home_label = labels.home_label??'home'
    }
    const market_type:string = useSelector((state:any) => state.auth.market_type, (left, right) => left === right)

    const latest_trades_list: undefined | TradeProps[] = useSelector((state:any) => state.market.latest_trades[`${event_type}:${event_id}`], (left, right) => left == right);
    let latest_trades:TradeProps[] = []
    if(latest_trades_list){ latest_trades = latest_trades_list.filter(t => t.market_type == market_type && t.market_id == active_market?.market_id) }

    let trades = market_filterTrades(latest_trades, event_id, event_type, participant_id)

    let trades_override:TradeProps[] = []
    const latest_trade = trades.find(t => t.side == active_market?.trade_side)
    const latest_o_trade = trades.find(t => t.side !== active_market?.trade_side)
    if(latest_trade && latest_o_trade){
        trades_override = [latest_o_trade, latest_trade]
    }
    const filtered_prices = prices.filter(p => p.event_id == event_id && p.event_type == event_type && p.side == active_side && p.market_id == active_market?.market_id).sort((a,b) => (a.external_name > b.external_name) ? 1 : ((b.external_name > a.external_name) ? -1 : 0))

    useEffect(() => {
        if(!init_market){ return }
        setPriceCardState({
            ...price_card_state,
            active_side: side?side:init_market.trade_side,
            active_market: init_market
        })
    },[init_market])


    useEffect(() => {
        getPricesFromServer()
    },[active_market, event_id, participant_id])
    

    const getPricesFromServer = async() => {
        if(!event_id || !event_type || !active_market){ return }
        setLoading(true);
        await dispatch(event_getLatestPrices(event_id, event_type, active_market.market_id, undefined, participant_id))
        setLoading(false);
    }

    const closeModal = () => {
        setPriceCardState({
            min_height: 100,
            active_side: '',
            active_market: undefined
        });
        dispatch({ type: V1_LOAD_EXTERNAL_PRICE_COMPARISON, external_price_comparison: {} })
    }

    const handleChangeMarket = (direction:'next'|'previous') => {
        if(!available_markets || !active_market){ return }
        let avail_indexes = available_markets.length - 1
        let curr_index = available_markets.findIndex(m => m.market_id == active_market.market_id)
        if(curr_index < 0){ return } //couldn't find it
        if(direction === 'next'){
            if(curr_index + 1 > avail_indexes){ return setPriceCardState({ ...price_card_state, active_market: available_markets[0], active_side: available_markets[0].trade_side }) }
            return setPriceCardState({ ...price_card_state, active_market: available_markets[curr_index + 1], active_side: available_markets[curr_index + 1].trade_side })
        }
        if(direction === 'previous'){
            if(curr_index - 1 < 0){ return setPriceCardState({ ...price_card_state, active_market: available_markets[avail_indexes], active_side: available_markets[avail_indexes].trade_side }) }
            return setPriceCardState({ ...price_card_state, active_market: available_markets[curr_index - 1], active_side: available_markets[curr_index - 1].trade_side })
        }
    }

    const renderPrices = (data: {item:ExternalPriceProps, index:number}) => {
        return (
            <View style={{ flexDirection:'row', padding:5 }}>
                <View style={{flex:1}}>
                    <Text size={14} color={Colors.shades.black} weight='regular'>{data.item.external_name}</Text>
                    <Text size={10} weight='regular' color={Colors.accents.accent200}>{(data.item.vig_pct*100).toFixed(2)}% Vig</Text>
                </View>
                {premium ?
                <View style={{ alignItems:'flex-end' }}>
                    <Text size={14} color={Colors.shades.black} weight='regular'>{active_market.var_1_required ? market_getVar1Label(data.item.var_1, active_market) : `${(data.item.probability * 100).toFixed(2)}%`} <Text size={14} weight='bold' color={Colors.shades.black}>{market_getOddsLabel(data.item.odds)}</Text></Text>
                    <Text size={10} weight='regular' color={Colors.accents.accent200}>As Of: {moment(data.item.create_datetime).fromNow()}</Text>
                </View>
                :
                <View>
                    <Icon name='lock' color={Colors.incentive.gold} size={12} type='feather'/>
                </View>
                }
            </View>
        )
    }
    const trade_side = active_market?.trade_side
    const o_side = active_market?.side_options.find(o => o.side !== active_market?.trade_side)?.side
    const last_trade = trades_override.find(t => t.side === active_side)
    const consensus_price = filtered_prices.find(p => p.external_name === 'Consensus')

    return (
        <Modal propagateSwipe={true} isVisible={event_id?true:false} deviceWidth={width} style={{ flex:1, margin:0, justifyContent: 'flex-end', alignItems:'center' }}
            animationIn='slideInUp' animationOut='slideOutDown' animationInTiming={500} onSwipeComplete={() => closeModal()} onBackdropPress={() => closeModal()}>
            <View style={{ ...localStyles.modalStyle, width:width }}>
                {!active_market || !trade_side || !o_side ?
                <View style={{ justifyContent:'center', alignItems:'center', padding:20 }}>
                    <ActivityIndicator color={Colors.brand.midnight} size='large' />
                </View>
                :
                <View style={{ height:550 }}>
                    <View nativeID='header' style={{ padding:10 }}>
                        <Text size={16} color={Colors.shades.black} weight='bold' textAlign='center'>External Price Comparison</Text>
                        <View style={{flexDirection:'row', alignItems:'center', justifyContent:'center', marginTop:10, paddingBottom:10, borderBottomWidth:1, borderColor:Colors.shades.shade100}}>
                            {available_markets && available_markets.length > 0 ?
                            <TouchableOpacity style={{flex:2/5, alignItems:'flex-end'}} onPress={() => handleChangeMarket('previous')}>
                                <Icon raised name='chevron-left' type='feather' color={Colors.brand.electric} size={14} />
                            </TouchableOpacity>
                            :<></>}
                            <Text size={14} weight='regular' textAlign='center' color={Colors.shades.black} style={{flex:1/5}}>{active_market.type==='Stat'?active_market.stat:active_market.type}</Text>
                            {available_markets && available_markets.length > 0 ?
                            <TouchableOpacity style={{flex:2/5, alignItems:'flex-start'}} onPress={() => handleChangeMarket('previous')}>
                                <Icon raised name='chevron-right' type='feather' color={Colors.brand.electric} size={14} />
                            </TouchableOpacity>
                            :<></>}
                        </View>
                        <View nativeID='side_switcher' style={{ padding:2, margin:5, marginTop:10, flexDirection:'row', borderRadius: 6, backgroundColor: '#e9e9e9' }}>
                            <TouchableOpacity style={{ flex:1, padding:12, backgroundColor:active_side==o_side?Colors.shades.white:'transparent', borderRadius:4, justifyContent:'center', alignItems:'center' }} onPress={() => setPriceCardState({ ...price_card_state, active_side:o_side })}>
                                <Text size={14} color={Colors.shades.black} weight={active_side===o_side?'bold':'regular'} textAlign='center'>{away_label??o_side}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ flex:1, padding:12, backgroundColor:active_side==trade_side?Colors.shades.white:'transparent', borderRadius:4, justifyContent:'center', alignItems:'center' }} onPress={() => setPriceCardState({ ...price_card_state, active_side:trade_side })}>
                                <Text size={14} color={Colors.shades.black} weight={active_side===trade_side?'bold':'regular'} textAlign='center'>{home_label??trade_side}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <ALaCartePremium identifier='external_pricing'/>
                    <ScrollView style={{ flex:1}}>
                    <View nativeID='prices' style={{ padding:20 }}>
                        {last_trade ?
                        <View style={{ flexDirection:'row', padding:5 }}>
                            <View style={{flex:2/3}}>
                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>BettorEdge</Text>
                                <Text size={10} weight='regular' color={Colors.accents.accent200}>0% Vig</Text>
                            </View>
                            <View style={{flex:1/3, alignItems:'flex-end'}}>
                                <Text size={14} color={Colors.brand.midnight} weight='regular'>{active_market.var_1_required ? market_getVar1Label(last_trade.var_1, active_market) : `${(last_trade.probability * 100).toFixed(2)}%`} <Text size={14} weight='bold' color={Colors.shades.black}>{market_getOddsLabel(last_trade.odds)}</Text></Text>
                                <Text size={10} weight='regular' color={Colors.accents.accent200}>Traded: {moment(last_trade.create_datetime).fromNow()}</Text>
                            </View>
                        </View>
                        :<></>}
                        {consensus_price ?
                        <View style={{ flexDirection:'row', padding:5 }}>
                            <View style={{flex:1}}>
                                <Text size={14} color={Colors.shades.black} weight='regular'>{consensus_price.external_name}</Text>
                                <Text size={10} weight='regular' color={Colors.accents.accent200}>{(consensus_price.vig_pct*100).toFixed(2)}% Vig</Text>
                            </View>
                            {premium ?
                            <View style={{ alignItems:'flex-end' }}>
                                <Text size={14} color={Colors.shades.black} weight='regular'>{active_market.var_1_required ? market_getVar1Label(consensus_price.var_1, active_market) : `${(consensus_price.probability * 100).toFixed(2)}%`} <Text size={14} weight='bold' color={Colors.shades.black}>{market_getOddsLabel(consensus_price.odds)}</Text></Text>
                                <Text size={10} weight='regular' color={Colors.accents.accent200}>As Of: {moment(consensus_price.create_datetime).fromNow()}</Text>
                            </View>
                            :
                            <View>
                                <Icon name='lock' color={Colors.incentive.gold} size={12} type='feather'/>
                            </View>
                            }
                        </View>
                        :<></>}
                        <Image style={{alignSelf:'center', height:1, width:width, margin:10}} source={require('../../../assets/images/faded_line.png')} />
                        {!live?
                        <Text size={14} color={Colors.brand.midnight} weight='bold' textAlign='center'>PRE-GAME</Text>
                        :<></>}
                        <FlatList data={filtered_prices.filter(p => p.external_name !== 'Consensus')} renderItem={renderPrices} keyExtractor={(item) => item.external_price_id.toString() } />
                        {!loading && filtered_prices.length == 0 ?
                        <View style={{justifyContent:'center', alignItems:'center', padding:20}}>
                            <Image
                                source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1698862064/open_box_nryphw.png' }}
                                style={{ width: 200, height: 200 }}
                                resizeMode='contain'
                            />
                            <Text style={{ marginTop:15 }} size={14} color={Colors.brand.midnight} weight='bold'>Ope! We couldn't find any prices for this market</Text>
                        </View>
                        :<></>}
                    </View>
                    </ScrollView>
                </View>
                }
                <TouchableOpacity style={{ marginRight:20, marginLeft:20, borderRadius:4, borderWidth:1, borderColor:Colors.brand.midnight, padding:10 }} onPress={() => closeModal()}>
                    <Text size={14} color={Colors.brand.midnight} weight='bold' textAlign='center'>Close</Text>
                </TouchableOpacity>
            </View>
            </Modal>
    )
}

const localStyles = {
    modalStyle: { backgroundColor: Colors.shades.white, borderTopRightRadius: 42, borderTopLeftRadius: 42, paddingBottom:35, paddingTop:20 },
    raised: { shadowColor: "rgba(0, 0, 0, 0.12)", shadowOffset: { width: 0, height: 10 }, shadowRadius: 10, shadowOpacity: 1 }
}

const areEqual = (prevProps:ExternalPriceCardProps, nextProps:ExternalPriceCardProps) => {
    if(prevProps.width != nextProps.width){return false }
    return true
}

export default React.memo(ExternalPriceCard, areEqual)