import { PodcastEpisodesProps, PodcastProps } from "../../../store/types/soc_svc"

export const getPodImage = (podcast:PodcastProps) => {
    let image_source = podcast.image_override?.url
    if(!image_source){ image_source = podcast.image?.url }
    if(image_source && image_source !== ''){ return image_source }
    return require('../../../assets/images/default_pod.webp')
}

export const getPodEpisodeImage = (pe:PodcastEpisodesProps, podcast?:PodcastProps) => {
    let image_source = pe.image_override?.url
    if(!image_source){ image_source = pe.image?.url }
    if(!image_source && podcast){
        image_source = getPodImage(podcast)
    }
    if(image_source && image_source !== ''){ return image_source }
    return require('../../../assets/images/default_pod.webp')
}