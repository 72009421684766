//Import Redux Store Required modules and root reducer
import { legacy_createStore, applyMiddleware } from 'redux'
import rootReducer from './store/reducers/rootReducer';
import  thunk from 'redux-thunk';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
//Set Confi
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'pref','draft']
}

const devTools = applyMiddleware(thunk)

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = legacy_createStore(persistedReducer, devTools);


export default store

