import moment, { Moment } from "moment"
import axios from 'axios';
import { useEffect, useState } from "react"
import getEnvVars from '../../env';
const { REACT_APP_SOCIAL_URL_DIRECT } = getEnvVars();


import { ActivityPostType, CompanyAdType, ImpressionType, MemoPostType, PostCommentType } from "../../objectTypes"
import { getCoords } from "../global/location";


export const handleClickActivity = (ad:CompanyAdType, clicker_id:string) => {
    const now = moment()
    let impression:ImpressionType = { viewer_id:clicker_id, activity_id:ad.company_ad_id, impression_id:'', location:getCoords(), player_id:ad.company_id, activity_type:'ad', impression_type:'click', impression_start_datetime:now, impression_end_datetime:now, impression_datetime:undefined }
    logImpression(impression)
}


export const handleViewingActivity = (ref:any, player_id:string, type:'memo'|'comment'|'activity'|'ad', item:MemoPostType | ActivityPostType | PostCommentType | CompanyAdType) => {
    const [ isIntersecting, setIntersecting ] = useState(false)
    const [ trackingStarted, setTrackingStarted ] = useState(false)
    const [ trackStartTime, setTrackStartTime ] = useState<Moment | undefined>()
    
    const observer = new IntersectionObserver(
      ([entry]) => { 
          ref.current.isIntersecting = entry.isIntersecting
          setIntersecting(entry.isIntersecting)
      }
    )

    const onbeforeunloadFn = (ev:any) => {
        if(ref.current.isIntersecting && ref.current.trackingStarted){
            handleViewTrackEvent(player_id, type, item, ref.current.trackStartTime, moment())
        }
    }
    useEffect(() => {
        if(!ref.current){ return }
      observer.observe(ref.current)
      window.addEventListener('beforeunload', onbeforeunloadFn);
      //window.removeEventListener('beforeunload', onbeforeunloadFn);
      // Remove the observer as soon as the component is unmounted
      return () => { 
          window.removeEventListener('beforeunload', onbeforeunloadFn)
          observer.disconnect() 
        }
    }, [])

    if(!trackingStarted && isIntersecting){
        ref.current.trackingStarted = true
        ref.current.trackStartTime = moment()
        setTrackingStarted(true)
        setTrackStartTime(moment())
    }
    if(trackingStarted && !isIntersecting){
        ref.current.trackingStarted = false
        setTrackingStarted(false)
        handleViewTrackEvent(player_id, type, item, trackStartTime, moment())
    }
  }

  const handleViewTrackEvent = (player_id:string, type: 'memo'|'comment'|'activity'|'ad', item:MemoPostType | ActivityPostType | PostCommentType | CompanyAdType, start:Moment | undefined, end:Moment|undefined) => {
    let impression:ImpressionType = { viewer_id:player_id, activity_id:'', impression_id:'', location:getCoords(), player_id:'', activity_type:type, impression_type:'view', impression_start_datetime:start, impression_end_datetime:end, impression_datetime:undefined }
        switch(type){
            case 'memo': 
                item = item as MemoPostType
                impression.player_id = item.player_id
                impression.activity_id = item.memo_post_id
                break
            case 'comment':
                item = item as PostCommentType
                impression.player_id = item.player_id
                impression.activity_id = item.post_comment_id
                break
            case 'activity':
                item = item as ActivityPostType
                impression.player_id = item.player_id
                impression.activity_id = item.activity_post_id
                break
            case 'ad':
                item = item as CompanyAdType
                impression.player_id = item.company_id
                impression.activity_id = item.company_ad_id
                break
            default: return
      }
      logImpression(impression)
  }


  const logImpression = (impression:ImpressionType):void => {
    axios.post(`${REACT_APP_SOCIAL_URL_DIRECT}/activityv2/impression`, { impression })
  }

  export const logPlayerLocation = async(coordinates:any, anonymous_id?:string | null):void => {
    try {
        const resp = await axios.post(`${REACT_APP_SOCIAL_URL_DIRECT}/playerv2/location`, { coordinates, anonymous_id })
        console.log(resp.data)
    } catch (e) {
        console.log(e)
    }
  }


export const mediaGelSignUpPixel = (uuid:string) => {
    fetch(`https://collector.dmp.cnna.io/com.snowplowanalytics.iglu/v1?schema=iglu:com.mediajel.events/sign_up/jsonschema/1-0-1&aid=BettorEdge&uuid=${uuid}`, {
        method: 'GET',
        mode: 'no-cors'
    })
}


export const fieldTestSignUpPixel = async(uuid:string) => {
    if(window.ftla){
        window.ftla.do('conversion', {
            value    : `0`,
            order_id : `${uuid}`
        })
    }
}