import React, { useState } from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { View } from 'react-native';
import { Text, TouchableOpacity } from '../../../globalComponents/NativeOverrides';
import HelpCallout from '../../../help/HelpCallout';
import { Icon } from 'react-native-elements';
import Colors from '../../../constants/Colorsv2';
import { OrderProps } from '../../../store/types/mk_svc';
import { order_calcAmericanOddsFromProbability, order_calcPotentialWinnings } from '../helpers/orders';
import { market_getOddsLabel } from '../helpers/markets';

type OrderSuggestionCardProps = {
    order:OrderProps,
    top:number,
    onHeightChange?:(height:number) => void,
    onAcceptSuggestion:(order:OrderProps) => void,
    onDeclineSuggestion: (order:OrderProps) => void,
}

const OrderSuggestionCard = ({ order, top, onAcceptSuggestion, onDeclineSuggestion, onHeightChange }:OrderSuggestionCardProps) => {
    const [ order_suggestion_size, setOrderSuggestionSize ] = useState({ x:0, y:0, height:0, width:0 })
    let suggested_order:OrderProps | undefined = undefined

    const handleSizeChange = (x:number, y:number, height:number, width:number) => {
        
        if(Math.abs(height - order_suggestion_size.height) > 5){
            if(onHeightChange){ onHeightChange(height - 75) }
        }
        setOrderSuggestionSize({ x, y, height, width })
    }

    const handleAccept = (new_order:OrderProps) => {
        if(!new_order){ return }
        onAcceptSuggestion(new_order)
    }

    const handleDecline = () => {
        if(!suggested_order){ return }
        onDeclineSuggestion({ ...order, jump_queue_probability:undefined, buy_now_probability:undefined })
    }

    //Determine if the buy_now_prob is within 6% of the existing order
    if(order.buy_now_probability){
        let diff = order.buy_now_probability - order.probability
        if(diff < order.collar_pct){ return <></> } //Difference is too low to suggest and will be covered by collar pct
        if(diff > .05){ return <></> } //Difference is too much and not worth suggesting

        suggested_order = { ...order }
        suggested_order.probability = order.buy_now_probability
        suggested_order.odds = order_calcAmericanOddsFromProbability(suggested_order.probability)
        suggested_order.potential_winnings = order_calcPotentialWinnings(suggested_order.odds, suggested_order.open_amt)
    }
    
    if(!suggested_order){ return <></> }
    const cl = order.market_type == 'FOR_MONEY' ? '$' : 'E'
    return (
        <LinearGradient 
            colors={['rgba(196, 196, 196, 0)', '#002A51']}
            style={{ position:'absolute', top, left:0, right:0, bottom:0 }}>
            <View style={{ backgroundColor:Colors.brand.electric, borderRadius:8, marginLeft:16, marginRight:16 }} onLayout={(ev) => {
                    const { x,y,width,height } = ev.nativeEvent.layout
                    handleSizeChange(x,y,height,width)
                }}>
                <View style={{padding:12}}>
                    <View style={{flexDirection:'row', alignItems:'center', marginBottom:4}}>
                        <Text size={14} weight='semibold' color={Colors.shades.white}>BEST AVAILABLE PRICE</Text>
                        <HelpCallout style={{marginLeft:10}} client_native_id='h16' type='container' pressed={() => console.log('close')}>
                            <Icon name='alert-circle' color={Colors.shades.white} size={14} type='feather' />
                        </HelpCallout>
                    </View>
                    <Text style={{ marginBottom:5 }} size={12} color={Colors.shades.white} weight='regular'>There's no available orders at this current price. Instantly fulfill by accepting the changes below.</Text>
                    <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginBottom:5}}>
                        <Text size={14} color={Colors.shades.white} weight='regular'>Probability</Text>
                        <Text size={14} color={Colors.shades.white} weight='regular'>{(suggested_order.probability*100).toFixed(2)}%</Text>
                    </View>
                    <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginBottom:5}}>
                        <Text size={14} color={Colors.shades.white} weight='regular'>Odds</Text>
                        <Text size={14} color={Colors.shades.white} weight='regular'>{market_getOddsLabel(suggested_order.odds)}</Text>
                    </View>
                    <View style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginBottom:5}}>
                        <Text size={14} color={Colors.shades.white} weight='regular'>New Winnings</Text>
                        <Text size={14} color={Colors.shades.white} weight='regular'>{cl}{suggested_order.potential_winnings.toFixed(2)}</Text>
                    </View>
                </View>
                <View style={{ flexDirection:'row' }}>
                    <TouchableOpacity native_id='btx_order_suggestion_decline' style={{ flex:1, backgroundColor:Colors.shades.white, padding:10, borderBottomLeftRadius:8 }} onPress={() => handleDecline()}>
                        <Text size={14} color={Colors.brand.midnight} weight='regular' textAlign='center'>DECLINE</Text>
                    </TouchableOpacity>
                    <TouchableOpacity native_id='btx_order_suggestion_accept' style={{ flex:2, backgroundColor:Colors.utility.success, padding:10, borderBottomRightRadius:8 }} onPress={() => handleAccept(suggested_order)}>
                        <Text size={14} color={Colors.shades.white} weight='regular' textAlign='center'>ACCEPT CHANGES & REVIEW</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </LinearGradient>
    )
}

export default OrderSuggestionCard