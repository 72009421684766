import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import { FlatList, TouchableOpacity, View } from 'react-native'
import Colors from '../../../constants/Colorsv2'
import { Text } from '../../../globalComponents/NativeOverrides'
import { EventProps, MatchProps, TournamentProps } from '../../../store/types/sr_svc'
import { Icon } from 'react-native-elements'

type ExpireOptionType = { option_id:string, optionLabel: string, expire_datetime:Moment }
type ExpirationDatePickerProps = { 
    event_type: 'match'|'tournament'|'team', 
    tournament?:TournamentProps,
    event?: EventProps,
    match?:MatchProps
    isVisible:boolean,
    onSelect:(ed:Moment) => void
}

function ExpirationDatePicker ({ isVisible, event_type, tournament, match, event, onSelect }:ExpirationDatePickerProps) {
    const [ expanded, setExpanded ] = useState(false)
    const [ options, setOptions ] = useState<ExpireOptionType[]>()
    const [ selected_end, setSelectedEnd ] = useState({ days: 0, hours: 1, minutes:0, custom_time: moment().add(1, 'hours') });
    const [ active_end, setActiveEnd ] = useState<string|undefined>(undefined)
    const [ selectedOption, setSelectedOption ] = useState<ExpireOptionType>()

    useEffect(() => {
        if(!isVisible){ return }
        let options:ExpireOptionType[] = []
        switch(event_type){
            case 'team':
                if(!event){ console.log('there was no team'); return }
                options = [{option_id:'marketOption:1', optionLabel:'in 5 Minutes', expire_datetime:moment().add(5, 'minutes')},{option_id:'marketOption:2', optionLabel:'in 15 Minutes', expire_datetime:moment().add(15, 'minutes')}, {option_id:'marketOption:3', optionLabel:'at Game Start', expire_datetime:moment(event.scheduled_datetime)}, { option_id:'marketOption:4', optionLabel:'at 5 Minutes After Game Start', expire_datetime:moment(event.scheduled_datetime).add(5, 'minutes')},{option_id:'market:custom', optionLabel:'at Custom Time', expire_datetime:moment().add(1,'hours')}]
                if(moment(event.scheduled_datetime).subtract(10, 'minutes').isBefore(moment())){ setSelectedOption(options[1]) } else { setSelectedOption(options[2]) }
                break
            case 'tournament':
                if(!tournament){ return }
                options = [{option_id:'marketOption:1', optionLabel:'in 5 Minutes', expire_datetime:moment().add(5, 'minutes')},{option_id:'marketOption:2', optionLabel:'in 15 Minutes', expire_datetime:moment().add(15, 'minutes')}, {option_id:'marketOption:3', optionLabel:'at Scheduled Time', expire_datetime:moment(tournament.scheduled_datetime)}, { option_id:'marketOption:4', optionLabel:'at 5 Minutes After Scheduled Time', expire_datetime:moment(tournament.scheduled_datetime).add(5, 'minutes')}, {option_id:'market:custom', optionLabel:'at Custom Time', expire_datetime:moment().add(1,'hours')}]
                if(moment(tournament.scheduled_datetime).subtract(10, 'minutes').isBefore(moment())){ setSelectedOption(options[1]) } else { setSelectedOption(options[2]) }
                break
            case 'match':
                if(!match){ return }
                options = [{option_id:'marketOption:1', optionLabel:'in 5 Minutes', expire_datetime:moment().add(5, 'minutes')},{option_id:'marketOption:2', optionLabel:'in 15 Minutes', expire_datetime:moment().add(15, 'minutes')}, {option_id:'marketOption:3', optionLabel:'at Scheduled Time', expire_datetime:moment(match.scheduled_datetime)}, { option_id:'marketOption:4', optionLabel:'at 5 Minutes After Scheduled Time', expire_datetime:moment(match.scheduled_datetime).add(5, 'minutes')}, {option_id:'market:custom', optionLabel:'at Custom Time', expire_datetime:moment().add(1,'hours')}]
                if(moment(match.scheduled_datetime).subtract(10, 'minutes').isBefore(moment())){ setSelectedOption(options[1]) } else { setSelectedOption(options[2]) }
                break
            default: return
        }
        setOptions(options)
    },[ isVisible, event, tournament, match ])

    useEffect(() => {
        if(selectedOption){
            if(selectedOption.option_id != 'market:custom'){ onSelect(selectedOption.expire_datetime) }
        }
    },[ selectedOption ])
    
    const handleSelectOption = (option:ExpireOptionType) => {
        setSelectedOption(option)
        if(option.option_id != 'market:custom'){
            setExpanded(false)
        }
    }

    const handleSelectEndOption = (num:number, end:string) => {
        let new_end = { ...selected_end, [end]: num }
        let new_time = moment().add(new_end.days, 'days').add(new_end.hours, 'hours').add(new_end.minutes, 'minutes')
        setSelectedEnd({ ...new_end, custom_time: new_time })
        setActiveEnd(undefined)
        onSelect(new_time)
    }

    const getEndOptions = () => {
        let end_options:number[] = []
        switch(active_end){
            case 'days':
                for (let i = 0; i < 31; i++) {
                    end_options.push(i)
                  }
                break
            case 'hours':
                for (let i = 0; i < 24; i++) {
                    end_options.push(i)
                  }
                  break
            case 'minutes':
                for (let i = 0; i < 60; i++) {
                    end_options.push(i)
                  }
                  break
            default: return []
        }
        return end_options
    }

    const renderExpireOptions = (data: { item:ExpireOptionType, index:number }) => {
        let selected = selectedOption?.option_id === data.item.option_id
        return (
            <TouchableOpacity style={{flexDirection:'row', alignItems:'center', marginTop:4, marginBottom:4}} onPress={() => handleSelectOption(data.item)}>
                <View style={{height:24, width:24, borderRadius:100, justifyContent:'center', alignItems:'center', borderWidth:1, borderColor:Colors.highlights.highlight200}}>
                    { selected ? 
                        <View style={{ height:14, width:14, borderRadius:100, backgroundColor:Colors.highlights.highlight200 }} />
                    :
                    <></>}
                </View>
                <View style={{marginLeft:12}}>
                    <Text size={14} color={Colors.brand.midnight} weight={selected?'semibold':'regular'} >{data.item.optionLabel}</Text>
                </View>
            </TouchableOpacity>
        )
    }
    const renderEndOptions = (data: {item: number, index:number}) => {
        if(!active_end){ return <></> }
        const selected = selected_end[active_end] == data.item ? true : false
        return (
            <TouchableOpacity style={{ padding:15,  borderRadius:4, backgroundColor:selected ? Colors.brand.electric : 'transparent' }} onPress={() => handleSelectEndOption(data.item, active_end)}>
                <Text size={14} color={selected ? Colors.shades.white :Colors.brand.electric}>{data.item}</Text>
            </TouchableOpacity>
        )
    }

    return (
        <>
            <View style={{flexDirection:'row', alignItems:'center', justifyContent:'space-between'}}>
                <Text size={14} color={Colors.shades.black}>Your order will expire <Text size={14} color={Colors.shades.black} weight='bold'>{selectedOption?.optionLabel}</Text></Text>
                <TouchableOpacity onPress={() => setExpanded(!expanded)}>
                    <Text size={14} color={Colors.brand.electric} weight='regular' textAlign='right'>{expanded?'Close':'Edit'}</Text>
                </TouchableOpacity>
            </View>
            { expanded ?
            <View style={{ marginTop:12 }}>
                <View style={{marginTop:12}}>
                    <FlatList data={options} renderItem={renderExpireOptions} keyExtractor={(item) => item.option_id} />
                </View>
                {selectedOption?.option_id == 'market:custom' ?
                <View nativeID='auto_close_timer' style={{ padding:10, marginTop:10, backgroundColor:Colors.shades.shade100 }}>
                    <Text size={14} color={Colors.brand.midnight} weight='semibold'>{selected_end.custom_time.format('MMM DD hh:mm a')}</Text>
                    <Text size={12} color={Colors.brand.slate} weight='regular'>Set when this order should expire</Text>   
                    <View style={{ flexDirection:'row', alignItems:'center', marginTop:10 }}>
                        <TouchableOpacity style={{ flex:1, flexDirection:'row' }} onPress={() => setActiveEnd('days')}>
                            <Text size={12} color={Colors.brand.electric} weight='regular'>{selected_end.days} days</Text>
                            <Icon containerStyle={{ marginLeft:3 }} name='chevron-down' size={14} color={Colors.brand.electric} type='feather'/>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flex:1, flexDirection:'row' }} onPress={() => setActiveEnd('hours')}>
                            <Text size={12} color={Colors.brand.electric} weight='regular'>{selected_end.hours} hours</Text>
                            <Icon containerStyle={{ marginLeft:3 }} name='chevron-down' size={14} color={Colors.brand.electric} type='feather'/>
                        </TouchableOpacity>
                        <TouchableOpacity style={{ flex:1, flexDirection:'row' }} onPress={() => setActiveEnd('minutes')}>
                            <Text size={12} color={Colors.brand.electric} weight='regular'>{selected_end.minutes} minutes</Text>
                            <Icon containerStyle={{ marginLeft:3 }} name='chevron-down' size={14} color={Colors.brand.electric} type='feather'/>
                        </TouchableOpacity>
                    </View>
                    {active_end ?
                    <View nativeID='end_options' style={{ margin:10 }}>
                        <FlatList
                            data={getEndOptions()}
                            renderItem={renderEndOptions}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                        />
                    </View>
                    :<></>}                     
                </View>
                :<></>}
            </View>
            :<></>}
        </>
    )
}

const areEqual = (prevProps:ExpirationDatePickerProps, nextProps:ExpirationDatePickerProps) => {
    if(prevProps.isVisible !== nextProps.isVisible) { return false }
    if(prevProps.event != nextProps.event){ return false }
    if(prevProps.tournament != nextProps.tournament){ return false }
    if(prevProps.match != nextProps.match){ return false }
    if(prevProps.event_type !== nextProps.event_type){ return false }
    return true
}

export default React.memo(ExpirationDatePicker, areEqual)