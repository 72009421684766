
import React, { useState } from 'react';
import { FlatList, Image, View } from 'react-native';
import { Icon } from 'react-native-elements';
import Colors from '../constants/Colorsv2';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { HelpActionType, HelpPromptType } from '../objectTypes';
import { handleHelpPromptAction } from './HelpLogic/helpers';


type T2HelpModalProps = { 
    isAuthenticated:boolean, 
    onAction?:(action_type:string) => void, 
    help_prompt:HelpPromptType, 
    is_tour?:boolean, 
    tour_length?:number, 
    onChangeTour?:any, 
    clearHelp:any 
}

const MAX_WIDTH = 450
const T2HelpModal = ({ isAuthenticated, clearHelp, help_prompt, is_tour, tour_length, onChangeTour, onAction }:T2HelpModalProps) => {
    const [ promptSize, setPromptSize ] = useState({ width:0, height:0 })

    let tour_steps = []
    if(is_tour && tour_length){
        for(let i = 1; i < tour_length + 1; i++){
            tour_steps.push(i)
        }
    }

    const updateImageSize = (media_obj:any) => {
        if(!media_obj){ return { width:0, height:0 } }
        let image_width = promptSize.width * 0.9
        if(image_width > MAX_WIDTH){ image_width = MAX_WIDTH }
        let image_ratio = media_obj.height / media_obj.width
        let image_height = image_width * image_ratio

        return { width:image_width, height: image_height }
    }

    const handleActionTaken = (action:HelpActionType) => {
        clearHelp()
        if(onAction){ onAction(action.action_type) }
        handleHelpPromptAction(help_prompt, action)
    }
    const handleChangeTour = (step:number) => {
        if(!onChangeTour){ return }
        onChangeTour(step)
    }

    const renderCarousel = (data:{ item:number, index:number }) => {
        const active = data.item == help_prompt.tour_priority
        return (
            <TouchableOpacity style={{margin:5, height:2, width:20, borderBottomWidth:2, borderColor:active?Colors.brand.electric:Colors.brand.slate}} onPress={() => handleChangeTour(data.item)}/>
        )
    }

    const renderActions = (data: {item:HelpActionType, index:number}) => {
        return (
            <TouchableOpacity style={{flex:1, backgroundColor:data.item.button_color, marginRight:4, borderRadius:23, padding:10, borderWidth:1, borderColor:data.item.button_border_color}} onPress={() => handleActionTaken(data.item)}>
                <Text size={16} color={data.item.action_label_font} weight='semibold' textAlign='center'>{data.item.action_label}</Text>
            </TouchableOpacity>
        )
    }

    let image_size = updateImageSize(help_prompt.media_obj)
    return (
        <TouchableOpacity native_id='btx_clear_t4_help' style={{position:'absolute', zIndex:100, bottom:0, top:0, right:0, left:0, padding:30, justifyContent:'center', alignItems:'center', backgroundColor:'rgba(17, 17, 17, 0.3)'}} onPress={() => clearHelp()}
        onLayout={(ev:any) => {
            const { width, height } = ev.nativeEvent.layout
            setPromptSize({ width, height })
        }}
        >
            <View style={{backgroundColor:help_prompt.help_background_color, width: image_size.width, borderRadius:6 }}>
                <View style={{ borderRadius:4 }}>
                    {help_prompt.media_obj?.media_url?
                    <Image source={help_prompt.media_obj.media_url} style={{height:image_size.height, width:image_size.width, borderTopRightRadius:4, borderTopLeftRadius:4 }} resizeMode='contain'/>
                    :<></>}
                    {is_tour && help_prompt.tour_priority && help_prompt.tour_priority != 1 ?
                    <TouchableOpacity style={{ position:'absolute', top:image_size.height/2, left:10, backgroundColor:'rgba(255,255,255,0.5)', borderRadius:100, padding:10, justifyContent:'center', alignItem:'center' }} onPress={() => handleChangeTour(help_prompt.tour_priority - 1)}>
                        <Icon name='chevron-left' type='feather' size={18} color={Colors.shades.white}/>
                    </TouchableOpacity>
                    :<></>}
                    {is_tour && help_prompt.tour_priority != tour_length ?
                    <TouchableOpacity style={{ position:'absolute', top:image_size.height/2, right:10, backgroundColor:'rgba(255,255,255,0.5)', borderRadius:100, padding:10, justifyContent:'center', alignItem:'center' }} onPress={() => handleChangeTour(help_prompt.tour_priority + 1)}>
                        <Icon name='chevron-right' type='feather' size={18} color={Colors.shades.white}/>
                    </TouchableOpacity>
                    :<></>}
                </View>
                <View style={{ backgroundColor:Colors.shades.white, borderBottomRightRadius:4, borderBottomLeftRadius:4, padding:20, paddingTop:5, minHeight:image_size.height, justifyContent:'space-between' }}>
                    <View>
                    {is_tour?
                        <View style={{justifyContent:'center', alignItems:'center', margin:5}}>
                            <FlatList data={tour_steps} renderItem={renderCarousel} keyExtractor={(item) => item.toString()} horizontal/>
                        </View>
                        :
                        <View style={{alignSelf:'center', marginTop:25, marginBottom:25, height:2, width:80, borderBottomWidth:2, borderColor:Colors.shades.white}} />
                    }
                    {promptSize.width !== 0 ?
                        <View>
                            <Text size={16} color={Colors.brand.midnight} weight='bold' textAlign='center'>{help_prompt.title}</Text>
                            <Text size={14} color={Colors.brand.midnight} weight='regular' textAlign='center' style={{ marginTop:10 }}>{help_prompt.body}</Text>
                            <Text size={14} color={help_prompt.footer_font_color??Colors.brand.midnight} weight='regular' textAlign='center' style={{ marginTop:20 }}>{help_prompt.footer}</Text>
                        </View>
                    :<></>}
                    </View>
                    
                    {promptSize.width !== 0 ?
                    <View>
                        {help_prompt.actions ?
                        <View style={{ marginTop: 15}}>
                            <FlatList data={help_prompt.actions} renderItem={renderActions} keyExtractor={(item) => item.action_label} numColumns={2}/>
                        </View>
                        :<></>}
                    </View>
                    :<></>}
                </View>
            </View>
        </TouchableOpacity>
    )
}

export default T2HelpModal