
import React, { useEffect } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import ProgressBar from './ProgressBar';
import Modal from 'modal-enhanced-react-native-web';
import Colors from '../constants/Colorsv2';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { OrderProps, PositionProps } from '../store/types/mk_svc';
import { market_clearOrderAlert, market_tipOrder } from '../store/actionsv1/markets';
import { connect } from 'react-redux';
import { player_donateToCharity, player_getCharities, player_getReferralByPlayerId } from '../store/actionsv1/player';
import { CharityDonationProps, CharityProps } from '../store/types/auth_svc';
import { Icon } from 'react-native-elements';
import { EventProps, LeagueProps, TournamentProps } from '../store/types/sr_svc';
import { market_getOddsLabel } from '../screensV1/Event/helpers/markets';
import { navigate } from '../navigation/RootNavigation';
import { Battle } from './Icons';

type OrderAlertModalProps = {
    width:number,
    order?:OrderProps,
    event?:EventProps
    tournament?:TournamentProps,
    charities: CharityProps[],
    market_clearOrderAlert:any
}

const OrderAlertModal = ({ width, order, market_clearOrderAlert, player, player_id, charities, player_getReferralByPlayerId, player_referral, promo, event, leagues, tournament, market_tipOrder, player_getCharities, player_donateToCharity }:OrderAlertModalProps) => {

    useEffect(() => {
        if(!order){ return }
        player_getCharities()
    },[order])

    useEffect(() => {
        if(!player_id){ return }
        
        player_getReferralByPlayerId(player_id)
    },[player_id])

    const handleDonation = async(vig:number) => {
        await player_donateToCharity({ donation_amt: vig, charity_id:charities[0].charity_id })
        return market_clearOrderAlert()
    }

    const getReferAmount = () => {
        if(!promo){ return '' }
        switch(promo.type){
            case 'promo_code': return `$${promo.amount}`
            case 'referral_code': return `$${promo.amount}`
            case 'referral_sweepstakes': return `UP TO $500`
            default: return ''
        }
    }

    const handleShareCode = () => {
        if(navigator.share){
            return navigator.share({title:'Join BettorEdge', text:`Sign up with my code: ${player_referral.referral_code} and receive ${getReferAmount()} once you verify your account`, url: `https://share.mybe.app/invite?refer=${player_referral.referral_code}`}).then(() => console.log('shared')).catch((e) => console.log(e))
        }
        return alert('Your browser does not support sharing.  Either switch to a mobile browser or copy and past the code')
    }

    const handleTip = async(vig:number) => {
        if(!order){ return market_clearOrderAlert() }
        await market_tipOrder(order?.order_id, .045, 'win')
        return market_clearOrderAlert()
    }

    const handleShare = () => {
        if(!order){ return }
        navigate('SocialStack', { screen: 'Shareable', initial:false, params: { order_id: order.order_id } })
        return market_clearOrderAlert()
    }

    const shareChallenge = async() => {
        if(!order){ return }
        if(navigator.share){
            return navigator.share({title:'Head2Head Challenge!', text:`I'll take ${order.title} at (${market_getOddsLabel(order.odds)}). Want to take the other side?`, url: `https://share.mybe.app/h2h/${player.username}`}).then(() => console.log('shared')).catch((e) => console.log(e))
        }
        return alert('Your browser does not support sharing.  Please switch to a mobile browser')
    }

    if(!order){ return <></> }
    const is_visible = order ? true : false
    const cl = order.market_type === 'FOR_MONEY' ? '$' : 'E'
    const max_value = order.called_amt + order.open_amt

    let vig = (order.open_amt + order.called_amt) * 0.045
    const recent_position:PositionProps | undefined = order?.positions[order.positions.length - 1]
    if(recent_position){
        vig = (recent_position.potential_winnings - recent_position.stake) * 0.045
    }

    let league:LeagueProps | undefined
    if(event){ league = leagues.find(l => l.league_id == event.league_id) }
    return (
        <Modal isVisible={is_visible} style={{ flex:1, margin:0, justifyContent: 'flex-end', alignItems:'center'}} animationIn='slideInUp' animationOut='slideOutDown' animationOutTiming={400}
        onBackdropPress={() => market_clearOrderAlert()} >
            <View style={{ width, backgroundColor:Colors.shades.white, borderTopRightRadius:42, borderTopLeftRadius:42, paddingBottom:35, paddingTop:25, padding:20 }}>
                <View nativeID='order_progress' style={{ backgroundColor:Colors.shades.shade100 }}>
                <View style={{ padding:20 }}>
                    <Text size={22} color={Colors.brand.midnight} textAlign='center' weight='bold' style={{ marginBottom:10 }}>{order.called_amt === 0 ? 'ORDER CREATED': 'ORDER FULFILLED'}</Text>
                    <ProgressBar 
                            max_value={max_value} 
                            current_value={order.called_amt} 
                            fill_color={Colors.brand.midnight} 
                            background_color={Colors.shades.white} 
                            max_label={`Order Amt: ${cl}`} 
                            val_label={`Filled Amt: ${cl}`}
                        />
                    <View nativeID='alert-info'>
                        {order.influencer && !order.tip_type ?
                        <View style={{ padding:10 }}>
                            <Image source={order.influencer.profile_pic} style={{ height:40, width:40, borderRadius:100, alignSelf:'center' }} resizeMode='cover' />
                            <Text size={16} style={{margin:2}} color={Colors.brand.midnight} weight='semibold' textAlign='center'>{order.influencer.first_name} {order.influencer.last_name}</Text>
                            <Text size={14} color={Colors.brand.slate} weight='regular' textAlign='center'>@{order.influencer.username}</Text>
                            <Text size={14} style={{marginTop:5}} color={Colors.brand.midnight} weight='regular' textAlign='center'>Thank {order.influencer.first_name} for the assist on the bet you’ve just {order.influence_type==='copy'?'copied':'faded'} by tipping the vig saved!</Text>
                            <TouchableOpacity style={{ marginTop:15, borderRadius: 22, backgroundColor:Colors.highlights.highlight400, justifyContent:'center'}} onPress={() => handleTip(vig)}>
                                <Text size={16} textAlign='center' color={Colors.shades.white} weight='semibold' style={{ padding:10, paddingRight:20, paddingLeft:20}}>Tip {4.5}% of winnings</Text>
                            </TouchableOpacity>
                        </View>
                        : order.h2h_id && order.h2h_user ?
                            <View style={{ padding:10, paddingLeft:0, paddingRight:0 }}>
                                <View nativeID='head_to_head_header' style={{ flexDirection:'row', padding:10, backgroundColor:Colors.shades.shade100 }}>
                                    <View nativeID='me' style={{ flex:1, alignItems:'flex-end', marginRight:2, opacity:0.5 }}>
                                        <Image
                                            source={{ uri: player.profile_pic && player.profile_pic != '' ? player.profile_pic : require('../assets/images/default-avatar.png') }}
                                            style={{ width: width * 0.4, height: width * 0.3}}
                                            resizeMode='cover'
                                        />
                                    </View>
                                    <View nativeID='competitor' style={{ flex:1, marginLeft:2, opacity:0.5 }}>
                                        <Image
                                            source={{ uri: order.h2h_user.profile_pic && order.h2h_user.profile_pic != '' ? order.h2h_user.profile_pic : require('../assets/images/default-avatar.png') }}
                                            style={{ width: width * 0.4, height: width * 0.3 }}
                                            resizeMode='cover'
                                        />
                                    </View>
                                    <View style={{ position:'absolute', top:0, bottom:0, left:0, right:0, justifyContent:'center', alignItems:'center' }}>
                                        <View style={{ backgroundColor:Colors.shades.white, borderRadius:100, padding:20, justifyContent:'center', alignItems:'center', ...style.raised }}>
                                            <Battle color={Colors.brand.slate} size={30} />
                                        </View>
                                    </View>
                                </View>
                                <Text size={16} color={Colors.brand.midnight} textAlign='center' weight='bold'>Challenge Sent To {order.h2h_user.username}!</Text>
                                <View style={{ flexDirection:'row', marginTop:15 }}>
                                    <TouchableOpacity style={{ flex:1, borderRadius: 22, borderWidth:1, backgroundColor:Colors.shades.white, borderColor:Colors.brand.electric, justifyContent:'center'}} onPress={() => {
                                        market_clearOrderAlert()
                                        navigate('SocialStack', { screen: 'H2H', initial:false, params: { player_id: order.h2h_id } })
                                    }}>
                                        <Text size={16} textAlign='center' color={Colors.brand.electric} weight='semibold' style={{ padding:10, paddingRight:20, paddingLeft:20}}>History</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ flex:2, borderRadius: 22, backgroundColor:Colors.highlights.highlight400, justifyContent:'center', marginLeft:4}} onPress={() => shareChallenge()}>
                                        <Text size={16} textAlign='center' color={Colors.shades.white} weight='semibold' style={{ padding:10, paddingRight:20, paddingLeft:20}}>Notify Challenger</Text>
                                    </TouchableOpacity>
                                </View>
                                <TouchableOpacity style={{ marginTop:15, backgroundColor:Colors.highlights.highlight200Faded, padding:10, borderRadius:8}} onPress={() => shareChallenge()}>
                                    <Text size={14} color={Colors.shades.black} weight='bold'>{order.h2h_user.username} does not have notifications enabled.</Text>
                                    <View style={{ flexDirection:'row', alignItems:'center', marginTop:5 }}>
                                        <Icon name='alert-circle' type='feather' size={24} color={Colors.shades.black} />
                                        <Text style={{ flex:1, marginLeft:5 }} size={14} color={Colors.shades.black} weight='regular'> Select 'Notify Challenger' to send them a link to accept this challenge!</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                            : order.called_amt == 0 ?
                            <View>
                            {promo && player_referral ?
                            <View style={{ marginTop:15 }}>
                                <View style={{ marginTop:10, padding:10, borderRadius:22, backgroundColor:Colors.shades.shade600  }}>
                                    <Text size={18} color={Colors.brand.midnight} weight='semibold' textAlign='center'>Know anyone that would take the other side?</Text>
                                    <View style={{ marginTop:10 }}>
                                        {promo.type == 'referral_code' ?
                                        <Text size={14} color={Colors.brand.midnight} weight='regular' textAlign='center'>We will spot your friend ${promo.amount} when they use your code! You'll get it too when they deposit!</Text>
                                        : promo.type == 'referral_sweepstakes' ?
                                        <Text size={14} color={Colors.brand.midnight} weight='regular' textAlign='center'>We will spot your friend UP TO $500 when they use your code! You'll get it too when they deposit!</Text>
                                        :promo.type == 'promo_code'?
                                        <Text size={14} color={Colors.brand.midnight} weight='regular' textAlign='center'>We will spot your friend ${promo.amount} when they use your code!</Text>
                                        :<></>}    
                                    </View>
                                    <TouchableOpacity style={{ marginTop:15, borderRadius:22, backgroundColor:Colors.brand.electric, padding:10 }} onPress={() => handleShareCode()}>
                                        <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>INVITE</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        :
                        <View>
                            <Image style={{ flex:1, width:width/1.5, height:width/4, alignSelf:'center' }} resizeMode='contain' source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1640217090/h9_media_jqp3xp.webp' }} />
                            <Text size={16} color={Colors.brand.midnight} textAlign='center' weight='bold'>Awaiting Fulfillment</Text>
                            <Text size={14} color={Colors.brand.midnight} textAlign='center' weight='regular' style={{marginTop:10, marginLeft:20, marginRight:20}}>Your order is available to be fulfilled in the marketplace.  You will be notified when this happens</Text>
                            <TouchableOpacity style={{ marginTop:15, borderRadius: 22, backgroundColor:Colors.highlights.highlight400, justifyContent:'center'}} onPress={() => market_clearOrderAlert()}>
                                <Text size={16} textAlign='center' color={Colors.shades.white} weight='semibold' style={{ padding:10, paddingRight:20, paddingLeft:20}}>Manage My Open Orders</Text>
                            </TouchableOpacity>
                        </View>
                            }
                        </View>
                        : 
                        <View>
                            {charities[0] ?
                            <View nativeID='charity' style={{ backgroundColor:Colors.shades.shade100, borderRadius:4, padding:10, marginTop:5 }}>
                                <Image style={{ width:width/3.3, height:width/3.3, alignSelf:'center' }} resizeMode='contain' source={{ uri: charities[0]?.charity_image }} />
                                <Text size={16} color={Colors.brand.midnight} textAlign='center' weight='bold'>🎉   HELP US GIVE BACK!   🎉</Text>
                                <Text size={14} color={Colors.brand.midnight} textAlign='center' weight='regular' style={{marginTop:10, marginLeft:20, marginRight:20}}>You can donate your saved vig to our chosen Charity, {charities[0].charity_name}</Text>
                                <View style={{ flexDirection:'row', alignItems:'center', marginTop:15 }}>
                                    <TouchableOpacity style={{ flex:1, marginRight:4, borderRadius: 22, borderColor:Colors.brand.midnight, borderWidth:1, justifyContent:'center'}} onPress={() => window.open(charities[0].charity_link, '_blank')}>
                                        <Text size={16} textAlign='center' color={Colors.brand.midnight} weight='semibold' style={{ padding:10, paddingRight:20, paddingLeft:20}}>Check It Out</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{  flex:1, marginLeft:4, borderRadius: 22, backgroundColor:Colors.highlights.highlight400, justifyContent:'center'}} onPress={() => handleDonation(vig)}>
                                        <Text size={16} textAlign='center' color={Colors.shades.white} weight='semibold' style={{ padding:10, paddingRight:20, paddingLeft:20}}>Donate ${vig?.toFixed(2)}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            :<></>}
                        </View>
                        }
                    </View>
                </View>
                </View>
                <View nativeID='order_details' style={{ paddingTop:5 }}>
                    {order.event_type === 'team' && event ?
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', padding:5 }}>
                        <Text style={{flex:1/4}} size={16} color={Colors.brand.midnight} weight='regular'>Event</Text>
                        <Text style={{flex:3/4}} size={16} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{league?.league_name}: {event.event_title}</Text>
                    </View>
                    :<></>}
                    {order.event_type === 'tournament' && tournament ?
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', padding:5 }}>
                        <Text style={{flex:1}} size={16} color={Colors.brand.midnight} weight='regular'>Event</Text>
                        <Text style={{flex:1}} size={16} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{tournament?.tournament_name}</Text>
                    </View>
                    :<></>}
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', padding:5 }}>
                        <Text style={{flex:1/3}} size={16} color={Colors.brand.midnight} weight='regular'>Outcome</Text>
                        <Text style={{flex:2/3}} size={16} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{order.title}</Text>
                    </View>
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', padding:5 }}>
                        <Text style={{flex:1}} size={16} color={Colors.brand.midnight} weight='regular'>{order.buy_sell_ind === 'buy' ? 'Fulfill Amount': 'Cash Out'}</Text>
                        <Text style={{flex:1}} size={16} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{cl}{order.called_amt?.toFixed(2)}</Text>
                    </View>
                    {recent_position ?
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', padding:5 }}>
                        <Text style={{flex:1}} size={16} color={Colors.brand.midnight} weight='regular'>Price</Text>
                        <Text style={{flex:1}} size={16} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{(recent_position.probability*100).toFixed(2)}% ({market_getOddsLabel(recent_position?.odds, 2)})</Text>
                    </View>
                    :
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', padding:5 }}>
                        <Text style={{flex:1}} size={16} color={Colors.brand.midnight} weight='regular'>Offer Price</Text>
                        <Text style={{flex:1}} size={16} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{(order.probability*100).toFixed(2)}% ({market_getOddsLabel(order.odds, 2)})</Text>
                    </View>
                    }
                    {order.buy_sell_ind === 'buy' && recent_position ?
                    <View style={{ flex:1, flexDirection:'row', alignItems:'center', padding:5 }}>
                        <Text style={{flex:1}} size={16} color={Colors.brand.midnight} weight='regular'>Potential Winnings</Text>
                        <Text style={{flex:1}} size={16} color={Colors.brand.midnight} weight='semibold' textAlign='right'>{cl}{recent_position.potential_winnings.toFixed(2)}</Text>
                    </View>
                    : 
                    <></>
                    }
                    
                </View>
                <View nativeID='order_alert_actions' style={{flex:1, flexDirection:'row', alignItems:'center'}}>
                    <TouchableOpacity style={{ flex:1, margin:10, borderRadius:22, borderWidth:1, borderColor:Colors.brand.midnight, padding:12 }} onPress={() => market_clearOrderAlert()}>
                        <Text size={16} textAlign='center' color={Colors.brand.midnight} weight='semibold'>CLOSE</Text>
                    </TouchableOpacity>
                    {order.called_amt > 0 ?
                    <TouchableOpacity style={{ flex:1, flexDirection:'row', alignItems:'center', justifyContent:'center', margin:10, borderRadius:22, backgroundColor:Colors.brand.midnight, padding:12 }} onPress={() => handleShare()}>
                        <Text size={16} textAlign='center' color={Colors.shades.white} weight='semibold'>SHARE</Text>
                        <Icon name='send' type='feather' size={16} color={Colors.shades.white} style={{ marginLeft:5 }}/>
                    </TouchableOpacity>
                    :<></>}
                </View>
            </View>
        </Modal>
    )
}

const style = StyleSheet.create({
    raised: { shadowColor: "#000", shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5 }
})

const areEqual = (prevProps:OrderAlertModalProps, nextProps:OrderAlertModalProps) => {
    if(prevProps.order !== nextProps.order){ return false }
    if(prevProps.width != nextProps.width){ return false }
    if(prevProps.player_id != nextProps.player_id){ return false }
    if(prevProps.promo?.promo_id != nextProps.promo?.promo_id){ return false }
    if(prevProps.player_referral?.player_referral_id != nextProps.player_referral?.player_referral_id){ return false }
    if(prevProps.charities.length !== nextProps.charities.length){ return false }
    if(prevProps.event !== nextProps.event){ return false }
    return true
}

const mapStateToProps = (state:any, ownProps:OrderAlertModalProps) => {
    return {
        player_id: state.player.player?.player_id,
        player: state.player.player,
        order: state.player.alert_order,
        charities: state.player.charities,
        leagues: state.event.leagues,
        player_referral: state.player.player_referral,
        promo: state.player.promo,
        event: state.event.events[state.player.alert_order?.event_id],
        tournament: state.event.tournaments[state.player.alert_order?.event_id]
    }
}

const mapDispatchToProps = (dispatch:any) => {
    return {
        market_clearOrderAlert: () => dispatch(market_clearOrderAlert()),
        player_getCharities: () => dispatch(player_getCharities()),
        player_getReferralByPlayerId: (player_id:string) => dispatch(player_getReferralByPlayerId(player_id)),
        market_tipOrder: (order_id:string, tip_pct:number, tip_type:string) => dispatch(market_tipOrder(order_id, tip_pct, tip_type)),
        player_donateToCharity: (charity_donation:CharityDonationProps) => dispatch(player_donateToCharity(charity_donation))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(OrderAlertModal, areEqual))