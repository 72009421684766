import React, { useEffect, useState } from 'react';
import { FlatList, Image, ScrollView, Touchable, View } from "react-native"
import { useSelector } from 'react-redux';
import { OrderProps } from '../store/types/mk_svc';
import { market_getUnreviewedOrders, market_reviewOrders } from '../store/actionsv1/markets';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import Colors from '../constants/Colorsv2';
import OrderCard from './OrderCard';
import { order_getAllOrderStatsFromOrders } from '../screensV1/Home/helpers/orders';
import { Icon } from 'react-native-elements';
import { navigate } from '../navigation/RootNavigation';


type OrderReviewCardProps = {
    width:number,
    height:number,
}

const MAX_WIDTH = 500

const OrderReviewCard = ({ width, height }:OrderReviewCardProps) => {
    const [ orders, setOrders ] = useState<OrderProps[]>([])
    const [ visible_order, setVisibleOrder ] = useState<OrderProps | undefined>(undefined);
    const is_authenticated = useSelector((state:any) => state.auth.isAuthenticated, (left, right) => left == right);
    const socket_connected = useSelector((state:any) => state.util.socket_connected, (left, right) => left == right);

    const view_width = width > MAX_WIDTH ? MAX_WIDTH : width
    
    useEffect(() => {
        if(!is_authenticated){ return }
        if(!socket_connected){ return }
        getUnreviewedOrdersFromServer()
    },[is_authenticated, socket_connected])

    useEffect(() => {
        if(orders.length == 0 || visible_order){ return }
        let winning_order = orders.find(o => o.positions[0] && o.positions.find(p => p.result_ind == 'win'))
        if(winning_order){ setVisibleOrder(winning_order) }
        else { setVisibleOrder(orders[0]) }
    },[orders])

    const getUnreviewedOrdersFromServer = async() => {
        const unreviewed_orders = await market_getUnreviewedOrders()
        setOrders(unreviewed_orders)
    }

    const handleClose = async() => {
        setOrders([])
        await market_reviewOrders(orders.map(o => o.order_id))
    }

    const handleShare = async() => {
        navigate('SocialStack', { screen: 'Shareable', initial:false })
        handleClose()
    }

    const handlePrevious = () => {
        let index = orders.findIndex(o => o.order_id == visible_order?.order_id)
        if(index < 0){ return } //Dont do anything
        let new_order = orders[index - 1]
        if(!new_order){ new_order = orders[orders.length - 1] }
        setVisibleOrder(new_order)
    }

    const handleNext = () => {
        let index = orders.findIndex(o => o.order_id == visible_order?.order_id)
        if(index < 0){ return } //Dont do anything
        let new_order = orders[index + 1]
        if(!new_order){ new_order = orders[0] }
        setVisibleOrder(new_order)
    }

    const getColor = (result_ind?:string) => {
        switch(result_ind){
            case 'win':
                return Colors.utility.success
            case 'lose':
                return Colors.utility.error
            case 'draw':
                return Colors.brand.midnight
            default:
                return Colors.shades.shade600
        }
    }

    const renderOrders = (data:{item:OrderProps, index:number}) => {
        const result = data.item.positions[0]?.result_ind
        if(!result){ return <></> }
        const active = visible_order?.order_id == data.item.order_id ? true : false
        return (
            <TouchableOpacity 
                style={{ margin:5, height: 12, width:12, borderRadius:100, backgroundColor:getColor(result), opacity:active?1:0.5 }} 
                onPress={() => setVisibleOrder(data.item)} 
            />
        )
    }

    //Do not show if the user is not authenticated
    if(!is_authenticated || orders.length == 0 || !visible_order){ return <></> }
    const order_stats = order_getAllOrderStatsFromOrders(orders.filter(o => o.market_type == 'FOR_MONEY'))

    return (
        <View style={{ position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
            <View style={{ borderRadius:8, backgroundColor:Colors.shades.shade100, padding:10, width:view_width-20, height: height * 0.9 }}>
                <View style={{ padding:10 }}>
                    <Text size={24} color={Colors.brand.midnight} textAlign='center' weight='bold'>Welcome Back!</Text>
                    <Text style={{ marginTop:3 }} size={16} color={Colors.brand.midnight} weight='regular' textAlign='center'>Check out what's happened since you've been gone</Text>
                </View>
                <View style={{ width:view_width - 40, height:1, marginBottom:10, borderWidth:1, borderColor:Colors.shades.shade600, alignSelf:'center' }} />
                <View style={{ flexDirection:'row', padding:10, borderRadius:8, backgroundColor:Colors.shades.shade600 }}>
                    <View nativeID='orders_resolved' style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                        <Text size={14}>Orders Settled</Text>
                        <Text style={{ marginTop:4 }} size={20} weight='bold'>{orders.length}</Text>
                    </View>
                    <View nativeID='orders_resolved' style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                        <Text size={14}>Earnings</Text>
                        <Text style={{ marginTop:4 }} size={20} weight='bold'>${order_stats.earnings.toFixed(2)}</Text>
                    </View>
                    <View nativeID='orders_resolved' style={{ flex:1, justifyContent:'center', alignItems:'center' }}>
                        <Text size={14}>ROI</Text>
                        <Text style={{ marginTop:4 }} size={20} weight='bold'>{(order_stats.roi * 100).toFixed(2)}%</Text>
                    </View>
                </View>
                <View style={{ flexDirection:'row', margin:10, alignItems:'center' }}>
                    <TouchableOpacity 
                        style={{ height:30, width:30, borderRadius:100, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.shade600 }}
                        onPress={() => handlePrevious()}>
                        <Icon type='feather' name='chevron-left' size={14} color={Colors.brand.midnight} />
                    </TouchableOpacity>
                    <View style={{ flex:1, justifyContent:'center', alignItems:'center', overflow:'hidden' }}>
                        <FlatList
                            data={orders}
                            renderItem={renderOrders}
                            horizontal
                            showsHorizontalScrollIndicator={false}
                        />
                    </View>
                    <TouchableOpacity 
                        style={{ height:30, width:30, borderRadius:100, justifyContent:'center', alignItems:'center', backgroundColor:Colors.shades.shade600 }}
                        onPress={() => handleNext()}>
                        <Icon type='feather' name='chevron-right' size={14} color={Colors.brand.midnight} />
                    </TouchableOpacity>
                </View>
                    <ScrollView style={{ flex:1 }}>
                    {visible_order ?
                    <View nativeID='visible_order'>
                        <OrderCard
                            order={visible_order}
                            view_mode='share'
                        />
                    </View>
                    :<></>}
                    </ScrollView>
                {order_stats.roi > 0 ?
                <TouchableOpacity style={{ margin:15, marginTop:30 }}
                onPress={() => {
                    navigate('DiscoverStack', { screen: 'Store', initial:false, params: { catalog_id: 1 } })
                    handleClose()
                }}
                >
                    <Image
                        source={{ uri: 'https://res.cloudinary.com/hoabts6mc/image/upload/v1698695580/BettorEdgeMerch_HatShirt_DiscoverAd_3UP_ezaadj.png' }}
                        style={{ width:view_width - 80, height: (view_width - 80) / 2.5, alignSelf:'center', marginTop:5 }}
                        resizeMode='cover'
                    />
                    <View style={{ position:'absolute', top:-15, left:0, right:0, justifyContent:'center', alignItems:'center'}}>
                        <View style={{ padding:10, backgroundColor:Colors.brand.cyan, borderRadius:8  }}>
                            <Text size={14} color={Colors.shades.white} textAlign='center' weight='bold'>You earned ${order_stats.earnings.toFixed(2)}! Treat yourself!</Text>
                        </View>
                    </View>
                </TouchableOpacity>
                :<></>}
                <View nativeID='action_row' style={{ flexDirection:'row' }}>
                    <TouchableOpacity style={{ flex:1, marginRight:3, padding:10, borderWidth:1, borderColor:Colors.brand.electric, borderRadius:8 }} onPress={() => handleClose()}>
                        <Text size={14} color={Colors.brand.electric} textAlign='center'>CLOSE</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ flex:2, flexDirection:'row', alignItems:'center', justifyContent:'center', marginLeft:3, padding:10, borderWidth:1, backgroundColor:Colors.brand.midnight, borderRadius:8 }}
                    onPress={() => handleShare()}>
                        <Icon name='share' type='feather' color={Colors.shades.white} size={14}/>
                        <Text style={{ marginLeft:10 }} size={14} color={Colors.shades.white} textAlign='center'>SHARE</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}

export default OrderReviewCard