import React, { useState } from 'react';
import { View } from 'react-native';
import Colors from '../constants/Colorsv2';
import { Text } from '../globalComponents/NativeOverrides';
type ProgressBarProps = { hide_labels?:boolean, height?:number, max_value:number, current_value:number, background_color:string, fill_color:string, max_label:string, val_label:string }
const ProgressBar = ({ max_value, current_value, height, hide_labels, background_color, fill_color, max_label, val_label }:ProgressBarProps) => {
    const [ progressSize, setProgressSize ] = useState({ x:0, y:0, width:0, height:0 })

    const getProgressWidth = (current_value:number) => {
        let progress = current_value / max_value
        if(progress > 1){ progress = 1 }
        return progressSize.width * progress
    }

    return (
        <View>
            <View style={{ flex:1, flexDirection:'row' }}>
                <View style={{ flex:1, padding:height??8, backgroundColor:background_color??Colors.shades.white, borderRadius:4 }} onLayout={(ev) => {
                const { x, y, width, height } = ev.nativeEvent.layout
                setProgressSize({ x, y, width, height })
            }}>
                    <View style={{ borderRadius:4, position:'absolute', backgroundColor:fill_color??Colors.brand.midnight, left:0, top:0, width:getProgressWidth(current_value??0), height:progressSize.height }} />
                </View>
            </View>
            {!hide_labels ?
            <View style={{ flexDirection:'row', justifyContent:'space-between', alignItems:'center', marginTop:4}}>
                <Text size={12} color={Colors.brand.midnight}>{val_label}{current_value?.toFixed(2)}</Text>
                <Text size={12} color={Colors.brand.midnight}>{max_label}{max_value?.toFixed(2)}</Text>
            </View>
            :<></>}
        </View>
        
    )
}

export default ProgressBar