import Chart from 'chart.js';
import Colors from '../../constants/Colorsv2';

export const chartModifier = (chartjs:any) => {
    chartjs.pluginService.register({
        afterUpdate: function (chart:any) {
            switch(chart.config.type){
                case 'doughnut':
                    return modifyDoughnutShapeAfterUpdate(chart)
                default: return
            }
        },

        afterDraw: function (chart:any) {
            switch(chart.config.type){
                case 'doughnut':
                    return modifyDoughnutShapeAfterDraw(chart)
                default: return
            }
        },
    });

    // write text plugin
    chartjs.pluginService.register({
        afterUpdate: function (chart:any) {
            switch(chart.config.type){
                case 'doughnut':
                    return modifyDoughnutTextAfterUpdate(chart)
                default: return
            } 
        },
        afterDraw: function (chart:any) {
            switch(chart.config.type){
                case 'doughnut':
                    return modifyDoughnutTextAfterDraw(chart)
                default: return
            }
        }
    })
}



export const renderDoughnutDataAndOptions = (value:number, max_value:number, primary_color:string, faded_color:string, value_description:string, label_type:string, label:string) => {
    const data = {
        labels: [
            "ROI",
            "MAX"
        ],
        datasets: [{
            data: [value, max_value],
            backgroundColor: [
                primary_color,
                faded_color
            ],
            hoverBackgroundColor: [
                primary_color,
                faded_color
            ]
        }]
    }

    const options = {
        maintainAspectRatio: false,
        responive: true,
        cutoutPercentage: 75,
        elements: {
            center: {
                // the longest text that could appear in the center
                maxText: '100',
                alignItems:'center',
                text: label_type==='pct'?`${value.toFixed(2)}%`:`${label}${value.toFixed(2)}`,
                fontColor: Colors.brand.midnight,
                fontFamily: "dinpro-bold",
                fontStyle: 'normal',
                fontSize: 24,
            },
            subHeader: {
                maxText: '100',
                alignItems:'center',
                text: value_description,
                fontColor: Colors.shades.shade400,
                fontFamily: "dinpro-regular",
                fontStyle: 'normal',
                fontSize: 16,
            }
            
        },
        legend : false,
    }

    return { data, options }
}



const modifyDoughnutShapeAfterUpdate = (chart:any) => {
    var arc = chart.getDatasetMeta(0).data[0]
    var a = chart.config.data.datasets.length -1;
    arc.round = {
        x: (chart.chartArea.left + chart.chartArea.right) / 2,
        y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
        radius: chart.innerRadius + chart.radiusLength / 2 + (a * chart.radiusLength),
        thickness: chart.radiusLength / 2 - 1,
        backgroundColor: arc._model.backgroundColor
    }
    /*
    var a=chart.config.data.datasets.length -1;
    for (let i in chart.config.data.datasets) {
        for(var j = chart.config.data.datasets[i].data.length - 1; j>= 0;--j) { 
            if (Number(j) == (chart.config.data.datasets[i].data.length - 1))
                continue;
            var arc = chart.getDatasetMeta(i).data[j];
            
        }
        a--;
    }
    */

    
}



const modifyDoughnutShapeAfterDraw = (chart:any) => {
    var ctx = chart.chart.ctx;
    for (let i in chart.config.data.datasets) {
        for(var j = chart.config.data.datasets[i].data.length - 1; j>= 0;--j) { 
            if (Number(j) == (chart.config.data.datasets[i].data.length - 1))
                continue;
            var arc = chart.getDatasetMeta(i).data[j];
            var startAngle = Math.PI / 2 - arc._view.startAngle;
            var endAngle = Math.PI / 2 - arc._view.endAngle;
            if(arc.round){
                ctx.save();
                ctx.translate(arc.round.x, arc.round.y);
                ctx.fillStyle = arc.round.backgroundColor;
                ctx.beginPath();
                ctx.arc(arc.round.radius * Math.sin(startAngle), arc.round.radius * Math.cos(startAngle), arc.round.thickness, 0, 2 * Math.PI);
                ctx.arc(arc.round.radius * Math.sin(endAngle), arc.round.radius * Math.cos(endAngle), arc.round.thickness, 0, 2 * Math.PI);
                ctx.closePath();
                ctx.fill();
                ctx.restore();
            }
            
        }
    }
} 


const modifyDoughnutTextAfterUpdate = (chart:any) => {
    if (chart.config.options.elements.center) {
        var helpers = Chart.helpers;
        var centerConfig = chart.config.options.elements.center;
        const subHeaderConfig = chart.config.options.elements.subHeader

        var globalConfig = Chart.defaults.global;    
        var fontStyle = helpers.getValueOrDefault(centerConfig.fontStyle, globalConfig.defaultFontStyle);
        var fontFamily = helpers.getValueOrDefault(centerConfig.fontFamily, globalConfig.defaultFontFamily);
        var fontSize = centerConfig?.fontSize??10
        
        var subFontSize = subHeaderConfig?.fontSize??10
        var subFontFamily = helpers.getValueOrDefault(subHeaderConfig?.fontFamily, globalConfig.defaultFontFamily);
        var subFontStyle = helpers.getValueOrDefault(subHeaderConfig?.fontStyle, globalConfig.defaultFontStyle);

        // save properties
        chart.center = {
            font: helpers.fontString(fontSize, fontStyle, fontFamily),
            subFont: helpers.fontString(subFontSize, subFontStyle, subFontFamily),
            fillStyle: helpers.getValueOrDefault(centerConfig.fontColor, globalConfig.defaultFontColor),
            subFillStyle: helpers.getValueOrDefault(subHeaderConfig?.fontColor, globalConfig.defaultFontColor)
        };
    }
}

const modifyDoughnutTextAfterDraw = (chart:any) => {
    if (chart.center) {
        var helpers = Chart.helpers;
        var centerConfig = chart.config.options.elements.center;
        var ctx = chart.chart.ctx;
        var lineHeight = centerConfig.lineHeight || 24;
        ctx.save();
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
        
        //Fill text for the header
        ctx.font = chart.center.font;
        ctx.fillStyle = chart.center.fillStyle;
        
        const subHeaderConfig = chart.config.options.elements.subHeader
        if(!subHeaderConfig){ 
            ctx.fillText(centerConfig.text, centerX, centerY);
            return ctx.restore() 
        }
        
        //There is a subheader! -- Push update the center
        centerY -= lineHeight / 2;
        ctx.fillText(centerConfig.text, centerX, centerY);

        //Change the font for the subheader
        ctx.font = chart.center.subFont
        centerY += lineHeight;
        ctx.fillStyle = chart.center.subFillStyle
        ctx.fillText(subHeaderConfig.text, centerX, centerY);

        return ctx.restore()
    }
    
}