
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import getEnvVars from '../../env';
import { V1_AUTHENTICATE_APP, V1_LOAD_ALERT, V1_LOAD_APP_ALERTS, V1_LOAD_INIT_NOTIFICATION, V1_LOAD_LOCATION, V1_LOAD_NOTIFICATION_ID, V1_LOAD_PLAYER_PREMIUM_ITEMS, V1_LOGIN, V1_LOGOUT, V1_OBSERVE_APP, V1_UPDATE_ACCOUNT, V1_UPDATE_MARKET_TYPE, V1_UPDATE_ME, V1_UPDATE_PLAYER_NOTIFICATION_COUNT } from './types';
import { socket_authenticate } from './socket';
import { setAuthorizationToken } from '../../screensV1/Auth/helpers/security';
import { BlackBookEntryProps, CreateAccountProps, LocationProps } from '../types/auth_svc';
import { getPlayerRegion } from '../../utils/global/location';
import { updateNotificationBadge } from '../../utils/notifications/webNotification';
import { player_getPushSubsByPlayerId } from './player';
import { errHandler } from '../errHandler';
import { APIOverrides, BEEventApi } from 'be-components';
const { AUTH_SVC_API } = getEnvVars()



export const login = (credentials: { username:string, password:string }) => {
    return async(dispatch:any) => {
        try {
            const device_id = await AsyncStorage.getItem(`dvcid`)
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/authenticate`, { ...credentials, device_id })
            const { access_token, refresh_token, expire_datetime } = resp.data;
            //auth_authenticateApp(access_token, device_id)
            dispatch({ type: V1_LOGIN, access_token, refresh_token, expire_datetime })
            dispatch(auth_getMe(device_id))
        } catch (e) {
            console.log('DONE HWERWEW')
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Invalid Credentials`, type:'error' } })
            console.log(e)
        }
    }
}

export const auth_agreeToTerms = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/terms/agree`)
            dispatch({ type: V1_UPDATE_ME, player: resp.data.player })
        } catch (e) {
            console.log(e)
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Unable to process`, type:'error' } })

        }
    }
}

export const auth_loginShortToken = (short_token:string) => {
    return async(dispatch:any, getState:any) => {
        try {
            const { distinct_id } = getState().util.analytic_tokens;
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/tokens/short/process`, { short_token, device_id:distinct_id })
            const { player_id, access_token, refresh_token, expire_datetime } = resp.data;
            await APIOverrides.authenticateApp({ access_token, refresh_token, expire_datetime, player_id, authenticated:true }, { cache:true })
            dispatch(auth_authenticateApp({ access_token, refresh_token, expire_datetime, player_id, authenticated:true }))
            socket_authenticate(access_token, distinct_id)
        } catch (e) {
            console.log('ERROR!')
            console.log(e)
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Invalid Token`, type:'error' } })
            console.log(e)
        }
    }
}

export const auth_getMe = (device_id?:string|null) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/player/me`)
            
            if(device_id){
                dispatch(player_getPushSubsByPlayerId(resp.data.player.player_id, device_id))
            }
            BEEventApi.identify(resp.data.player.player_id)
            dispatch({ type: V1_UPDATE_ME, player: resp.data.player })
            dispatch({ type: V1_LOAD_PLAYER_PREMIUM_ITEMS, player_premium_items:resp.data.player_premium_items })
        } catch (e) {
            dispatch(auth_logout())
            console.log(e)
        }
    }
}

export const auth_phoneAuth = async(phone:string, device_id:string, referral_code?:string, silent?:boolean):Promise<string> => {
    try {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/phone/authenticate`, { phone, device_id, referral_code, silent })
        return resp.data.player_id
    } catch (e) {
        errHandler(e)
        throw new Error('Unable to process')
    }
}

export const auth_phoneAuthVerify = (token:string, device_id:string) => {
    return async(dispatch:any) => {
        try {
            let resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/phone/verify`, { token, device_id })
            const { access_token, expire_datetime, refresh_token } = resp.data
            //auth_authenticateApp(access_token, device_id)
            //const player = jwtDecode(access_token)
            
            dispatch({ type: V1_LOGIN, access_token, refresh_token, expire_datetime })
            dispatch(auth_getMe(device_id))
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`You are verified! Thank you.`, type:'success' } })
        } catch (e) {
            errHandler(e)
        }
    }
}


export const auth_authenticateApp = (auth_tokens:any) => {
    return (dispatch:any, getState:any) => {
        dispatch({ type:V1_AUTHENTICATE_APP, auth_tokens: { ...auth_tokens } });
        const { distinct_id } = getState().util.analytic_tokens;
        dispatch(auth_getMe(distinct_id))
    }
}

export const auth_observeApp = (analytic_tokens:any) => {
    return (dispatch:any) => {
        dispatch({ type:V1_OBSERVE_APP, analytic_tokens: { ...analytic_tokens } })
    }
}

export const auth_logoutApp = () => {
    return (dispatch:any) => {
        dispatch({ type: V1_LOGOUT })
        APIOverrides.logoutApp();
        
        dispatch({ type: V1_LOAD_ALERT, alert: { title:`Successfully logged out`, type:'success' } })
    }
}

export const auth_logout = () => {
    return (dispatch:any, getState:any) => {
        dispatch({ type: V1_LOGOUT })
        APIOverrides.logoutApp();
        let app_tokens = getState().util.app_tokens
        dispatch({ type: V1_AUTHENTICATE_APP, auth_tokens: { ...app_tokens, access_token:undefined, refresh_token:undefined, player_id:undefined } })
        dispatch({ type: V1_LOAD_ALERT, alert: { title:`Successfully logged out`, type:'success' } })
    }
}

export const auth_getAppAlerts = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/alerts/active`)
            dispatch({ type: V1_LOAD_APP_ALERTS, app_alerts:resp.data.app_alerts })
        } catch (e) {
            console.log(e)
        }
    }
}



export const auth_getNotificationCount = () => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/notifications/count`)
            let count = parseInt(resp.data.player_notification_count)
            if(isNaN(count)){ count = 0 }
            updateNotificationBadge(count)
            dispatch({ type: V1_UPDATE_PLAYER_NOTIFICATION_COUNT, player_notification_count: count })
        } catch (e) {
            console.log(e)
        }
    }
}

export const updateAccount = (account:CreateAccountProps) => {
    return (dispatch:any) => {
        dispatch({ type: V1_UPDATE_ACCOUNT, account })
    }
}




export const getLocations = async():Promise<LocationProps[]> => {
    try {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/locations/all`)
        return resp.data.locations
    } catch (e) {
        console.log(e)
        return []
    }
}

export const auth_changeMarketType = () => {
    return async(dispatch:any, getState:any) => {
        try {
            let market_type = getState().auth.market_type
            if(market_type == 'FOR_MONEY'){
                dispatch({ type: V1_UPDATE_MARKET_TYPE, market_type: 'FREE' })
                return
            }
            let location = getState().util.location
            if(!location.legal_ind){
                console.log('in here!')
                dispatch({ type: V1_LOAD_LOCATION, ...location, show:true })
                return
            }
            dispatch({ type: V1_UPDATE_MARKET_TYPE, market_type:'FOR_MONEY' })

        } catch (e) {
            console.log(e);
            return
        }
    }
}

/**
 * Checks for an existing black book entry for the device id
 * @param device_id 
 * @returns 
 */
export const auth_getBlackBookByDeviceId = async(device_id:string):Promise<BlackBookEntryProps | undefined> => {
    try {
        const resp = await axios.get(`${AUTH_SVC_API}/v1/players/book/device/${device_id}`)
        return resp.data.black_book_entry
    } catch (error) {
        return undefined
    }
}

/**
 * Create a new blackbook entry and no dispatch.  Returns directly to the function
 * @param black_book_entry 
 * @returns 
 */
export const auth_createBlackBookEntry = async(black_book_entry:BlackBookEntryProps):Promise<BlackBookEntryProps | undefined> => {
    try {
        const resp = await axios.post(`${AUTH_SVC_API}/v1/players/book/create`, { black_book_entry })
        return resp.data.black_book_entry
    } catch (e) {
        return undefined
    }
}


//V1 API
export const player_VerifyAttribute = (attribute:string, attribute_value:string) => {
    return async() => {
        try {
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/attribute/valid`, { attribute, attribute_value })
            if(!resp.data.valid){ throw new Error(`${attribute} cannot be used`) }
        } catch (e) {
            console.log(e)
            throw new Error(e.message)
        }
    }
}


export const auth_createPlayer = (player:CreateAccountProps) => {
    return async(dispatch:any) => {
        try {
            const device_id = await AsyncStorage.getItem(`dvcid`)
            const location = await getPlayerRegion()
            if(!location){ return alert('Unable to gather location.  Please enable location before continuing') }
            const resp = await axios.post(`${AUTH_SVC_API}/v1/players/player/create`, { player: { ...player, home_location: location??'TBD' }, device_id, repassword: player.rePassword, referral_code:player.referral_code })
            const { access_token, refresh_token, expire_datetime } = resp.data;
            //auth_authenticateApp(access_token, device_id)
            dispatch({ type: V1_LOGIN, access_token, refresh_token, expire_datetime })
            dispatch(auth_getMe(device_id))
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Welcome ${player.first_name}!`, type:'success', body: 'Thank you for joining BettorEdge!' } })
        } catch (e) {
            dispatch({ type: V1_LOAD_ALERT, alert: { title:`Error`, type:'error', body: 'We are sorry, but we cannot create your account at this time' } })
            throw new Error('Unable to create account')
        }
    }
}


//HELP
export const auth_getHelpByNativeId = (native_id:string) => {
    return async(dispatch:any) => {
        try {
            console.log(`getting help!!!`)
        } catch (e) {
            console.log(e)
        }
    }
}


export const auth_setNotification = (notification_id?:string) => {
    return (dispatch:any) => {
        dispatch({ type: V1_LOAD_NOTIFICATION_ID, notification_id: notification_id })
    }
}



export const auth_getNotificationById = (notification_id:string) => {
    return async(dispatch:any) => {
        try {
            const resp = await axios.get(`${AUTH_SVC_API}/v1/players/notifications/notification/${notification_id}`)
            dispatch({ type: V1_LOAD_INIT_NOTIFICATION, player_notification: resp.data.player_notification })
        } catch (e) {
            console.log(e)
        }
    }
}

export const auth_clearNotification = () => {
    return (dispatch:any) => {
        console.log('moving it')
        dispatch({ type: V1_LOAD_INIT_NOTIFICATION, player_notification:undefined })
    }
}