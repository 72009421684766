import React, { useEffect, useState } from 'react';
import { ActivityIndicator, FlatList, TextInput, View } from "react-native"
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../../../constants/Colorsv2';
import { Text, TouchableOpacity } from '../../../globalComponents/NativeOverrides';
import { event_getAthleteByAthleteId, event_getEventByEventId, event_getMatchByMatchId, event_getTeamByTeamId, event_getTournamentByTournamentId } from '../../../store/actionsv1/events';
import { market_resolveMarket, market_updateMarketResolve } from '../../../store/actionsv1/markets';
import { PublicPlayerProps } from '../../../store/types/auth_svc';
import { MarketProps, MarketResolveProps, MarketSideOptionProps } from '../../../store/types/mk_svc';
import { AthleteProps, EventProps, MatchProps, TeamProps, TournamentProps } from '../../../store/types/sr_svc';
import Switch from '../../Social/components/Switch';



const MarketResolveForm = () => {
    const [ loading, setLoading ] = useState(false);
    const [ selected_side, setSelectedSide ] = useState<string | undefined>(undefined)

    const dispatch = useDispatch();
    const market_resolve:MarketResolveProps | undefined = useSelector((state:any) => state.market.market_resolve, (left, right) => left?.updated == right?.updated)

    let player:PublicPlayerProps | undefined = useSelector((state:any) => state.player.player, (left, right) => left?.player_id == right?.player_id);
    let event:EventProps | undefined = useSelector((state:any) => state.event.events[market_resolve?.event_id??''], (left, right) => left?.event_id == right?.event_id);

    let tournament:TournamentProps |undefined = useSelector((state:any) => state.event.tournaments[market_resolve?.event_id??''], (left, right) => left?.tournament_id == right?.tournament_id)
    let match:MatchProps | undefined = useSelector((state:any) => state.event.matches[market_resolve?.event_id??''], (left, right) => left?.match_id == right?.match_id)
    const market:MarketProps | undefined = useSelector((state:any) => state.market.markets.find(m => m.market_id == market_resolve?.market_id))

    let side_options:MarketSideOptionProps[] = []
    if(market){
        side_options = market.side_options.concat([{ side: 'draw', label: 'Draw / No Bet', id_source: market.side_options[0].id_source, parseable_title: '' }])
    }

    let athlete:AthleteProps | undefined = useSelector((state:any) => state.event.athletes_list.athletes.find(a => a.athlete_id == market_resolve?.side_id))
    let team:TeamProps | undefined = useSelector((state:any) => state.event.teams_list.teams.find(a => a.team_id == market_resolve?.side_id))

    useEffect(() => {
        if(!market_resolve){ return setSelectedSide(undefined) }
        getOrderDetailsFromServer(market_resolve.event_id)
    },[market_resolve?.updated])

    const getOrderDetailsFromServer = async(event_id:string) => {
        if(!market_resolve){ return }
        if(market_resolve.event_type === 'team' && !event){ await dispatch(event_getEventByEventId(event_id)) }
        if(market_resolve.event_type === 'tournament' && !tournament){ await dispatch(event_getTournamentByTournamentId(event_id)) }
        if(market_resolve.event_type === 'match' && !match){ await dispatch(event_getMatchByMatchId(event_id)) }
        if(market_resolve.side_id && market_resolve.side_type == 'athlete' && !athlete){ await dispatch(event_getAthleteByAthleteId(market_resolve.side_id)) }
        if(market_resolve.side_id && market_resolve.side_type == 'team' && !team){ await dispatch(event_getTeamByTeamId(market_resolve.side_id)) }
    }

    const resolveMarket = async() => {
        if(!market_resolve || !selected_side){ return alert('Must select a side') }
        if(loading){ return alert('Hold your horses cowboy.  Still working on it') }
        setLoading(true);
        await dispatch(market_resolveMarket({ ...market_resolve, winnings_side:selected_side }))
        setLoading(false);
        dispatch(market_updateMarketResolve(undefined))
    }

    const renderSideOptions = (data: { item:MarketSideOptionProps, index:number }) => {
        const selected = data.item.side == selected_side ? true : false
        return (
            <TouchableOpacity style={{ margin:10, padding:10, borderRadius:8, borderWidth:1, backgroundColor:selected?Colors.utility.success:'transparent', borderColor:Colors.shades.shade600 }} onPress={() => setSelectedSide(data.item.side)}>
                <Text size={14} textAlign='center' color={selected?Colors.shades.white:Colors.brand.midnight}>{data.item.label}</Text>
            </TouchableOpacity>
        )
    }

    if(!player){ return ( <></> ) }
    if(!market_resolve){ return ( <></> ) }
    if(!market){ return ( <></> ) }
    return (
        <View style={{ position:'absolute', top:0, right:0, left:0, bottom:0, backgroundColor:Colors.shades.black_faded, justifyContent:'center', alignItems:'center' }}>
            <View nativeID='resolve_form' style={{ borderRadius:8, backgroundColor:Colors.shades.white, padding:10, margin:20 }}>
                <View nativeID='event_info' style={{ padding:10, backgroundColor:Colors.shades.shade100 }}>
                    <Text style={{ padding:10 }} size={18} color={Colors.brand.midnight} textAlign='center' weight='bold'>Event Details</Text>
                    {market_resolve.event_type === 'team' && event ?
                    <View nativeID='event_details' style={{ flexDirection:'row', alignItems:'center', marginTop:5 }}>
                        <Text textAlign='center' size={14} color={Colors.brand.midnight}>{event.event_title}</Text>
                    </View>
                    :<></>}
                    {market_resolve.event_type === 'tournament' && tournament ?
                    <View nativeID='tournament_details' style={{ flexDirection:'row', alignItems:'center', marginTop:5 }}>
                        <Text textAlign='center' style={{ flex:1 }} size={14} color={Colors.brand.midnight}>{tournament.tournament_name}</Text>
                    </View>
                    :<></>}
                    {market_resolve.event_type === 'match' && match ?
                    <View nativeID='match_details' style={{ flexDirection:'row', alignItems:'center', marginTop:5 }}>
                        <Text textAlign='center' style={{ flex:1 }} size={14} color={Colors.brand.midnight}>{match.match_title}</Text>
                    </View>
                    :<></>}
                </View>
                {market_resolve.side_id ?
                <View nativeID='side_info' style={{ marginTop:10, padding:10, backgroundColor:Colors.shades.shade100 }}>
                    {market_resolve.side_type == 'athlete' && athlete ?
                    <View nativeID='athlete_details' style={{ alignItems:'center', marginTop:5 }}>
                        <Text style={{ padding:10 }} size={18} color={Colors.brand.midnight} textAlign='center' weight='bold'>Athlete Details</Text>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight} textAlign='center'>{athlete.first_name} {athlete.last_name}</Text>
                    </View>
                    : market_resolve.side_type == 'team' && team ?
                    <View nativeID='team_details' style={{ alignItems:'center', marginTop:5 }}>
                        <Text style={{ padding:10 }} size={18} color={Colors.brand.midnight} textAlign='center' weight='bold'>Team Details</Text>
                        <Text style={{ flex:1 }} size={14} color={Colors.brand.midnight}>{team.market_name} {team.name}</Text>
                    </View>
                    :<></>}
                </View>
                :<></>}
                <View nativeID='event_info' style={{ marginTop:10, padding:10, backgroundColor:Colors.shades.shade100 }}>
                    <Text style={{ padding:10 }} size={18} color={Colors.brand.midnight} textAlign='center' weight='bold'>Market Details</Text>
                    <Text size={14} color={Colors.brand.midnight} textAlign='center'>{market.description}</Text>
                </View>
                <View nativeID='market_side_options' style={{ marginTop:10, padding:10, backgroundColor:Colors.shades.shade100 }}>
                <Text style={{ padding:10 }} size={18} color={Colors.brand.midnight} textAlign='center' weight='bold'>Select Winning Side</Text>
                    <View style={{ alignItems:'center' }}>
                        <FlatList
                            data={side_options}
                            renderItem={renderSideOptions}
                            horizontal
                        />
                    </View>
                    {selected_side == 'draw' ?
                    <View>
                        <View nativeID='manual_only_switch' style={{ flexDirection:'row', padding:10, marginTop:10, backgroundColor:Colors.shades.shade100 }}>
                            <View style={{ flex:4/5 }}>
                                <Text size={14} color={Colors.brand.midnight} weight='semibold'>No Bet</Text>
                                <Text size={12} color={Colors.brand.slate} weight='regular'>Is this market void due to a general rule violation?</Text>
                            </View>
                            <View style={{ flex:1/5, justifyContent:'center', alignItems:'flex-end' }}>
                                <Switch
                                    switch_type='on_off'
                                    value={market_resolve.no_bet?true:false}
                                    onChange={(value) => dispatch(market_updateMarketResolve({ ...market_resolve, no_bet:value, updated:market_resolve.updated + 1 }))}
                                />
                            </View>
                        </View>
                        {market_resolve.no_bet ?
                        <View nativeID='response value' style={{ padding:10 }}>
                            <Text size={14} color={Colors.brand.midnight} weight='bold'>No Bet Reason</Text>
                            <Text style={{ marginTop:1 }} size={12} color={Colors.brand.slate} weight='regular'>Which general rule caused this market to no bet?</Text>
                            <TextInput
                                style={{ flex:3/4, marginTop:10, padding:10, borderRadius:8, borderWidth:1, borderColor:Colors.brand.slate, fontFamily: 'barlow-regular', fontSize:14 }}
                                placeholder={'DNP'}
                                placeholderTextColor={Colors.brand.slate}
                                value={market_resolve?.no_bet_reason??''}
                                onChangeText={(text) => dispatch(market_updateMarketResolve({ ...market_resolve, no_bet_reason:text, updated:market_resolve.updated + 1 }))}
                            />
                        </View>
                        :<></>}
                    </View>
                    :<></>}
                </View>
                <View nativeID='actions' style={{ flexDirection:'row', marginTop:10 }}>
                    <TouchableOpacity style={{ flex:1/3, marginRight:4, borderRadius:4, borderColor:Colors.brand.electric, borderWidth:1, padding:10 }} onPress={() => dispatch(market_updateMarketResolve(undefined))}>
                        <Text size={14} color={Colors.brand.electric} weight='semibold' textAlign='center'>Cancel</Text>
                    </TouchableOpacity>
                    <TouchableOpacity disabled={!selected_side || loading} style={{ flex:2/3, marginLeft:4, borderRadius:4, padding:10, backgroundColor:Colors.utility.success, opacity:selected_side?1:0.5 }} onPress={() => resolveMarket()}>
                        {loading ?
                        <ActivityIndicator size='small' color={Colors.shades.white}/>
                        :
                        <Text size={14} color={Colors.shades.white} weight='semibold' textAlign='center'>Resolve</Text>
                        }
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}

export default MarketResolveForm