import { SocialReducerProps, V1_LOAD_ARTICLES, V1_LOAD_ARTICLE_FEEDS, V1_LOAD_FEED_POSTS, V1_LOAD_FULL_SIZE_IMAGE, V1_LOAD_GLOBAL_VIDEO, V1_LOAD_GROUP, V1_LOAD_HOT_POSTS, V1_LOAD_LINKS, V1_LOAD_LIVE_FEEDS, V1_LOAD_MY_POST_REACTIONS, V1_LOAD_NEW_PLAYERS, V1_LOAD_PLAYERS, V1_LOAD_PLAYER_LINKS, V1_LOAD_PLAYER_TAGS, V1_LOAD_PODCASTS, V1_LOAD_POST_COMMENTS, V1_LOAD_RECENT_ARTICLES, V1_LOAD_RECENT_PODCASTS, V1_LOAD_SEARCHED_PLAYERS, V1_LOAD_SUGGESTED_PLAYERS, V1_LOAD_TAGS, V1_LOAD_VIEWING_EPISODES, V1_LOAD_VIEWING_FOLLOWER_STATS, V1_LOAD_VIEWING_ORDERS, V1_LOAD_VIEWING_PLAYER, V1_LOAD_VIEWING_PLAYER_FOLLOWERS, V1_LOAD_VIEWING_PLAYER_LINKS, V1_LOAD_VIEWING_PLAYER_ORDERS, V1_LOAD_VIEWING_PLAYER_POSTS, V1_LOAD_VIEWING_PLAYER_TAGS, V1_LOGOUT, V1_UPDATE_AD, V1_UPDATE_ARTICLES, V1_UPDATE_GROUP_MESSAGE, V1_UPDATE_LIVE_FEED, V1_UPDATE_MY_POST_REACTIONS, V1_UPDATE_PLAYER_FOLLOWERS, V1_UPDATE_PLAYER_LINK, V1_UPDATE_PLAYER_TAG, V1_UPDATE_PLAYING_PODCAST, V1_UPDATE_POST, V1_UPDATE_POST_COMMENTS, V1_UPDATE_POST_COMMENT_STAT, V1_UPDATE_POST_REACTION_STATS } from "../actionsv1/types"
import { PublicPlayerProps } from "../types/auth_svc"
import { OrderProps } from "../types/mk_svc"
import { ArticleFeedProps, ArticleProps, PodcastProps, PostProps, PostReactionProps, PostReactionStatsProps } from "../types/soc_svc"

const DEFAULT_STATE:SocialReducerProps = {
    stored_players: [],
    searched_players: [],
    suggested_players: [],
    new_players: [],
    stored_posts: [],
    stored_post_comments: [],
    viewing_player_followers: [],
    viewing_player_follower_stats: undefined,
    viewing_player_posts: [],
    viewing_player_orders: [],
    viewing_orders:[],
    viewing_player_tags: [],
    viewing_player_links:[],
    stored_podcasts: [],
    stored_articles: [],
    stored_article_feeds: [],
    recent_articles: [],
    recent_podcasts:[],
    viewing_episodes: [],
    playing_podcast_episode: undefined,
    group:undefined,
    group_messages:[],
    group_players:[],
    articles_list: {
        updated:0,
        articles: []
    },
    live_feeds_list: {
        updated: 0,
        article_feeds: []
    },
    stored_ads: [],
    full_size_image:undefined,
    feed_posts: [],
    hot_posts: [],
    player_followers: [],
    links: [],
    player_links: [],
    player_tags: [],
    post_reaction_stats: [],
    post_comment_stats: [],
    my_post_reactions: [],
    tags: [],
}

const socialReducer = (state = DEFAULT_STATE, action:any) => {
    switch(action.type){
        case V1_LOAD_PLAYERS:
            return {
                ...state,
                stored_players: state.stored_players.filter(sp => !action.players.find((p:PublicPlayerProps) => p.player_id == sp.player_id)).concat(action.players)
            }
        case V1_LOAD_SUGGESTED_PLAYERS:
            return {
                ...state,
                suggested_players: action.player_ids
            }
        case V1_LOAD_SEARCHED_PLAYERS:
            return {
                ...state,
                searched_players: action.offset === 0 ? action.player_ids : state.searched_players.concat(action.player_ids)
            }
        case V1_LOAD_NEW_PLAYERS:
            return {
                ...state,
                new_players: action.offset === 0 ? action.player_ids : state.new_players.concat(action.player_ids)
            }
        case V1_LOAD_VIEWING_FOLLOWER_STATS:
            return {
                ...state,
                viewing_player_follower_stats: action.player_follower_stats
            }
        case V1_LOAD_VIEWING_PLAYER_POSTS:
            return {
                ...state,
                viewing_player_posts: action.offset === 0 ? action.posts.map((p:PostProps) => p.memo_post_id) : state.viewing_player_posts.concat(action.posts.map((p:PostProps) => p.memo_post_id)),
                stored_posts: state.stored_posts.filter(p => !action.posts.find((fp:PostProps) => fp.memo_post_id == p.memo_post_id)).concat(action.posts)
            }
        case V1_LOAD_FEED_POSTS:
            return {
                ...state,
                feed_posts: action.offset === 0 ? action.feed_posts.map((p:PostProps) => p.memo_post_id) : state.feed_posts.concat(action.feed_posts.map((p:PostProps) => p.memo_post_id)),
                stored_posts: state.stored_posts.filter(p => !action.feed_posts.find((fp:PostProps) => fp.memo_post_id == p.memo_post_id)).concat(action.feed_posts)
            }
        case V1_LOAD_HOT_POSTS:
            return {
                ...state,
                hot_posts: action.offset === 0 ? action.hot_posts.map((p:PostProps) => p.memo_post_id) : state.hot_posts.concat(action.hot_posts.map((p:PostProps) => p.memo_post_id)),
                stored_posts: state.stored_posts.filter(p => !action.hot_posts.find((fp:PostProps) => fp.memo_post_id == p.memo_post_id)).concat(action.hot_posts)
            }
        case V1_UPDATE_POST:
            return {
                ...state,
                stored_posts: state.stored_posts.filter(p => p.memo_post_id != action.post.memo_post_id).concat(action.post)
            }
        case V1_UPDATE_PLAYER_FOLLOWERS:
            return {
                ...state,
                player_followers: state.player_followers.filter(pf => pf.player_follower_id !== action.player_follower.player_follower_id).concat(action.player_follower)
            }
        case V1_UPDATE_POST_REACTION_STATS: 
            return {
                ...state,
                post_reaction_stats: state.post_reaction_stats.filter(pr => !action.post_reaction_stats.find((prs:PostReactionStatsProps) => prs.activity_post_id == pr.activity_post_id)).concat(action.post_reaction_stats)
            }
        case V1_UPDATE_POST_COMMENT_STAT:
            return {
                ...state,
                post_comment_stats: state.post_comment_stats.filter(pcs => pcs.activity_post_id != action.post_comment_stat.activity_post_id).concat(action.post_comment_stat)
            }
        case V1_LOAD_MY_POST_REACTIONS:
            return {
                ...state,
                my_post_reactions: state.my_post_reactions.filter(pr => pr.activity_post_id != action.post_id).concat(action.post_reactions)
            }
        case V1_UPDATE_MY_POST_REACTIONS:
            return {
                ...state,
                my_post_reactions: state.my_post_reactions.filter(pr => pr.post_reaction_id != action.post_reaction.post_reaction_id).concat(action.post_reaction)
            }
        case V1_LOAD_VIEWING_ORDERS:
            return {
                ...state,
                viewing_orders: state.viewing_orders.filter(o => !action.orders.find((or:OrderProps) => or.order_id == o.order_id)).concat(action.orders)
            }
        case V1_LOAD_VIEWING_PLAYER_ORDERS:
            return {
                ...state,
                viewing_player_orders: action.offset === 0 ? action.orders : state.viewing_player_orders.concat(action.orders)
            }
        case V1_LOAD_POST_COMMENTS:
            return {
                ...state,
                stored_post_comments: action.post_comments
            }
        case V1_UPDATE_POST_COMMENTS:
            return {
                ...state,
                stored_post_comments: state.stored_post_comments.filter(pc => pc.post_comment_id != action.post_comment.post_comment_id).concat(action.post_comment)
            }
        case V1_LOAD_VIEWING_PLAYER_FOLLOWERS:
            return {
                ...state,
                viewing_player_followers: action.player_followers
            }
        case V1_LOAD_TAGS:
            return {
                ...state,
                tags: action.tags,
                player_tags: state.player_tags.filter(pt => !action.player_tags.find(apt => apt.player_tag_id == pt.player_tag_id)).concat(action.player_tags)
            }
        case V1_LOAD_PLAYER_TAGS:
            return {
                ...state,
                player_tags: action.player_tags
            }
        case V1_UPDATE_PLAYER_TAG:
            return {
                ...state,
                player_tags: state.player_tags.filter(pt => pt.tag_id !== action.player_tag.tag_id).concat(action.player_tag)
            }
        case V1_LOAD_VIEWING_PLAYER_TAGS:
            return {
                ...state,
                viewing_player_tags: action.player_tags
            }
        case V1_LOAD_PLAYER_LINKS:
            return {
                ...state,
                player_links: action.player_links
            }
        case V1_UPDATE_PLAYER_LINK:
            return {
                ...state,
                player_links: state.player_links.filter(pl => pl.player_link_id != action.player_link.player_link_id).concat(action.player_link)
            }
        case V1_LOAD_VIEWING_PLAYER_LINKS:
            return {
                ...state,
                viewing_player_links: action.player_links
            }
        case V1_LOAD_LINKS:
            return {
                ...state,
                links: action.links
            }
        case V1_LOAD_GROUP:
            return {
                ...state,
                group: action.group,
                group_players:action.group_players,
                group_messages: action.group_messages
            }
        case V1_UPDATE_GROUP_MESSAGE:
            if(action.group_message.group_id != state.group?.group_id){ return state } //Ignore if no longer in that group
            return {
                ...state,
                group_messages: state.group_messages.filter(gp => gp.group_message_id != action.group_message.group_message_id).concat(action.group_message)
            }
        case V1_LOAD_PODCASTS:
            return {
                ...state,
                stored_podcasts: action.offset === 0 ? action.podcasts : state.stored_podcasts.filter(sp => !action.podcasts.find((p:PodcastProps) => p.podcast_id == sp.podcast_id)).concat(action.podcasts)
            }
        case V1_LOAD_RECENT_PODCASTS:
            return {
                ...state,
                recent_podcasts: action.recent_podcast_ids
            }
        case V1_LOAD_ARTICLES:
            return {
                ...state,
                stored_articles: action.offset === 0 ? action.articles : state.stored_articles.filter(sp => !action.articles.find((p:ArticleProps) => p.article_id == sp.article_id)).concat(action.articles)
            }
        case V1_UPDATE_ARTICLES:
            return {
                ...state,
                articles_list: {
                    updated: state.articles_list.updated + 1,
                    articles: state.articles_list.articles.filter(a => !action.articles.find((na:ArticleProps) => na.article_id == a.article_id)).concat(action.articles)
                }
            }
        case V1_LOAD_ARTICLE_FEEDS:
            return {
                ...state,
                stored_article_feeds: action.offset === 0 ? action.article_feeds : state.stored_article_feeds.filter(sp => !action.article_feeds.find((p:ArticleFeedProps) => p.article_feed_id == sp.article_feed_id)).concat(action.article_feeds)
            }
        case V1_LOAD_RECENT_ARTICLES:
            return {
                ...state,
                recent_articles: action.recent_article_ids
            }
        case V1_LOAD_VIEWING_EPISODES:
            return {
                ...state,
                viewing_episodes: action.offset === 0 ? action.podcast_episodes : state.viewing_episodes.concat(action.podcast_episodes)
            }
        case V1_UPDATE_PLAYING_PODCAST:
            return {
                ...state,
                playing_podcast_episode: action.podcast_episode
            }
        case V1_UPDATE_AD:
            return {
                ...state,
                stored_ads: state.stored_ads.filter(a => a.company_ad_id != action.ad.company_ad_id).concat(action.ad)
            }
        case V1_LOAD_LIVE_FEEDS:
            return {
                ...state,
                live_feeds_list: {
                    updated: state.live_feeds_list.updated + 1,
                    article_feeds: action.article_feeds
                }
            }
        case V1_UPDATE_LIVE_FEED:
            return {
                ...state,
                live_feeds_list: {
                    updated: state.live_feeds_list.updated + 1,
                    article_feeds: state.live_feeds_list.article_feeds.filter(f => f.article_feed_id != action.article_feed.article_feed_id).concat(action.article_feed)
                }
            }
        case V1_LOAD_FULL_SIZE_IMAGE:
            return {
                ...state,
                full_size_image: action.full_size_image
            }
        case V1_LOGOUT: return DEFAULT_STATE
        default:
            return state
    }
 }
 
 export default socialReducer