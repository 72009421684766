import React, { useEffect, useState } from 'react';
import { ActivityIndicator, View } from "react-native";
import { useDispatch, useSelector } from 'react-redux';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { fit } from "@cloudinary/base/actions/resize";
import { Cloudinary, CloudinaryImage } from "@cloudinary/base";
import { AdvancedImage } from '@cloudinary/react';
import Colors from '../constants/Colorsv2';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { V1_LOAD_FULL_SIZE_IMAGE } from '../store/actionsv1/types';
import { quality } from '@cloudinary/base/actions/delivery';

const cld = new Cloudinary({
    cloud: {
      cloudName: 'hoabts6mc'
    },
    url: {
      secure:true
    }
  });


const FullSizeImage = ({}) => {
    const [ loading, setLoading ] = useState(false);
    const [ cld_image, setCLDImage ] = useState<CloudinaryImage | undefined>(undefined);
    const dispatch = useDispatch();
    const full_size_image:any = useSelector((state:any) => state.social.full_size_image, (left, right) => left?.public_id == right?.public_id);
    const app_size:{ width:number, height:number } = useSelector((state:any) => state.util.app_size, (left, right) => left.width == right.width)

    const getPredominentColor = (imageElement:any) => {
        if(!imageElement.colors || imageElement.colors?.length === 0){ return Colors.brand.slate }
        return imageElement.colors[0][0]
    }

    useEffect(() => {
        if(!full_size_image?.public_id){ return }
        console.log('DOING SOMETIME!!!')
        loadImage(full_size_image.public_id)
    },[full_size_image?.public_id])

    const loadImage = async(public_id:string) => {
        setLoading(true)
        let new_image = await cld.image(public_id)
        .resize(fit().width(app_size.width - 20).height(app_size.height - 20)).delivery(quality('auto'))
        setCLDImage(new_image)
        setLoading(false)
    }

    const handleCloseImage = () => {
        dispatch({ type: V1_LOAD_FULL_SIZE_IMAGE, full_size_image: undefined })
        setCLDImage(undefined)
    }

    const insets = useSafeAreaInsets();

    if(!full_size_image){ return <></> }
    return (
        <View style={{ position:'absolute', justifyContent:'center', alignItems:'center', top:0, right:0, left:0, bottom: 0, backgroundColor:'rgba(242,245,247,0.9)' }}>
            { loading || !cld_image ?
            <ActivityIndicator size='large' color={Colors.brand.midnight} />
            :<></>}
            <AdvancedImage cldImg={cld_image}/>
            <View style={{ position:'absolute', bottom:insets.bottom + 10, right:0, left:0, justifyContent:'center', alignItems:'center' }}>
                <TouchableOpacity style={{ borderRadius: 20, padding:10, backgroundColor:Colors.utility.error }} onPress={() => handleCloseImage()}>
                    <Text size={16} color={Colors.shades.white} weight='semibold' style={{ marginRight:20, marginLeft:20 }}>HIDE IMAGE</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default FullSizeImage