import React, { useState } from 'react';
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useDispatch, useSelector } from "react-redux"
import Colors from "../constants/Colorsv2";
import { Image, View } from "react-native";
import { MyPlayerProps } from "../store/types/auth_svc";
import { auth_agreeToTerms } from '../store/actionsv1/auth';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { ActivityIndicator } from 'react-native';



const TermsModal = () => {
    const [ agreed, setAgreed ] = useState(false);
    
    const dispatch = useDispatch();
    const insets = useSafeAreaInsets();
    const player:MyPlayerProps = useSelector((state:any) => state.player.player, (left, right) => left?.updated == right?.updated);

    const agreeToTerms = async() => {
        if(!agreed){ return alert('Please check the box before submitting') }
        await dispatch(auth_agreeToTerms())
    }

    if(!player || player.terms_agreed_on){ return <></> }
    return (
        <View style={{ position:'absolute', top:0, bottom:0, left:0, right:0, padding:20, backgroundColor:Colors.shades.black_faded, alignItems:'center', justifyContent:'center' }}>
            <View style={{ minHeight:300, minWidth:300, maxWidth:450, borderRadius:22, shadowColor: "#000", shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.25, shadowRadius: 3.84, elevation: 5 }}>
                <View nativeID='be_logo' style={{ padding:15, borderTopRightRadius:22, borderTopLeftRadius:22, backgroundColor:Colors.brand.cobalt }}>
                    <Image
                        source={{ uri: require('../assets/images/be_descriptor.webp') }}
                        style={{ width: 200, height:45, alignSelf:'center' }}
                        resizeMode='center'
                    />
                </View>
                <View style={{ flex:1, backgroundColor:Colors.shades.white, borderBottomRightRadius:22, borderBottomLeftRadius:22, padding:10 }}>
                    <View style={{ flex:1 }}>
                        <Text size={16} color={Colors.brand.midnight} textAlign='center' weight='bold'>We Have Updated Our Terms & Conditions</Text>
                        <TouchableOpacity style={{ padding:10, marginTop:20 }} onPress={() => window.open(`https://www.bettoredge.com/terms-of-use`, '_blank')}>
                            <Text size={14} color={Colors.brand.electric} textAlign='center'>REVIEW HERE</Text>
                        </TouchableOpacity>
                    </View>
                    <View>
                        <TouchableOpacity native_id='terms_agree_checkbox' style={{ flexDirection:'row', alignItems:'center', padding:10 }} onPress={() => setAgreed(!agreed)}>
                            <View style={{ height:22, width:22, marginRight:10, borderColor:Colors.brand.midnight, borderWidth:1, justifyContent:'center', alignItems:'center' }}>
                                {agreed ?
                                <View style={{ height:16, width:16, backgroundColor:Colors.brand.midnight }} />
                                :<></>}
                            </View>
                            <Text size={12} color={Colors.brand.midnight}>By checking this box, I confirm that I understand and agree to all of the terms and conditions set forth by BettorEdge.</Text>
                        </TouchableOpacity>
                        <TouchableOpacity disabled={!agreed} style={{ margin:10, backgroundColor:Colors.utility.success, padding:13, borderRadius:8, opacity:!agreed?0.5:1  }}
                        onPress={() => agreeToTerms()}>
                            <Text size={14} color={Colors.shades.white} weight='bold' textAlign='center'>SUBMIT</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default TermsModal