import React, { useEffect, useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from 'react-redux';
import Colors from '../constants/Colorsv2';
import { Text, TouchableOpacity } from '../globalComponents/NativeOverrides';
import { checkSocketConnection, socket_connect } from '../store/actionsv1/socket';


const AppNofityBanner = ({}) => {
    const connected = useSelector((state:any) => state.util.socket_connected, (left, right) => left == right)
    const insets = useSafeAreaInsets()

    
    const handlePress = () => {
        socket_connect()
    }

    if(connected){ return <></> }
    return (
        <TouchableOpacity style={{ position:'absolute', bottom:insets.bottom + 40, right:10, padding:20 }} onPress={() => handlePress()}>
            <ActivityIndicator size='large' color={Colors.brand.midnight}/>
        </TouchableOpacity>
    )
}

export default AppNofityBanner