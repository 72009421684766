import { CHANGE_PREFERENCE, LOAD_LOCATIONS, CHANGE_ROUTE_NAME, LAUNCH_GUIDE, LOGOUT, LAUNCH_INFO, CHANGE_MODAL_NAME, LOAD_CHARITIES, SET_HEADER_HEIGHT, SET_AUDIO_HEIGHT } from '../actions/types';

const DEFAULT_STATE = {
    market_type: 'FREE',
    locations: [],
    charities: [],
    routeName: '',
    modalName: '',
    homeTour: false,
    marketTour: false,
    orderTour: false,
    walletTour: false,
    vouchTour: false,
    info: null,
    headerHeight:0
}

const authReducer = (state = DEFAULT_STATE, action) => {
   switch(action.type){
        case CHANGE_PREFERENCE:
            return {
                ...state,
                [action.preference] : action.setting
            }
        case LOAD_LOCATIONS:
            return {
                ...state,
                locations: action.locations
            }
        case CHANGE_ROUTE_NAME:
            return {
                ...state,
                routeName: action.routeName
            }
        case CHANGE_MODAL_NAME:
            return {
                ...state,
                modalName: action.modalName
            }
        case LAUNCH_GUIDE:
            return {
                ...state,
                [action.guide]: action.is_active
            }
        case LAUNCH_INFO:
            return {
                ...state,
                info: action.info
            }
        case LOAD_CHARITIES: 
            return {
                ...state,
                charities: action.charities
            }
        case SET_HEADER_HEIGHT:
            return {
                ...state,
                headerHeight: action.height
            }
        
        case LOGOUT:
            return DEFAULT_STATE
       default:
           return state
   }
}

export default authReducer