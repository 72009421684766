import React, { useEffect, useRef, useState } from 'react';
import { View, Image, TouchableOpacity } from 'react-native';
import { Text } from '../../../globalComponents/NativeOverrides';
import { connect, useDispatch, useSelector } from 'react-redux';
import Colors from '../../../constants/Colorsv2';
import { PodcastEpisodeProps } from '../../../store/types/soc_svc';
import { util_updateAudioHeight } from '../../../store/actionsv1/utils';
import { getPodEpisodeImage } from '../../../screensV1/Social/helpers/podcasts';
import { navigate } from '../../../navigation/RootNavigation';
import { Icon } from 'react-native-elements';
import { social_updatePlayingPodcastEpisode } from '../../../store/actionsv1/social';

type PlaybackBarProps = { navigation:any, header_height:number, audio_height:number}
const PlaybackBar = ({ navigation, header_height, audio_height }: PlaybackBarProps) => {
    const [ player_controls, setPlayerControls ] = useState({ isPlaying:false })
    const [ playing, setPlaying ] = useState(false);
    const [ playerSize, setPlayerSize ] = useState({ width:0, height:0, barWidth:0, startOffset:0, fullOffset:0, fullWidth:0});
    const [ playerPosition, setPlayerPosition ] = useState(0);
    const [ bar_size, setBarSize ] = useState({ bar_width:0, full_offset: 0, start_offset:0 });
    const [ audioDuration, setAudioDuration ] = useState(0);
    const [ audioTimes, setAudioTimes ] = useState({ currentTime:'0:00', timeRemaining:'0:00' })
    const dispatch = useDispatch();
    const playing_podcast_episode:PodcastEpisodeProps | undefined = useSelector((state:any) => state.social.playing_podcast_episode, (left, right) => left?.podcast_episode_id == right?.podcast_episode_id)
    const audioRef = useRef(null)

    useEffect(() => {
        if(!playing_podcast_episode || !audioRef?.current){ return }
        if(!player_controls.isPlaying ){ 
            audioRef.current.play() 
            setPlaying(true)
        }
        if(player_controls.isPlaying){ 
            audioRef.current.pause() 
            setPlaying(false)
        }
    },[playing_podcast_episode])

    const handleChangePosition = (x:number) => {
        if(!x){ return }
        if(!audioRef?.current){ return }
        //Get position in the bar by removing of the front non bar real estate
        const positionInBar = x - bar_size.full_offset
        //Get pct of the bar that we went
        const pctIntoBar = positionInBar / bar_size.bar_width
        //Use pct to move same pct of duration
        const newTime = pctIntoBar * audioDuration
        audioRef.current.currentTime = newTime
    }
    const handleSkipAhead = () => {
        if(!audioRef?.current){ return }
        let is_zero = audioRef.current?.currentTime - 15
        if(is_zero < 0){ audioRef.current.currentTime = 0 }
        else { audioRef.current.currentTime -= 15 }
    }
    const handleDurationChange = () => {
        if(!audioRef?.current){ return }
        setAudioDuration(audioRef.current.duration)
    }
    const handlePause = () => {
        if(!audioRef?.current){ return }
        audioRef.current.pause()
        setPlaying(false)
    }
    const handlePlay = () => {
        if(!audioRef?.current){ return }
        audioRef.current.play()
        setPlaying(true)
    }
    const handleTimeUpdate = () => {
        if(!audioRef?.current){ return }
        if(audioDuration === 0){ return }
        const moveExponent = bar_size.bar_width / audioDuration
        const currTime = audioRef.current.currentTime
        setPlayerPosition(currTime * moveExponent)
        let remainingMinutes = Math.floor((audioDuration - currTime) / 60).toFixed()
        let remainingSeconds = ((audioDuration - currTime) % 60).toFixed()
        if(remainingSeconds.length === 1){ remainingSeconds = `0${remainingSeconds}` }
        if(remainingSeconds == '60'){ remainingSeconds = '00' }
        //if(remainingMinutes.length === 1){ return `0${remainingMinutes}` }
        let timeInMinutes = Math.floor((currTime / 60)).toFixed()
        let timeInSeconds = (currTime % 60).toFixed()
        if(timeInSeconds.length === 1){ timeInSeconds = `0${timeInSeconds}` }
        if(timeInMinutes.length === 1){ timeInMinutes = `0${timeInMinutes}` }
        setAudioTimes({ currentTime:`${timeInMinutes}:${timeInSeconds}`, timeRemaining: `${remainingMinutes}:${remainingSeconds}` })
    }
    //console.log(audioRef.current?.currentTime)
    //console.log(audioDuration)
    if(!playing_podcast_episode){ return (<></>) }
    let playVisible = true
    if(audioRef?.current && playing){ playVisible = false }
    let title = playing_podcast_episode.title??'Podcast'
    if(title.length > 30){ title = `${title.substring(0, 30)}...` }
    return (
        <View style={{ flexDirection:'row', alignItems: 'center', position:'absolute', top: header_height, left:0, right:0, backgroundColor:Colors.brand.new, paddingLeft:6, paddingRight:6 }}
        onLayout={(ev) => {
            const height = ev.nativeEvent.layout.height
            setPlayerSize({ ...playerSize, fullWidth:ev.nativeEvent.layout.width })
            if(height == 0 || height == audio_height){ return }
            return dispatch(util_updateAudioHeight(height))
        }}>
            <TouchableOpacity onPress={() => navigate('SocialStack', { screen: 'PodcastEpisodes', initial:false, params: { podcast_id: playing_podcast_episode.podcast_id } })}>
                <Image style={{ height: playerSize.fullWidth * 0.1, width:playerSize.fullWidth * 0.1, borderRadius:4 }} source={getPodEpisodeImage(playing_podcast_episode)} />
            </TouchableOpacity>
            <View style={{flex:1, margin:10, marginLeft:8, marginRight:20}}>
                <Text size={12} weight='semibold' color={Colors.shades.white} textAlign='center'>{title}</Text>
                <View nativeID='play-bar' style={{ flexDirection:'row', alignItems:'center', marginTop:10, marginBottom:5}} onLayout={(e) => {
                    setPlayerSize({ ...playerSize, height:e.nativeEvent.layout.height,  width: e.nativeEvent.layout.width })
                    }}>
                    <Text size={12} weight='regular' color={Colors.shades.white}>{audioTimes.currentTime}</Text>
                    <TouchableOpacity style={{flex:1, marginRight:10, marginLeft:10}} 
                    onPress={(ev) => handleChangePosition(ev.nativeEvent.x - 5.5)}
                    onLayout={(e) => {
                        if(Math.abs(bar_size.bar_width - e.nativeEvent.layout.width) < 10){ return }
                        console.log(e.nativeEvent.layout.left)
                        setBarSize({ bar_width: e.nativeEvent.layout.width, start_offset:e.nativeEvent.layout.x, full_offset: e.nativeEvent.layout.left??0 })
                    }}>
                        <View style={{ position:'absolute', top:0, bottom:0, right:0, left:0, borderBottomWidth:2, borderColor:'#7F9AB2'}} />
                    </TouchableOpacity>
                    <Text size={12} weight='regular' color={Colors.shades.white}>-{audioTimes.timeRemaining}</Text>
                    <View style={{position:'absolute', width:11, height:11, borderRadius:100, left:bar_size.start_offset + playerPosition, backgroundColor:Colors.shades.white}} />
                </View>
            </View>
            <View style={{padding:5, flexDirection:'row', alignItems:'center'}}>
                <TouchableOpacity style={{padding:8}} onPress={() => handleSkipAhead()}>
                    <Image source={require('../../../assets/images/icon_skip_ahead.png')} style={{ width:16.5, height:17 }}/>
                </TouchableOpacity>
                { playVisible ? 
                <TouchableOpacity style={{padding:8}} onPress={() => handlePlay()}>
                    <Image source={require('../../../assets/images/icon_play.png')} style={{ width:10, height:12 }}/>
                </TouchableOpacity>
                :
                <TouchableOpacity style={{padding:8}} onPress={() => handlePause()}>
                    <Image source={require('../../../assets/images/icon_pause.png')} style={{ width:10, height:17 }}/>
                </TouchableOpacity>
                }
                <TouchableOpacity style={{padding:8}} onPress={() => { 
                    audioRef?.current.pause()
                    dispatch(social_updatePlayingPodcastEpisode(undefined))
                    dispatch(util_updateAudioHeight(0))
                }}>
                    <Icon name='x-circle' type='feather' size={16} color={Colors.utility.error} /> 
                </TouchableOpacity>
            </View>
            <audio ref={audioRef} onTimeUpdate={() => handleTimeUpdate()} onDurationChange={() => handleDurationChange()} playsInline src={playing_podcast_episode.url} autoPlay/>
        </View>
    )
} 

const mapStateToProps = (state:any) => {
    return {
        header_height: state.util.header_height,
        audio_height: state.util.audio_height
    }
}

export default connect(mapStateToProps, null)(React.memo(PlaybackBar))