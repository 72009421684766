import React from 'react';
import { View } from 'react-native';
import { Components, ProfileManager, LinearGradient, BEEventApi, APIOverrides } from "be-components"
import { useDispatch, useSelector } from "react-redux"
import Colors from '../constants/Colorsv2';
import { V1_SHOW_PROFILE, V1_UPDATE_ME } from '../store/actionsv1/types';
import { MyPlayerProps } from '../store/types/auth_svc';
import { auth_logout } from '../store/actionsv1/auth';
import { useSafeAreaInsets } from 'react-native-safe-area-context';


type ProfileModalProps = {
   // onAuthenticated: (resp:any) => void
}
const MAX_WIDTH = 500
const ProfileModal = ({  }:ProfileModalProps) => {
    const dispatch = useDispatch();
    const app_size = useSelector((state:any) => state.util.app_size, (left, right) => left == right);
    const show_profile = useSelector((state:any) => state.util.show_profile, (left, right) => left.visible == right.visible);
    const player = useSelector((state:any) => state.player.player, (left, right) => left?.updated == right?.updated);
    const justifyContent = app_size.width > MAX_WIDTH ? 'center' : 'flex-end'
    const profile_width = app_size.width > MAX_WIDTH ? MAX_WIDTH : app_size.width

    const insets = useSafeAreaInsets();

    const handlePlayerUpdate = async(p:MyPlayerProps, attribute?:string) => {
        dispatch({ type:V1_UPDATE_ME, p })
        await BEEventApi.saveEvent({
            event_name: 'player_update',
            event_data: {
                attribute
            },
            level:1
        })
    }

    const handleLogout = async() => {
        await APIOverrides.logoutApp();
        dispatch(auth_logout())
    }

    if(!show_profile.visible){ return <></> }

    return (
        <View style={{ position:'absolute', left:0, bottom:0, right:0, top:false?0:undefined, justifyContent, backgroundColor:Colors.shades.black_faded}}>
            <Components.Spring
                to={0}
                from={1000}
                slide='vertical'
                style={{ mass:1, friction:200 }}
                >
                <View style={{ alignItems:'center' }}>
                <LinearGradient
                    style={{ 
                        height:app_size.height,
                        width:profile_width, 
                        backgroundColor:Colors.shades.white,
                        paddingTop: justifyContent!='center' && show_profile.visible ? insets.top : 0,
                        paddingBottom:justifyContent != 'center' && show_profile.visible ? insets.bottom: 0,
                        borderTopRightRadius:justifyContent == 'center' ? 22 : 0, 
                        borderTopLeftRadius:justifyContent == 'center' ? 22 : 0, 
                        borderBottomLeftRadius:justifyContent == 'center' ? 22 : 0,
                        borderBottomRightRadius:justifyContent == 'center' ? 22 : 0

                    }}
                    colors={[Colors.shades.shade100, Colors.shades.shade600]}
            >
                        <ProfileManager
                            onPlayerUpdate={(p, attribute) => handlePlayerUpdate(p, attribute)}
                            show_welcome={show_profile.show_welcome}
                            hide_powered_by
                            onNextStep={(step) => BEEventApi.saveEvent({
                                event_name: 'walkthrough_next',
                                event_data: {
                                    step
                                },
                                level:1
                            })}
                            onVouchStart={() => BEEventApi.saveEvent({
                                event_name: 'vouch_start',
                                event_data: {},
                                level:1
                            })}
                            player={player}
                            walkthrough={show_profile.walkthrough}
                            profile_attribute={show_profile.profile_attribute}
                            width={profile_width}
                            onClose={() => dispatch({ type:V1_SHOW_PROFILE, show_profile: { visible:false } })}
                            onLogout={() => handleLogout()}
                        />
                    </LinearGradient>
                </View>
            </Components.Spring>
        </View>
    )
}

export default ProfileModal