import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import Colors from '../../../constants/Colorsv2';

type SwitchType = { value: boolean, switch_type: 'binary' | 'on_off', onChange:(value:boolean) => void }
function Switch({ value, switch_type, onChange }:SwitchType) {

    const handleChange = () => {
        if(onChange){
            onChange(!value)
        }
    }

    if(switch_type === 'on_off'){
        return (
            <TouchableOpacity style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', backgroundColor:value?Colors.highlights.highlight400:Colors.brand.slate, padding:3, borderRadius:12, width:48,height:24}} onPress={() => handleChange()}>
                { value ? 
                    <>
                    <View />
                    <View style={{ borderRadius:100, width:18, height:18, backgroundColor:Colors.shades.white}} />
                    </>
                :
                    <>
                    <View style={{ borderRadius:100, width:18, height:18, backgroundColor:Colors.shades.white}} />
                    <View/>
                    </>
                }
            </TouchableOpacity>
        )
    }
    return (
        <TouchableOpacity style={{flexDirection:'row', justifyContent:'space-between', alignItems:'center', backgroundColor:Colors.shades.white, padding:3, borderRadius:12, width:48,height:24}} onPress={() => handleChange()}>
            { value ? 
                <>
                <View />
                <View style={{ borderRadius:100, width:18, height:18, backgroundColor:Colors.highlights.highlight400}} />
            </>
            :
            <>
                <View style={{ borderRadius:100, width:18, height:18, backgroundColor:Colors.highlights.highlight400}} />
                <View />
            </>
            }
        </TouchableOpacity>
    )
    
}   

export default Switch