import React, { useEffect, useState } from 'react';
import { View } from "react-native"
import { Icon } from 'react-native-elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useDispatch, useSelector } from "react-redux"
import Colors from "../constants/Colorsv2"
import { Text, TouchableOpacity } from "../globalComponents/NativeOverrides"
import { player_clearAlert } from "../store/actionsv1/player"
import { AlertProps } from "../store/types/local"


const AlertBanner = () => {
    const [ visible_alert, setVisibleAlert ] = useState<AlertProps | undefined>(undefined)

    const dispatch = useDispatch()
    const insets = useSafeAreaInsets()

    const alerts:AlertProps[] = useSelector((state:any) => state.player.alerts, (left, right) => left.length === right.length)

    useEffect(() => {
        if(!visible_alert){ setVisibleAlert(alerts[0]) }
    },[alerts.length])

    useEffect(() => {
        if(!visible_alert){ return }
        setTimeout(() => {
            clearAlert()
        }, 5000);
    },[visible_alert])

    const clearAlert = () => {
        if(!visible_alert){ return }
        setVisibleAlert(undefined)
        dispatch(player_clearAlert(visible_alert))
    }

    const handleAlertPress = () => {
        clearAlert()
    }

    const getAlertColor = (alert:AlertProps) => {
        if(!alert){ return }
        switch(alert.type){
            case 'success': return Colors.highlights.highlight400
            case 'warning': return Colors.utility.warning
            case 'error': return Colors.utility.error
            case 'alert': return Colors.brand.electric
        }
    }

    const getAlertIcon = (alert:AlertProps) => {
        if(!alert){ return 'alert-circle' }
        switch(alert.type){
            case 'success': return 'thumbs-up'
            case 'warning': return 'alert-circle'
            case 'error': return 'thumbs-down'
            default: return 'alert-circle'
        }
    }
    if(!visible_alert){ return <></> }
    return (
        <TouchableOpacity style={{ position:'absolute', flexDirection:'row', alignItems:'center', top:0, right:0, left:0, backgroundColor:getAlertColor(visible_alert), padding:10, paddingTop:10 + insets.top }} onPress={() => handleAlertPress()}>
            <View nativeID='alert-type-image' style={{ padding:10, justifyContent:'center', alignItems:'center' }}>
                <Icon name={getAlertIcon(visible_alert)} type='feather' color={Colors.shades.white} size={18}/>
            </View>
            <View nativeID='alert-info' style={{ flex:1 }}>
                <Text size={16} color={Colors.shades.white} weight='bold'>{visible_alert.title}</Text>
                {visible_alert.body ?
                <Text size={14} color={Colors.shades.white} weight='semibold'>{visible_alert.body}</Text>
                :<></>}
            </View>
        </TouchableOpacity>
    )
}


export default AlertBanner